import Accordion from "components/Accordion";
import { constants } from "library/constants";
import { mergeStyles } from "office-ui-fabric-react";
import React from "react";
import FinanceTempAccidentFields from "./FinanceTempAccidentFields";
import FinanceTempAssistanceFields from "./FinanceTempAssistanceFields";
import FinanceTempFleetLegalFields from "./FinanceTempFleetLegalFields";
import FinanceTempHorsePowerFields from "./FinanceTempHorsePowerFields";
import FinanceTempHull from "./FinanceTempHull";
import FinanceTempLegalFields from "./FinanceTempLegalFields";
import FinanceTempMtpl from "./FinanceTempMtpl";

const containerStyle = mergeStyles({
  display: "flex",
  flexDirection: "column",
  gap: 2,
});

const FinanceTempFields = ({
  formikProps,
  userRole,
  route,
  vehicle,
  insurerList,
}) => {
  const isEditTempTradeLicensePlate =
    route === constants.editTempVehicle && vehicle?.isTradeLicensePlate;
  return (
    <div className={containerStyle}>
      <Accordion label={"bfm.vehicleForm.pivotMtpl.label"}>
        <FinanceTempMtpl
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
          insurerList={insurerList}
        />
      </Accordion>
      <Accordion label={"bfm.vehicleForm.pivotHull.label"}>
        <FinanceTempHull
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
          insurerList={insurerList}
        />
      </Accordion>
      <Accordion label={"bfm.vehicleForm.pivotAccident.label"}>
        <FinanceTempAccidentFields
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
        />
      </Accordion>
      <Accordion label={"bfm.vehicleForm.pivotAssistance.label"}>
        <FinanceTempAssistanceFields
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
        />
      </Accordion>
      <Accordion label={"bfm.vehicleForm.pivotLegal.label"}>
        <FinanceTempLegalFields
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
        />
      </Accordion>
      <Accordion label={"bfm.vehicleForm.pivotFleetLegal.label"}>
        <FinanceTempFleetLegalFields
          formikProps={formikProps}
          userRole={userRole}
          route={route}
          vehicle={vehicle}
          insurerList={insurerList}
        />
      </Accordion>

      {!isEditTempTradeLicensePlate && (
        <Accordion label={"bfm.vehicleForm.pivotEnginePowerTax.label"}>
          <FinanceTempHorsePowerFields
            formikProps={formikProps}
            userRole={userRole}
            route={route}
            vehicle={vehicle}
          />
        </Accordion>
      )}
    </div>
  );
};

export default FinanceTempFields;
