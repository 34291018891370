import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ApplicationReportDialogComponent } from "components/ApplicationReportDialogComponent";
import { ChangeVehicleIssuingStatusDialogComponent } from "components/ChangeVehicleIssuingStatusDialogComponent/ChangeVehicleIssuingStatusDialogComponent";
import { ChangeVehiclePendingIssuingStatusDialogComponent } from "components/ChangeVehiclePendingIssuingStatusDialogComponent/ChangeVehiclePendingIssuingStatusDialogComponent";
import { useCurrentRoute } from "config/routes";
import { constants } from "library/constants";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { fleetFormSlice, loadFleetWithInsurersActions } from "store/fleetForm";
import { ExportExcelVehicles } from "../../components/ExportExcelVehicles";
import {
  fleetPageSlice,
  loadVehiclesActions,
  selectAllVehicles,
  selectColumnsTable,
} from "../../store/fleetPage";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import { sanitizeFileName } from "../../utils/utils";
import { CancelStatusDialog } from "./CancelStatusDialog";
import { ChangeVehicleStatusDialog } from "./ChangeVehicleStatusDialog";
import { DeleteDialog } from "./DeleteDialog";
import { DeleteTEMPDialog } from "./DeleteTEMPDialog";
import { DuplicateWithPremiumsDialog } from "./DuplicateWithPremiumsDialog";
import FleetListToolbar from "./FleetListToolbar";
import VehicleListTable from "./VehicleListTable/VehicleListTable";
import { Navigation } from "./navigation/Navigation";

export const Fleet = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const route = useCurrentRoute();
  const isFleetRoute = route === constants.fleet;
  const vehicles = useSelector(selectAllVehicles);
  const [clearAllSelectedVehicles, setClearAllSelectedVehicles] =
    useState(false);

  const detailsListColumnsRedux = useSelector(selectColumnsTable)(t);
  const refExcelExport = useRef<ExcelExport>();
  const isChangeStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehicleStatusDialog.isOpen
  );
  const isChangeIssuingStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehicleIssuingStatusDialog.isOpen
  );
  const isChangePendingIssuingStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehiclePendingIssuingStatusDialog.isOpen
  );
  const isCancelStatusDialogOpen = useSelector(
    (s) => s.fleetPage.cancelVehicleStatusDialog.isOpen
  );
  const isProrataReportDialogOpen = useSelector(
    (s) => s.fleetPage.prorataReportDialog.isOpen
  );
  const isApplicationReportDialogOpen = useSelector(
    (s) => s.fleetPage.applicationReportDialog.isOpen
  );
  const fleetName = useSelector((s) => s.fleetPage.fleet?.fleetName);
  const fleetNameFromForm = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.fleetName
  );

  const isDuplicateVehicleWithPremiumsDialogOpen = useSelector(
    (s) => s.fleetPage.duplicateVehicleWithPremiumsDialog.isOpen
  );

  const columns = useSelector((s) => s.fleetPage.columns);

  const fileName = useMemo(() => {
    return sanitizeFileName(
      "Vehicles_" +
        (fleetNameFromForm ?? "List") +
        moment(new Date()).format("_YYYY_MM_DD_HH_mm_ss")
    );
  }, [fleetNameFromForm]);

  useEffect(() => {
    return () => {
      dispatch(fleetPageSlice.actions.resetState());
      dispatch(fleetFormSlice.actions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!fleetName && !fleetNameFromForm) {
      dispatch(
        loadFleetWithInsurersActions.trigger({ fleetId, getById: true })
      );
    }
  }, [fleetId, dispatch, fleetName, fleetNameFromForm]);

  useEffect(() => {
    dispatch(
      loadVehiclesActions.trigger({
        isReset: true,
        shouldDelay: false,
      })
    );
  }, [fleetId, dispatch]);

  const detailsListColumns = useMemo(() => {
    return detailsListColumnsRedux
      ?.map((column) => {
        if (column?.key === "insuranceLineIcons") {
          return {
            ...column,
            minWidth: 320,
            maxWidth: 400,
          };
        }
        return column;
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
  }, [detailsListColumnsRedux]);

  const columnsToPassToExcel = useMemo(() => {
    return columns.filter((c) => c.isVisible && c.key !== "fleetName");
  }, [columns]);

  return (
    <>
      <Navigation refExcelExport={refExcelExport?.current} />
      <FleetListToolbar
        setClearAllSelectedVehicles={setClearAllSelectedVehicles}
      />
      <VehicleListTable
        items={vehicles}
        columns={detailsListColumns}
        setClearAllSelectedVehicles={setClearAllSelectedVehicles}
        clearAllSelectedVehicles={clearAllSelectedVehicles}
      />
      {isFleetRoute && <DeleteTEMPDialog />}
      {isFleetRoute && <DeleteDialog />}
      {isChangeIssuingStatusDialogOpen && isFleetRoute && (
        <ChangeVehicleIssuingStatusDialogComponent />
      )}
      {isChangePendingIssuingStatusDialogOpen && isFleetRoute && (
        <ChangeVehiclePendingIssuingStatusDialogComponent />
      )}
      {isChangeStatusDialogOpen && isFleetRoute && (
        <ChangeVehicleStatusDialog />
      )}
      {isDuplicateVehicleWithPremiumsDialogOpen && (
        <DuplicateWithPremiumsDialog />
      )}
      {isCancelStatusDialogOpen && isFleetRoute && <CancelStatusDialog />}

      {/* {isProrataReportDialogOpen && isFleetRoute && <ProrataReportDialog />} */}
      {isApplicationReportDialogOpen && isFleetRoute && (
        <ApplicationReportDialogComponent />
      )}

      <ExportExcelVehicles
        ref={refExcelExport}
        columns={columnsToPassToExcel.filter((col) => col.key !== "filler")}
        fileName={fileName}
      />
    </>
  );
};
