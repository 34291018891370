import {
  Timeline,
  TimelineLegendSegment,
  TimelineLegendSegmentItem,
  TimelineLegendSegmentItemType,
  TimelineSegmentType,
  TimelineType
} from "types/types";
import {
  colors,
  displayVehicleInfo,
  licenseColors,
  sharedLicenseColors,
  sharedLicenseInfoColors
} from "utils/utils";
import { PrepareTimelineParams } from "./prepareTimeline";

export const prepareLegend = (
  timeline: Timeline,
  params: PrepareTimelineParams
): TimelineLegendSegment[] => {
  const retVal: TimelineLegendSegment[] = [];
  params.types.forEach((type) => {
    retVal.push(prepareLegendSegment(type, timeline, params));
  });
  return retVal;
};

const prepareLegendSegment = (
  type: TimelineType,
  timeline: Timeline,
  params: PrepareTimelineParams
): TimelineLegendSegment => {
  const retVal: TimelineLegendSegment = {
    segmentType: type,
    title: prepareLegendSegmentTitle(type),
    items: prepareLegendSegmentItems(type, timeline, params),
  };
  return retVal;
};

const prepareLegendSegmentTitle = (type: TimelineType): string => {
  switch (type) {
    case TimelineType.VEHICLE_PREMIUM:
      return "bfm.vehiclePremiums";
    case TimelineType.VEHICLE_PREMIUM_BOOKINGS:
      return "bfm.bookingsOneTime.label";
    case TimelineType.VEHICLE_STATUS:
      return "greco.vehicleStatus";
    case TimelineType.SHARED_LICENSE_PLATE_STATUS:
      return "bfm.sharedLicensePlate";
    case TimelineType.VEHICLE_LICENSE_PLATE:
      return "bfm.vehicleForm.licensePlate.label";
    default:
      break;
  }
};
const prepareLegendSegmentItems = (
  type: TimelineType,
  timeline: Timeline,
  params: PrepareTimelineParams
): TimelineLegendSegmentItem[] => {
  switch (type) {
    case TimelineType.VEHICLE_PREMIUM:
      return prepareLegendPremiumSegmentItems(timeline);
    case TimelineType.VEHICLE_PREMIUM_BOOKINGS:
      return prepareLegendPremiumBookings(timeline);
    case TimelineType.VEHICLE_STATUS:
      return prepareLegendStatusSegmentItems(timeline);
    case TimelineType.SHARED_LICENSE_PLATE_STATUS:
      return prepareLegendSharedLicensePlateSegmentItems(timeline, params);
    case TimelineType.VEHICLE_LICENSE_PLATE:
      return prepareLegendLicensePlateSegmentItems(timeline);
    default:
      break;
  }
};

const prepareLegendPremiumSegmentItems = (
  timeline: Timeline
): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  retVal.push(...prepareLegendPremiumTable());
  retVal.push(...prepareLegendPremiumSegmentTypes());
  retVal.push(...prepareLegendPremiumInsurers(timeline));
  return retVal;
};

const prepareLegendPremiumTable = (): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  retVal.push({
    label: "bfm.legend.premium.current.label",
    itemType: TimelineLegendSegmentItemType.TEXT,
    itemAttributes: { main: "bfm.legend.premium.current.main" },
  });
  retVal.push({
    label: "bfm.legend.premium.overall.label",
    itemType: TimelineLegendSegmentItemType.TEXT,
    itemAttributes: { main: "bfm.legend.premium.overall.main" },
  });
  retVal.push({
    label: "bfm.legend.premium.totalSegments.label",
    itemType: TimelineLegendSegmentItemType.TEXT,
    itemAttributes: { main: "bfm.legend.premium.totalSegments.main" },
  });
  retVal.push({
    label: "bfm.legend.premium.totalOverall.label",
    itemType: TimelineLegendSegmentItemType.TEXT,
    itemAttributes: { main: "bfm.legend.premium.totalOverall.main" },
  });
  return retVal;
};

const prepareLegendPremiumSegmentTypes = (): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  retVal.push({
    label: "bfm.legend.premium.regularPremium.label",
    itemType: TimelineLegendSegmentItemType.PREMIUM_REGULAR,
    itemAttributes: {
      fillColor: "gray",
      strokeColor: "gray",
    },
  });
  retVal.push({
    label: "bfm.legend.premium.suspendedPremium.label",
    itemType: TimelineLegendSegmentItemType.PREMIUM_SUSPENDED,
    itemAttributes: { fillColor: "gray", strokeColor: "gray" },
  });
  retVal.push({
    label: "bfm.legend.premium.zeroPremium.label",
    itemType: TimelineLegendSegmentItemType.PREMIUM_NOTHING_TO_PAY,
    itemAttributes: { fillColor: "gray", strokeColor: "gray" },
  });
  retVal.push({
    label: "bfm.legend.premium.zeroPremiumNotMain.label",
    itemType: TimelineLegendSegmentItemType.PREMIUM_NOT_MAIN_SHARED_VEHICLE,
    itemAttributes: { fillColor: "gray", strokeColor: "gray" },
  });
  return retVal;
};

const prepareLegendPremiumInsurers = (
  timeline: Timeline
): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  const uniqueInsurers = [
    ...new Set(
      timeline.data
        .filter(
          (dataEl) =>
            (dataEl.domain.type === TimelineType.VEHICLE_PREMIUM &&
              dataEl.segmentType === TimelineSegmentType.PREMIUM) ||
            dataEl.segmentType === TimelineSegmentType.SUSPENSION
        )
        .map((dataEl) => dataEl.extraData.insurer)
    ),
  ];
  uniqueInsurers.forEach((insurer, index) => {
    retVal.push({
      label: insurer,
      itemType: TimelineLegendSegmentItemType.PREMIUM_INSURER,
      itemAttributes: {
        fillColor: colors[index%colors.length].color,
        strokeColor: colors[index%colors.length].border,
      },
    });
  });

  return retVal;
};

const prepareLegendPremiumBookings = (
  timeline: Timeline
): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  retVal.push({
    label: "VehicleIssuingStatus.CORRECT",
    itemType: TimelineLegendSegmentItemType.PREMIUM_BOOKING_CORRECT,
    itemAttributes: {
      fillColor: "#0b6a0b",
      pointColor: "green",
    },
  });
  retVal.push({
    label: "VehicleIssuingStatus.INCORRECT",
    itemType: TimelineLegendSegmentItemType.PREMIUM_BOOKING_INCORRECT,
    itemAttributes: {
      fillColor: "#a4262c",
      pointColor: "red",
    },
  });
  retVal.push({
    label: "VehicleIssuingStatus.NOT_YET_BOOKED",
    itemType: TimelineLegendSegmentItemType.PREMIUM_BOOKING_NOT_YET_BOOKED,
    itemAttributes: {
      fillColor: "lightgray",
      pointColor: "gray",
    },
  });
  retVal.push({
    label: "VehicleStatusCode.PENDING",
    itemType: TimelineLegendSegmentItemType.PREMIUM_BOOKING_PENDING,
    itemAttributes: {},
  });
  retVal.push({
    label: "bfm.bookings.legend.plusminus.label",
    itemType: TimelineLegendSegmentItemType.PREMIUM_BOOKING_PLUSMINUS,
    itemAttributes: {},
  });
  retVal.push({
    label: "bfm.bookings.legend.onetimepremium.label",
    itemType: TimelineLegendSegmentItemType.PREMIUM_BOOKING_ONETIMEPREMIUM,
    itemAttributes: {},
  });
  return retVal;
};

const prepareLegendStatusSegmentItems = (
  timeline: Timeline
): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  retVal.push({
    label: "VehicleStatusCode.ACTIVE",
    itemType: TimelineLegendSegmentItemType.STATUS,
    itemAttributes: {
      fillColor: "#0b6a0b",
      pointColor: "green",
    },
  });
  retVal.push({
    label: "VehicleStatusCode.SUSPENDED",
    itemType: TimelineLegendSegmentItemType.STATUS,
    itemAttributes: {
      fillColor: "#a4262c",
      pointColor: "red",
    },
  });
  retVal.push({
    label: "VehicleStatusCode.DEACTIVATED",
    itemType: TimelineLegendSegmentItemType.STATUS,
    itemAttributes: {
      fillColor: "lightgray",
      pointColor: "gray",
    },
  });
  retVal.push({
    label: "VehicleStatusCode.PENDING",
    itemType: TimelineLegendSegmentItemType.STATUS,
    itemAttributes: {},
  });
  return retVal;
};

const prepareLegendSharedLicensePlateSegmentItems = (
  timeline: Timeline,
  params: PrepareTimelineParams
): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  const uniqueLicensePlates = [
    ...new Set(
      timeline.data
        .filter(
          (dataEl) =>
            dataEl.domain.type === TimelineType.SHARED_LICENSE_PLATE_STATUS
        )
        .map((dataEl) => dataEl.associatedObject.licensePlate)
    ),
  ];
  uniqueLicensePlates.forEach((licensePlate, index) => {
    retVal.push({
      label: licensePlate,
      itemType: TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE,
      itemAttributes: {
        fillColor: sharedLicenseColors[index%sharedLicenseColors.length].color,
        strokeColor: sharedLicenseColors[index%sharedLicenseColors.length].border,
      },
    });
  });
  if (!params.types.includes(TimelineType.VEHICLE_PREMIUM)) {
    retVal.push({
      label: "VehicleStatusCode.ACTIVE",
      itemType: TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE_VEHICLE,
      itemAttributes: {
        fillColor: "green",
        pointColor: "green",
      },
    });
    retVal.push({
      label: "VehicleStatusCode.SUSPENDED",
      itemType: TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE_VEHICLE,
      itemAttributes: {
        fillColor: "red",
        pointColor: "red",
      },
    });
  }
  const uniqueVehicles = [
    ...new Set(
      timeline.data
        .filter(
          (dataEl) =>
            dataEl.segmentType === TimelineSegmentType.MAIN_SHARED_VEHICLE &&
            (dataEl.domain.type === TimelineType.SHARED_LICENSE_PLATE_MTPL ||
              dataEl.domain.type ===
                TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX)
        )
        .map((dataEl) => {
          return displayVehicleInfo(
            dataEl?.associatedObject?.vehicleShortInfo,
            dataEl.domain.type === TimelineType.SHARED_LICENSE_PLATE_MTPL
              ? params.taxonomy.PremiumType.byCode["PremiumType.MTPL"].id
              : params.taxonomy.PremiumType.byCode["PremiumType.HORSE_POWER"]
                  .id,
            params.taxonomy,
            params.t
          );
        })
    ),
  ];

  uniqueVehicles.forEach((vehicleId, index) => {
    retVal.push({
      label: vehicleId,
      itemType: TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE_VEHICLE,
      itemAttributes: {
        fillColor: sharedLicenseInfoColors[index%sharedLicenseInfoColors.length].color,
        strokeColor: sharedLicenseInfoColors[index%sharedLicenseInfoColors.length].border,
      },
    });
  });
  return retVal;
};

const prepareLegendLicensePlateSegmentItems = (
  timeline: Timeline
): TimelineLegendSegmentItem[] => {
  const retVal: TimelineLegendSegmentItem[] = [];
  const uniqueLicensePlates = [
    ...new Set(
      timeline.data
        .filter(
          (dataEl) => dataEl.segmentType === TimelineSegmentType.LICENSE_PLATE
        )
        .map((dataEl) => dataEl.associatedObject.licensePlate)
    ),
  ];
  uniqueLicensePlates.forEach((licensePlate, index) => {
    retVal.push({
      label: licensePlate,
      itemType: TimelineLegendSegmentItemType.LICENSE_PLATE,
      itemAttributes: {
        fillColor: licenseColors[index%licenseColors.length].color,
        strokeColor: licenseColors[index%licenseColors.length].border,
      },
    });
  });

  return retVal;
};
