export const primary = "#005aa1";

export const fluentTheme = {
  themeLighter: "#deecf9",
};

export const presence = {
  ONLINE: "#6bb700",
  DO_NOT_DISTURB: "#c50f1f",
};

export const messaging = {
  errorBlockIcon: "#a80000",
  errorBlock: "#fde7e9",
  severeWarning: "#fed9cc",
};

export const shared = {
  green20: "#0b6a0b",
  orange10: "#ffaa44",
  blueMagenta10: "#8378de",
};

export const neutrals = {
  neutralSecondary: "#605e5c",
  neutralSecondaryAlt: "#8a8886",
  neutralTertiary: "#a19f9d",
  neutralTertiaryAlt: "#c8c6c4",
  neutralQuaternary: "#d2d0ce",
  neutralQuaternaryAlt: "#e1dfdd",
  neutralLight: "#edebe9",
  neutralLighter: "#f3f2f1",
  neutralLighterAlt: "#faf9f8",
};

export const accent = {
  yellow: "#ffb900",
  red: "#d13438",
  redDark: "#a4262c",
  greenDark: "#004b1c",
  green: "#107c10",
  orangeLighter: "#ff8c00",
  purpleLight: "#b4a0ff",
  purple: "#5c2d91",
  orange: "#d83b01",
};

export const products = {
  excel: "#217346",
};

export const vehicleStatus: { [key: string]: string } = {
  "VehicleStatusCode.PENDING_FOR_ACTIVATION": shared.green20,
  "VehicleStatusCode.ACTIVE": shared.green20,
  "VehicleStatusCode.PENDING_FOR_SUSPENSION": accent.redDark,
  "VehicleStatusCode.SUSPENDED": accent.redDark,
  "VehicleStatusCode.PENDING_FOR_DEACTIVATION": neutrals.neutralTertiary,
  "VehicleStatusCode.DEACTIVATED": neutrals.neutralTertiary,
  "VehicleStatusCode.NOT_DEACTIVATED": "transparent",
  "VehicleStatusCode.ALL": "transparent",
};
