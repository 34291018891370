import { Label, mergeStyleSets } from "@fluentui/react";
import { currencyFormat } from "components/timeline/visual-control/main-timeline/segments/OneTimePremiumSegmentTooltip";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { VehiclePremiumBookingInfo } from "types/types";

type Props = {
  bookingData: VehiclePremiumBookingInfo[];
  policyNumber: number | string;
  insurerNameMap: any[];
  totalPerPolicy: number;
  premiumType: string;
};

const classes = mergeStyleSets({
  mt: {
    marginTop: 20,
  },

  insurerName: {
    marginLeft: 10,
    fontStyle: "italic",
  },
  total: {
    margin: "0 5px",
    fontStyle: "italic",
  },
});

const PolicyItemDetails = ({
  policyNumber,
  insurerNameMap,
  bookingData,
  totalPerPolicy,
  premiumType,
}: Props) => {
  const insurerList = useSelector((s) => s.vehicle.insurerList);
  const { t } = useTranslation();

  const insurerLineTotal = useMemo(() => {
    return currencyFormat(
      bookingData
        .filter((bk) => bk.insurerPolicyNumber === policyNumber)
        .map((bk) => bk.bookedAmount)
        .reduce((prev, current) => (prev = prev + current), 0)
    );
  }, [bookingData, policyNumber]);

  const insurerName = useMemo(() => {
    return insurerList.find(
      (inc) => inc.insurerInternalNumber === insurerNameMap[policyNumber]
    ).insurerName;
  }, [insurerList, insurerNameMap, policyNumber]);

  const insurerLineTotalLabel = t("bfm.insuranceLineTotal");
  const policyTotalLabel = t("bfm.policyTotal");
  return (
    <div className={classes.mt}>
      <div className={classes.mt}>
        <Label>
          {policyNumber}
          <span className={classes.insurerName}>{insurerName}</span>
        </Label>
      </div>
      {premiumType !== "GENERAL" ? (
        <Label>
          {insurerLineTotalLabel}:
          <span className={classes.total}>{insurerLineTotal}</span>
          &euro;
        </Label>
      ) : null}
      <Label>
        {policyTotalLabel}:{" "}
        <span className={classes.total}>{currencyFormat(totalPerPolicy)}</span>
        &euro;
      </Label>
    </div>
  );
};

export default PolicyItemDetails;
