import CheckboxField from "components/controls/CheckboxField";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import { vehicleFormClasses } from "pages/vehicle/VehicleForm";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import {
  isDisabledExternal,
  isDisabledExternalOrHasBeenActivated,
} from "../../../helpers";
import useInsurerList from "./hooks/useInsurerList";

const FinanceTempMtpl = ({
  formikProps,
  userRole,
  route,
  vehicle,
  insurerList,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, handleChange, values, errors } = formikProps;
  const taxonomy = useSelector((s) => s.taxonomy);

  const paymentMethodOptions = useMemo(() => {
    return taxonomy?.PaymentMethod?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PaymentMethod?.items]);

  const paymentMeanOptions = useMemo(() => {
    return taxonomy?.PremiumPaymentMean?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy?.PremiumPaymentMean?.items]);

  const insurerData = useInsurerList({
    insurerList,
    values,
    name: "mtplInsurerPartnerNumber",
    subName: "mtplInsurerBranchOffice",
    setFieldValue,
  });

  return (
    <>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.mtplLastStartDate}
          name={"mtplLastStartDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.startDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplLastStartDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.mtplImportEndDate}
          name={"mtplImportEndDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.endDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplImportEndDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.mtplPolicyCancelationDocumentDate}
          name={"mtplPolicyCancelationDocumentDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.cancelationDocumentDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplPolicyCancelationDocumentDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="mtplInsurerPartnerNumber"
          label={"bfm.vehicleForm.mtplInsurerPartnerNumber.label"}
          setFieldValue={setFieldValue}
          options={insurerData.insurerOptions}
          defaultValue={values?.mtplInsurerPartnerNumber}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplInsurerPartnerNumber"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="mtplInsurerBranchOffice"
          label={"bfm.vehicleForm.insurerBranchOffice.label"}
          setFieldValue={setFieldValue}
          options={insurerData.insurerBranchOfficeOptions}
          defaultValue={values?.mtplInsurerBranchOffice}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplInsurerBranchOffice"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <TextInputField
          value={values.mtplPolicyNumber}
          onChange={handleChange}
          errors={errors}
          name={"mtplPolicyNumber"}
          // multiline
          // rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.policyNumber.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplPolicyNumber"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternal({ userRole, route })}
          errors={errors}
          name="mtplPaymentMethod"
          label={"bfm.vehicleForm.paymentMethod.label"}
          setFieldValue={setFieldValue}
          options={paymentMethodOptions}
          defaultValue={values.mtplPaymentMethod}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplPaymentMethod"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <ComboBoxField
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          errors={errors}
          name="paymentMean"
          label={"bfm.vehicleForm.paymentMean.label"}
          setFieldValue={setFieldValue}
          options={paymentMeanOptions}
          defaultValue={values.paymentMean}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "paymentMean"
          )}
          confidentiality={null}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.mtplAnnualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"mtplAnnualGrossPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplAnnualGrossPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DoubleNumberInputField
          setFieldValue={setFieldValue}
          value={values?.mtplAnnualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"mtplAnnualNetPremium"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.annualNetPremium.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplAnnualNetPremium"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.mtplSuspensionDays}
          onChange={handleChange}
          errors={errors}
          name={"mtplSuspensionDays"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.suspensionDays.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplSuspensionDays"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.mtplActivationDays}
          onChange={handleChange}
          errors={errors}
          name={"mtplActivationDays"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.mtplActivationDays.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplActivationDays"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.mtplSuspensionDaysLimit}
          onChange={handleChange}
          errors={errors}
          name={"mtplSuspensionDaysLimit"}
          disabled={isDisabledExternalOrHasBeenActivated({
            userRole,
            route,
            vehicle,
          })}
          label={"bfm.vehicleForm.mtplSuspensionDaysLimit.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplSuspensionDaysLimit"
          )}
          isNegativeAllowed={true}
        />
      </div>

      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.mtplDueMonth}
          onChange={handleChange}
          errors={errors}
          inputProps={{ type: "number", max: "12" }}
          name={"mtplDueMonth"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.mtplDueMonth.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplDueMonth"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <NumberInputField
          setFieldValue={setFieldValue}
          value={values.mtplDueDay}
          onChange={handleChange}
          errors={errors}
          name={"mtplDueDay"}
          disabled={isDisabledExternalOrHasBeenActivated({
            route,
            vehicle,
            userRole,
          })}
          label={"bfm.vehicleForm.mtplDueDay.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplDueDay"
          )}
        />
      </div>
      <div className={vehicleFormClasses.item}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.mtplPolicyDocumentDate}
          name={"mtplPolicyDocumentDate"}
          disabled={route === "readOnlyVehicle"}
          label={"bfm.vehicleForm.documentDate.label"}
          placeholder={t("bfm.selectDate")}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplPolicyDocumentDate"
          )}
          confidentiality={null}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <CheckboxField
          checked={values?.mtplIsPolicyIssued}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"mtplIsPolicyIssued"}
          disabled={isDisabledExternalOrHasBeenActivated({
            userRole,
            route,
            vehicle,
          })}
          label={"bfm.mtplIsPolicyIssued.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplIsPolicyIssued"
          )}
        />
      </div>
      <div className={vehicleFormClasses.fullWidthFormItem}>
        <TextInputField
          value={values.mtplComment}
          onChange={handleChange}
          errors={errors}
          name={"mtplComment"}
          multiline
          rows={4}
          disabled={isDisabledExternal({ userRole, route })}
          label={"bfm.vehicleForm.mtplComment.label"}
          isMarked={vehicle?.fieldsUpdatedByExternalUser?.includes(
            "mtplComment"
          )}
        />
      </div>
    </>
  );
};

export default FinanceTempMtpl;
