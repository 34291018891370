import {
  Callout,
  DelayedRender,
  Dialog,
  DialogFooter,
  Text,
  mergeStyleSets,
} from "@fluentui/react";
import { useBoolean, useId } from "@uifabric/react-hooks";
import { toast } from "components/FluentToast";
import { Form, Formik } from "formik";
import { useDuplicateVehicleWithPremiums } from "hooks/data/mutations/useDuplicateVehicleWithPremiums";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  closeDuplicateWithPremiumsDialog,
  loadVehiclesActions,
  setDuplicateVehicleWithPremiumsDialog,
} from "store/fleetPage";
import * as yup from "yup";
import { FormSpinner } from "../../components/FormSpinner";
import { transformForServer } from "../../components/form/transformForServer";
import { StyledPrimaryButton } from "../../components/styled";
import { RefProvider } from "../../contexts/RefProvider";
import { useSelector } from "../../store/hooks";
import { setYupLocale } from "../../utils/setYupLocale";
import { removeEmpty } from "../../utils/utils";
import { DuplicateWithPremiumsDialogContents } from "./DuplicateWithPremiumsDialogContents";
import { duplicateWithPremiumsDialogConfig } from "./duplicateWithPremiumsDialogConfig";
import { validationObjectConfig } from "./duplicateWithPremiumsDialogValidation";

const styles = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 320,
    padding: "20px 24px",
  },
});

export const DuplicateWithPremiumsDialog = () => {
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const phraseActivated = usePhraseActivationStatus().activated;
  const dispatch = useDispatch();

  const { mutate: onDuplicateVehicleWithPremiums, isLoading } =
    useDuplicateVehicleWithPremiums();

  const buttonId = useId("callout-button");
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);

  const isTradeLicensePlate = useSelector(
    (s) => s.fleetPage.duplicateVehicleWithPremiumsDialog.isTradeLicensePlate
  );

  const vehicleId = useSelector(
    (s) => s.fleetPage.duplicateVehicleWithPremiumsDialog.vehicleId
  );
  const fleetId = useSelector(
    (s) => s.fleetPage.duplicateVehicleWithPremiumsDialog.fleetId
  );
  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(isTradeLicensePlate);
    return yup.object(yupObject);
  }, [t, isTradeLicensePlate]);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: duplicateWithPremiumsDialogConfig,
    }) as any;
    result = removeEmpty(result);
    if (data?.fleetLegalPolicyNumber) {
      result.fleetLegalPolicyNumber = data?.fleetLegalPolicyNumber;
    }

    onDuplicateVehicleWithPremiums(
      {
        vehicleId: vehicleId,
        fleetId: fleetId,
        body: result,
      },
      {
        onSuccess: () => {
          dispatch(loadVehiclesActions.trigger({ isReset: true }));
          dispatch(
            setDuplicateVehicleWithPremiumsDialog({
              isOpen: false,
              isTradeLicensePlate: false,
              vehicleId: null,
              fleetId: null,
            })
          );
          toast.success("bfm.success");
        },
      }
    );
  };

  const initialValues = useMemo(() => {
    let initObj = {
      licensePlate: "",
      vin: "",
      mtplPolicyNumber: "",
      hullPolicyNumber: "",
      certificateNumber: "",
      fleetLegalPolicyNumber: "",
      nationalCodeId: "",
    };
    return initObj;
  }, []);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: t("bfm.duplicateVehicleWithPremiums.label"),
        showCloseButton: true,
      }}
      minWidth={700}
      onDismiss={() => {
        dispatch(closeDuplicateWithPremiumsDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm, values }) => {
            return (
              <Form>
                <DuplicateWithPremiumsDialogContents
                  isTradeLicensePlate={isTradeLicensePlate}
                />
                <DialogFooter>
                  <StyledPrimaryButton
                    id={buttonId}
                    text={t("greco.save")}
                    onClick={() => {
                      if (
                        !values.hullPolicyNumber &&
                        !values.mtplPolicyNumber &&
                        !values?.fleetLegalPolicyNumber
                      ) {
                        toggleIsCalloutVisible();
                      } else {
                        setDidTrySubmit(true);
                        submitForm();
                      }
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />

                  {isCalloutVisible && (
                    <Callout
                      className={styles.callout}
                      target={`#${buttonId}`}
                      onDismiss={toggleIsCalloutVisible}
                      role="status"
                      aria-live="assertive"
                    >
                      <DelayedRender>
                        <Text variant="small">
                          {t(
                            "bfm.duplicateVehicleWithPremiums.validation.label"
                          )}
                        </Text>
                      </DelayedRender>
                    </Callout>
                  )}
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
