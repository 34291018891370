import { Column, ColumnOptions as GrecoColumnOptions } from "@greco/components";
import { useGetInsurerListByCountry } from "hooks/data/queries/useGetInsurerListByCountry";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  selectColumnsSelectedView,
  selectSelectedView,
  setColumns,
  setSelectedViewIdFleet,
} from "../../store/fleetPage";
import {
  clearAllFilters,
  hideAllColumns,
  reorderColumn,
  selectAllColumnsFleetPageColumnOptions,
  setFilter,
  setTempColumnsFleet,
  showAllColumns,
  toggleColumnVisibility,
} from "../../store/fleetPageColumnOptions";
import { useSelector } from "../../store/hooks";
import {
  selectColumnOptionsSettings,
  setAllSettings,
} from "../../store/settings";
import { AppDispatch } from "../../store/store";
import { useAddAppSettings } from "hooks/data/mutations/useAddAppSettings";
import { Setting } from "types/types";
import { setSelectedViewId } from "store/requireActionVehicles";
import { useUpdateAppSettings } from "hooks/data/mutations/useUpdateAppSettings";
import { useDeleteAppSettings } from "hooks/data/mutations/useDeleteAppSettings";

type Props = {
  closePanel: () => void;
  countryCode: number | undefined;
};

export const ColumnOptions = ({ closePanel, countryCode }: Props) => {
  const tempColumns = useSelector(selectAllColumnsFleetPageColumnOptions);
  const dispatch: AppDispatch = useDispatch();
  const selectedView = useSelector(selectSelectedView) as any;
  const selectedViewColumns = useSelector(selectColumnsSelectedView) as any;
  const taxonomy = useSelector((s) => s.taxonomy);
  const applicationTax = taxonomy.ApplicationCode.byCode["Application.BFM"];
  const columnOptionsTax =
    taxonomy.ApplicationSetting.byCode["AppSetting.ColumnOptions"];
  const columnOptionsSettings = useSelector(selectColumnOptionsSettings);
  const areSettingsLoading = useSelector((s) => s.settings.isLoading);
  const areSettingsLoaded = useSelector((s) => s.settings.isLoaded);

  const { t } = useTranslation();

  const { mutate: addAppSetting, isLoading: isLoadingCreate } =
    useAddAppSettings();

  const { mutate: updateAppSetting, isLoading: isLoadingUpdate } =
    useUpdateAppSettings();

  const { mutate: deleteAppSetting, isLoading: isLoadingDelete } =
    useDeleteAppSettings();

  const { data: insurerList, isLoading: loadingInsurerList } =
    useGetInsurerListByCountry(countryCode);

  const searchInsurerTaxonomyData = insurerList?.map((item) => {
    return { code: item.insurerName, id: item.insurerInternalNumber };
  });

  const transformedColumns = useMemo(() => {
    const transformedColumns = tempColumns.map((c) => {
      if (c.key === "fleetName") {
        return {
          ...c,
          label: t(c.labelKey),
          isPresentInColumnOptions: false,
        };
      }

      return {
        ...c,
        label: t(c.labelKey),
      };
    });
    const fixedColumn = transformedColumns.filter((column) => column.isFixed);

    const unfixedTransformedColumns = transformedColumns.filter(
      (column) => !column.isFixed
    );

    return fixedColumn.concat(unfixedTransformedColumns);
  }, [t, tempColumns]);

  const isViewLoading =
    areSettingsLoading ||
    isLoadingCreate ||
    isLoadingUpdate ||
    isLoadingDelete ||
    loadingInsurerList;

  if (!searchInsurerTaxonomyData) {
    return;
  }

  return (
    <GrecoColumnOptions
      t={t}
      columns={transformedColumns as Column[]}
      isReady={areSettingsLoaded}
      isViewLoading={isViewLoading}
      closePanel={closePanel}
      onChangeView={(id) => {
        dispatch(setSelectedViewIdFleet(id));
      }}
      onClickApply={() => {
        dispatch(setColumns(tempColumns));
        closePanel();
      }}
      onClickClearAllFilters={() => {
        dispatch(clearAllFilters());
      }}
      onClickDeleteView={() => {
        deleteAppSetting(selectedView.userAppSettingId, {
          onSuccess: (res) => {
            dispatch(setAllSettings(res.data));
            dispatch(setSelectedViewIdFleet(null));
            dispatch(setSelectedViewId(null));
          },
        });
      }}
      onClickReset={() => {
        dispatch(setTempColumnsFleet(selectedViewColumns));
      }}
      onClickSaveView={() => {
        updateAppSetting(
          {
            ...selectedView,
            userAppSettingValue: JSON.stringify(
              tempColumns.map((column) => {
                if (column.key === "insuranceLineIcons") {
                  return {
                    ...column,
                    minWidth: 320,
                    maxWidth: 400,
                  };
                }
                return column;
              })
            ),
          },
          {
            onSuccess: (res) => {
              dispatch(setAllSettings(res.data));
            },
          }
        );
      }}
      onCreateView={(data) => {
        const applicationCodeTax =
          taxonomy.ApplicationCode.byCode["Application.BFM"];

        addAppSetting(
          {
            applicationCodeId: applicationTax.id,
            appSettingCodeId: columnOptionsTax.id,
            userAppSettingValue: JSON.stringify(tempColumns),
            ...data,
          },
          {
            onSuccess: (res) => {
              const allSettings = res?.data as Setting[];
              const newSetting = allSettings.find((s) => {
                return (
                  s.applicationCodeId === applicationCodeTax?.id &&
                  s.userAppSettingName === data.userAppSettingName
                );
              });
              dispatch(setSelectedViewIdFleet(newSetting.userAppSettingId));
              dispatch(setSelectedViewId(newSetting.userAppSettingId));
              dispatch(setAllSettings(allSettings));

              closePanel();
            },
          }
        );
      }}
      onUpdateView={(data) => {
        updateAppSetting(
          {
            ...selectedView,
            ...data,
          },
          {
            onSuccess: (res) => {
              dispatch(setAllSettings(res.data));
            },
          }
        );
      }}
      onDeselectAll={() => {
        dispatch(hideAllColumns());
      }}
      onSelectAll={() => {
        dispatch(showAllColumns());
      }}
      onReorderColumn={(src, dest) => {
        dispatch(
          reorderColumn({
            index1: src,
            index2: dest,
          })
        );
      }}
      onToggleColumnVisibility={(columnKey) => {
        dispatch(toggleColumnVisibility(columnKey));
      }}
      selectedView={selectedView}
      setFilter={(columnKey, filter) => {
        dispatch(setFilter({ columnKey, filter }));
      }}
      taxonomy={{
        ...taxonomy.response,
        mtplInsurerPartnerNumber: [...searchInsurerTaxonomyData],
        hullInsurerPartnerNumber: [...searchInsurerTaxonomyData],
        fleetLegalInsurerPartnerNumber: [...searchInsurerTaxonomyData],
      }}
      views={columnOptionsSettings}
    />
  );
};
