import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

const formatForDoubleFieldPercent11 = (value) => {
  const annualNetPremium = Number(
    Number(value?.replace(".", "")?.replace(",", ".")) / 1.11
  )
    .toFixed(2)
    ?.toString()
    ?.replaceAll(".", ",");
  return annualNetPremium;
};

const formatForDoubleFieldPercent4 = (value) => {
  const annualNetPremium = Number(
    Number(value?.replace(".", "")?.replace(",", ".")) / 1.04
  )
    .toFixed(2)
    ?.toString()
    ?.replaceAll(".", ",");
  return annualNetPremium;
};

const AnnualFields = ({
  premiumTypeSelected,
  fieldItemStyle,
  errors,
  setFieldValue,
  handleChange,
  values,
}) => {
  const { t } = useTranslation();
  const calculatedAnnualGrossPremium = useCallback(() => {
    if (!premiumTypeSelected) return "";
    if (premiumTypeSelected?.code === "ACCIDENT") {
      if (values.annualGrossPremium) {
        const annualNetPremium = formatForDoubleFieldPercent4(
          values.annualGrossPremium
        );
        setFieldValue("annualNetPremium", annualNetPremium);
      }
    } else {
      if (values.annualGrossPremium) {
        const annualNetPremium = formatForDoubleFieldPercent11(
          values.annualGrossPremium
        );
        setFieldValue("annualNetPremium", annualNetPremium);
      }
    }
    return "";
  }, [premiumTypeSelected, setFieldValue, values.annualGrossPremium]);

  useEffect(() => {
    calculatedAnnualGrossPremium();
  }, [calculatedAnnualGrossPremium]);

  return (
    <>
      <div className={fieldItemStyle}>
        <DoubleNumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.annualGrossPremium}
          onChange={handleChange}
          errors={errors}
          name={"annualGrossPremium"}
          disabled={false}
          label={"bfm.vehicleForm.annualGrossPremium.label"}
          defaultValueFunction={(value) => value}
        />
      </div>
      <div className={fieldItemStyle}>
        <DoubleNumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.annualNetPremium}
          onChange={handleChange}
          errors={errors}
          name={"annualNetPremium"}
          disabled={false}
          label={"bfm.vehicleForm.annualNetPremium.label"}
        />
      </div>
    </>
  );
};

export default AnnualFields;
