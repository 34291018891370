import { pointer } from "d3";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../../../../store/hooks";
import { openContextMenu } from "../../../../../store/timeline";
import {
  PremiumType,
  TimelineContextMenuType,
  TimelineLegendSegmentItemType,
  TimelineSegmentType,
  TimelineType,
} from "../../../../../types/types";
import { invertColor, truncateTextIfLong } from "../../../../../utils/utils";
import { InfinityArrow } from "./InfinityArrow";
import { LicensePlateSegmentTooltip } from "./LicensePlateSegmentTooltip";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

export const LicensePlateSegments = ({ xScale, yScale, innerWidth }) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const dispatch = useDispatch();
  const data = useSelector((s) => s.timeline.timeline.filteredData);
  const vehicle = useSelector((s) => s.vehicle?.vehicle);

  const shouldMenusBeAvailable = !(vehicle && vehicle.isTradeLicensePlate);

  const legend = useSelector((s) => s.timeline.timeline.legend);
  if (!data) return null;
  const licenseLegend = legend.find(
    (el) => el.segmentType === TimelineType.VEHICLE_LICENSE_PLATE
  );

  const licensePlateItems = licenseLegend?.items?.filter(
    (item) => item.itemType === TimelineLegendSegmentItemType.LICENSE_PLATE
  );
  const segments = data
    .filter(
      (segment) => segment.domain.type === TimelineType.VEHICLE_LICENSE_PLATE
    )
    .map((d, index) => {
      let segmentClass = "";
      let fillColor = "";
      let strokeColor = "";
      let strokeWidth = "";

      if (d.segmentType === TimelineSegmentType.LICENSE_PLATE) {
        const licenseItem = licensePlateItems.find(
          (item) => item.label === d.domain.value
        );
        segmentClass = "mark_plain";
        fillColor = licenseItem.itemAttributes.fillColor;
        strokeColor = licenseItem.itemAttributes.strokeColor;

        strokeWidth = "3";
        if (d.associatedObject.totalProRataPremium === 0) {
          segmentClass = segmentClass + " transparent_segment";
        }
        if (isDarkMode) {
          fillColor = invertColor(fillColor);
          strokeColor = invertColor(strokeColor);
        }
      } else {
        return null;
      }

      const rectRef = React.createRef<SVGRectElement>();
      let x1 = xScale(d.startDate);
      let x2 = xScale(d.endDate);
      if (x1 < 0) x1 = 0;
      if (x2 > innerWidth) x2 = innerWidth;
      let width = x2 - x1;
      // \
      // /
      return (
        <g key={d.startDate + ""}>
          <LicensePlateSegmentTooltip d={d}>
            <g
              id={
                "licenseplate1_" +
                (d.associatedObject && d.associatedObject?.vehicleLicensePlateId
                  ? d.associatedObject.vehicleLicensePlateId
                  : index)
              }
            >
              <rect
                ref={rectRef}
                className={`${segmentClass} ${
                  shouldMenusBeAvailable ? "clickable" : null
                }`}
                x={x1}
                width={width + 1}
                y={
                  yScale(PremiumType.LICENSE_PLATE) +
                  yScale.bandwidth() * (1 / 6)
                }
                height={yScale.bandwidth() * (2 / 3)}
                fill={fillColor}
                onClick={(e) => {
                  return dispatch(
                    openContextMenu({
                      type: TimelineContextMenuType.LICENSE_PLATE,
                      item: d,
                      ref: { x: e.pageX, y: e.pageY },
                      date: xScale.invert(pointer(e)[0]),
                    })
                  );
                }}
              ></rect>

              <text
                fill={strokeColor}
                style={{
                  textAnchor: "middle",
                  fontSize: "0.75em",
                  cursor: "pointer",
                  pointerEvents: "none",
                }}
                x={(x1 + x2) / 2}
                dy=".32em"
                y={yScale(PremiumType.LICENSE_PLATE) + yScale.bandwidth() / 2}
              >
                {truncateTextIfLong(
                  d.associatedObject?.licensePlate,
                  x2 - x1,
                  8
                )}
              </text>
            </g>
          </LicensePlateSegmentTooltip>
          <LicensePlateSegmentTooltip d={d}>
            <g
              id={
                "licenseplate2_" +
                (d.associatedObject && d.associatedObject?.vehicleLicensePlateId
                  ? d.associatedObject.vehicleLicensePlateId
                  : index)
              }
              onClick={(e) => {
                //openStatusContextMenu
                return dispatch(
                  openContextMenu({
                    type: TimelineContextMenuType.LICENSE_PLATE_POINT,
                    item: d,
                    ref: { x: e.pageX, y: e.pageY },
                    date: xScale.invert(pointer(e)[0]),
                  })
                );
              }}
            >
              <rect
                className={`status ${
                  shouldMenusBeAvailable ? "clickable" : null
                }`}
                x={x1 - (yScale.bandwidth() * 1) / 3}
                width={yScale.bandwidth() * (2 / 3)}
                y={
                  yScale(PremiumType.LICENSE_PLATE) +
                  yScale.bandwidth() * (1 / 6)
                }
                height={yScale.bandwidth() * (2 / 3)}
                fill={"white"}
                stroke={strokeColor}
                strokeWidth={2}
              />
            </g>
          </LicensePlateSegmentTooltip>
          {d.extraData?.isInfinitePremium ? (
            <InfinityArrow
              yScale={yScale}
              strokeColor={strokeColor}
              d={d}
              x2={x2}
              domainValueFunc={(d) => PremiumType.LICENSE_PLATE}
            />
          ) : null}
        </g>
      );
    });
  return <>{segments}</>;
};
