import { Checkbox, IconButton, TooltipHost } from "@fluentui/react";
import { ColumnOptionsButton } from "@greco/components";
import { ClientFilter } from "components/ClientFilter";
import { TlpFilter } from "components/TlpFilter";
import { ExportExcelButton } from "pages/fleet/navigation/ExportExcelButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelectedClients, setSelectedMainIds } from "store/filter";
import { ResultsScore } from "../../../components/ResultsScore";
import { BackButton } from "../../../components/navigation/BackButton";
import { Links } from "../../../components/navigation/Links";
import {
  Flex,
  FlexRight,
  HSpace,
  NavigationBar,
} from "../../../components/styled";
import { useCurrentRoute } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import {
  loadVehiclesActions,
  setIsColumnOptionsPanelOpen,
  setTlpFilter,
  setVehicleAttentionFilter,
} from "../../../store/requireActionVehicles";
import { Search } from "./Search";
import { useGetClientList } from "hooks/data/queries/useGetClientList";

type Props = {
  refExcelExport?: any;
};

export const Navigation = ({ refExcelExport }: Props) => {
  const { t } = useTranslation();
  const route = useCurrentRoute();
  const { data: clientList } = useGetClientList();

  const vehicleAttentionFilter = useSelector(
    (s) => s.requireActionVehicles.vehicleAttentionFilter
  );

  const tlpFilter = useSelector((s) => s.requireActionVehicles.tlpFilter);

  const vehiclesTotalCount = useSelector(
    (s) => s.requireActionVehicles.vehiclesTotalCount
  );
  const areVehiclesLoading = useSelector(
    (s) => s.requireActionVehicles.loadVehiclesStatus === "loading"
  );
  const dispatch = useDispatch();
  const isRequireActionVehicles = route === "requireActionVehicles";

  const selectedClients = useSelector((s) => s.filter.selectedClients);

  const clientsTooltip = selectedClients
    ? selectedClients
        .map(
          (clId) =>
            clientList.find((cl) => cl.clientInternalNumber === clId).clientName
        )
        .join(", ")
    : null;

  return (
    <>
      <NavigationBar>
        <HSpace />
        <HSpace />
        {!isRequireActionVehicles && <BackButton />}
        <Links />
        <HSpace />
        {refExcelExport && (
          <ExportExcelButton
            refExcelExport={refExcelExport}
            isFleetPage={false}
          />
        )}
      </NavigationBar>

      <NavigationBar>
        <HSpace width={10} />
        <TooltipHost content={t("Refresh")}>
          <IconButton
            iconProps={{ iconName: "Refresh" }}
            onClick={() => {
              dispatch(
                loadVehiclesActions.trigger({
                  isReset: true,
                  shouldDelay: false,
                  shouldEmptyVehicles: true,
                })
              );
            }}
            disabled={areVehiclesLoading}
          />
        </TooltipHost>

        <HSpace />
        <Search />
        <HSpace width={20} />
        <Checkbox
          label={t("bfm.needsAttention")}
          checked={vehicleAttentionFilter}
          onChange={() =>
            dispatch(setVehicleAttentionFilter(!vehicleAttentionFilter))
          }
        />
        <HSpace width={20} />
        <TlpFilter
          tlpValue={tlpFilter}
          onChange={(val) => {
            dispatch(setTlpFilter(val.key));
          }}
        />
        <HSpace width={20} />
        <TooltipHost
          content={clientsTooltip}
          styles={{
            root: {
              marginTop: "4px",
            },
          }}
        >
          <ClientFilter />
        </TooltipHost>
        {selectedClients && selectedClients.length > 0 ? (
          <>
            <HSpace />
            <TooltipHost content={"Remove filter"}>
              <IconButton
                iconProps={{ iconName: "ChromeClose" }}
                onClick={() => {
                  dispatch(setSelectedClients([]));
                  dispatch(setSelectedMainIds([]));
                }}
              />
            </TooltipHost>
          </>
        ) : null}

        <FlexRight>
          <Flex>
            <ColumnOptionsButton
              t={t}
              isActive={false}
              onClick={() => {
                dispatch(setIsColumnOptionsPanelOpen(true));
              }}
            />
            <HSpace />
            <ResultsScore score={vehiclesTotalCount} />
          </Flex>
        </FlexRight>
      </NavigationBar>
    </>
  );
};
