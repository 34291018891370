import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import TextInputField from "components/controls/TextInputField";
import { DueDateField } from "components/form/DueDateField";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { PremiumType } from "types/types";

const MtplAndFleetLegalDependencyFields = ({
  premiumTypeSelected,
  fieldItemStyle,
  errors,
  setFieldValue,
  handleChange,
  values,
}) => {
  const { t } = useTranslation();

  const taxonomy = useSelector((s) => s.taxonomy);
  const taxonomyByCode = taxonomy.PremiumType.byCode;

  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);

  const fleetPolicyNumber = fleet?.fleetPolicyNumber;

  const fleetLegalPolicyNumber = fleet?.fleetLegalPolicyNumber;

  const isMtplPremiumType =
    premiumTypeSelected?.value === taxonomyByCode[PremiumType.MTPL]?.id;
  const isFleetLegalProrataPremiumType =
    premiumTypeSelected?.value ===
    taxonomyByCode[PremiumType.FLEETLEGAL_PRORATA]?.id;
  const isFleetLegalClosingDatePremiumType =
    premiumTypeSelected?.value ===
    taxonomyByCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

  const selectedInsurer = values?.insurerPartnerNumber?.value;

  const insurerBranchOfficeOptions = useMemo(() => {
    const reportInsurerBranchOffices = insurerList.find(
      (insurer) => insurer.insurerInternalNumber === selectedInsurer
    )?.reportInsurerBranchOffices;
    const options = reportInsurerBranchOffices?.map((tax) => {
      return {
        value: tax.branchOfficeInternalNumber,
        label:
          tax.branchOfficeName + " (" + tax.branchOfficeInternalNumber + ")",
      };
    });

    return options;
  }, [insurerList, values]);

  useEffect(() => {
    if (
      !isMtplPremiumType &&
      !isFleetLegalClosingDatePremiumType &&
      !isFleetLegalProrataPremiumType
    ) {
      setFieldValue("insurerPolicyNumber", null);

      return;
    }
    if (isMtplPremiumType) {
      setFieldValue("insurerPolicyNumber", fleetPolicyNumber);
      return;
    }
    if (isFleetLegalClosingDatePremiumType || isFleetLegalProrataPremiumType) {
      setFieldValue("insurerPolicyNumber", fleetLegalPolicyNumber);
      return;
    }
  }, [premiumTypeSelected]);

  return (
    <>
      <div className={fieldItemStyle}>
        <ComboBoxField
          disabled={false}
          errors={errors}
          name={"insurerBranchOffice"}
          label={"bfm.vehicleForm.insurerBranchOffice.label"}
          setFieldValue={setFieldValue}
          options={insurerBranchOfficeOptions || []}
          defaultValue={values?.insurerBranchOffice}
          confidentiality={null}
        />
      </div>
      <div className={fieldItemStyle}>
        <TextInputField
          value={values.insurerPolicyNumber}
          onChange={handleChange}
          errors={errors}
          name={"insurerPolicyNumber"}
          disabled={false}
          required
          label={"bfm.insurerPolicyNumber.label"}
        />
      </div>
      <div className={fieldItemStyle}>
        <DueDateField
          fieldConfig={{
            name: "dueDate",
            type: "dueDate",
            dayFieldName: "dueDay",
            monthFieldName: "dueMonth",
            isRequired: true,
            d: ({ t }) => ({
              label: "bfm.fleetForm.dueDate.label",
            }),
          }}
        />
      </div>
      <div className={fieldItemStyle}>
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.expiryDate}
          name={"expiryDate"}
          disabled={false}
          label={"bfm.vehicleForm.expiryDate.label"}
          placeholder={t("bfm.selectDate")}
          required
          confidentiality={null}
        />
      </div>
    </>
  );
};

export default MtplAndFleetLegalDependencyFields;
