import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { replace } from "connected-react-router";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { history } from "utils/_helpers";
import { RouteKey, routes } from "../config/routes";
import {
  Fleet,
  FleetDefaultSetting,
  FleetWithInsurersResponse,
  Insurer,
  InsurerSettings,
  Vehicle,
  VehicleRedux,
  VehicleWithInsurersResponse,
} from "../types/types";
import * as API from "./api/api";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

export type PremiumTypeKey =
  | "GENERAL"
  | "MTPL"
  | "HULL"
  | "HULL_BASIC"
  | "ACCIDENT"
  | "ASSISTANCE"
  | "LEGAL"
  | "HORSE_POWER"
  | "FLEETLEGAL_PRORATA";

type VehicleState = {
  vehicle: Vehicle | null;
  fleet: Fleet | null;
  loadVehicleStatus: LoadStatus;
  loadVehicleWithInsurersStatus: LoadStatus;
  loadTEMPVehicleStatus: LoadStatus;
  loadInsurersStatus: LoadStatus;
  loadFleetStatus: LoadStatus;
  loadInsurerSettingsStatus: LoadStatus;
  isSubmitted: boolean;
  serverErrors: { [key: string]: string };
  tab: any;
  premiumBookingTab: any;
  isErrorPanelOpen: boolean;
  updateIssuingStatusStatus: LoadStatus;
  updateIssuingStatusDialog: {
    isOpen: boolean;
  };
  modifyStatusStatus: LoadStatus;

  fleetDefaultSettings: FleetDefaultSetting[] | null;
  insurerSettings: InsurerSettings[] | null;
  loadDefaultSettingsLoadStatus: LoadStatus;
  insurerList: Insurer[];

  changeVehicleStatusDialog: {
    isOpen: boolean;
    fleetId: VehicleRedux["fleetId"] | null;
    vehicle?: VehicleRedux | null;
    vehicleIds?: number[];
    date: Date;
    addNew: boolean;
  };
  changeVehicleStatusStatus: {
    vehicleId: VehicleRedux["vehicleId"] | null;
    vehicleIds?: number[];
  };
  importScanDocumentDialog: {
    isOpen: boolean;
    file: any;
  };
  scannedFormFields: any;
};

const initialState: VehicleState = {
  vehicle: null,
  fleet: null,
  loadVehicleStatus: "none",
  loadVehicleWithInsurersStatus: "none",
  loadInsurersStatus: "none",
  loadTEMPVehicleStatus: "none",
  loadFleetStatus: "none",
  loadInsurerSettingsStatus: "none",
  isSubmitted: false,
  serverErrors: {},
  tab: "general-vehicle-form",
  premiumBookingTab: "general",
  isErrorPanelOpen: false,
  updateIssuingStatusStatus: "none",
  updateIssuingStatusDialog: {
    isOpen: false,
  },
  insurerSettings: null,
  modifyStatusStatus: "none",

  fleetDefaultSettings: null,
  loadDefaultSettingsLoadStatus: "none",
  insurerList: null,
  changeVehicleStatusDialog: {
    isOpen: false,
    fleetId: null,
    vehicle: null,
    date: null,
    addNew: null,
  },
  changeVehicleStatusStatus: {
    vehicleId: null,
  },
  importScanDocumentDialog: {
    isOpen: false,
    file: null,
  },
  scannedFormFields: null,
};

export const loadInsurerSettingsActions = createAsyncRoutine<
  void,
  void,
  InsurerSettings[],
  any
>("vehicle/loadInsurerSettings");

export const loadVehicleActions = createAsyncRoutine<
  {
    fleetId: Fleet["fleetId"];
    vehicleId: Vehicle["vehicleId"];
  },
  void,
  Vehicle,
  any
>("vehicle/load");

export const loadVehicleWithInsurersActions = createAsyncRoutine<
  {
    fleetId: Fleet["fleetId"];
    vehicleId: Vehicle["vehicleId"];
    isVehiclePage?: Boolean;
  },
  void,
  VehicleWithInsurersResponse,
  any
>("vehicleWithInsurers/load");

export const loadTEMPVehicleActions = createAsyncRoutine<
  {
    fleetId: Fleet["fleetId"];
    vehicleId: Vehicle["vehicleId"];
  },
  void,
  Vehicle,
  any
>("vehicle/loadTEMP");

export const loadInsurersActions = createAsyncRoutine<
  number,
  void,
  Insurer[],
  any
>("vehicle/loadInsurers");

export const loadFleetActions = createAsyncRoutine<
  {
    fleetId: Fleet["fleetId"];
  },
  void,
  Fleet,
  any
>("vehicle/loadFleet");

export const loadFleetWithInsurersActions = createAsyncRoutine<
  { fleetId: any; isVehiclePage?: Boolean },
  void,
  FleetWithInsurersResponse,
  any
>("vehicle/loadFleetWithInsurers");

export const updateIssuingStatus = createAsyncRoutine<
  API.UpdateIssuingStatusRequestParams & {
    onSuccess?: () => void;
    onError?: (err: any) => void;
  },
  void,
  Vehicle,
  any
>("vehicle/updateIssuingStatus");

export const loadFleetDefaultSettingsRoutine = createAsyncRoutine<
  { fleetId: any; onSuccess?: () => void; onError?: (err?: any) => void },
  void,
  FleetDefaultSetting[],
  any
>("vehicle/loadFleetDefaultSettings");

export const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setImportScanDocumentFile: (s, a: any) => {
      s.importScanDocumentDialog.file = a.payload;
    },
    setIsSubmitted: (s, a: PayloadAction<VehicleState["isSubmitted"]>) => {
      s.isSubmitted = a.payload;
    },
    setVehicle: (s, a: PayloadAction<VehicleState["vehicle"]>) => {
      s.vehicle = a.payload;
    },
    setVehicleTimelineOperationRetVal: (s, a: PayloadAction<any>) => {
      s.vehicle = a.payload;
    },

    setInsurerList: (s, a: PayloadAction<VehicleState["insurerList"]>) => {
      s.insurerList = a.payload;
    },
    setServerErrors: (s, a: PayloadAction<VehicleState["serverErrors"]>) => {
      s.serverErrors = a.payload;
    },
    setTab: (s, a: PayloadAction<VehicleState["tab"]>) => {
      s.tab = a.payload;
    },
    setPremiumBookingTab: (
      s,
      a: PayloadAction<VehicleState["premiumBookingTab"]>
    ) => {
      s.premiumBookingTab = a.payload;
    },
    setIsErrorPanelOpen: (
      s,
      a: PayloadAction<VehicleState["isErrorPanelOpen"]>
    ) => {
      s.isErrorPanelOpen = a.payload;
    },
    toggleIsErrorPanelOpen: (s) => {
      if (s.isErrorPanelOpen) {
        s.isErrorPanelOpen = false;
      }
      //
      else {
        s.isErrorPanelOpen = true;
      }
    },

    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
    resetInsurers: (
      s,
      a: PayloadAction<Partial<VehicleState["insurerList"]>>
    ) => {
      s.insurerList = [];
    },
    setChangeVehicleStatusDialog: (
      s,
      a: PayloadAction<VehicleState["changeVehicleStatusDialog"]>
    ) => {
      s.changeVehicleStatusDialog = a.payload;
    },
    setImportScanDocument: (
      s,
      a: PayloadAction<VehicleState["importScanDocumentDialog"]>
    ) => {
      s.importScanDocumentDialog = a.payload;
    },
    setScannedFormFields: (s, a: PayloadAction<any>) => {
      s.scannedFormFields = a.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVehicleActions.loading, (s) => {
      s.loadVehicleStatus = "loading";
    });
    builder.addCase(loadVehicleActions.success, (s, a) => {
      s.loadVehicleStatus = "success";
      s.vehicle = a.payload;
    });
    builder.addCase(loadVehicleActions.error, (s) => {
      s.loadVehicleStatus = "error";
    });

    builder.addCase(loadVehicleWithInsurersActions.loading, (s) => {
      s.loadVehicleWithInsurersStatus = "loading";
    });
    builder.addCase(loadVehicleWithInsurersActions.success, (s, a) => {
      s.loadVehicleWithInsurersStatus = "success";
      s.vehicle = a.payload.vehicle;
      s.insurerList = a.payload.insurers;
      s.fleet = a.payload.fleet;
    });
    builder.addCase(loadVehicleWithInsurersActions.error, (s) => {
      s.loadVehicleWithInsurersStatus = "error";
    });

    builder.addCase(loadTEMPVehicleActions.loading, (s) => {
      s.loadTEMPVehicleStatus = "loading";
    });
    builder.addCase(loadTEMPVehicleActions.success, (s, a) => {
      s.loadTEMPVehicleStatus = "success";
      s.vehicle = a.payload;
    });
    builder.addCase(loadTEMPVehicleActions.error, (s) => {
      s.loadTEMPVehicleStatus = "error";
    });
    builder.addCase(loadInsurersActions.loading, (s) => {
      s.loadInsurersStatus = "loading";
    });
    builder.addCase(loadInsurersActions.success, (s, a) => {
      s.loadInsurersStatus = "success";
      s.insurerList = a.payload;
    });

    builder.addCase(loadFleetActions.loading, (s) => {
      s.loadFleetStatus = "loading";
    });
    builder.addCase(loadFleetActions.success, (s, a) => {
      s.loadFleetStatus = "success";
      s.fleet = a.payload;
    });
    builder.addCase(loadFleetWithInsurersActions.loading, (s) => {
      s.loadFleetStatus = "loading";
    });
    builder.addCase(loadFleetWithInsurersActions.success, (s, a) => {
      s.loadFleetStatus = "success";
      s.fleet = a.payload.fleet;
      s.insurerList = a.payload.insurers;
      s.loadFleetStatus = null;
    });
    builder.addCase(loadFleetActions.error, (s) => {
      s.loadFleetStatus = "error";
    });
    builder.addCase(updateIssuingStatus.loading, (s) => {
      s.updateIssuingStatusStatus = "loading";
    });
    builder.addCase(updateIssuingStatus.success, (s, a) => {
      s.updateIssuingStatusStatus = "success";
      s.vehicle = a.payload;
    });
    builder.addCase(updateIssuingStatus.error, (s) => {
      s.updateIssuingStatusStatus = "error";
    });

    builder.addCase(loadFleetDefaultSettingsRoutine.loading, (s) => {
      s.loadDefaultSettingsLoadStatus = "loading";
    });
    builder.addCase(loadFleetDefaultSettingsRoutine.success, (s, a) => {
      s.loadDefaultSettingsLoadStatus = "success";
      s.fleetDefaultSettings = a.payload;
    });
    builder.addCase(loadFleetDefaultSettingsRoutine.error, (s) => {
      s.loadDefaultSettingsLoadStatus = "error";
    });

    builder.addCase(loadInsurerSettingsActions.loading, (s, a) => {
      s.loadInsurerSettingsStatus = "loading";
    });
    builder.addCase(loadInsurerSettingsActions.success, (s, a) => {
      s.loadInsurerSettingsStatus = "success";
      s.insurerSettings = a.payload;
    });
    builder.addCase(loadInsurerSettingsActions.error, (s, a) => {
      s.loadInsurerSettingsStatus = "error";
    });
  },
});

export const {
  actions: {
    setIsSubmitted,
    setImportScanDocumentFile,
    setScannedFormFields,
    setVehicle,
    setServerErrors,
    setTab,
    setPremiumBookingTab,
    setIsErrorPanelOpen,
    toggleIsErrorPanelOpen,
    setChangeVehicleStatusDialog,
    setImportScanDocument,
    resetState,
    setVehicleTimelineOperationRetVal,
  },
  reducer: vehicleReducer,
} = vehicleSlice;

function* loadVehicleSaga() {
  yield takeEvery(
    loadVehicleActions.trigger,
    function* (a: ReturnType<typeof loadVehicleActions.trigger>) {
      const { fleetId, vehicleId } = a.payload;
      try {
        yield put(loadVehicleActions.loading());
        const res = yield call(
          API.getVehicleById,
          fleetId as any,
          vehicleId as any
        );
        yield put(loadVehicleActions.success(res.data));
      } catch (err: any) {
        yield put(loadVehicleActions.error(err));
        handleAxiosError(err);
        if ([400, 403, 404].includes(err.response.status)) {
          yield put(replace("/"));
        }
      }
    }
  );
}

function* loadVehicleWithInsurersSaga() {
  yield takeEvery(
    loadVehicleWithInsurersActions.trigger,
    function* (a: ReturnType<typeof loadVehicleWithInsurersActions.trigger>) {
      const { fleetId, vehicleId, isVehiclePage } = a.payload;
      try {
        yield put(loadVehicleWithInsurersActions.loading());
        const res = yield call(
          API.getVehicleById,
          fleetId as any,
          vehicleId as any
        );
        let fleet;
        if (isVehiclePage) {
          //call GetFleetById
          fleet = yield call(API.GetFleetById, fleetId);
        } else {
          fleet = yield call(API.getFleetWithPremiums, fleetId);
        }
        const insurers = yield call(
          API.getInsurersByCountryId,
          fleet.data.countryCode
        );
        yield put(
          loadVehicleWithInsurersActions.success({
            vehicle: res.data,
            insurers: insurers.data,
            fleet: fleet.data,
          })
        );
      } catch (err: any) {
        yield put(loadVehicleWithInsurersActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

function* loadTEMPVehicleSaga() {
  yield takeEvery(
    loadTEMPVehicleActions.trigger,
    function* (a: ReturnType<typeof loadTEMPVehicleActions.trigger>) {
      const { fleetId, vehicleId } = a.payload;
      try {
        yield put(loadTEMPVehicleActions.loading());
        const res = yield call(
          API.getTEMPVehicleById,
          fleetId as any,
          vehicleId as any
        );
        if (res.data.isDeleted) {
          // yield put(push(routes.fleet.getPath(fleetId)));
          history.navigate(routes.fleet.getPath(fleetId));
        }
        yield put(loadTEMPVehicleActions.success(res.data));
      } catch (err: any) {
        yield put(loadTEMPVehicleActions.error(err));
        handleAxiosError(err);
        if ([400, 403, 404].includes(err.response.status)) {
          yield put(replace("/"));
        }
      }
    }
  );
}

function* loadFleetSaga() {
  yield takeEvery(
    loadFleetActions.trigger,
    function* (a: ReturnType<typeof loadFleetActions.trigger>) {
      const { fleetId } = a.payload;
      try {
        yield put(loadFleetActions.loading());
        const res = yield call(API.getFleetWithPremiums, fleetId as any);
        yield put(loadFleetActions.success(res.data));
      } catch (err: any) {
        yield put(loadFleetActions.error(err));
        handleAxiosError(err);
        if (err.response?.status === 403) {
          yield put(replace("/"));
        }
      }
    }
  );
}

export function* loadFleetWithInsurersSaga() {
  yield takeEvery(
    loadFleetWithInsurersActions.trigger,
    function* (a: ReturnType<typeof loadFleetWithInsurersActions.trigger>) {
      const { fleetId, isVehiclePage } = a.payload;
      try {
        yield put(loadFleetWithInsurersActions.loading());
        let res;
        if (isVehiclePage) {
          res = yield call(API.GetFleetById, fleetId); //i za shared license plate
        } else {
          res = yield call(API.getFleetWithPremiums, fleetId);
        }
        const insurers = yield call(
          API.getInsurersByCountryId,
          res.data.countryCode
        );
        yield put(
          loadFleetWithInsurersActions.success({
            fleet: res.data,
            insurers: insurers.data,
          })
        );
      } catch (err: any) {
        yield put(loadFleetWithInsurersActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.fleets.getPath()));
        }
      }
    }
  );
}

function* loadFleetDefaultSettingsSaga() {
  yield takeEvery(
    loadFleetDefaultSettingsRoutine.trigger,
    function* (a: ReturnType<typeof loadFleetDefaultSettingsRoutine.trigger>) {
      const { onSuccess, onError, fleetId } = a.payload;
      try {
        yield put(loadFleetDefaultSettingsRoutine.loading());
        const res = yield call(API.getFleetDefaultSettings, fleetId);
        yield put(loadFleetDefaultSettingsRoutine.success(res.data));
        onSuccess && onSuccess();
      } catch (err) {
        yield put(loadFleetDefaultSettingsRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

export function* loadInsurerSettingsSaga() {
  yield takeEvery(
    loadInsurerSettingsActions.trigger,
    function* (a: ReturnType<typeof loadInsurerSettingsActions.trigger>) {
      try {
        yield put(loadInsurerSettingsActions.loading());
        const insurers = yield call(API.getInsurers);
        yield put(loadInsurerSettingsActions.success(insurers.data));
      } catch (err: any) {
        if (err.response.status === 404) {
          const insurers = [];
          yield put(loadInsurerSettingsActions.success(insurers));
          return;
        }
        yield put(loadInsurerSettingsActions.error(err));
        handleAxiosError(err);
        if (err.response.status === 403) {
          yield put(replace(routes.insurers.getPath()));
        }
      }
    }
  );
}

export function* vehicleSaga() {
  yield fork(loadVehicleWithInsurersSaga);
  yield fork(loadVehicleSaga);
  yield fork(loadTEMPVehicleSaga);
  yield fork(loadFleetSaga);
  yield fork(loadFleetWithInsurersSaga);
  yield fork(loadFleetDefaultSettingsSaga);
  yield fork(loadInsurerSettingsSaga);
}

export const selectShouldDisableForm = (s: RootState) => (route: RouteKey) => {
  if (route === "editTempVehicle") {
    return [s.vehicle.loadTEMPVehicleStatus, s.vehicle.loadFleetStatus].some(
      (s) => s === "loading"
    );
  }
  //
  else if (route === "editVehicle") {
    return [s.vehicle.loadVehicleStatus, s.vehicle.loadFleetStatus].some(
      (s) => s === "loading"
    );
  }
  //
  else if (route === "duplicateVehicle") {
    return [
      s.vehicle.loadVehicleStatus,
      s.vehicle.loadDefaultSettingsLoadStatus,
      s.vehicle.loadFleetStatus,
    ].some((s) => s === "loading");
  }
  //
  else if (route === "createVehicle" || route === "createTradeVehicle") {
    return [
      s.vehicle.loadDefaultSettingsLoadStatus,
      s.vehicle.loadFleetStatus,
    ].some((s) => s === "loading");
  } else if (route === "createFleet" || route === "editFleet") {
    return [].some((s) => s === "loading");
  }
  return false;
};
