import {
  Checkbox,
  DefaultButton,
  DialogFooter,
  mergeStyleSets,
} from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import { EActionDialog } from "pages/fleet-form/FleetForm/constants";
import VehicleTypeDependencyField from "pages/fleet-form/actionDialogs/VehicleTypeDependencyField";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";

const AnnualGrossPremiumFormFields = ({
  settingId,
  fieldProps,
  defaultData,
  insurerOptions,
  carUsageOptions,
  vehicleTypeCodeOptions,
  isLoading,
  premiumTypeOptions,
  isTradeLicensePlate,
  setTradeLicensePlate,
  listPriceTypeOptions,
}) => {
  const classes = getClassNames();
  const { setFieldValue, values, errors, handleChange } = fieldProps;
  const { t } = useTranslation();
  const isReadOnly =
    useSelector((s) => s.fleetForm.dialog.type) === EActionDialog.ReadOnly;

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem}>
          <Checkbox
            id="isTradeLicensePlate"
            label={t("bfm.tradeLicensePlates.label")}
            styles={{
              root: {
                fontWeight: 600,
              },
            }}
            disabled={settingId || isReadOnly}
            checked={isTradeLicensePlate}
            onChange={(ev) => {
              setFieldValue("vehicleType", null);
              setFieldValue("carUsage", null);
              setTradeLicensePlate(!isTradeLicensePlate);
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem}>
          <DoubleNumberInputField
            id="attributeValue"
            setFieldValue={setFieldValue}
            value={values.attributeValue}
            onChange={handleChange}
            errors={errors}
            required
            name={"attributeValue"}
            disabled={isReadOnly}
            label={"bfm.fleet.setting.attributeValue.label"}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <ComboBoxField
            id="premiumType"
            disabled={!!settingId || isReadOnly}
            errors={errors}
            name="premiumType"
            required
            label={"bfm.fleet.premiumType.label"}
            setFieldValue={setFieldValue}
            options={premiumTypeOptions || []}
            defaultValue={values?.premiumType}
            confidentiality={null}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <ComboBoxField
            id="insurerPartnerNumber"
            disabled={!!settingId || isReadOnly}
            errors={errors}
            required
            name="insurerPartnerNumber"
            label={"bfm.insurer.label"}
            setFieldValue={setFieldValue}
            options={insurerOptions || []}
            defaultValue={values?.insurerPartnerNumber}
            confidentiality={null}
          />
        </div>
      </div>
      {values.premiumType?.code === "HULL_BASIC" ||
      values.premiumType?.code === "HULL" ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
            <>
              <ComboBoxField
                id="listPriceType"
                disabled={!!settingId || isReadOnly}
                errors={errors}
                name="listPriceType"
                required
                label={"bfm.vehicleForm.listPriceTypeCode.label"}
                setFieldValue={setFieldValue}
                options={listPriceTypeOptions || []}
                defaultValue={values?.listPriceType}
                confidentiality={null}
              />
              <div
                className={classes.fieldItem}
                style={{ display: "flex", gap: "15px" }}
              >
                <div style={{ width: "50%" }}>
                  <DoubleNumberInputField
                    id="listPriceMin"
                    setFieldValue={setFieldValue}
                    value={values.listPriceMin}
                    onChange={handleChange}
                    errors={errors}
                    required={
                      values.listPriceMin !== "" || values.listPriceMax !== ""
                    }
                    name={"listPriceMin"}
                    disabled={!!settingId || isReadOnly}
                    label={"bfm.listPriceMin.label"}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <DoubleNumberInputField
                    id="listPriceMax"
                    setFieldValue={setFieldValue}
                    value={values.listPriceMax}
                    onChange={handleChange}
                    errors={errors}
                    required={
                      values.listPriceMin !== "" || values.listPriceMax !== ""
                    }
                    name={"listPriceMax"}
                    disabled={!!settingId || isReadOnly}
                    label={"bfm.listPriceMax.label"}
                  />
                </div>
              </div>
            </>
          </div>
          <div
            className={classes.fieldItem}
            style={{ marginLeft: "10px" }}
          ></div>
        </div>
      ) : null}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          {!isTradeLicensePlate ? (
            <ComboBoxField
              id="carUsage"
              disabled={!!settingId || isReadOnly}
              errors={errors}
              name="carUsage"
              label={"bfm.vehicleForm.carUsageCode.label"}
              setFieldValue={setFieldValue}
              options={carUsageOptions || []}
              defaultValue={values?.carUsage}
              confidentiality={null}
            />
          ) : null}
        </div>

        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          {!isTradeLicensePlate ? (
            <ComboBoxField
              id="vehicleType"
              disabled={!!settingId || isTradeLicensePlate || isReadOnly}
              errors={errors}
              name="vehicleType"
              required={!isTradeLicensePlate}
              label={"bfm.vehicleForm.vehicleTypeCode.label"}
              setFieldValue={setFieldValue}
              options={vehicleTypeCodeOptions || []}
              defaultValue={values?.vehicleType}
              confidentiality={null}
            />
          ) : null}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <VehicleTypeDependencyField
          values={values}
          vehicleTypeSelected={values?.vehicleType}
          fieldItemStyle={classes.fieldItem}
          errors={errors}
          setFieldValue={setFieldValue}
          selectedDependency={defaultData}
          disabled={!!settingId || isReadOnly}
        />
      </div>
      <div className={classes.placeholder}></div>
      {!isReadOnly && (
        <DialogFooter className={classes.footer}>
          <DefaultButton
            id="saveButton"
            primary
            text={t("greco.save")}
            type="submit"
            iconProps={{
              iconName: "Save",
            }}
            disabled={isLoading}
          />
        </DialogFooter>
      )}
    </>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    placeholder: {
      height: 200,
    },
    fieldItem: {
      width: "100%",
      marginTop: 10,
    },
    footer: {
      marginTop: 50,
    },
  });

export default AnnualGrossPremiumFormFields;
