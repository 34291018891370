import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { defaultColumns } from "../pages/fleet/VehicleListTable/columns";
import {
  BooleanFilter,
  ExpandedColumn,
  Filter,
  NumberFilter,
} from "../types/columns";
import { RootState } from "./store";

export type FleetPageColumnOptionsState = {
  columns: EntityState<ExpandedColumn>;
};

const columnsAdapter = createEntityAdapter<ExpandedColumn>({
  selectId: (c) => c.key,
});

export const fleetPageColumnOptionsSlice = createSlice({
  name: "fleetPageColumnOptions",
  initialState: {
    columns: columnsAdapter.getInitialState(),
  },
  reducers: {
    setColumns: (s, a: PayloadAction<ExpandedColumn[]>) => {
      columnsAdapter.setAll(s.columns, a.payload);
    },
    toggleColumnVisibility: (s, a: PayloadAction<ExpandedColumn["key"]>) => {
      const col = s.columns.entities[a.payload];
      if (!col) return;
      columnsAdapter.updateOne(s.columns, {
        id: a.payload,
        changes: {
          isVisible: !col.isVisible,
        },
      });
    },
    showAllColumns: (s) => {
      s.columns.ids.forEach((id) => {
        s.columns.entities[id].isVisible = true;
      });
    },
    hideAllColumns: (s) => {
      s.columns.ids.forEach((id) => {
        const col = s.columns.entities[id];
        if (!col.isFixed) {
          col.isVisible = false;
        }
      });
    },
    reorderColumn: (
      s,
      a: PayloadAction<{ index1: number; index2: number }>
    ) => {
      const { index1, index2 } = a.payload;
      s.columns.ids.splice(index2, 0, s.columns.ids.splice(index1, 1)[0]);
    },
    updateNumberFilter: (
      s,
      a: PayloadAction<{ key: ExpandedColumn["key"]; filter: NumberFilter }>
    ) => {
      const { key, filter } = a.payload;
      const col = s.columns.entities[key];
      if (col.filter && col.filter.type === "number") {
        col.filter = filter;
      }
    },
    setFilter: (s, a: PayloadAction<{ columnKey: string; filter: any }>) => {
      s.columns.entities[a.payload.columnKey].filter = a.payload.filter;
    },
    clearAllFilters: (
      s,
      a: PayloadAction<{
        key: ExpandedColumn["key"];
        value: BooleanFilter["value"];
      }>
    ) => {
      s.columns.ids.forEach((id) => {
        const col = s.columns.entities[id];
        const defaultCol = defaultColumns.find((c) => c.key === col.key);
        col.filter = defaultCol?.filter;
      });
    },
  },
});

export const {
  actions: {
    setColumns: setTempColumnsFleet,
    toggleColumnVisibility,
    showAllColumns,
    hideAllColumns,
    reorderColumn,
    updateNumberFilter,
    clearAllFilters,
    setFilter,
  },
  reducer: fleetPageColumnOptionsReducer,
} = fleetPageColumnOptionsSlice;

export const { selectAll: selectAllColumnsFleetPageColumnOptions } =
  columnsAdapter.getSelectors<RootState>(
    (s) => s.fleetPageColumnOptions.columns
  );

const localSelectors = columnsAdapter.getSelectors();

export const selectAreAllVisible = createSelector(
  (s) => localSelectors.selectAll(s.fleetPageColumnOptions.columns),
  (columns: any) => {
    return !columns.some((c) => !c.isVisible);
  }
);

export const selectAreColumnsDirty = createSelector(
  (s) => localSelectors.selectAll(s.fleetPageColumnOptions.columns),
  (localColumns) => (reduxColumns) => {
    return JSON.stringify(localColumns) !== JSON.stringify(reduxColumns);
  }
);

export const selectAreAnyFiltered = createSelector(
  (s) => localSelectors.selectAll(s.fleetPageColumnOptions.columns),
  (columns: any) => {
    return columns.some(
      (c) => c.filter && c.filter.isFiltered(c.filter as any)
    );
  }
);
