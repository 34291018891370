import { useFormikContext } from "formik";
import { useEffect } from "react";
import { PremiumType } from "types/types";
import { stringToNumber } from "../../../utils/utils";

export const useAnnualNetPremium = (
  premiumTypeKey,
  annualGrossPremiumName,
  annualNetPremiumName,
  isTempVehicle
) => {
  const formik = useFormikContext();
  const annualGrossPremium = Number(
    formik.values[annualGrossPremiumName] &&
      formik.values[annualGrossPremiumName] !== ""
      ? stringToNumber(formik.values[annualGrossPremiumName])
      : 0
  );

  useEffect(() => {
    if (!isTempVehicle) return;
    const netPremiumPerc = premiumTypeKey === PremiumType.ACCIDENT ? 4 : 11;
    const annualNetPremium =
      Math.round(
        ((annualGrossPremium / (100 + netPremiumPerc)) * 100 + Number.EPSILON) *
          100
      ) / 100;
    const annualNetPremimString = annualNetPremium.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (formik.dirty && !formik.isSubmitting) {
      formik.setFieldValue(annualNetPremiumName, annualNetPremimString);
    }
  }, [
    annualGrossPremium,
    annualNetPremiumName,
    formik.touched[annualGrossPremiumName],
    formik.values[annualGrossPremiumName],
    premiumTypeKey,
  ]);
};
