import {
  Checkbox,
  DefaultButton,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import TextInputField from "components/controls/TextInputField";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import { StyledPrimaryButton } from "components/styled";
import { Formik } from "formik";
import { useAdjustDeductibleFleetPremium } from "hooks/data/mutations/useAdjustDeductibleFleetPremium";
import { delay } from "library/delay";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import {
  setDialog,
  setDialogDefaultValueOperation,
  setFleetWithPremiums,
} from "../../../store/fleetForm";
import { useSelector } from "../../../store/hooks";
import useActionConfig from "../hooks/useActionConfg";
import FormActionInsurerField from "./components/FormActionInsurerField";
import { useDefaultSettings } from "./hooks/useDefaultSettings";
import VehicleTypeDependencyField from "./VehicleTypeDependencyField";

const AdjustDeductibleFormDialog = () => {
  const { t } = useTranslation();
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);
  const { mutate: onAdjustDeductible, isLoading } =
    useAdjustDeductibleFleetPremium();

  const taxonomy = useSelector((s) => s.taxonomy);

  const actionsConfig = useActionConfig();
  const dialogData = useSelector((s) => s.fleetForm.dialog);
  const defaultData =
    dialogData.type === "editSetting" && dialogData.data?.selectedSetting;
  const carUsageOptions = actionsConfig.carUsageOptions;

  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;

  useDefaultSettings();

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;
  const insurerOptions = actionsConfig.insurerOptions;

  const initialValues = {
    hullDeductiblePercent: null,
    insurerPartnerNumber: null,
    vehicleType: null,
    listPriceType: null,
    listPriceMin: null,
    listPriceMax: null,

    cubicCapacityCcmRange: null,
    powerKwFrom: null,
    powerKwTo: null,
    weightCapacityKgRange: null,
    validFromDate: new Date(new Date().getFullYear(), 0, 1),
    reasonForModificationComment: null,
    carUsage: null,

    emptyWeightKgFrom: null,
    emptyWeightKgTo: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      hullDeductiblePercent: yup.mixed().required(),
      insurerPartnerNumber: yup.mixed().required(),
      cubicCapacityCcmRange: yup.mixed().nullable(),
      powerKwFrom: yup.number().nullable(),
      powerKwTo: yup
        .number()
        .min(
          yup.ref("powerKwFrom"),
          "Field powerKwTo must be greater than powerKwFrom"
        )
        .nullable(),
      weightCapacityKgRange: yup.mixed().nullable(),
      validFromDate: yup.mixed().required(),
      vehicleType: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(),
      reasonForModificationComment: yup.string().max(512).nullable(),

      emptyWeightKgFrom: yup.number().nullable(),
      emptyWeightKgTo: yup
        .number()
        .min(
          yup.ref("emptyWeightKgFrom"),
          "Field emptyWeightKgTo must be greater than emptyWeightKgFrom"
        )
        .nullable(),
    });
  }, [t, isTradeLicensePlate]);
  const classes = getClassNames();

  const onSubmit = (values) => {
    const body = {
      isTradeLicensePlate: isTradeLicensePlate,
      deductibleChangePercent: Number(
        values.hullDeductiblePercent?.replace(".", "")?.replace(",", ".")
      ),
      listPriceType: values?.listPriceType?.value,
      listPriceMin: values?.listPriceMin
        ? Number(formatDoubleFieldValue(values?.listPriceMin))
        : undefined,
      listPriceMax: values?.listPriceMax
        ? Number(formatDoubleFieldValue(values?.listPriceMax))
        : undefined,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,
      weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      powerKwFrom: values?.powerKwFrom,
      powerKwTo: values?.powerKwTo,

      numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
      validFromDate: moment(values?.validFromDate).format("YYYY-MM-DD"),
      reasonForModificationComment: values?.reasonForModificationComment,
      carUsage: values?.carUsage?.value,

      premiumType: 2, // HULL VALUE

      emptyWeightKgFrom: values?.emptyWeightKgFrom,
      emptyWeightKgTo: values?.emptyWeightKgTo,
    };

    onAdjustDeductible(
      {
        fleetId,
        body,
      },
      {
        onSuccess: (res) => {
          dispatch(setFleetWithPremiums(res?.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };
  const validate = (values) => {
    const errors = {} as any;

    if (
      values.listPriceMin &&
      values.listPriceMax &&
      Number.parseFloat(
        values.listPriceMin
          .replaceAll(" ", "")
          .replaceAll(".", "")
          .replaceAll(",", ".")
      ) >
        Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        )
    ) {
      errors.listPriceMin = t("greco.form.errors.number.max", {
        max: Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ),
      });
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { values, handleSubmit, setFieldValue, handleChange, errors } =
          props;
        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className={classes.fieldItem}>
                    <Checkbox
                      label={t("bfm.tradeLicensePlates.label")}
                      styles={{
                        root: {
                          fontWeight: 600,
                        },
                      }}
                      disabled={false}
                      checked={isTradeLicensePlate}
                      onChange={(ev) => {
                        setFieldValue("vehicleType", null);
                        setFieldValue("carUsage", null);
                        setTradeLicensePlate(!isTradeLicensePlate);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.fieldItem}>
                  <DoubleNumberInputField
                    setFieldValue={setFieldValue}
                    value={values.hullDeductiblePercent}
                    onChange={handleChange}
                    errors={errors}
                    required
                    name={"hullDeductiblePercent"}
                    disabled={false}
                    defaultValueFunction={(value) => value}
                    label={
                      "bfm.fleet.adjustDeductible.deductiblePercentage.label"
                    }
                    maxDecimals={4}
                  />
                </div>
                <div className={classes.fieldItem}>
                  <FormActionInsurerField
                    actionFormType={"adjustDeductible"}
                    allInsurerOptions={insurerOptions}
                    values={values}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className={classes.fieldItem}>
                  <ComboBoxField
                    disabled={false}
                    errors={errors}
                    name="listPriceType"
                    label={"bfm.vehicleForm.listPriceTypeCode.label"}
                    setFieldValue={setFieldValue}
                    options={listPriceTypeOptions || []}
                    defaultValue={values?.listPriceType}
                    confidentiality={null}
                  />
                </div>
                <div
                  className={classes.fieldItem}
                  style={{ display: "flex", gap: "15px" }}
                >
                  <div style={{ width: "50%" }}>
                    <DoubleNumberInputField
                      setFieldValue={setFieldValue}
                      value={values.listPriceMin}
                      onChange={handleChange}
                      errors={errors}
                      name={"listPriceMin"}
                      disabled={false}
                      label={"bfm.listPriceMin.label"}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <DoubleNumberInputField
                      setFieldValue={setFieldValue}
                      value={values.listPriceMax}
                      onChange={handleChange}
                      errors={errors}
                      name={"listPriceMax"}
                      disabled={false}
                      label={"bfm.listPriceMax.label"}
                    />
                  </div>
                </div>
                <div className={classes.fieldItem}>
                  {!isTradeLicensePlate ? (
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="carUsage"
                      label={"bfm.vehicleForm.carUsageCode.label"}
                      setFieldValue={setFieldValue}
                      options={carUsageOptions || []}
                      defaultValue={values?.carUsage}
                      confidentiality={null}
                    />
                  ) : null}
                </div>
                {!isTradeLicensePlate ? (
                  <div className={classes.fieldItem}>
                    <ComboBoxField
                      disabled={isTradeLicensePlate}
                      errors={errors}
                      name="vehicleType"
                      required={true}
                      label={"bfm.vehicleForm.vehicleTypeCode.label"}
                      setFieldValue={setFieldValue}
                      options={vehicleTypeCodeOptions || []}
                      defaultValue={values?.vehicleType}
                      confidentiality={null}
                    />
                  </div>
                ) : null}
                <div className={classes.fieldItem}>
                  <VehicleTypeDependencyField
                    values={values}
                    vehicleTypeSelected={values?.vehicleType}
                    fieldItemStyle={classes.fieldItem}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    selectedDependency={defaultData}
                    disabled={false}
                    includeMargins={false}
                  />
                </div>
                <div className={classes.fieldItem}>
                  <DatePickerField
                    errors={errors}
                    setFieldValue={setFieldValue}
                    defaultValue={values.validFromDate}
                    name={"validFromDate"}
                    disabled={false}
                    label={"bfm.vehicleForm.validFromDate.label"}
                    placeholder={t("bfm.selectDate")}
                    required
                    confidentiality={null}
                  />
                </div>
                <div className={classes.fieldItem}>
                  <TextInputField
                    value={values.reasonForModificationComment}
                    onChange={handleChange}
                    errors={errors}
                    name={"reasonForModificationComment"}
                    multiline
                    rows={4}
                    disabled={false}
                    label={"bfm.comment.label"}
                  />
                </div>
                <div className={classes.footer}>
                  <TooltipHost content={t("bfm.fillInAllDefaults.label")}>
                    <StyledPrimaryButton
                      text={t("bfm.defaultSettings.label")}
                      onClick={() => {
                        let fieldNames1 = [];

                        //fieldNames1.push("annualGrossPremium");
                        fieldNames1.push("deductible");

                        dispatch(
                          setDialogDefaultValueOperation({
                            fieldNames: fieldNames1,
                            formValues: {
                              ...values,
                              premiumType: {
                                value:
                                  taxonomy.PremiumType.byCode[
                                    "PremiumType.HULL"
                                  ].id,
                              },
                              isTradeLicensePlate: isTradeLicensePlate,
                            },
                          })
                        );
                      }}
                      iconProps={{
                        iconName: "Shield",
                      }}
                      disabled={isLoading}
                    />
                  </TooltipHost>

                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </div>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 150,
    },
  });

export default AdjustDeductibleFormDialog;
