import React from "react";
import { useSelector } from "../../../store/hooks";
import { TimelineDialogType } from "../../../types/types";
import { AddPremiumDialog } from "./AddPremiumDialog/AddPremiumDialog";
import { ModifyBookingDialog } from "./ModifyBookingDialog";
import { ModifyLicensePlateDialog } from "./ModifyLicensePlateDialog";
import { ModifyOneTimePremiumDialog } from "./ModifyOneTimePremiumDialog";
import { ModifyPremiumDialog } from "./ModifyPremiumDialog";
import { ModifySharedLicensePlateDialog } from "./ModifySharedLicensePlateDialog";
import { ModifyStatusPointDialog } from "./ModifyStatusPointDialog";

export const TimelineDialog = () => {
  const type = useSelector((s) => s.timeline.dialog.type);

  if (
    type === TimelineDialogType.ADD_PREMIUM ||
    type === TimelineDialogType.INSERT_PREMIUM
  ) {
    return <AddPremiumDialog />;
  }
  if (
    type === TimelineDialogType.ADJUST_PREMIUM ||
    type === TimelineDialogType.CORRECT_PREMIUM ||
    type === TimelineDialogType.CONTINUE_PREMIUM ||
    type === TimelineDialogType.CANCEL_PREMIUM ||
    type === TimelineDialogType.DELETE_PREMIUM
  ) {
    return <ModifyPremiumDialog />;
  }

  if (
    type === TimelineDialogType.ADD_STATUS ||
    type === TimelineDialogType.DELETE_STATUS ||
    type === TimelineDialogType.CORRECT_STATUS ||
    type === TimelineDialogType.INSERT_STATUS ||
    type === TimelineDialogType.ACCEPT_STATUS ||
    type === TimelineDialogType.APPROVE_PENDING_STATUS ||
    type === TimelineDialogType.CANCEL_STATUS
  ) {
    return <ModifyStatusPointDialog />;
  }

  if (type === TimelineDialogType.CHANGE_BOOKING) {
    return <ModifyBookingDialog />;
  }
  if (
    type === TimelineDialogType.INSERT_LICENSE_PLATE ||
    type === TimelineDialogType.DELETE_LICENSE_PLATE ||
    type === TimelineDialogType.CORRECT_LICENSE_PLATE ||
    type === TimelineDialogType.CHANGE_LICENSE_PLATE
  ) {
    return <ModifyLicensePlateDialog />;
  }

  if (
    type === TimelineDialogType.ADD_ONETIME_PREMIUM ||
    type === TimelineDialogType.DELETE_ONETIME_PREMIUM ||
    type === TimelineDialogType.CHANGE_ONETIME_PREMIUM
  ) {
    return <ModifyOneTimePremiumDialog />;
  }

  if (
    type === TimelineDialogType.INSERT_SHARED_LICENSE_PLATE ||
    type === TimelineDialogType.CANCEL_SHARED_LICENSE_PLATE ||
    type === TimelineDialogType.CORRECT_SHARED_LICENSE_PLATE ||
    type === TimelineDialogType.DELETE_SHARED_LICENSE_PLATE ||
    type === TimelineDialogType.CONTINUE_SHARED_LICENSE_PLATE ||
    type === TimelineDialogType.ACTIVATE_SHARED_LICENSE_PLATE ||
    type === TimelineDialogType.SUSPEND_SHARED_LICENSE_PLATE
  ) {
    return <ModifySharedLicensePlateDialog />;
  }
};
