import { toast } from "components/FluentToast";
import { Formik } from "formik";
import { useCreateFleetVehicleDefaultSetting } from "hooks/data/mutations/useCreateFleetVehicleDefaultSetting";
import { useUpdateFleetVehicleDefaultSetting } from "hooks/data/mutations/useUpdateFleetVehicleDefaultSetting";
import { delay } from "library/delay";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import useDefaultSettingsData from "../hooks/useDefaultSettingsData";
import AnnualGrossPremiumFormFields from "./AnnualGrossPremiumFormFields";

type Props = {
  settingId: string;
  attributeName: string;
  isLoading: boolean;
};

const AnnualGrossPremium = ({ settingId, attributeName, isLoading }: Props) => {
  const { t } = useTranslation();
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);

  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);

  const { mutate: onUpdateFleetVehicleDefaultSetting } =
    useUpdateFleetVehicleDefaultSetting();

  const { mutate: onCreateFleetVehicleDefaultSetting } =
    useCreateFleetVehicleDefaultSetting();

  const hasTradeLicensePlateDefault = useSelector(
    (s) => s.fleetForm.dialog.data?.hasTradeLicensePlate
  );

  const actionsConfig = useActionConfig();

  const defaultData = useDefaultSettingsData();

  const rowVersion = defaultData?.rowVersion;

  const dispatch = useDispatch();
  const vehicleType = defaultData?.vehicleType;

  useEffect(() => {
    if ((!vehicleType && settingId) || hasTradeLicensePlateDefault) {
      setTradeLicensePlate(true);
    }
  }, [vehicleType, settingId]);

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const premiumTypeOptions = actionsConfig.premiumTypeOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;

  const insurerOptions = actionsConfig.insurerOptions;

  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const carUsageOptions = actionsConfig.carUsageOptions;
  const initialValues = useMemo(() => {
    return {
      attributeValue:
        typeof defaultData?.attributeValue === "string"
          ? defaultData.attributeValue.replaceAll(".", ",")
          : defaultData?.attributeValue,
      premiumType: defaultData
        ? premiumTypeOptions.find(
            (item) => item.value === defaultData.premiumType
          )
        : "",
      insurerPartnerNumber: defaultData
        ? insurerOptions.find(
            (item) => item.value === defaultData.insurerPartnerNumber
          )
        : "",
      listPriceType: defaultData
        ? listPriceTypeOptions.find(
            (item) => item.value === defaultData.listPriceType
          )
        : "",

      listPriceMin: defaultData ? defaultData.listPriceMin + "" : "",
      listPriceMax: defaultData ? defaultData.listPriceMax + "" : "",

      vehicleType: defaultData
        ? vehicleTypeCodeOptions.find(
            (item) => item.value === defaultData.vehicleType
          )
        : "",
      carUsage: defaultData
        ? carUsageOptions.find((item) => item.value === defaultData.carUsage)
        : "",
      cubicCapacityCcmRange: defaultData
        ? defaultData.cubicCapacityCcmRange
        : "",
      powerKwTo: defaultData ? defaultData.powerKwTo : "",

      powerKwFrom: defaultData ? defaultData.powerKwFrom : "",
      emptyWeightKgFrom: defaultData ? defaultData?.emptyWeightKgFrom : "",
      emptyWeightKgTo: defaultData ? defaultData.emptyWeightKgTo : "",
      weightCapacityKgRange: defaultData
        ? defaultData.weightCapacityKgRange
        : "",
      maxGrossWeightKgRange: defaultData
        ? defaultData.maxGrossWeightKgRange
        : "",
    };
  }, [
    defaultData,
    insurerOptions,
    premiumTypeOptions,
    vehicleTypeCodeOptions,
    listPriceTypeOptions,
    carUsageOptions,
  ]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      attributeValue: yup.string().required(),
      premiumType: yup.mixed().required(),

      insurerPartnerNumber: yup.mixed().required(),
      //vehicleMainGroup: yup.mixed().required(),
      vehicleType: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(),
      cubicCapacityCcmRange: yup.mixed().nullable(),
      powerKwFrom: yup.number().nullable(),
      powerKwTo: yup
        .number()
        .min(
          yup.ref("powerKwFrom"),
          "Field powerKwTo must be greater than powerKwFrom"
        )
        .nullable(),
      emptyWeightKgFrom: yup.number().nullable(),
      emptyWeightKgTo: yup
        .number()
        .min(
          yup.ref("emptyWeightKgFrom"),
          "Field emptyWeightKgTo must be greater than emptyWeightKgFrom"
        )
        .nullable(),
      weightCapacityKgRange: yup.mixed().nullable(),
    });
  }, [t, isTradeLicensePlate]);

  const onSubmit = (values) => {
    const body = {
      isTradeLicensePlate: isTradeLicensePlate,
      attributeValue: Number(formatDoubleFieldValue(values?.attributeValue)),
      attributeName,
      premiumType: values?.premiumType.value,
      listPriceType: values?.listPriceType?.value,
      listPriceMin: values?.listPriceMin
        ? Number(formatDoubleFieldValue(values?.listPriceMin))
        : undefined,
      listPriceMax: values?.listPriceMax
        ? Number(formatDoubleFieldValue(values?.listPriceMax))
        : undefined,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      //vehicleMainGroup: values?.vehicleMainGroup?.value,
      carUsage: values?.carUsage?.value,
      vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,
      weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      powerKwFrom: values?.powerKwFrom,
      powerKwTo: values?.powerKwTo,
      emptyWeightKgFrom: values?.emptyWeightKgFrom,
      emptyWeightKgTo: values?.emptyWeightKgTo,
      numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
    };

    if (!!settingId) {
      onUpdateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          fleetDefaultSettingId: settingId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
            rowVersion,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
          },
        }
      );
    } else {
      onCreateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
          },
        }
      );
    }
  };
  const validate = (values) => {
    const errors = {} as any;
    if (
      values.premiumType?.code === "HULL_BASIC" ||
      values.premiumType?.code === "HULL"
    ) {
      if (!values.listPriceType?.value) {
        errors.listPriceType = t("greco.required");
      }
      if (values.listPriceMin === "" && values.listPriceMax !== "") {
        errors.listPriceMin = t("greco.required");
      }
      if (values.listPriceMax === "" && values.listPriceMin !== "") {
        errors.listPriceMax = t("greco.required");
      }
      if (
        !errors.listPriceMin &&
        !errors.listPriceMax &&
        Number.parseFloat(
          values.listPriceMin
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ) >
          Number.parseFloat(
            values.listPriceMax
              .replaceAll(" ", "")
              .replaceAll(".", "")
              .replaceAll(",", ".")
          )
      ) {
        errors.listPriceMin = t("greco.form.errors.number.max", {
          max: Number.parseFloat(
            values.listPriceMax
              .replaceAll(" ", "")
              .replaceAll(".", "")
              .replaceAll(",", ".")
          ),
        });
      }
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { handleSubmit } = props;

        return (
          <>
            <div>
              <form onSubmit={handleSubmit}>
                <AnnualGrossPremiumFormFields
                  listPriceTypeOptions={listPriceTypeOptions}
                  isTradeLicensePlate={isTradeLicensePlate}
                  setTradeLicensePlate={setTradeLicensePlate}
                  settingId={settingId}
                  fieldProps={props}
                  defaultData={defaultData}
                  insurerOptions={insurerOptions}
                  carUsageOptions={carUsageOptions}
                  vehicleTypeCodeOptions={vehicleTypeCodeOptions}
                  isLoading={isLoading}
                  premiumTypeOptions={premiumTypeOptions}
                />
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AnnualGrossPremium;
