import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { ExpandedColumn } from "../types/columns";

type Props = {
  columns: ExpandedColumn[];
  fileName: string;
};

export const ExportExcelVehicles = forwardRef<ExcelExport, Props>(
  ({ columns, fileName }, ref) => {
    const { t } = useTranslation();
    return (
      <ExcelExport ref={ref} fileName={fileName}>
        {columns.map((c) => {
          if (c.excel && c.excel.isVisible === false) return null;
          let width = c.width;
          if (c.excel && c.excel.width !== undefined) {
            width = c.excel.width;
          }
          return (
            <ExcelExportColumn
              key={c.key}
              field={c.key}
              title={t(c.labelKey)}
              width={width}
              headerCellOptions={{
                background: "#005aa1",
                color: "#fff",
                textAlign: c.align ?? ("center" as any),
              }}
              cellOptions={{
                textAlign: c.align ?? ("center" as any),
              }}
            />
          );
        })}
      </ExcelExport>
    );
  }
);
