import {
  ContextualMenu,
  IContextualMenuItem,
  mergeStyles,
} from "@fluentui/react";
import { toast } from "components/FluentToast";

import { isExternalOrReadonlyAdmin } from "form/util";
import { useTransferTempVehicle } from "hooks/data/mutations/useTransferTempVehicle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserRole } from "types/types";
import { routes } from "../../../../config/routes";
import {
  setDeleteDialog,
  setDeleteTEMPDialog,
  setDuplicateVehicleWithPremiumsDialog,
  setRemoveTEMPVehicle,
} from "../../../../store/fleetPage";
import { useSelector } from "../../../../store/hooks";
import { AppDispatch } from "../../../../store/store";

type Props = {
  vehicle: any;
  fleetId: number;
  onClickChangeStatus: () => void;
  onClickChangeIssuingStatus: () => void;
  onClickChangePendingIssuingStatus: () => void;
  // onClickCorrectStatusDate: () => void;
  onClickApproveStatus: () => void;
  onClickCancelStatus: () => void;
  isUpdateVehicleStatusLoading: boolean;
  isApproveVehicleStatusLoading: boolean;
  target: any;
  onDismiss: any;
};

const displayNoneClass = mergeStyles({
  display: "none",
});

const ContextualMenuItem = ({
  vehicle,
  fleetId,
  onDismiss,
  target,
  onClickChangeStatus,
  // onClickCorrectStatusDate,
  onClickChangeIssuingStatus,
  onClickChangePendingIssuingStatus,
  onClickApproveStatus,
  onClickCancelStatus,
  isUpdateVehicleStatusLoading,
  isApproveVehicleStatusLoading,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const { mutate: onTransferTempVehicle } = useTransferTempVehicle();

  const userRole = useSelector((s) => s.auth.userRole);

  const selectedVehicleIds = useSelector((s) => s.fleetPage.selectedVehicleIds);
  const isMultipleAdminSelection =
    selectedVehicleIds.length > 1 && userRole !== UserRole.external;
  const isMultipleExternalSelection =
    selectedVehicleIds.length > 1 && userRole === UserRole.external;
  const vehicleStatusTaxCode =
    taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode]?.code;

  const shouldDisableCorrectStatusDate =
    new Date(vehicle.vehicleLastStatusDate) <
      new Date(vehicle.fleetPolicyCoverPeriodStartDate) ||
    new Date(vehicle.vehicleLastStatusDate) >=
      new Date(vehicle.fleetPolicyCoverPeriodEndDate);

  let items: IContextualMenuItem[] = [
    {
      key: "open",
      text: t("bfm.openInNewTab.label"),
      onClick: () => {
        window.open(
          routes[
            vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
          ].getPath(vehicle?.fleetId, vehicle?.vehicleId)
        );
      },
      disabled: isUpdateVehicleStatusLoading || isMultipleAdminSelection,
    },
    {
      key: "edit",
      text: t("bfm.editVehicle.label"),
      onClick: () => {
        if (vehicle.isTEMP) {
          navigate(
            routes.editTempVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
          );
        }
        //
        else {
          navigate(
            routes.editVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
          );
        }
      },
      disabled:
        userRole === UserRole.adminreadonly ||
        isUpdateVehicleStatusLoading ||
        isMultipleAdminSelection ||
        isMultipleExternalSelection,
    },
    {
      key: "duplicate",
      text: t("bfm.duplicateVehicle.label"),
      onClick: () => {
        navigate(
          routes.duplicateVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
        );
      },
      className: vehicle.isTEMP ? displayNoneClass : "",
      disabled:
        userRole === UserRole.adminreadonly ||
        isUpdateVehicleStatusLoading ||
        isMultipleAdminSelection ||
        isMultipleExternalSelection,
    },
    {
      key: "duplicateWithPremiums",
      text: t("bfm.duplicateVehicleWithPremiums.label"),
      onClick: () => {
        dispatch(
          setDuplicateVehicleWithPremiumsDialog({
            isOpen: true,
            vehicleId: vehicle.vehicleId,
            fleetId: "" + fleetId,
            isTradeLicensePlate: vehicle.isTradeLicensePlate,
          })
        );
      },
      className:
        vehicle.isTEMP || isExternalOrReadonlyAdmin(userRole)
          ? displayNoneClass
          : "",
      disabled:
        isUpdateVehicleStatusLoading ||
        isMultipleAdminSelection ||
        isMultipleExternalSelection,
    },
    {
      key: "changeStatus",
      text: t("bfm.changeStatus.label"),
      onClick: onClickChangeStatus,
      className: vehicle.isTEMP
        ? mergeStyles({
            display: "none",
          })
        : "",
      disabled:
        (userRole === UserRole.external &&
          [
            "VehicleStatusCode.PENDING_FOR_ACTIVATION",
            "VehicleStatusCode.PENDING_FOR_SUSPENSION",
            "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
          ].includes(vehicleStatusTaxCode)) ||
        userRole === UserRole.adminreadonly ||
        isUpdateVehicleStatusLoading,
    },
    {
      key: "changeIssuingStatus",
      text: t("bfm.changeIssuingStatus.label"),
      onClick: onClickChangeIssuingStatus,
      className: vehicle.isTEMP
        ? displayNoneClass
        : "" || isExternalOrReadonlyAdmin(userRole)
        ? displayNoneClass
        : "",
      disabled: isUpdateVehicleStatusLoading,
    },
    {
      key: "transfer",
      text: t("sdafdasf"),
      onClick: (e) => {
        e.preventDefault();
        onTransferTempVehicle(
          {
            fleetId: vehicle.fleetId,
            vehicleId: vehicle.vehicleId,
          },

          {
            onSuccess: (res) => {
              dispatch(setRemoveTEMPVehicle(res.data));
              toast.success(t("bfm.notification.transfer.success"));
              onDismiss();
            },
          }
        );
      },
      disabled: isExternalOrReadonlyAdmin(userRole) || isMultipleAdminSelection,
      className: !vehicle.isTEMP ? displayNoneClass : "",
    },
    {
      key: "delete",
      text: t("bfm.deleteTempVehicle.label"),
      className: isExternalOrReadonlyAdmin(userRole) ? displayNoneClass : "",
      disabled: isExternalOrReadonlyAdmin(userRole),
      onClick: () => {
        if (vehicle.isTEMP) {
          dispatch(
            setDeleteTEMPDialog({
              isOpen: true,
              fleetId: vehicle.fleetId,
              vehicleId: vehicle.vehicleId,
            })
          );
        }
        //
        else {
          dispatch(
            setDeleteDialog({
              isOpen: true,
              vehicleIds: selectedVehicleIds,
              fleetId: "" + fleetId,
            })
          );
        }
      },
    },
    {
      key: "approveStatus",
      text: t("bfm.approveStatus"),
      onClick: onClickApproveStatus,
      disabled: isApproveVehicleStatusLoading,
      className:
        isExternalOrReadonlyAdmin(userRole) ||
        vehicle.isTEMP ||
        ![
          "VehicleStatusCode.PENDING_FOR_ACTIVATION",
          "VehicleStatusCode.PENDING_FOR_SUSPENSION",
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
        ].includes(vehicleStatusTaxCode)
          ? displayNoneClass
          : "",
    },
    {
      key: "cancelStatus",
      text: t("bfm.cancelStatus"),
      onClick: onClickCancelStatus,
      disabled:
        isApproveVehicleStatusLoading ||
        isMultipleAdminSelection ||
        userRole === UserRole.adminreadonly,
      className:
        vehicle.isTEMP ||
        ![
          "VehicleStatusCode.PENDING_FOR_ACTIVATION",
          "VehicleStatusCode.PENDING_FOR_SUSPENSION",
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
        ].includes(vehicleStatusTaxCode)
          ? displayNoneClass
          : "",
    },
  ];

  return (
    <ContextualMenu
      items={items}
      target={target}
      gapSpace={10}
      onDismiss={onDismiss}
    />
  );
};

export default ContextualMenuItem;
