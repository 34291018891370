import { useConst } from "@uifabric/react-hooks";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  MarqueeSelection,
  Modal,
  ScrollablePane,
  SelectAllVisibility,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  Sticky,
  StickyPositionType,
  TooltipHost,
  mergeStyleSets,
  mergeStyles,
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { usePrevious } from "react-use";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { messaging } from "../../../config/colors";
import { routes } from "../../../config/routes";
import {
  loadVehiclesActions,
  setSortFleetPage,
  toogleSelectedVehiclesIds,
} from "../../../store/fleetPage";
import { useSelector } from "../../../store/hooks";
import VehicleContextMenu from "./VehicleContextMenu/VehicleContextMenu";
import ContextButton from "./columnCells/ContextButton";

type TContextualMenyProps = {
  onDismiss: Function;
  target: any;
};

const VehicleListTable = ({
  items,
  columns,
  clearAllSelectedVehicles,
  setClearAllSelectedVehicles,
}) => {
  const theme = useTheme() as IStyledTheme;
  // const tempColumns = useSelector(selectAllColumnsFleetPageColumnOptions);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadVehiclesStatus = useSelector((s) => s.fleetPage.loadVehiclesStatus);
  const vehiclesTotalCount = useSelector((s) => s.fleetPage.vehiclesTotalCount);
  const sort = useSelector((s) => s.fleetPage.sort);
  const taxonomy = useSelector((s) => s.taxonomy);

  const [isLoadingNewFiles, setIsLoadingNewFiles] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [contextualMenuProps, setContextualMenuProps] = useState<
    TContextualMenyProps | undefined
  >(undefined);

  const isLoading = loadVehiclesStatus === "loading";

  const classes = getClassNames(theme);

  const selection = useConst(
    () =>
      new Selection({
        onSelectionChanged: async () => {
          const selected = selection.getSelection();
          setSelectedVehicles(selected);
          const selectedVehiclesIds = selected.map(
            (vehicle: any) => vehicle.vehicleId
          );
          await dispatch(toogleSelectedVehiclesIds(selectedVehiclesIds));
        },
      })
  );

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort.key === column.key;

    if (isSorted) {
      if (sort.dir === "desc" && sort.key === "vehicleLastModifiedAt") {
        dispatch(
          setSortFleetPage({
            key: column.key,
            dir: "asc",
          })
        );
        return;
      }
      if (sort.dir === "asc") {
        dispatch(
          setSortFleetPage({
            key: column.key,
            dir: "desc",
          })
        );
      } else {
        dispatch(
          setSortFleetPage({
            key: "vehicleLastModifiedAt",
            dir: "desc",
          })
        );
      }
    } else {
      dispatch(
        setSortFleetPage({
          key: column.key,
          dir: "asc",
        })
      );
    }
  };

  const onItemInvoked = (vehicle) => {
    const link = routes[
      vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
    ].getPath(vehicle?.fleetId, vehicle?.vehicleId);
    navigate(link);
  };

  const onItemContextMenu = (
    item?: any,
    index?: number | undefined,
    ev?: Event | undefined
  ): boolean => {
    rightClickHandler(item, ev, columns[index || 0]);
    return false;
  };

  const rightClickHandler = (item: any, ev: any, column: IColumn): void => {
    setContextualMenuProps({
      target: column ? ev : { x: ev.pageX, y: ev.pageY },
      onDismiss: () => {
        setContextualMenuProps(undefined);
      },
    });
  };

  const _getKey = (item: any, index?: number): string => {
    return item && item.vehicleId;
  };

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      <>
        {defaultRender!({
          ...props,
          selectAllVisibility: SelectAllVisibility.hidden,
          onRenderColumnHeaderTooltip,
        })}
      </>
    </Sticky>
  );

  const fetchMoreData = async () => {
    if (isLoading || isLoadingNewFiles) return;
    setIsLoadingNewFiles(true);
    await dispatch(
      loadVehiclesActions.trigger({
        skip: items.length,
        top: 40,
        isReset: false,
      })
    );
  };

  const endReached = items.length >= vehiclesTotalCount;
  const fetchNewFiles = (ev) => {
    const element = ev.target;
    if (element && !isLoading) {
      let remaningPixels =
        element.scrollHeight - element.scrollTop - element.clientHeight <= 150;
      if (remaningPixels && !endReached) {
        fetchMoreData();
      }
    }
  };

  const prevItemsLength = usePrevious(items.length);
  useEffect(() => {
    if (prevItemsLength && prevItemsLength !== items.length) {
      setIsLoadingNewFiles(false);
    }
  }, [items, prevItemsLength]);

  useEffect(() => {
    if (clearAllSelectedVehicles) {
      selection.setAllSelected(false);
      setClearAllSelectedVehicles(false);
    }
  }, [clearAllSelectedVehicles, selection, setClearAllSelectedVehicles]);

  const onRenderRow = (props) => {
    if (props) {
      const row = props.item;
      let customRootClass = mergeStyles({});
      if (!row) return undefined;
      const issuingStatusTaxonomyCode =
        taxonomy.VehicleIssuingStatus.byId[row.vehicleIssuingStatusCode]?.code;

      if (issuingStatusTaxonomyCode === "VehicleIssuingStatus.INCORRECT") {
        customRootClass = mergeStyles({
          // Add your custom styles here
          backgroundColor: theme.isDark
            ? theme.palette.redDark + " !important"
            : messaging.severeWarning + " !important",
          // ... other styles
        });
      }
      if (issuingStatusTaxonomyCode === "VehicleIssuingStatus.NOT_YET_BOOKED") {
        customRootClass = mergeStyles({
          // Add your custom styles here
          backgroundColor: theme.isDark
            ? theme.palette.neutralLighter + " !important"
            : theme.palette.neutralQuaternary + " !important",
          // ... other styles
        });
      }

      return <DetailsRow {...props} className={customRootClass} />;
    }
    return null;
  };
  return (
    <>
      <div className={classes.container} id="scrollableDiv">
        {contextualMenuProps && (
          <VehicleContextMenu
            vehicle={selectedVehicles}
            target={contextualMenuProps.target}
            onDismiss={contextualMenuProps.onDismiss}
          />
        )}
        <ScrollablePane
          onScroll={(ev) => {
            if (!endReached && !isLoading) {
              fetchNewFiles(ev);
            }
          }}
        >
          <MarqueeSelection selection={selection}>
            <ShimmeredDetailsList
              items={items || []}
              compact={false}
              columns={columns || []}
              selectionMode={SelectionMode.multiple}
              setKey="multiple"
              getKey={_getKey}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderItemColumn={(item, index, column) => {
                if (column.key === "options") {
                  return (
                    <span className="action-icon">
                      <ContextButton vehicle={item} />
                    </span>
                  );
                }
                return item[column.key];
              }}
              selection={selection}
              enableShimmer={isLoading && !isLoadingNewFiles}
              shimmerLines={20}
              selectionPreservedOnEmptyClick={true}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              constrainMode={ConstrainMode.unconstrained}
              className={classes.table}
              onRenderRow={onRenderRow}
              onItemInvoked={onItemInvoked}
              onItemContextMenu={onItemContextMenu}
              onColumnHeaderClick={(ev, column) => {
                onClickHeaderCell(column);
              }}
              onRenderDetailsHeader={(
                props: IDetailsHeaderProps | undefined,
                defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
              ) => onRenderDetailsHeader(props, defaultRender)}
            />
          </MarqueeSelection>
        </ScrollablePane>
      </div>
      <Modal //prevent scrolling on loading data
        styles={{
          root: {
            opacity: 0,
          },
        }}
        isOpen={isLoading}
        isBlocking={isLoading}
      ></Modal>
      {isLoading && <Spinner />}
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "80vh",
      height: "calc(100vh - 50px - 120px)",
      marginBottom: "20px",
      position: "relative",
      overflow: "auto",
      width: "100%",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px ",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark ? "rgb(9, 27, 41) " : "rgb(208, 231, 248) ",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark ? "rgb(9, 27, 41) " : "rgb(208, 231, 248) ",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
        userSelect: "text ",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

export default VehicleListTable;
