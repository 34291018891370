import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "../types/types";

export type AuthState = {
  userRole: null | UserRole;
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userRole: null,
  } as AuthState,
  reducers: {
    setUserRole: (s, a: PayloadAction<AuthState["userRole"]>) => {
      s.userRole = a.payload;
    },
  },
});

export const {
  actions: { setUserRole },
  reducer: authReducer,
} = authSlice;
