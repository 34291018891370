import { formatISO } from "date-fns";
import { stringToNumber } from "../../utils/utils";

export const transformForServer = ({
  obj,
  config,
}: {
  obj: { [key: string]: any };
  config: any;
}) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const fieldConfig = config[key];

    if (!fieldConfig) {
      result[key] = null;
      return result;
    }
    if (fieldConfig.type === "date") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = formatISO(value, { representation: "date" });
    }
    //
    else if (fieldConfig.type === "number") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = stringToNumber(value);
    } else if (fieldConfig.type === "dueDate") {
      const stringValue: string = value;
      if (!stringValue) {
        result[fieldConfig.dayFieldName] = null;
        result[fieldConfig.monthFieldName] = null;
        return result;
      }
      result[fieldConfig.dayFieldName] = stringToNumber(
        stringValue.substring(0, 2)
      );
      result[fieldConfig.monthFieldName] = stringToNumber(
        stringValue.substring(2, 4)
      );
    }
    //
    else if (fieldConfig.type === "taxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "timelineoperationvehiclecombobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "combobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "hullproduct") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "hulltypecombobox") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "insurerTaxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "insurerVehicleTaxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    } else if (fieldConfig.type === "insurerBranchOfficeTaxonomy") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      result[key] = value.value;
    }
    //
    else if (fieldConfig.type === "text") {
      if (value === null || value === undefined) {
        result[key] = null;
        return result;
      }
      if (typeof value === "string") {
        result[key] = value.trim();
      }
      //
      else {
        result[key] = value;
      }
    }
    //
    else if (fieldConfig.type === "checkbox") {
      result[key] = value;
    }
    return result;
  }, {});
};
