import { Dialog, DialogFooter, Icon } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useCancelVehiclePendingStatus } from "hooks/data/mutations/useCancelVehiclePendingStatus";
import { useCorrectVehicleStatus } from "hooks/data/mutations/useCorrectVehicleStatus";
import { useDeleteVehicleStatus } from "hooks/data/mutations/useDeleteVehicleStatus";
import { useInsertVehicleStatus } from "hooks/data/mutations/useInsertVehicleStatus";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import * as yup from "yup";
import { toast } from "../../../components/FluentToast";
import { FormSpinner } from "../../../components/FormSpinner";
import { transformForServer } from "../../../components/form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../../components/styled";
import { RefProvider } from "../../../contexts/RefProvider";
import { useSelector } from "../../../store/hooks";
import { closeDialog, setOperationRetVal } from "../../../store/timeline";
import { TimelineDialogType } from "../../../types/types";
import { setYupLocale } from "../../../utils/setYupLocale";
import { removeEmpty } from "../../../utils/utils";
import { ModifyStatusPointDialogContents } from "./ModifyStatusPointDialogContents";
import { modifyStatusPointDialogConfig } from "./modifyStatusPointDialogConfig";
import { validationObjectConfig } from "./modifyStatusPointDialogValidation";
import { transformForForm } from "./transformForForm";

export enum ModifyStatusPointDialogType {
  Insert,
  Correct,
  Delete,
  Cancel,
}

export const ModifyStatusPointDialog = () => {
  const theme = useTheme() as IStyledTheme;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const item = useSelector((s) => s.timeline.dialog.item);
  const validFrom = useSelector((s) => s.timeline.dialog.date);
  const validTo = useSelector((s) => s.timeline.dialog.item.endDate);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const phraseActivated = usePhraseActivationStatus().activated;

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(item, dialogType);
    return yup.object(yupObject);
  }, [t, dialogType, item]);

  const {
    mutate: onCancelVehiclePendingStatus,
    isLoading: cancelVehiclePendingStatusInProgress,
  } = useCancelVehiclePendingStatus();
  const {
    mutate: onCorrectVehiclePendingStatus,
    isLoading: correctVehiclePendingStatusInProgress,
  } = useCorrectVehicleStatus();
  const {
    mutate: onDeleteVehiclePendingStatus,
    isLoading: deleteVehiclePendingStatusInProgress,
  } = useDeleteVehicleStatus();
  const {
    mutate: onInsertVehiclePendingStatus,
    isLoading: insertVehiclePendingStatusInProgress,
  } = useInsertVehicleStatus();

  const handleSuccess = (res) => {
    dispatch(setOperationRetVal(res?.data));
    toast.success(t("bfm.success"));
    dispatch(closeDialog());
  };

  const isLoading =
    cancelVehiclePendingStatusInProgress ||
    correctVehiclePendingStatusInProgress ||
    deleteVehiclePendingStatusInProgress ||
    insertVehiclePendingStatusInProgress;

  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const taxonomy = useSelector((s) => s.taxonomy);
  const insurerList = useSelector((s) => s.vehicle.insurerList);
  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: modifyStatusPointDialogConfig,
    }) as any;
    result = removeEmpty(result);
    let objectToSend: any = {
      vehicleId: vehicle.vehicleId as any,
      vehicleStatusCode: item?.associatedObject?.statusCode,
      vehicleStatusId: item?.associatedObject?.statusId as any,
      fleetId: vehicle.fleetId as any,

      body: {
        ...result,
        vehicleStatusCode: item?.associatedObject?.statusCode,
      },
    };
    if (dialogType === TimelineDialogType.CORRECT_STATUS) {
      onCorrectVehiclePendingStatus(
        {
          rowVersion,
          params: objectToSend,
        },
        { onSuccess: handleSuccess }
      );
    }

    if (dialogType === TimelineDialogType.INSERT_STATUS) {
      onInsertVehiclePendingStatus(
        {
          rowVersion,
          params: objectToSend,
        },
        { onSuccess: handleSuccess }
      );
    }
    if (dialogType === TimelineDialogType.DELETE_STATUS) {
      onDeleteVehiclePendingStatus(
        {
          rowVersion,
          params: objectToSend,
        },
        { onSuccess: handleSuccess }
      );
    }
    if (dialogType === TimelineDialogType.CANCEL_STATUS) {
      objectToSend = {
        fleetId: vehicle.fleetId as any,
        vehicleId: vehicle.vehicleId as any,
        comment: data.comment,
        rowVersion,
        ...result,
      };

      onCancelVehiclePendingStatus(
        {
          rowVersion,
          params: objectToSend,
        },
        { onSuccess: handleSuccess }
      );
    }
  };
  const Title = (
    <Flex>
      <Icon
        iconName="CircleCorrectition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {dialogType === TimelineDialogType.CORRECT_STATUS
          ? t("bfm.correctStatusDate")
          : null}
        {dialogType === TimelineDialogType.INSERT_STATUS
          ? t("bfm.insertStatus") +
            (" " +
              (taxonomy.VehicleStatusCode.byId[item.associatedObject.statusCode]
                .code === "VehicleStatusCode.ACTIVE"
                ? t("VehicleStatusCode.SUSPENDED")
                : t("VehicleStatusCode.ACTIVE")))
          : null}
        {dialogType === TimelineDialogType.DELETE_STATUS
          ? t("bfm.deleteStatusChange")
          : null}
        {dialogType === TimelineDialogType.CANCEL_STATUS
          ? t("bfm.cancelVehicleStatus")
          : null}
      </div>
    </Flex>
  );

  const initialValues = useMemo(() => {
    let initObj = transformForForm({
      obj: {
        vehicleStatusCode: item?.associatedObject?.statusCode,
        vehicleLastStatusDate: item?.startDate,
        vehicleStatusId: item?.associatedObject?.statusId,
        validFromDate: validFrom,
        validToDate: validTo,
      },
      config: modifyStatusPointDialogConfig,
      t,
      taxonomy,
      insurerList,
      possibleMainVehicles: null,
    });
    initObj = {
      ...initObj,
    };
    return initObj;
  }, [t, taxonomy, insurerList, item, validFrom, validTo]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={400}
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <Form>
                <ModifyStatusPointDialogContents dialogType={dialogType} />
                <DialogFooter>
                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </DialogFooter>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
