import ComboBoxField from "components/controls/ComboBoxField";
import NumberInputField from "components/controls/NumberInputField";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePrevious } from "react-use";
import { useSelector } from "store/hooks";
import styled from "styled-components";

const defaultConfigs = [];

const VehicleTypeDependencyField = ({
  vehicleTypeSelected,
  fieldItemStyle,
  errors,
  setFieldValue,
  selectedDependency = {},
  disabled = false,
  includeMargins = true,
  values,
}) => {
  const [selectedFieldConfigs, setSelectedFieldConfigs] =
    useState(defaultConfigs);
  const [defaultValues, setDefaultValues] = useState(defaultConfigs);
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const createFieldConfig = useCallback(() => {
    if (!vehicleTypeSelected) {
      setSelectedFieldConfigs(defaultConfigs);
      return;
    }
    switch (vehicleTypeSelected?.code) {
      //MOPED
      case "VehicleTypeLegal.MOPED":
      case "VehicleTypeLegal.THREE_WHEELED_MOPED":
        const options_moped = taxonomy.NumberOfSeatsMoped.items.map((item) => ({
          value: item.id,
          label: t(item.code),
          code: item?.code?.split(".")[1],
        }));
        setSelectedFieldConfigs([
          {
            options: options_moped,
            label: t("bfm.vehicleForm.seats.label"),
            name: "numberOfSeatsMoped",
          },
        ]);

        break;
      // KRAD
      case "VehicleTypeLegal.MOTORCYCLE":
      case "VehicleTypeLegal.MOTORCYCLE_WITH_SIDECAR":
      case "VehicleTypeLegal.THREE_WHEELER_VEHICLE":
        const optionsHg1 = taxonomy.CubicCapacityCcmRangeHg1.items.map(
          (item) => ({
            value: item.id,
            label: t(item.code),
            code: item?.code?.split(".")[1],
          })
        );

        setSelectedFieldConfigs([
          {
            label: t("bfm.vehicleForm.powerKwFrom.label"),
            name: "powerKwFrom",
          },
          {
            label: t("bfm.vehicleForm.powerKwTo.label"),
            name: "powerKwTo",
          },
          {
            options: optionsHg1,
            label: t("bfm.vehicleForm.cubicCapacityCcm.label"),
            name: "cubicCapacityCcmRange",
          },
        ]);
        break;
      // PKW
      case "VehicleTypeLegal.PASSENGER_CAR":
        setSelectedFieldConfigs([
          {
            label: t("bfm.vehicleForm.emptyWeightKgFrom.label"),
            name: "emptyWeightKgFrom",
          },
          {
            label: t("bfm.vehicleForm.emptyWeightKgTo.label"),
            name: "emptyWeightKgTo",
          },
          {
            label: t("bfm.vehicleForm.powerKwFrom.label"),
            name: "powerKwFrom",
          },
          {
            label: t("bfm.vehicleForm.powerKwTo.label"),
            name: "powerKwTo",
          },
        ]);

        break;
      case "VehicleTypeLegal.ESTATE_CAR":
        setSelectedFieldConfigs([
          {
            label: t("bfm.vehicleForm.powerKwFrom.label"),
            name: "powerKwFrom",
          },
          {
            label: t("bfm.vehicleForm.powerKwTo.label"),
            name: "powerKwTo",
          },
        ]);

        break;
      //OMNIBUS
      case "VehicleTypeLegal.BUS":
        const options_bus = taxonomy.NumberOfSeatsRange.items.map((item) => ({
          value: item.id,
          label: t(item.code),
          code: item?.code?.split(".")[1],
        }));
        setSelectedFieldConfigs([
          {
            options: options_bus,
            label: t("bfm.vehicleForm.seats.label"),
            name: "numberOfSeatsRange",
          },
        ]);

        break;

      // LKW
      case "VehicleTypeLegal.TRUCK":
        const optionsHg5 = taxonomy.WeightCapacityKgRange.items.map((item) => ({
          value: item.id,
          label: t(item.code),
          code: item?.code?.split(".")[1],
        }));

        const optionsHg5_2 = taxonomy.MaxGrossWeightKgRange.items.map(
          (item) => ({
            value: item.id,
            label: t(item.code),
            code: item?.code?.split(".")[1],
          })
        );

        setSelectedFieldConfigs([
          {
            options: optionsHg5,
            label: t("bfm.fleet.adjustmentDialog.weightCapacityKgRange"),
            name: "weightCapacityKgRange",
          },

          {
            options: optionsHg5_2,
            label: t("bfm.fleet.adjustmentDialog.maxGrossWeightKgRange"),
            name: "maxGrossWeightKgRange",
          },
          {
            label: t("bfm.vehicleForm.emptyWeightKgFrom.label"),
            name: "emptyWeightKgFrom",
          },
          {
            label: t("bfm.vehicleForm.emptyWeightKgTo.label"),
            name: "emptyWeightKgTo",
          },
        ]);

        break;

      //TRUCK-TRACTOR
      case "VehicleTypeLegal.TRUCK_TRACTOR":
        setSelectedFieldConfigs([
          {
            label: t("bfm.vehicleForm.emptyWeightKgFrom.label"),
            name: "emptyWeightKgFrom",
          },
          {
            label: t("bfm.vehicleForm.emptyWeightKgTo.label"),
            name: "emptyWeightKgTo",
          },
        ]);

        break;

      // Trailer
      case "VehicleTypeLegal.TRAILER":
      case "VehicleTypeLegal.SEMI_TRAILER":
      case "VehicleTypeLegal.CENTRE_AXLE_TRAILER":
      case "VehicleTypeLegal.RIGID_DRAWBAR_TRAILER":
      case "VehicleTypeLegal.TRAILER_WORK_MASCHINE":
      case "VehicleTypeLegal.SINGLE_AXLE_TRAILER":
        const optionsTrailer = taxonomy.WeightCapacityKgRange.items.map(
          (item) => ({
            value: item.id,
            label: t(item.code),
            code: item?.code?.split(".")[1],
          })
        );
        setSelectedFieldConfigs([
          {
            options: optionsTrailer,
            label: t("bfm.fleet.adjustmentDialog.weightCapacityKgRange"),
            name: "weightCapacityKgRange",
          },
        ]);

        break;
      default:
        setSelectedFieldConfigs(defaultConfigs);
    }
  }, [
    vehicleTypeSelected,
    taxonomy.CubicCapacityCcmRangeHg1.items,
    taxonomy.WeightCapacityKgRange.items,
    taxonomy.MaxGrossWeightKgRange.items,
    taxonomy.NumberOfSeatsMoped.items,
    taxonomy.NumberOfSeatsRange.items,
    t,
  ]);

  const prevVehicleType = usePrevious(vehicleTypeSelected);

  useEffect(() => {
    setFieldValue("cubicCapacityCcmRange", null);
    setFieldValue("weightCapacityKgRange", null);
    setFieldValue("maxGrossWeightKgRange", null);
    setFieldValue("numberOfSeatsMoped", null);
    setFieldValue("numberOfSeatsRange", null);
    if (prevVehicleType) {
      setFieldValue("powerKwFrom");
      setFieldValue("powerKwTo");
      setFieldValue("emptyWeightKgTo");
      setFieldValue("emptyWeightKgFrom");
    }

    createFieldConfig();
  }, [createFieldConfig, vehicleTypeSelected]);

  useEffect(() => {
    const defValues = [];

    selectedFieldConfigs.forEach((selectedFieldConfig) => {
      const defValue = selectedFieldConfig?.options
        ? selectedFieldConfig.options.find(
            (item) =>
              item.value === selectedDependency[selectedFieldConfig.name]
          )
        : selectedDependency[selectedFieldConfig.name];
      defValues.push(defValue ?? null);
    });
    setDefaultValues(defValues);
  }, [selectedDependency, setSelectedFieldConfigs, selectedFieldConfigs]);

  if (!selectedFieldConfigs.length) return null;

  return (
    <FieldsContainer>
      {selectedFieldConfigs.map((selectedFieldConfig, index) => {
        const defaultValue = defaultValues[index];
        const inputValue = values[selectedFieldConfig?.name];
        return selectedFieldConfig?.options ? (
          <ComboBoxField
            id={selectedFieldConfig.name}
            key={selectedFieldConfig.name + index}
            disabled={disabled}
            errors={errors}
            name={selectedFieldConfig.name}
            label={selectedFieldConfig.label}
            setFieldValue={setFieldValue}
            options={selectedFieldConfig.options || []}
            defaultValue={defaultValue}
            confidentiality={null}
          />
        ) : (
          <NumberInputField
            key={selectedFieldConfig.name + index}
            errors={errors}
            disabled={disabled}
            label={selectedFieldConfig.label}
            value={inputValue?.toString() || defaultValue?.toString()}
            setFieldValue={setFieldValue}
            name={selectedFieldConfig.name}
          />
        );
      })}
    </FieldsContainer>
  );
};

export default VehicleTypeDependencyField;

const FieldsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;
