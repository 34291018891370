import { List } from "@fluentui/react";
import { mergeStyleSets } from "@uifabric/merge-styles";
import ComboBoxField from "components/controls/ComboBoxField";
import TextInputField from "components/controls/TextInputField";
import { isExternalOrReadonlyAdmin } from "form/util";
import { constants } from "library/constants";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { formatNumber } from "utils/number";
import { useCurrentRoute } from "../../../../../config/routes";
import Dropzone from "../../../Dropzone/Dropzone";
import { fieldType } from "../../fleetConfig";
import ClientSection from "./ClientSection";

const classes = mergeStyleSets({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px",
  },
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "33%",
    },
  },
  itemmainins: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "49%",
    },
  },
  uploadFile: {
    width: "100%",
  },
  insurerlist: {
    overflow: "auto",
    maxHeight: 65,
    border: "1px solid #CCC",
    marginTop: 20,
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "49%",
    },
  },
  insurerlistentry: {
    position: "relative",
    boxSizing: "border-box",
    display: "block",
    borderLeft: "3px solid ",
    paddingLeft: 3,
    whiteSpace: "nowrap",
  },
});

export const FleetTab = ({
  userRole,
  formikProps,
  fleetConf,
  fleetRef,
  file,
  setFile,
}) => {
  const theme = useTheme() as IStyledTheme;
  const route = useCurrentRoute();
  const { t } = useTranslation();
  const { values, errors, handleChange, setFieldValue } = formikProps;

  const insurerList = useSelector((s) => s.fleetForm.insurerList);
  const insurerOptions = useMemo(() => {
    return insurerList?.map((insurer) => ({
      value: insurer.insurerInternalNumber,
      label: insurer.insurerName + " (" + insurer.insurerInternalNumber + ")",
    }));
  }, [insurerList, t]);

  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);

  const mtplInsurensInfo = fleet?.mtplInsurersInfo;
  const hullInsurensInfo = fleet?.hullInsurersInfo;

  const fleetLegalInsurersInfo = fleet?.fleetLegalInsurersInfo;

  const mtplInsurers = insurerOptions.filter((item) =>
    mtplInsurensInfo?.find(
      (infoItem) => infoItem.insurerInternalNumber === item.value
    )
  );

  const hullInsurers = insurerOptions.filter((item) =>
    hullInsurensInfo?.find(
      (infoItem) => infoItem.insurerInternalNumber === item.value
    )
  );

  const fleetLegalInsurers = insurerOptions.filter((item) =>
    fleetLegalInsurersInfo?.find(
      (infoItem) => infoItem.insurerInternalNumber === item.value
    )
  );

  const onRenderCell = (item: any, index: number): JSX.Element => {
    return <div className={classes.insurerlistentry}>{item.label}</div>;
  };

  const isCreateFleet = route === constants.createFleet;
  const isEditFleet = route === constants.editFleet;

  const filteredFleetConfForPreview = fleetConf.filter(
    (item) => item.name !== "fleetName" && !item?.hideInPreview
  );

  return (
    <div className={classes.root} ref={fleetRef} id="fleet-tab">
      {isCreateFleet && (
        <>
          <div className={classes.item}>
            <TextInputField
              value={values?.fleetName}
              errors={errors}
              onChange={handleChange}
              required
              name={"fleetName"}
              disabled={false}
              label={"bfm.fleetForm.fleetName.label"}
            />
          </div>
          <ClientSection />
          {!isExternalOrReadonlyAdmin(userRole) && (
            <div className={classes.uploadFile}>
              <Dropzone
                file={file}
                setFile={setFile}
                formikProps={formikProps}
              />
            </div>
          )}
        </>
      )}
      {isEditFleet && (
        <>
          <div className={classes.item}>
            <TextInputField
              value={values?.fleetName}
              errors={errors}
              onChange={handleChange}
              required
              name={"fleetName"}
              disabled={false}
              label={"bfm.fleetForm.fleetName.label"}
            />
          </div>

          {filteredFleetConfForPreview.map((item, index) => {
            let value = item?.value;

            if (item.field === "number" || item.field === "float") {
              value = value && formatNumber(value);
            }

            if (item.name === "businessYearStartMonth") {
              return (
                !isExternalOrReadonlyAdmin(userRole) && (
                  <div className={classes.uploadFile} key={index}>
                    <Dropzone
                      file={file}
                      isOnlyExistingVehiclesAttributesUpdate={
                        isEditFleet || false
                      }
                      setFile={setFile}
                      formikProps={formikProps}
                    />
                  </div>
                )
              );
            }
            if (item.field === fieldType.dayMonth) {
              return (
                <div className={classes.item} key={item.key}>
                  <TextInputField
                    isDayMonthField
                    onChange={handleChange}
                    value={values[item.name]}
                    errors={errors}
                    name={item.name}
                    disabled={false}
                    label={item.key}
                  />
                </div>
              );
            }

            return (
              <div className={classes.item} key={item.key}>
                <TextInputField
                  onChange={() => {}}
                  value={value}
                  errors={[]}
                  name=""
                  disabled={item?.onlyPreview}
                  label={item.key}
                />
              </div>
            );
          })}
          <div className={classes.itemmainins}>
            <ComboBoxField
              disabled={false}
              errors={[]}
              name="mainMtplInsurerPartnerNumber"
              label={"bfm.fleetForm.mainMtplInsurerPartnerName.label"}
              setFieldValue={setFieldValue}
              options={insurerOptions || []}
              defaultValue={values?.mainMtplInsurerPartnerNumber}
              confidentiality={null}
            />
          </div>
          <div className={classes.insurerlist} data-is-scrollable>
            <List
              items={mtplInsurers}
              onRenderCell={onRenderCell}
              style={{
                color: theme.palette.black,
              }}
            />
          </div>
          <div className={classes.itemmainins}>
            <ComboBoxField
              disabled={false}
              defaultOpen={true}
              errors={[]}
              name="mainHullInsurerPartnerNumber"
              label={"bfm.fleetForm.mainHullInsurerPartnerName.label"}
              setFieldValue={setFieldValue}
              options={insurerOptions || []}
              defaultValue={values?.mainHullInsurerPartnerNumber}
              confidentiality={null}
            />
          </div>
          <div className={classes.insurerlist} data-is-scrollable>
            <List
              items={hullInsurers}
              onRenderCell={onRenderCell}
              style={{
                color: theme.palette.black,
              }}
            />
          </div>
          <div className={classes.itemmainins}>
            <ComboBoxField
              disabled={false}
              defaultOpen={true}
              errors={[]}
              name="mainFleetLegalInsurerPartnerNumber"
              label={"bfm.fleetForm.mainFleetLegalInsurerPartnerName.label"}
              setFieldValue={setFieldValue}
              options={insurerOptions || []}
              defaultValue={values?.mainFleetLegalInsurerPartnerNumber}
              confidentiality={null}
            />
          </div>
          <div className={classes.insurerlist} data-is-scrollable>
            <List
              items={fleetLegalInsurers}
              onRenderCell={onRenderCell}
              style={{
                color: theme.palette.black,
              }}
            />
          </div>
          <div className={classes.item}>
            <TextInputField
              value={values?.fleetPolicyNumber}
              errors={errors}
              onChange={handleChange}
              name={"fleetPolicyNumber"}
              disabled={false}
              label={"bfm.fleetForm.fleetPolicyNumber.label"}
            />
          </div>
          <div className={classes.item}>
            <TextInputField
              value={values?.fleetLegalPolicyNumber}
              errors={errors}
              onChange={handleChange}
              name={"fleetLegalPolicyNumber"}
              disabled={false}
              label={"bfm.fleetForm.fleetLegalPolicyNumber.label"}
            />
          </div>
          <div className={classes.item} />
          <div className={classes.item}>
            <TextInputField
              value={values?.fleetComment}
              errors={errors}
              onChange={handleChange}
              name={"fleetComment"}
              multiline
              rows={5}
              disabled={false}
              label={"bfm.fleetForm.fleetComment.label"}
            />
          </div>
        </>
      )}
    </div>
  );
};
