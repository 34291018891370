import ComboBoxField from "components/controls/ComboBoxField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import { FormikErrors } from "formik";
import React, { useEffect } from "react";
import useActionConfig from "../../hooks/useActionConfg";
import AnnualFields from "./AnnualFields";

const defaultValues = {
  paymentMethod: null,
  paymentMean: null,
  activationDays: null,
  suspensionDays: null,
  garageRiskDiscountPercent: null,
};

type Props = {
  premiumTypeSelected: any;
  fieldItemStyle: string;
  errors: FormikErrors<any>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  handleChange: (e: React.ChangeEvent<any>) => void;
  isUpdateForm?: boolean;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
};

const MtplAndHullDependencyFields = ({
  premiumTypeSelected,
  fieldItemStyle,
  errors,
  setFieldValue,
  handleChange,
  values,
  isUpdateForm = false,
  setFieldTouched = () => {},
}: Props) => {
  const actionsConfig = useActionConfig();

  const paymentMethodOptions = actionsConfig.paymentMethodOptions;
  const paymentMeanOptions = actionsConfig.paymentMeanOptions;

  const isHullType = premiumTypeSelected?.code.includes("HULL");
  const isMtplType = premiumTypeSelected?.code.includes("MTPL");
  const isFleetLegalProrataType =
    premiumTypeSelected?.code.includes("FLEETLEGAL_PRORATA");
  const isFleetLegalClosingDateType = premiumTypeSelected?.code.includes(
    "FLEETLEGAL_CLOSINGDATE"
  );

  useEffect(() => {
    if (
      !isHullType &&
      !isMtplType &&
      !isFleetLegalClosingDateType &&
      !isFleetLegalProrataType
    ) {
      setFieldValue("paymentMethod", null);
      setFieldValue("paymentMean", null);
      setFieldValue("activationDays", null);
      setFieldValue("suspensionDays", null);
      setFieldValue("garageRiskDiscountPercent", null);
    }
  }, [
    isHullType,
    setFieldValue,
    isMtplType,
    isFleetLegalClosingDateType,
    isFleetLegalProrataType,
  ]);

  if (
    !isHullType &&
    !isMtplType &&
    !isFleetLegalClosingDateType &&
    !isFleetLegalProrataType
  )
    return (
      <AnnualFields
        handleChange={handleChange}
        values={values}
        premiumTypeSelected={premiumTypeSelected}
        fieldItemStyle={fieldItemStyle}
        errors={errors}
        setFieldValue={setFieldValue}
      />
    );

  return (
    <>
      <AnnualFields
        handleChange={handleChange}
        values={values}
        premiumTypeSelected={premiumTypeSelected}
        fieldItemStyle={fieldItemStyle}
        errors={errors}
        setFieldValue={setFieldValue}
      />
      {!isUpdateForm && (
        <>
          <div className={fieldItemStyle}>
            <ComboBoxField
              required
              disabled={false}
              errors={errors}
              name={"paymentMethod"}
              label={"bfm.vehicleForm.paymentMethod.label"}
              setFieldValue={setFieldValue}
              options={paymentMethodOptions || []}
              defaultValue={values?.paymentMethod}
              onSetFieldTouched={() => {
                setFieldTouched &&
                  setFieldTouched("motorTaxPaymentMethod", true);
              }}
              confidentiality={null}
            />
          </div>
          <div className={fieldItemStyle}>
            <ComboBoxField
              required
              disabled={false}
              errors={errors}
              name={"paymentMean"}
              label={"bfm.vehicleForm.paymentMean.label"}
              setFieldValue={setFieldValue}
              options={paymentMeanOptions || []}
              defaultValue={values?.paymentMean}
              confidentiality={null}
            />
          </div>
          <div className={fieldItemStyle}>
            <NumberInputField
              required
              setFieldValue={setFieldValue}
              value={values?.activationDays}
              onChange={handleChange}
              errors={errors}
              name={"activationDays"}
              disabled={false}
              insurerSettingsFunction={(value) => value}
              label={"bfm.vehicleForm.horsePowerBasedTaxActivationDays.label"}
            />
          </div>
          <div className={fieldItemStyle}>
            <NumberInputField
              required
              setFieldValue={setFieldValue}
              value={values?.suspensionDays}
              onChange={handleChange}
              errors={errors}
              name={"suspensionDays"}
              disabled={false}
              insurerSettingsFunction={(value) => value}
              label={"bfm.vehicleForm.horsePowerBasedTaxSuspensionDays.label"}
            />
          </div>
          {isHullType && (
            <div className={fieldItemStyle}>
              <DoubleNumberInputField
                required
                setFieldValue={setFieldValue}
                value={values?.garageRiskDiscountPercent}
                onChange={handleChange}
                errors={errors}
                name={"garageRiskDiscountPercent"}
                disabled={false}
                //   maxLength={11}
                insurerSettingsFunction={(value) => value}
                label={"bfm.vehicleForm.hullGarageRiskDiscountPercent.label"}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MtplAndHullDependencyFields;
