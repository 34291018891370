import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  resetDialogInsurerSettings,
  setDialogInsurerSettingsOperationResult,
} from "store/timeline";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineDialogType,
  TimelineSegment,
  TimelineSegmentType,
} from "types/types";
import { toast } from "../../../components/FluentToast";
import { useSelector } from "../../../store/hooks";

export const useInsurerSettings = () => {
  const dialogDate = useSelector((s) => s.timeline.dialog.date);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const timelineSegments = useSelector((s) => s.timeline.timeline.data);
  const hullIsBasicItem = useSelector(
    (s) => s.timeline.dialog.item?.extraData?.lineSpecial
  );

  const { t } = useTranslation();

  const vehicleMainGroupCode = useSelector(
    (s) => s.vehicle.vehicle.vehicleMainGroupCode
  );
  const dispatch = useDispatch();

  const insurerSettings = useSelector((s) => s.vehicle.insurerSettings);

  const premiumTypeCode = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );
  const taxonomy = useSelector((s) => s.taxonomy);
  const premiumTypeId = taxonomy.PremiumType.byCode[premiumTypeCode]?.id;

  const defaultValueOperation = useSelector(
    (s) => s.timeline.dialog.insurerSettingsOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;

      const premiumTypeHorsePower =
        taxonomy.PremiumType.byCode["PremiumType.HORSE_POWER"].id;

      if (
        premiumTypeCode !== "PremiumType.MTPL" &&
        premiumTypeCode !== "PremiumType.HULL" &&
        premiumTypeCode !== "PremiumType.HULL_BASIC" &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_PRORATA &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_CLOSINGDATE &&
        premiumTypeCode !== "PremiumType.HORSE_POWER"
      ) {
        const segment: TimelineSegment = timelineSegments
          ?.filter(
            (seg) =>
              seg.domain.value === "PremiumType.MTPL" &&
              (seg.segmentType === TimelineSegmentType.PREMIUM ||
                seg.segmentType === TimelineSegmentType.SUSPENSION)
          )
          ?.find(
            (seg) =>
              moment(seg.startDate).toDate() <= dialogDate &&
              moment(seg.endDate).toDate() > dialogDate
          );
        if (segment) {
          insurer = segment.associatedObject.insurerPartnerNumber;
        }
      }

      let hullIsBasic = null;
      let premiumType = premiumTypeId;
      if (
        dialogType === TimelineDialogType.ADD_PREMIUM ||
        dialogType === TimelineDialogType.INSERT_PREMIUM
      ) {
        let hullIsBasicForm = formValues.hullIsBasic?.value;
        if (hullIsBasicForm) {
          hullIsBasic = hullIsBasicForm === "true" ? true : false;
        }
      }
      if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
        hullIsBasic =
          hullIsBasicItem &&
          hullIsBasicItem === ETimelineExtraDataLineSpecial.BASIC;
      }
      if (premiumTypeCode === "PremiumType.HULL" && hullIsBasic) {
        premiumType = taxonomy.PremiumType.byCode["PremiumType.HULL_BASIC"].id;
      }

      const insurerSetting = insurerSettings?.find(
        (is) => is.insurerPartnerNumber === insurer
      );

      if (insurerSetting) {
        let valueString = null;

        let results = {};
        for (const fieldName of fieldNames) {
          switch (fieldName) {
            case "suspensionDays":
              valueString = insurerSetting.suspensionDays + "";
              results[fieldName] = valueString;
              //dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "activationDays":
              valueString = insurerSetting.activationDays + "";

              results[fieldName] = valueString;
              // dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "garageRiskDiscountPercent":
              if (hullIsBasic) {
                valueString = insurerSetting.garageRiskDiscountPercentHullBasic;
              } else {
                valueString = insurerSetting.garageRiskDiscountPercentHull;
              }
              valueString = valueString.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              valueString = valueString + "";
              results[fieldName] = valueString;
              //dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "suspensionDaysLimit":
              const setting1 =
                insurerSetting?.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails?.find(
                  (el) =>
                    el.premiumTypeCode === premiumType &&
                    el.vehicleMainGroupCode === vehicleMainGroupCode
                );
              valueString = setting1?.suspensionDaysLimit + "";
              results[fieldName] = valueString;
              break;
            case "motorTaxSuspensionDaysLimit":
              const setting2 =
                insurerSetting?.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.find(
                  (el) =>
                    el.premiumTypeCode === premiumTypeHorsePower &&
                    el.vehicleMainGroupCode === vehicleMainGroupCode
                );
              valueString = setting2?.suspensionDaysLimit + "";
              results[fieldName] = valueString;
              break;
          }

          //toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
        }
        dispatch(setDialogInsurerSettingsOperationResult(results));
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInInsurerSettingsNoValue.label"));
        dispatch(resetDialogInsurerSettings(null));
      }
    }
  }, [defaultValueOperation]);
};
