import moment from "moment";
import * as yup from "yup";
import { PremiumType } from "../../../types/types";

export const validationObjectConfig = (
  premiumTypeKey,
  t,
  isTradeLicensePlate,
  isInsertOperation
): any => {
  let yupObject: any = {
    annualGrossPremium: yup.string().required(),
    annualNetPremium: yup.string().required(),

    validFromDate: yup.string().required(),
    reasonForModificationComment: yup.string().trim().max(512).nullable(),
    suspensionDaysLimit: yup.number().required().min(-1),
  };
  if (isInsertOperation) {
    yupObject = {
      ...yupObject,
      validToDate: yup.string().required().nullable(),
    };
  }

  if (
    premiumTypeKey === PremiumType.HULL ||
    premiumTypeKey === PremiumType.HULL_BASIC
  ) {
    yupObject = {
      ...yupObject,
      garageRiskDiscountPercent: yup.string().required(),
      hullIsBasic: yup.mixed().required(),
      hullDeductiblePercent: yup.number().max(100),
    };
  }
  if (
    premiumTypeKey === PremiumType.MTPL ||
    premiumTypeKey === PremiumType.HULL ||
    premiumTypeKey === PremiumType.HULL_BASIC ||
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA ||
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE
  ) {
    yupObject = {
      ...yupObject,
      dueDate: yup
        .string()
        .required()
        .test("dueDate", t("bfm.fms.InvalidData.txt"), (value) => {
          const day = (value + "").substring(0, 2);
          const month = (value + "").substring(2, 4);
          return moment("2020-" + month + "-" + day).isValid();
        }),
      insurerPartnerNumber: yup.mixed().required(),
      insurerBranchOffice: yup.mixed(),
      insurerPolicyNumber: yup.string().required(),
      paymentMean: yup.string().required(),
      suspensionDays: yup.string().required(),
      activationDays: yup.string().required(),
    };
    if (!isInsertOperation) {
      yupObject = {
        ...yupObject,
        expiryDate: yup.string().required().nullable(),
      };
    }
  }

  if (
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA ||
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE
  ) {
    yupObject = {
      ...yupObject,
      premiumType: yup.mixed().required(),
      insurerPolicyNumber: yup.string().required(),
      annualGrossPremium: yup.string().required(),
    };
    if (!isInsertOperation) {
      yupObject = {
        ...yupObject,
        expiryDate: yup.string().required().nullable(),
      };
    }
  }

  if (
    premiumTypeKey === PremiumType.MTPL ||
    premiumTypeKey === PremiumType.HULL ||
    premiumTypeKey === PremiumType.HULL_BASIC ||
    premiumTypeKey === PremiumType.HORSE_POWER ||
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA ||
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE
  ) {
    yupObject = {
      ...yupObject,
      paymentMethod: yup.mixed().required(),
      paymentMean: yup.mixed().required(),
    };
  }
  if (premiumTypeKey === PremiumType.HORSE_POWER) {
    yupObject = {
      paymentMethod: yup.mixed().required(),
      paymentMean: yup.mixed().required(),

      validFromDate: yup.date().required(),
      reasonForModificationComment: yup.string().trim().max(512).nullable(),
      suspensionDaysLimit: yup.number().required().min(-1),
    };
  }

  if (premiumTypeKey === PremiumType.MTPL && !isTradeLicensePlate) {
    yupObject = {
      ...yupObject,

      motorTaxExclusionReason: yup.mixed().when("isMotorTaxIncluded", {
        is: false,
        then: yup.mixed().required(),
      }),
      motorTaxPaymentMethod: yup.mixed().when("isMotorTaxIncluded", {
        is: true,
        then: yup.mixed().required(),
      }),
      // motorTaxPaymentMean: yup.mixed().when("isMotorTaxIncluded", {
      //   is: true,
      //   then: yup.mixed().required(),
      // }),
      motorTaxSuspensionDaysLimit: yup.number().when("isMotorTaxIncluded", {
        is: true,
        then: yup.number().required().min(-1),
      }),
      motorTaxExclusionComment: yup.string().max(512).nullable(),
    };
  }
  return yupObject;
};
