import * as yup from "yup";
import { TimelineDialogType } from "../../../types/types";

export const validationObjectConfig = (item, dialogType): any => {
  const fieldSets = [];
  const vehicleLastStatusDate = {
    vehicleLastStatusDate: yup.date().required().nullable(),
  };
  const comment = {
    comment: yup.string().trim().max(512),
  };

  const validFromDate = {
    validFromDate: yup.date().required().nullable(),
  };
  const validToDate = {
    validToDate: yup.date().required().nullable(),
  };
  const commentReason = {
    reasonForModificationComment: yup.string().trim().max(512),
  };

  fieldSets.push(vehicleLastStatusDate); //0
  fieldSets.push(comment); //1
  fieldSets.push(validFromDate); //2
  fieldSets.push(validToDate); //3
  fieldSets.push(commentReason); //4

  let yupObject: any = {};
  if (dialogType === TimelineDialogType.CORRECT_STATUS) {
    yupObject = {
      ...fieldSets[0],
      ...fieldSets[1],
    };
  }
  if (dialogType === TimelineDialogType.INSERT_STATUS) {
    yupObject = {
      ...fieldSets[2],
      ...fieldSets[3],
      ...fieldSets[4],
    };
  }
  if (dialogType === TimelineDialogType.DELETE_STATUS) {
    yupObject = {
      ...fieldSets[1],
    };
  }
  if (dialogType === TimelineDialogType.CANCEL_STATUS) {
    yupObject = {
      ...fieldSets[1],
    };
  }
  return yupObject;
};
