import React from "react";
import { useTranslation } from "react-i18next";
import { TimelineLegendSegmentItemType, TimelineType } from "types/types";
import { useSelector } from "../../../../store/hooks";
import { LegendSegment } from "./LegendSegment";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

interface IProps {
  x: number;
  width: number;
}
/* Component */
export const Legend = ({ x, width }: IProps) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const { t } = useTranslation();
  const segments = useSelector((s) => s.timeline.timeline.legend);
  const types = useSelector((s) => s.timeline.types);
  const segmentsRender = [];
  let yPos = -50;
  segments.forEach((segment, index) =>
    segment.items.length > 0
      ? segmentsRender.push(
          <g
            className={isDarkMode ? "tickdark" : "tick"}
            key={"segment" + index}
          >
            <text
              className="boldText"
              style={{ textAnchor: "start" }}
              x={x - 70}
              dy=".32em"
              y={(yPos = yPos + 50)}
            >
              {t(segment.title)}
            </text>
            {segment.items
              .filter(
                (item) =>
                  item.itemType !==
                    TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE ||
                  (item.itemType ===
                    TimelineLegendSegmentItemType.SHARED_LICENSE_PLATE &&
                    types.includes(TimelineType.VEHICLE_PREMIUM))
              )
              .map((item, i) => (
                <g key={"segment" + index + "item" + i}>
                  <LegendSegment
                    x={i % 2 === 1 ? width / 2 - 70 : 0}
                    y={i % 2 === 1 ? yPos : (yPos = yPos + 40)}
                    item={item}
                  />
                  <text
                    style={{ textAnchor: "start" }}
                    x={i % 2 === 1 ? x + width / 2 : x + 70}
                    dy=".32em"
                    y={yPos}
                  >
                    {"- " + t(item.label)}
                  </text>
                </g>
              ))}
            <line x1={0} x2={width - 120} y1={yPos + 30} y2={yPos + 30} />
          </g>
        )
      : null
  );
  return <g className={isDarkMode ? "tickdark" : "tick"}>{segmentsRender}</g>;
};
