import {
  Checkbox,
  DefaultButton,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import TextInputField from "components/controls/TextInputField";
import { toast } from "components/FluentToast";
import { FormSpinner } from "components/FormSpinner";
import { StyledPrimaryButton } from "components/styled";
import { Formik } from "formik";

import { useBulkUpdateFleetPremium } from "hooks/data/mutations/useBulkUpdateFleetPremium";
import { delay } from "library/delay";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  setDialog,
  setDialogDefaultValueOperation,
  setFleetWithPremiums,
} from "store/fleetForm";
import { useSelector } from "store/hooks";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import useActionConfig from "../hooks/useActionConfg";
import MtplAndHullDependencyFields from "./AddPremiumFormDialog/MtplAndHullDependencyFields";
import DocumentDateDependencyField from "./components/DocumentDateDependencyField";
import FormActionInsurerField from "./components/FormActionInsurerField";
import IsPolicyIssuedDependencyField from "./components/IsPolicyIssuedDependencyField";
import { useDefaultSettings } from "./hooks/useDefaultSettings";
import VehicleTypeDependencyField from "./VehicleTypeDependencyField";

const UpdatePremiumFormDialog = () => {
  const { t } = useTranslation();
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const { mutate: onUpdateFleetPremium, isLoading } =
    useBulkUpdateFleetPremium();

  const actionsConfig = useActionConfig();
  const dialogData = useSelector((s) => s.fleetForm.dialog);
  const defaultData =
    dialogData.type === "editSetting" && dialogData.data?.selectedSetting;
  const carUsageOptions = actionsConfig.carUsageOptions;

  const dispatch = useDispatch();
  useDefaultSettings();
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };
  const premiumTypeOptions = actionsConfig.premiumTypeOptions;
  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;

  const insurerOptions = actionsConfig.insurerOptions;
  const initialValues = {
    premiumType: null,
    insurerPartnerNumber: null,
    annualNetPremium: null,
    annualGrossPremium: null,
    vehicleType: null,
    listPriceType: null,
    listPriceMin: null,
    listPriceMax: null,
    cubicCapacityCcmRange: null,
    powerKwFrom: null,
    powerKwTo: null,
    weightCapacityKgRange: null,
    validFromDate: new Date(new Date().getFullYear(), 0, 1),
    documentDate: null,
    isPolicyIssued: false,

    reasonForModificationComment: null,
    carUsage: null,

    emptyWeightKgFrom: null,
    emptyWeightKgTo: null,
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      premiumType: yup.mixed().required(),
      insurerPartnerNumber: yup.mixed().required(),
      annualNetPremium: yup.string().required(),
      annualGrossPremium: yup.string().required(),
      vehicleType: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(),
      cubicCapacityCcmRange: yup.mixed().nullable(),
      powerKwFrom: yup.number().nullable(),
      powerKwTo: yup
        .number()
        .min(
          yup.ref("powerKwFrom"),
          "Field powerKwTo must be greater than powerKwFrom"
        )
        .nullable(),
      weightCapacityKgRange: yup.mixed().nullable(),
      validFromDate: yup.mixed().required(),
      documentDate: yup.mixed().nullable(),
      isPolicyIssued: yup.mixed().nullable(),
      reasonForModificationComment: yup.string().max(512).nullable(),

      emptyWeightKgFrom: yup.number().nullable(),
      emptyWeightKgTo: yup
        .number()
        .min(
          yup.ref("emptyWeightKgFrom"),
          "Field emptyWeightKgTo must be greater than emptyWeightKgFrom"
        )
        .nullable(),
    });
  }, [t, isTradeLicensePlate]);
  const classes = getClassNames();

  const onSubmit = (values) => {
    const body = {
      isTradeLicensePlate: isTradeLicensePlate,

      premiumType: values?.premiumType.value,
      listPriceType: values?.listPriceType?.value,
      listPriceMin: values?.listPriceMin
        ? Number(formatDoubleFieldValue(values?.listPriceMin))
        : undefined,
      listPriceMax: values?.listPriceMax
        ? Number(formatDoubleFieldValue(values?.listPriceMax))
        : undefined,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,
      annualNetPremium: Number(
        values.annualNetPremium?.replace(".", "")?.replace(",", ".")
      ),
      annualGrossPremium: Number(
        values.annualGrossPremium?.replace(".", "")?.replace(",", ".")
      ),
      weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      powerKwFrom: values?.powerKwFrom,
      powerKwTo: values?.powerKwTo,
      numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
      validFromDate: moment(values?.validFromDate).format("YYYY-MM-DD"),
      documentDate: values?.documentDate
        ? moment(values?.documentDate).format("YYYY-MM-DD")
        : null,
      reasonForModificationComment: values?.reasonForModificationComment,
      carUsage: values?.carUsage?.value,

      emptyWeightKgFrom: values?.emptyWeightKgFrom,
      emptyWeightKgTo: values?.emptyWeightKgTo,
      isPolicyIssued: values?.isPolicyIssued,
    };

    onUpdateFleetPremium(
      {
        fleetId: fleet.fleetId,
        body,
      },
      {
        onSuccess: (res) => {
          dispatch(setFleetWithPremiums(res?.data));
          toast.success(t("bfm.success"));
          close();
        },
      }
    );
  };
  const validate = (values) => {
    const errors = {} as any;

    if (
      values.listPriceMin &&
      values.listPriceMax &&
      Number.parseFloat(
        values.listPriceMin
          .replaceAll(" ", "")
          .replaceAll(".", "")
          .replaceAll(",", ".")
      ) >
        Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        )
    ) {
      errors.listPriceMin = t("greco.form.errors.number.max", {
        max: Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ),
      });
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { values, handleSubmit, setFieldValue, handleChange, errors } =
          props;
        return (
          <>
            <div className={classes.main}>
              <form onSubmit={handleSubmit}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className={classes.fieldItem}>
                    <Checkbox
                      label={t("bfm.tradeLicensePlates.label")}
                      styles={{
                        root: {
                          fontWeight: 600,
                        },
                      }}
                      disabled={false}
                      checked={isTradeLicensePlate}
                      onChange={(ev) => {
                        setFieldValue("vehicleType", null);
                        setFieldValue("carUsage", null);
                        setTradeLicensePlate(!isTradeLicensePlate);
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* 1 */}
                  <div
                    className={classes.fieldItem}
                    style={{ marginRight: "10px" }}
                  >
                    <div className={classes.fieldItem}>
                      <ComboBoxField
                        disabled={false}
                        errors={errors}
                        name="premiumType"
                        required
                        label={"bfm.fleet.premiumType.label"}
                        setFieldValue={setFieldValue}
                        options={premiumTypeOptions || []}
                        defaultValue={null}
                        confidentiality={null}
                      />
                    </div>
                    <div className={classes.fieldItem}>
                      <FormActionInsurerField
                        actionFormType={"update"}
                        allInsurerOptions={insurerOptions}
                        values={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    <MtplAndHullDependencyFields
                      isUpdateForm
                      handleChange={handleChange}
                      values={values}
                      premiumTypeSelected={values?.premiumType}
                      fieldItemStyle={classes.fieldItem}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                    <div className={classes.fieldItem}>
                      <DocumentDateDependencyField
                        premiumTypeSelected={values?.premiumType}
                        classesItem={classes.fieldItem}
                        errors={errors}
                        fieldName={"documentDate"}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </div>
                    <div className={classes.fieldItem}>
                      <IsPolicyIssuedDependencyField
                        premiumTypeSelected={values?.premiumType}
                        classesItem={classes.fieldItem}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </div>
                  </div>

                  <div
                    className={classes.fieldItem}
                    style={{ marginLeft: "10px" }}
                  >
                    {values.premiumType?.code === "HULL_BASIC" ||
                    values.premiumType?.code === "HULL" ? (
                      <>
                        <div className={classes.fieldItem}>
                          <ComboBoxField
                            disabled={false}
                            errors={errors}
                            name="listPriceType"
                            label={"bfm.vehicleForm.listPriceTypeCode.label"}
                            setFieldValue={setFieldValue}
                            options={listPriceTypeOptions || []}
                            defaultValue={values?.listPriceType}
                            confidentiality={null}
                          />
                        </div>
                        <div
                          className={classes.fieldItem}
                          style={{ display: "flex", gap: "15px" }}
                        >
                          <div style={{ width: "50%" }}>
                            <DoubleNumberInputField
                              setFieldValue={setFieldValue}
                              value={values.listPriceMin}
                              onChange={handleChange}
                              errors={errors}
                              name={"listPriceMin"}
                              disabled={false}
                              label={"bfm.listPriceMin.label"}
                            />
                          </div>
                          <div style={{ width: "50%" }}>
                            <DoubleNumberInputField
                              style={{ width: "50%" }}
                              setFieldValue={setFieldValue}
                              value={values.listPriceMax}
                              onChange={handleChange}
                              errors={errors}
                              name={"listPriceMax"}
                              disabled={false}
                              label={"bfm.listPriceMax.label"}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className={classes.fieldItem}>
                      {!isTradeLicensePlate ? (
                        <ComboBoxField
                          disabled={false}
                          errors={errors}
                          name="carUsage"
                          label={"bfm.vehicleForm.carUsageCode.label"}
                          setFieldValue={setFieldValue}
                          options={carUsageOptions || []}
                          defaultValue={values?.carUsage}
                          confidentiality={null}
                        />
                      ) : null}
                    </div>
                    {!isTradeLicensePlate ? (
                      <div className={classes.fieldItem}>
                        <ComboBoxField
                          disabled={isTradeLicensePlate}
                          errors={errors}
                          name="vehicleType"
                          required={true}
                          label={"bfm.vehicleForm.vehicleTypeCode.label"}
                          setFieldValue={setFieldValue}
                          options={vehicleTypeCodeOptions || []}
                          defaultValue={values?.vehicleType}
                          confidentiality={null}
                        />
                      </div>
                    ) : null}
                    <div className={classes.fieldItem}>
                      <VehicleTypeDependencyField
                        values={values}
                        vehicleTypeSelected={values?.vehicleType}
                        fieldItemStyle={classes.fieldItem}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        selectedDependency={defaultData}
                        disabled={false}
                        includeMargins={false}
                      />
                    </div>
                    <div className={classes.fieldItem}>
                      <DatePickerField
                        errors={errors}
                        setFieldValue={setFieldValue}
                        defaultValue={values.validFromDate}
                        name={"validFromDate"}
                        disabled={false}
                        label={"bfm.vehicleForm.validFromDate.label"}
                        placeholder={t("bfm.selectDate")}
                        required
                        confidentiality={null}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className={classes.fieldItem}>
                    <TextInputField
                      value={values.reasonForModificationComment}
                      onChange={handleChange}
                      errors={errors}
                      name={"reasonForModificationComment"}
                      multiline
                      rows={4}
                      disabled={false}
                      label={"bfm.comment.label"}
                    />
                  </div>
                </div>
                <div className={classes.footer}>
                  <TooltipHost content={t("bfm.fillInAllDefaults.label")}>
                    <StyledPrimaryButton
                      text={t("bfm.defaultSettings.label")}
                      onClick={() => {
                        let fieldNames1 = [];

                        fieldNames1.push("annualGrossPremium");
                        // fieldNames1.push("deductible");

                        dispatch(
                          setDialogDefaultValueOperation({
                            fieldNames: fieldNames1,
                            formValues: {
                              ...values,
                              isTradeLicensePlate: isTradeLicensePlate,
                            },
                          })
                        );
                      }}
                      iconProps={{
                        iconName: "Shield",
                      }}
                      disabled={isLoading}
                    />
                  </TooltipHost>
                  <DefaultButton
                    primary
                    text={t("greco.save")}
                    type="submit"
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </div>
                {isLoading && <FormSpinner />}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginTop: 50,
    },
  });

export default UpdatePremiumFormDialog;
