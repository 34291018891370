import CheckboxField from "components/controls/CheckboxField";
import ComboBoxField from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import NumberInputField from "components/controls/NumberInputField";
import TextInputField from "components/controls/TextInputField";
import DateField from "components/form/DateField";
import { DueDateField } from "components/form/DueDateField";
import { NumberField } from "components/form/NumberField";
import { useFormikContext } from "formik";
import moment from "moment";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import { InsurerBranchOfficeTaxonomyField } from "pages/vehicle/InsurerBranchOfficeTaxonomyField";
import { InsurerVehicleTaxonomyField } from "pages/vehicle/InsurerVehicleTaxonomyField";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import styled from "styled-components";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
  TimelineDialogType,
} from "types/types";

function FleetLegalFormContent() {
  const taxonomy = useSelector((s) => s.taxonomy);
  const item = useSelector((s) => s.timeline.dialog.item);

  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const isCancelationPremium = useSelector(
    (s) => s.timeline.dialog.item?.associatedObject?.isCancelationPremium
  );

  const { t } = useTranslation();
  const { setFieldValue, errors, values, handleChange } =
    useFormikContext<any>();
  const { premiumTypeOptions, paymentMeanOptions, paymentMethodOptions } =
    useActionConfig();

  const legalPremiumTypeOptions = useMemo(
    () =>
      premiumTypeOptions.filter((item) => {
        return (
          item.code === "FLEETLEGAL_PRORATA" ||
          item.code === "FLEETLEGAL_CLOSINGDATE"
        );
      }),
    [premiumTypeOptions]
  );

  const isCorrectPremium = dialogType === TimelineDialogType.CORRECT_PREMIUM;

  const isAdjustPremium = dialogType === TimelineDialogType.ADJUST_PREMIUM;
  const isAddPremium = dialogType === TimelineDialogType.ADD_PREMIUM;

  const isInsertPremium = dialogType === TimelineDialogType.INSERT_PREMIUM;

  const validToDate = values?.validToDate;

  const isFleetLegalClosingDate = (() => {
    if (isAddPremium) {
      return (
        values?.premiumType?.value ===
        taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id
      );
    }
    if (isCorrectPremium) {
      return (
        item.extraData.lineSpecial ===
        ETimelineExtraDataLineSpecial.CLOSING_DATE
      );
    }
    return null;
  })();

  return (
    <>
      <FieldsContainer>
        {!isCorrectPremium && !isAdjustPremium && (
          <ComboBoxField
            disabled={false}
            errors={errors}
            required
            name="premiumType"
            label={"bfm.fleetLegalType.label"}
            setFieldValue={setFieldValue}
            options={legalPremiumTypeOptions || []}
            defaultValue={values?.premiumType || legalPremiumTypeOptions[0]}
            confidentiality={null}
          />
        )}

        {(isAddPremium || isCorrectPremium) && isFleetLegalClosingDate && (
          <>
            <CheckboxField
              checked={values?.isFirstForFleetLegalClosingDate}
              errors={errors}
              setFieldValue={setFieldValue}
              name={"isFirstForFleetLegalClosingDate"}
              disabled={false}
              label={"bfm.isFirstForFleetLegalClosingDate.label"}
              isMarked={false}
            />
            {isCorrectPremium && <div></div>}
          </>
        )}

        <DueDateField
          fieldConfig={{
            name: "dueDate",
            type: "dueDate",
            dayFieldName: "dueDay",
            monthFieldName: "dueMonth",
            isRequired: true,
            d: ({ t }) => ({
              label: "bfm.fleetForm.dueDate.label",
            }),
          }}
        />
        <DatePickerField
          errors={errors}
          setFieldValue={setFieldValue}
          defaultValue={values.validFromDate}
          name={"validFromDate"}
          disabled={false}
          label={"bfm.vehicleForm.validFromDate.label"}
          placeholder={t("bfm.selectDate")}
          required
          confidentiality={null}
        />

        {!isAdjustPremium && (
          <>
            <InsurerVehicleTaxonomyField
              fieldConfig={{
                name: "insurerPartnerNumber",
                type: "insurerVehicleTaxonomy",
                isRequired: true,
                d: ({ t }) => ({
                  label: "bfm.insurer.label",
                }),
              }}
            />
            {!isInsertPremium && (
              <DatePickerField
                errors={errors}
                setFieldValue={setFieldValue}
                defaultValue={values?.expiryDate}
                name={"expiryDate"}
                disabled={false}
                label={"bfm.vehicleForm.expiryDate.label"}
                placeholder={t("bfm.selectDate")}
                required
                confidentiality={null}
              />
            )}

            <InsurerBranchOfficeTaxonomyField
              fieldConfig={{
                name: "insurerBranchOffice",
                insurerFieldName: "insurerPartnerNumber",
                type: "insurerBranchOfficeTaxonomy",
                isRequired: false,
                d: ({ t }) => ({
                  label: "bfm.vehicleForm.insurerBranchOffice.label",
                }),
              }}
            />
          </>
        )}

        {(isCancelationPremium || isInsertPremium) && (
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values?.validToDate}
            name={"validToDate"}
            disabled={false}
            label={"bfm.vehicleForm.validToDate.label"}
            placeholder={t("bfm.selectDate")}
            required
            confidentiality={null}
          />
        )}

        <ComboBoxField
          disabled={false}
          errors={errors}
          required
          name="paymentMethod"
          label={"bfm.vehicleForm.paymentMethod.label"}
          setFieldValue={setFieldValue}
          options={paymentMethodOptions || []}
          defaultValue={values?.paymentMethod}
          confidentiality={null}
        />
        {isCancelationPremium && isCorrectPremium && (
          <DatePickerField
            errors={errors}
            setFieldValue={setFieldValue}
            defaultValue={values?.cancelationDocumentDate}
            name={"cancelationDocumentDate"}
            disabled={false}
            label={"bfm.vehicleForm.cancelationDocumentDate.label"}
            placeholder={t("bfm.selectDate")}
            confidentiality={null}
          />
        )}
        {!isAdjustPremium && (
          <TextInputField
            value={values.insurerPolicyNumber}
            onChange={handleChange}
            errors={errors}
            required
            name={"insurerPolicyNumber"}
            disabled={false}
            label={t("bfm.vehicleForm.policyNumber.label")}
          />
        )}

        <ComboBoxField
          disabled={false}
          errors={errors}
          required
          name="paymentMean"
          label={"bfm.vehicleForm.paymentMean.label"}
          setFieldValue={setFieldValue}
          options={paymentMeanOptions || []}
          defaultValue={values?.paymentMean}
          confidentiality={null}
        />

        <CheckboxField
          required
          checked={values?.isPolicyIssued}
          errors={errors}
          setFieldValue={setFieldValue}
          name={"isPolicyIssued"}
          disabled={false}
          label={"bfm.vehicleForm.isPolicyIssued.label"}
          isMarked={false}
        />

        <NumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.suspensionDays}
          onChange={handleChange}
          errors={errors}
          name={"suspensionDays"}
          disabled={false}
          label={"bfm.vehicleForm.horsePowerBasedTaxSuspensionDays.label"}
          isMarked={false}
        />

        <DateField
          fieldConfig={{
            name: "documentDate",
            type: "date",
            isRequired: false,
            d: ({ t, values, errors, setFieldValue }) => ({
              label: "bfm.vehicleForm.documentDate.label",
              values,
              errors,
              setFieldValue,
              defaultValue: isAdjustPremium
                ? null
                : isAddPremium || isInsertPremium
                ? moment(new Date()).toDate()
                : values?.documentDate,
            }),
          }}
        />
        <NumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.activationDays}
          onChange={handleChange}
          errors={errors}
          name={"activationDays"}
          disabled={false}
          label={"bfm.vehicleForm.horsePowerBasedTaxActivationDays.label"}
          isMarked={false}
        />
        <NumberField
          fieldConfig={{
            name: "annualGrossPremium",
            type: "number",
            isRequired: true,
            d: ({ t, vehicle }) => ({
              label: "bfm.vehicleForm.annualGrossPremium.label",
              defaultValueFunction: (value) => value,
            }),
          }}
        />

        <NumberInputField
          required
          setFieldValue={setFieldValue}
          value={values?.suspensionDaysLimit}
          onChange={handleChange}
          isNegativeAllowed
          errors={errors}
          name={"suspensionDaysLimit"}
          disabled={false}
          label={"bfm.vehicleForm.horsePowerBasedTaxSuspensionDaysLimit.label"}
          isMarked={false}
        />

        <NumberField
          fieldConfig={{
            name: "annualNetPremium",
            type: "number",
            isRequired: true,
            d: ({ t }) => ({
              label: "bfm.annualNetPremium.label",
            }),
          }}
        />
      </FieldsContainer>
      <TextInputField
        value={values.reasonForModificationComment}
        onChange={handleChange}
        errors={errors}
        name={"reasonForModificationComment"}
        multiline
        rows={4}
        disabled={false}
        label={"bfm.comment.label"}
      />
    </>
  );
}

export default FleetLegalFormContent;

const FieldsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;
