import { DefaultButton } from "@fluentui/react";
import { toast } from "components/FluentToast";
import { Formik } from "formik";
import { useCreateFleetVehicleDefaultSetting } from "hooks/data/mutations/useCreateFleetVehicleDefaultSetting";
import { useUpdateFleetVehicleDefaultSetting } from "hooks/data/mutations/useUpdateFleetVehicleDefaultSetting";
import { delay } from "library/delay";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import useDefaultSettingsData from "../hooks/useDefaultSettingsData";
import HullDeductibleFormFields from "./HullDeductibleFormFields";

type Props = {
  settingId: string;
  attributeName: string;
  isLoading: boolean;
};

const HullDeductibleForm = ({ settingId, attributeName, isLoading }: Props) => {
  const { t } = useTranslation();
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const [isDirty, setIsDirty] = useState(false);
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);

  const { mutate: onUpdateFleetVehicleDefaultSetting } =
    useUpdateFleetVehicleDefaultSetting();

  const { mutate: onCreateFleetVehicleDefaultSetting } =
    useCreateFleetVehicleDefaultSetting();

  const actionsConfig = useActionConfig();
  const dialogData = useSelector((s) => s.fleetForm.dialog);
  const hasTradeLicensePlateDefault = useSelector(
    (s) => s.fleetForm.dialog.data?.hasTradeLicensePlate
  );

  const defaultData = useDefaultSettingsData();

  const rowVersion = defaultData?.rowVersion;

  const dispatch = useDispatch();
  const vehicleType = defaultData?.vehicleType;

  useEffect(() => {
    if ((!vehicleType && settingId) || hasTradeLicensePlateDefault) {
      setTradeLicensePlate(true);
    }
  }, [vehicleType, settingId]);
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };
  const premiumTypeOptions = actionsConfig.premiumTypeOptionsHull;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;

  const insurerOptions = actionsConfig.insurerOptions;
  //const vehicleMainGroupOptions = actionsConfig.vehicleMainGroupOptions;
  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const carUsageOptions = actionsConfig.carUsageOptions;

  const initialValues = useMemo(() => {
    const hullValues = defaultData.attributeValue
      ? JSON.parse(defaultData.attributeValue)
      : null;
    return {
      hullDeductibleMinAmount: hullValues?.hullDeductibleMinAmount,
      hullDeductibleMaxAmount: hullValues?.hullDeductibleMaxAmount,
      hullDeductiblePercent: hullValues?.hullDeductiblePercent,
      hullDeductibleComment: hullValues?.hullDeductibleComment,
      premiumType: defaultData
        ? premiumTypeOptions.find(
            (item) => item.value === defaultData.premiumType
          )
        : "",
      insurerPartnerNumber: defaultData
        ? insurerOptions.find(
            (item) => item.value === defaultData.insurerPartnerNumber
          )
        : "",
      listPriceType: defaultData
        ? listPriceTypeOptions.find(
            (item) => item.value === defaultData.listPriceType
          )
        : "",

      listPriceMin: defaultData ? defaultData.listPriceMin + "" : "",
      listPriceMax: defaultData ? defaultData.listPriceMax + "" : "",
      // vehicleMainGroup: defaultData
      //   ? vehicleMainGroupOptions.find(
      //       (item) => item.value === defaultData.vehicleMainGroup
      //     )
      //   : "",
      vehicleType: defaultData
        ? vehicleTypeCodeOptions.find(
            (item) => item.value === defaultData.vehicleType
          )
        : "",
      carUsage: defaultData
        ? carUsageOptions.find((item) => item.value === defaultData.carUsage)
        : "",
      cubicCapacityCcmRange: defaultData
        ? defaultData.cubicCapacityCcmRange
        : "",
      powerKwTo: defaultData ? defaultData.powerKwTo : "",

      powerKwFrom: defaultData ? defaultData.powerKwFrom : "",
      weightCapacityKgRange: defaultData
        ? defaultData.weightCapacityKgRange
        : "",
      maxGrossWeightKgRange: defaultData
        ? defaultData.maxGrossWeightKgRange
        : "",
      emptyWeightKgFrom: defaultData ? defaultData?.emptyWeightKgFrom : "",
      emptyWeightKgTo: defaultData ? defaultData.emptyWeightKgTo : "",
    };
  }, [
    defaultData,
    insurerOptions,
    premiumTypeOptions,
    vehicleTypeCodeOptions,
    listPriceTypeOptions,
    carUsageOptions,
  ]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      hullDeductibleMinAmount: yup.string(),
      hullDeductibleMaxAmount: yup.string(),
      hullDeductiblePercent: yup.string(),
      hullDeductibleComment: yup.string(),

      // .test(
      //   ">100",
      //   t("greco.form.errors.number.max", { max: 100 }),
      //   (value) => {
      //     const formatedValue = formatDoubleFieldValue(value);
      //     if (formatedValue > 100) {
      //       return false;
      //     }
      //     return value;
      //   }
      // )
      premiumType: yup.mixed().required(),
      insurerPartnerNumber: yup.mixed().required(),
      //vehicleMainGroup: yup.mixed().required(),
      vehicleType: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(),
      cubicCapacityCcmRange: yup.mixed().nullable(),
      powerKwFrom: yup.number().nullable(),
      powerKwTo: yup
        .number()
        .min(
          yup.ref("powerKwFrom"),
          "Field powerKwTo must be greater than powerKwFrom"
        )
        .nullable(),
      emptyWeightKgFrom: yup.number().nullable(),
      emptyWeightKgTo: yup
        .number()
        .min(
          yup.ref("emptyWeightKgFrom"),
          "Field emptyWeightKgTo must be greater than emptyWeightKgFrom"
        )
        .nullable(),
      weightCapacityKgRange: yup.mixed().nullable(),
    });
  }, [t, isTradeLicensePlate]);

  const onSubmit = (values) => {
    const body = {
      isTradeLicensePlate: isTradeLicensePlate,
      attributeValue: JSON.stringify({
        hullDeductibleMinAmount: values?.hullDeductibleMinAmount,
        hullDeductibleMaxAmount: values?.hullDeductibleMaxAmount,
        hullDeductiblePercent: values?.hullDeductiblePercent,
        hullDeductibleComment: values?.hullDeductibleComment,
      }),
      attributeName,
      premiumType: values?.premiumType.value,
      listPriceType: values?.listPriceType?.value,
      listPriceMin: values?.listPriceMin
        ? Number(formatDoubleFieldValue(values?.listPriceMin))
        : undefined,
      listPriceMax: values?.listPriceMax
        ? Number(formatDoubleFieldValue(values?.listPriceMax))
        : undefined,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      //vehicleMainGroup: values?.vehicleMainGroup?.value,
      carUsage: values?.carUsage?.value,
      vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,
      weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      powerKwFrom: values?.powerKwFrom,
      powerKwTo: values?.powerKwTo,
      numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
      emptyWeightKgFrom: values?.emptyWeightKgFrom,
      emptyWeightKgTo: values?.emptyWeightKgTo,
    };

    if (!!settingId) {
      onUpdateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          fleetDefaultSettingId: settingId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
            rowVersion,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
          },
        }
      );
    } else {
      onCreateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
          },
        }
      );
    }
  };

  const validate = (values) => {
    const errors = {} as any;
    if (
      !values.hullDeductibleMinAmount &&
      !values.hullDeductibleMaxAmount &&
      !values.hullDeductiblePercent &&
      !values.hullDeductibleComment
    ) {
      errors.hullDeductibleMinAmount = t(
        "bfm.vehicleForm.hullDeductible.error"
      );
    }
    const hullDeductiblePercentNumber = parseFloat(
      (values.hullDeductiblePercent as string)
        ?.replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (hullDeductiblePercentNumber) {
      if (hullDeductiblePercentNumber > 100) {
        errors.hullDeductiblePercent = t(
          "greco.form.errors.number.lessorequal",
          {
            max: 100,
          }
        );
      } else if (hullDeductiblePercentNumber < 0) {
        errors.hullDeductiblePercent = t(
          "greco.form.errors.number.greaterorequal",
          {
            min: 0,
          }
        );
      }
    }
    if (!values.listPriceType?.value) {
      errors.listPriceType = t("greco.required");
    }
    if (values.listPriceMin === "" && values.listPriceMax !== "") {
      errors.listPriceMin = t("greco.required");
    }
    if (values.listPriceMax === "" && values.listPriceMin !== "") {
      errors.listPriceMax = t("greco.required");
    }
    if (
      !errors.listPriceMin &&
      !errors.listPriceMax &&
      Number.parseFloat(
        values.listPriceMin
          .replaceAll(" ", "")
          .replaceAll(".", "")
          .replaceAll(",", ".")
      ) >
        Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        )
    ) {
      errors.listPriceMin = t("greco.form.errors.number.max", {
        max: Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ),
      });
    }
    //...

    return errors;
  };

  const openSettingDialog = (
    type: string,
    attributeName: string,
    title: string,
    settingId?: number,
    selectedSetting?: any
  ) => {
    close();
    dispatch(
      setDialog({
        type,
        isOpen: true,
        title: `${
          settingId
            ? t("bfm.fleet.defaultSettings.edit.label")
            : t("bfm.fleet.defaultSettings.create.label")
        } - ${title}`,
        icon: "Add",
        data: { attributeName, settingId, selectedSetting },
      })
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <>
            <div>
              <form onSubmit={handleSubmit}>
                <HullDeductibleFormFields
                  listPriceTypeOptions={listPriceTypeOptions}
                  isTradeLicensePlate={isTradeLicensePlate}
                  setTradeLicensePlate={setTradeLicensePlate}
                  settingId={settingId}
                  fieldProps={props}
                  defaultData={defaultData}
                  insurerOptions={insurerOptions}
                  //vehicleMainGroupOptions={vehicleMainGroupOptions}
                  carUsageOptions={carUsageOptions}
                  vehicleTypeCodeOptions={vehicleTypeCodeOptions}
                  isLoading={isLoading}
                  premiumTypeOptions={premiumTypeOptions}
                  setIsDirty={setIsDirty}
                />
              </form>
              {dialogData.type === "editSetting" && (
                <DefaultButton
                  onClick={() => {
                    openSettingDialog(
                      "copySettings",
                      "deductible",
                      t("bfm.hulldeductible.label"),
                      null,
                      defaultData
                    );
                  }}
                >
                  Copy and create new
                </DefaultButton>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default HullDeductibleForm;
