import { mergeStyleSets, Nav } from "@fluentui/react";
import { useCurrentRoute } from "config/routes";
import { constants } from "library/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setTab } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const getClasses = (theme, isDarkMode) =>
  mergeStyleSets({
    navigation: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "100%",
      "& .ms-Nav-groupContent": {
        marginBottom: "0",
      },
      ".ms-Nav-compositeLink.is-selected .ms-Button": {
        background: isDarkMode ? "#001321" : "#d0e7f8",
      },
    },
  });

const FleetSidebarNav = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((s) => s.auth.userRole);
  const route = useCurrentRoute();
  const tab = useSelector((s) => s.fleetForm.tab);
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const classes = getClasses(theme, isDarkMode);
  const { t } = useTranslation();

  const isEditFleet = route === constants.editFleet;
  const handleNavItemClick = (e, tab) => {
    const tabItem: any = document.querySelector(`#${tab.key}`);
    document.querySelector("#fleet-page-wrapper")!.scroll({
      // document.querySelector("#app-wrapper")!.scroll({
      top: tabItem?.offsetTop,
      left: 0,
      behavior: "auto",
    });
    if (!tab) return;
    dispatch(setTab(tab.key as any));
  };
  return (
    <>
      <Nav
        onLinkClick={handleNavItemClick}
        selectedKey={tab}
        groups={getNavLinkGroups(t, isEditFleet)}
        className={classes.navigation}
      />
    </>
  );
};

const getNavLinkGroups = (
  // isFinanceVisible: boolean,
  // isVehicleVisible: boolean,
  t: any,
  isEditFleet: boolean
) => {
  const groups = [
    {
      links: [
        {
          name: t("bfm.fleet"),
          url: "",
          key: "fleet-tab",
        },
        {
          name: t("greco.users"),
          url: "",
          key: "users-tab",
        },
      ],
    },
  ];

  if (isEditFleet) {
    groups[0].links.push(
      ...[
        {
          name: t("bfm.fleet.tabs.history"),
          url: "",
          key: "history-tab",
        },
        {
          name: t("bfm.fleet.tabs.insurerDefaultSettings"),
          url: "",
          key: "inusrer-default-values-tab",
        },
        {
          name: t("bfm.fleet.tabs.defaultValues"),
          url: "",
          key: "default-values-tab",
        },
      ]
    );
  }

  return groups;
};
export default FleetSidebarNav;
