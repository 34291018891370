import { pointer } from "d3";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openContextMenu } from "store/timeline";
import { useSelector } from "../../../../../store/hooks";
import {
  TimelineContextMenuType,
  TimelineSegmentType,
  TimelineType,
} from "../../../../../types/types";
import { BookingSegmentTooltip } from "./BookingSegmentTooltip";

export const BookingSegments = ({
  xScale,
  yScale,
  innerWidth,
  showBookings,
}) => {
  const data = useSelector((s) => s.timeline.timeline.filteredData);
  const showBookingsWithDocumentDate = useSelector(
    (s) => s.timeline.timeline.showBookingsWithDocumentDate
  );
  const dispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  if (!data) return null;
  if (!showBookings) return null;

  const segments = data
    .filter(
      (segment) =>
        segment.domain.type === TimelineType.VEHICLE_PREMIUM &&
        segment.segmentType === TimelineSegmentType.BOOKING
    )
    .map((d, index) => {
      let x1 = xScale(d.startDate);
      let display = true;
      if (x1 < 0) display = false;
      if (x1 > innerWidth) display = false;
      // \
      // /
      const vehicleIssuingStatus =
        taxonomy.VehicleIssuingStatus.byId[
          d.associatedObject.vehicleIssuingStatusCode
        ]?.code;

      let circleClass =
        vehicleIssuingStatus === "VehicleIssuingStatus.INCORRECT"
          ? "bookingCircle booking-point-incorrect"
          : vehicleIssuingStatus === "VehicleIssuingStatus.CORRECT"
          ? "bookingCircle booking-point-correct"
          : "bookingCircle booking-point-notyetbooked";
      let strokeWidth = 2;

      const xv = x1;
      const yv = yScale(d.domain.value) + yScale.bandwidth();
      const deltax = 7;
      const deltay = 6;
      return display ? (
        <BookingSegmentTooltip key={index} d={d}>
          <g
            id={
              "booking_" +
              (d.associatedObject && d.associatedObject?.vehiclePremiumBookingId
                ? d.associatedObject.vehiclePremiumBookingId
                : index)
            }
            name={"booking_" + d.domain.value.replace("PremiumType.", "")}
            style={{
              cursor: d.extraData.premiums.length > 1 ? "normal" : "pointer",
            }}
            onClick={(e) => {
              if (d.extraData.premiums.length > 1) return null;
              return dispatch(
                openContextMenu({
                  type: TimelineContextMenuType.BOOKING,
                  item: d,
                  ref: { x: e.pageX, y: e.pageY },
                  date: xScale.invert(pointer(e)[0]),
                })
              );
            }}
          >
            <circle
              className={circleClass}
              r={5.5}
              cx={xv}
              cy={yv}
              strokeWidth={strokeWidth}
            />
            {d.associatedObject.isPending ? (
              <circle
                className={circleClass}
                r={2.5}
                cx={xv}
                cy={yv}
                strokeWidth={0.5}
              />
            ) : null}
            {d.extraData.negativeAmount &&
            d.extraData.negativeAmount === true ? (
              <>
                <line
                  x1={deltax + xv - 1.5}
                  y1={deltay + yv}
                  x2={deltax + xv + 1.5}
                  y2={deltay + yv}
                  stroke={"#bb0000"}
                  strokeWidth={1.5}
                />
              </>
            ) : null}
            {d.extraData.positiveAmount &&
            d.extraData.positiveAmount === true ? (
              <>
                <line
                  x1={deltax + xv - 2.5}
                  y1={-deltay + yv}
                  x2={deltax + xv + 2.5}
                  y2={-deltay + yv}
                  stroke={"#008800"}
                  strokeWidth={1.5}
                />
                <line
                  x1={deltax + xv}
                  y1={-deltay + yv - 2.5}
                  x2={deltax + xv}
                  y2={-deltay + yv + 2.5}
                  stroke={"#008800"}
                  strokeWidth={1.5}
                />
              </>
            ) : null}
          </g>
        </BookingSegmentTooltip>
      ) : null;
    });

  const documentDateSegments = data
    .filter(
      (segment) =>
        segment.domain.type === TimelineType.VEHICLE_PREMIUM &&
        segment.segmentType === TimelineSegmentType.BOOKING_DOCUMENT_DATE
    )
    .map((d, index) => {
      let x1 = xScale(d.startDate);
      let display = true;
      if (x1 < 0) display = false;
      if (x1 > innerWidth) display = false;
      // \
      // /
      let strokeWidth = 2;

      const xv = x1;
      const yv = yScale(d.domain.value) + yScale.bandwidth();
      const deltax = 7;
      const deltay = 6;
      return display ? (
        <g
          style={{
            cursor: "normal",
          }}
        >
          <circle
            style={{
              stroke: "#000000",
              color: "#000000",
            }}
            r={2.5}
            cx={xv}
            cy={yv + 8}
            strokeWidth={strokeWidth}
          />
        </g>
      ) : null;
    });
  return (
    <>
      {segments}
      {
        showBookingsWithDocumentDate ? null : null //documentDateSegments
      }
    </>
  );
};
