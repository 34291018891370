import { useNavigate } from "react-router-dom";

import { mergeStyleSets } from "@fluentui/react";
import { toast } from "components/FluentToast";
import FormSubmitSpinner from "components/FormSubmitSpinner";
import { useCurrentRoute } from "config/routes";
import { Formik } from "formik";
import { useCreateInsurerSettings } from "hooks/data/mutations/useCreateInsurerSettings";
import { useUpdateInsurerSettings } from "hooks/data/mutations/useUpdateInsurerSettings";
import { useGetInsurerListByCountry } from "hooks/data/queries/useGetInsurerListByCountry";
import { useGetInsurerSettings } from "hooks/data/queries/useGetInsurerSettings";
import { constants } from "library/constants";
import { delay } from "library/delay";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSelector } from "store/hooks";
import { selectShouldDisableForm } from "store/vehicle";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { Country_AUSTRIA, PremiumType, UserRole } from "types/types";
import { setYupLocale2 } from "utils/setYupLocale";
import * as yup from "yup";
import FormItemTitle from "./FormItemTitle";
import InsurerFormSkeleton from "./InsurerFormSkeleton";
import InsurerFormToolbar from "./InsurerFormToolbar";
import NumberInputField from "./NumberInputField";
import {
  convertFormToRequestObject,
  convertInsurerToFormObject,
} from "./insurerFormUtils";

const premiumTypes = [
  PremiumType.MTPL,
  PremiumType.HULL,
  PremiumType.HULL_BASIC,
  PremiumType.LEGAL,
  PremiumType.ACCIDENT,
  PremiumType.ASSISTANCE,
  PremiumType.HORSE_POWER,
];

const InsurerForm = () => {
  const theme = useTheme() as IStyledTheme;
  const navigate = useNavigate();
  const route = useCurrentRoute();

  const { insurerId } = useParams() as any;
  const isEditInsurer = route === constants.editInsurer;

  const { data: insurer, isLoading: insurerLoading } = useGetInsurerSettings(
    isEditInsurer ? insurerId : null
  );

  const { data: insurerList } = useGetInsurerListByCountry(Country_AUSTRIA);

  const {
    mutate: onUpdateInsurerSettings,
    isLoading: updateSettingsInProgress,
  } = useUpdateInsurerSettings();

  const {
    mutate: onCreateInsurerSettings,
    isLoading: createInsurerSettingsInProgress,
  } = useCreateInsurerSettings();

  const isLoading =
    useSelector(selectShouldDisableForm)(route) ||
    updateSettingsInProgress ||
    createInsurerSettingsInProgress ||
    insurerLoading;

  const insurerRef = useRef(null);

  const classes = getClassNames(theme);
  const isCreateInsurer = route === constants.createInsurer;

  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const [selectedInsurer, setSelectedInsurer] = useState(null);
  const userRole = useSelector((s) => s.auth.userRole);

  const initialValues = useMemo(() => {
    if (isEditInsurer) {
      if (!insurer) return null;
      return convertInsurerToFormObject(insurer, taxonomy, premiumTypes);
    } else {
      return {
        garageRiskDiscountPercentHull: "0,00",
        garageRiskDiscountPercentHullBasic: "0,00",
        suspensionDays: "0",
        activationDays: "0",
        suspensionDaysLimit: Array.apply(null, Array(91)).map((e, index) => {
          if ((index + 1) % 7 === 0) return "45";
          return "0";
        }),
      };
    }
  }, [isEditInsurer, insurer, taxonomy]);

  const validationSchema = useMemo(() => {
    setYupLocale2(t);
    const schemaObj = {
      garageRiskDiscountPercentHull: yup
        .number()
        .required()
        .min(0)
        .max(100)
        .transform((_, value) => {
          if (value.includes(".")) {
            return null;
          }
          return +value.replace(/,/, ".");
        }),
      garageRiskDiscountPercentHullBasic: yup
        .number()
        .required()
        .min(0.0)
        .max(100.0)
        .transform((_, value) => {
          if (value.includes(".")) {
            return null;
          }
          return +value.replace(/,/, ".");
        }),
      suspensionDays: yup.number().integer().required().min(0).max(2),
      activationDays: yup.number().integer().required().min(0).max(2),
      suspensionDaysLimit: yup
        .array()
        .of(yup.number().integer().required().min(-1)),
    };
    return yup.object(schemaObj);
  }, []);

  useEffect(() => {
    if (insurerId && insurerList) {
      setSelectedInsurer(
        insurerList.find((el) => el.insurerInternalNumber + "" === insurerId)
      );
    }
  }, [insurerId, insurerList]);

  const onSubmit = async (data) => {
    const returnObject = convertFormToRequestObject(
      data,
      selectedInsurer,
      taxonomy,
      premiumTypes,
      isEditInsurer,
      insurer
    );

    if (isCreateInsurer) {
      onCreateInsurerSettings(returnObject, {
        onSuccess: () => {
          toast.success(t("bfm.insurerForm.successfullyCreatedInsurer"));
          navigate(`/insurers`);
        },
      });
    } else if (isEditInsurer) {
      onUpdateInsurerSettings(returnObject, {
        onSuccess: () => {
          toast.success(t("bfm.insurerForm.successfullyUpdatedInsurer"));
          navigate(`/insurers`);
        },
      });
    }
  };

  return (
    <>
      {isLoading && <FormSubmitSpinner />}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        // validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          let i = 0;
          const daysTableHeader = premiumTypes.map((item, index) => {
            return (
              <div key={`e${index}`} className={classesTab.days}>
                <div className={classes.textColor}>{t(item)}</div>
              </div>
            );
          });
          const daysTable = taxonomy?.VehicleMainGroup?.items.map(
            (item, index1) => {
              return (
                <React.Fragment key={`eee${index1}`}>
                  <div className={classesTab.days_left}>
                    <div className={classes.textColor}>
                      {item.code.replaceAll("VehicleMainGroup.", "")}
                    </div>
                  </div>
                  {premiumTypes.map((item2, index2) => {
                    const retVal = (
                      <div
                        key={`d_${index1}_${index2}`}
                        className={classesTab.days}
                      >
                        <NumberInputField
                          setFieldValue={props.setFieldValue}
                          value={props.values?.suspensionDaysLimit[i]}
                          errors={props.errors}
                          onChange={props.handleChange}
                          name={`suspensionDaysLimit[${i}]`}
                          disabled={userRole !== UserRole.admin}
                          label={""}
                          tooltip={t("bfm.suspensionDaysLimitMatrix.label", [
                            item.code.replaceAll("VehicleMainGroup.", ""),
                            t(item2),
                          ])}
                        />
                      </div>
                    );
                    i = i + 1;
                    return retVal;
                  })}
                </React.Fragment>
              );
            }
          );
          return (
            <>
              <div id="insurer-page-wrapper" className={classes.main}>
                <form onSubmit={props.handleSubmit}>
                  <div className={classes.navigationWrap}>
                    {(isEditInsurer || isCreateInsurer) && (
                      <>
                        <InsurerFormToolbar />
                      </>
                    )}
                  </div>
                  {!selectedInsurer && !insurer ? (
                    <InsurerFormSkeleton />
                  ) : (
                    <div>
                      <div className={classes.wrap}>
                        <div className={classes.form}>
                          <FormItemTitle
                            title={selectedInsurer?.insurerName}
                            iconName={"Shield"}
                          />
                          <div
                            className={classesTab.root}
                            ref={insurerRef}
                            id="defaultSettings-tab"
                          >
                            <div className={classesTab.root}>
                              <div className={classes.textColorBold}>
                                {t("bfm.vehicleForm.suspensionDaysLimit.label")}
                              </div>
                            </div>
                            <div
                              className={classesTab.root}
                              ref={insurerRef}
                              id="days-tab"
                            >
                              <div className={classesTab.days_left}></div>
                              {daysTableHeader}
                              {daysTable}
                            </div>
                            <>
                              <div className={classesTab.item}></div>
                              <div className={classesTab.item}></div>
                              <div className={classesTab.item}></div>
                              <div className={classesTab.item}></div>

                              <div className={classesTab.item}>
                                <NumberInputField
                                  setFieldValue={props.setFieldValue}
                                  value={
                                    props.values?.garageRiskDiscountPercentHull
                                  }
                                  errors={props.errors}
                                  onChange={props.handleChange}
                                  required
                                  name={"garageRiskDiscountPercentHull"}
                                  disabled={userRole !== UserRole.admin}
                                  label={
                                    "bfm.garageRiskDiscountPercentHull.label"
                                  }
                                />
                                {/* {garageRiskDiscountPercentHull} */}
                              </div>
                              <div className={classesTab.item}>
                                <NumberInputField
                                  setFieldValue={props.setFieldValue}
                                  value={
                                    props.values
                                      ?.garageRiskDiscountPercentHullBasic
                                  }
                                  errors={props.errors}
                                  onChange={props.handleChange}
                                  required
                                  name={"garageRiskDiscountPercentHullBasic"}
                                  disabled={userRole !== UserRole.admin}
                                  label={
                                    "bfm.garageRiskDiscountPercentHullBasic.label"
                                  }
                                />
                                {/* {garageRiskDiscountPercentHullBasic} */}
                              </div>
                              <div className={classesTab.item}></div>
                              <div className={classesTab.item}></div>
                              <div className={classesTab.item}>
                                <NumberInputField
                                  setFieldValue={props.setFieldValue}
                                  value={props.values?.suspensionDays}
                                  errors={props.errors}
                                  onChange={props.handleChange}
                                  required
                                  name={"suspensionDays"}
                                  disabled={userRole !== UserRole.admin}
                                  label={"bfm.vehicleForm.suspensionDays.label"}
                                />
                                {/* {suspensionDays} */}
                              </div>
                              <div className={classesTab.item}>
                                <NumberInputField
                                  setFieldValue={props.setFieldValue}
                                  value={props.values?.activationDays}
                                  errors={props.errors}
                                  onChange={props.handleChange}
                                  required
                                  name={"activationDays"}
                                  disabled={userRole !== UserRole.admin}
                                  label={"bfm.vehicleForm.activationDays.label"}
                                />
                                {/* {activationDays} */}
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const classesTab = mergeStyleSets({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px",
  },
  item: {
    padding: "20px",
    width: "49%",
    "@media(min-width: 1500px)": {
      width: "25%",
    },
  },
  days_left: {
    padding: "14px 24px 0px",
    width: "8%",
    "@media(min-width: 1500px)": {
      width: "8%",
    },
  },
  days: {
    padding: "0px 10px",
    width: "13%",
    "@media(min-width: 1500px)": {
      width: "13%",
    },
  },
  uploadFile: {
    width: "100%",
  },
});

const getClassNames = (theme) =>
  mergeStyleSets({
    textColor: {
      color: theme.palette.black,
    },
    textColorBold: {
      color: theme.palette.black,
      fontWeight: "600",
      fontSize: 14,
    },
    wrap: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      margin: "50px auto 0",
      borderRadius: "4px",
      maxWidth: "80%",
      height: "90%", //
      position: "relative",
      marginBottom: "100px",
    },
    navigationWrap: {
      position: "sticky",
      top: 0,
      zIndex: 2,
    },
    main: {
      overflow: "auto", //
      height: "100%",
      position: "relative",
      paddingTop: 0,
      flex: 1,
    },
    form: {
      width: "100%",
      padding: "20px",
      overfow: "auto",
    },
  });

export default InsurerForm;
