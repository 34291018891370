import { IFieldConfig } from "../../../components/form/types";

export const modifyPremiumDialogConfig: IFieldConfig = {
  insurerBranchOffice: {
    name: "insurerBranchOffice",
    insurerFieldName: "insurerPartnerNumber",
    type: "insurerBranchOfficeTaxonomy",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.insurerBranchOffice.label",
    }),
  },
  insurerPolicyNumber: {
    name: "insurerPolicyNumber",
    type: "text",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.policyNumber.label",
    }),
  },
  validFromDate: {
    name: "validFromDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validFromDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validFromDate,
    }),
  },
  validToDate: {
    name: "validToDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.validToDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.validToDate,
    }),
  },
  annualGrossPremium: {
    name: "annualGrossPremium",
    type: "number",
    isRequired: true,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.annualGrossPremium.label",
      defaultValueFunction: (value) => value,
    }),
  },
  annualNetPremium: {
    name: "annualNetPremium",
    type: "number",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.annualNetPremium.label",
    }),
  },
  suspensionDays: {
    name: "suspensionDays",
    type: "number",
    format: "integer",
    isRequired: true,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.horsePowerBasedTaxSuspensionDays.label",
      insurerSettingsFunction: (value) => value,
    }),
  },
  activationDays: {
    name: "activationDays",
    type: "number",
    format: "integer",
    isRequired: true,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.horsePowerBasedTaxActivationDays.label",
      insurerSettingsFunction: (value) => value,
    }),
  },
  suspensionDaysLimit: {
    name: "suspensionDaysLimit",
    type: "number",
    format: "integer",
    isRequired: true,
    isNegativeAllowed: true,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.horsePowerBasedTaxSuspensionDaysLimit.label",
      insurerSettingsFunction: (value) => value,
    }),
  },
  garageRiskDiscountPercent: {
    name: "garageRiskDiscountPercent",
    type: "number",
    isRequired: true,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.hullGarageRiskDiscountPercent.label",
      insurerSettingsFunction: (value) => value,
    }),
  },
  reasonForModificationComment: {
    name: "reasonForModificationComment",
    type: "text",
    isMultiline: true,
    rows: 3,
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.comment.label",
    }),
  },
  insurerPartnerNumber: {
    name: "insurerPartnerNumber",
    type: "insurerVehicleTaxonomy",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.insurer.label",
    }),
  },
  motorTaxExclusionReason: {
    name: "motorTaxExclusionReason",
    type: "taxonomy",
    taxonomyKey: "ReasonForMotorTaxExclusion",
    isRequired: false,
    d: ({ t, values }) => ({
      label: "bfm.reasonForMotorTaxExclusion.label",
    }),
  },

  carUsageCode: {
    name: "carUsageCode",
    type: "taxonomy",
    taxonomyKey: "VehicleUsageType",
    isRequired: false,
    d: ({ t, values }) => ({
      label: "bfm.vehicleForm.carUsageCode.label",
    }),
  },
  co2Emission: {
    name: "co2Emission",
    type: "number",
    isRequired: false,
    isNegativeAllowed: true,
    format: "integer",
    d: ({ t }) => ({
      label: "bfm.vehicleForm.co2Emission.label",
    }),
  },
  cubicCapacityCcm: {
    name: "cubicCapacityCcm",
    type: "number",
    isRequired: false,
    format: "integer",
    d: ({ t, values }) => ({
      label: "bfm.vehicleForm.cubicCapacityCcm.label",
    }),
  },
  firstRegistrationDate: {
    name: "firstRegistrationDate",
    type: "date",
    isRequired: false,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.firstRegistrationDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.firstRegistrationDate,
    }),
  },
  maxGrossWeightKg: {
    name: "maxGrossWeightKg",
    type: "number",
    format: "integer",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.maxGrossWeightKg.label",
    }),
  },
  powerKw: {
    name: "powerKw",
    type: "number",
    format: "integer",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.powerKw.label",
    }),
  },
  vehiclePowerTypeCode: {
    name: "vehiclePowerTypeCode",
    type: "taxonomy",
    taxonomyKey: "VehiclePowerType",
    isRequired: false,
    d: ({ t, values }) => ({
      label: "bfm.vehicleForm.vehiclePowerTypeCode.label",
    }),
  },
  vehicleTypeCode: {
    name: "vehicleTypeCode",
    type: "taxonomy",
    taxonomyKey: "VehicleTypeLegal",
    isRequired: false,
    d: ({ t, values }) => ({
      label: "bfm.vehicleForm.vehicleTypeCode.label",
    }),
  },
  paymentMethod: {
    name: "paymentMethod",
    type: "taxonomy",
    taxonomyKey: "PaymentMethod",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.paymentMethod.label",
    }),
  },
  paymentMean: {
    name: "paymentMean",
    type: "taxonomy",
    taxonomyKey: "PremiumPaymentMean",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.paymentMean.label",
    }),
  },
  expiryDate: {
    name: "expiryDate",
    type: "date",
    isRequired: true,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.expiryDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.expiryDate,
    }),
  },
  isPolicyIssued: {
    name: "isPolicyIssued",
    type: "checkbox",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.isPolicyIssued.label",
    }),
  },
  hullProduct: {
    name: "hullProduct",
    type: "hullproduct",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.hullProduct.label",
    }),
  },
  hullDeductible: {
    name: "hullDeductible",
    type: "number",
    isRequired: false,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.hullDeductible.label",
      defaultValueFunction: (value) => value,
    }),
  },
  hullDeductiblePercent: {
    name: "hullDeductiblePercent",
    type: "number",
    format: "integer",
    isRequired: false,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.hullDeductiblePercent.label",
      defaultValueFunction: (value) => value,
    }),
  },
  hullDeductibleMaxAmount: {
    name: "hullDeductibleMaxAmount",
    type: "number",
    isRequired: false,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.hullDeductibleMaxAmount.label",
      defaultValueFunction: (value) => value,
    }),
  },
  hullDeductibleMinAmount: {
    name: "hullDeductibleMinAmount",
    type: "number",
    isRequired: false,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.hullDeductibleMinAmount.label",
      defaultValueFunction: (value) => value,
    }),
  },
  hullDeductibleComment: {
    name: "hullDeductibleComment",
    type: "text",
    isMultiline: true,
    rows: 3,
    isRequired: false,
    d: ({ t, vehicle }) => ({
      label: "bfm.vehicleForm.hullDeductibleComment.label",
      defaultValueFunction: (value) => value,
    }),
  },
  gap: {
    name: "gap",
    type: "checkbox",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.gap.label",
    }),
  },
  replacementValue: {
    name: "replacementValue",
    type: "checkbox",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.replacementValue.label",
    }),
  },
  grossNegligence: {
    name: "grossNegligence",
    type: "checkbox",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.grossNegligence.label",
    }),
  },
  electroPlusPackage: {
    name: "electroPlusPackage",
    type: "checkbox",
    isRequired: false,
    d: ({ t }) => ({
      label: "bfm.vehicleForm.electroPlusPackage.label",
    }),
  },
  dueDate: {
    name: "dueDate",
    type: "dueDate",
    dayFieldName: "dueDay",
    monthFieldName: "dueMonth",
    isRequired: true,
    d: ({ t }) => ({
      label: "bfm.fleetForm.dueDate.label",
    }),
  },
  documentDate: {
    name: "documentDate",
    type: "date",
    isRequired: false,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.documentDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.documentDate,
    }),
  },
  cancelationDocumentDate: {
    name: "cancelationDocumentDate",
    type: "date",
    isRequired: false,
    d: ({ t, values, errors, setFieldValue }) => ({
      label: "bfm.vehicleForm.cancelationDocumentDate.label",
      values,
      errors,
      setFieldValue,
      defaultValue: values?.cancelationDocumentDate,
    }),
  },
};
