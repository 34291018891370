import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useCurrentRoute } from "../../../config/routes";
import * as VehicleSlice from "../../../store/vehicle";

export const useFetchVehicle = () => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const { fleetId, vehicleId } = useParams() as any;

  useEffect(() => {
    if (route === "editTempVehicle") {
      dispatch(
        VehicleSlice.loadTEMPVehicleActions.trigger({
          fleetId,
          vehicleId,
        })
      );
    }
    //
    else if (
      ["editVehicle", "duplicateVehicle", "readOnlyVehicle"].includes(route)
    ) {
      (async () => {
        await dispatch(
          VehicleSlice.loadVehicleWithInsurersActions.trigger({
            fleetId,
            vehicleId,
            isVehiclePage: true,
          })
        );
        // await dispatch(
        //   VehicleSlice.loadFleetWithInsurersActions.trigger({
        //     fleetId,
        //     isVehiclePage: true,
        //   })
        // );
      })();
    }
  }, [route, dispatch, fleetId, vehicleId]);
};
