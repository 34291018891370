import { Checkbox, mergeStyleSets } from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import TextInputField from "components/controls/TextInputField";
import { useEqualPaymentMethods } from "components/timeline/hooks/useEqualPaymentMethods";
import { useFormikContext } from "formik";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PremiumType } from "types/types";

function MotorTaxFields() {
  const { t } = useTranslation();
  const actionsConfig = useActionConfig();
  const { values, setFieldValue, errors, handleChange, touched } =
    useFormikContext<any>();
  const [isMotorTaxIncluded, setIsMotorTaxIncluded] = useState(true);
  useEqualPaymentMethods(PremiumType.MTPL);

  const paymentMethodOptions = actionsConfig.paymentMethodOptions;
  const reasonForMotorTaxExclusionOptions =
    actionsConfig.reasonForMotorTaxExclusionOptions;

  const classes = getClasses();

  const onChange = (
    ev: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) => {
    setIsMotorTaxIncluded(isChecked);
  };

  useEffect(() => {
    setFieldValue("isMotorTaxIncluded", isMotorTaxIncluded);
    setFieldValue("motorTaxExclusionComment", ""); //clear comment on motor tax status change
  }, [isMotorTaxIncluded]);

  return (
    <div>
      <Checkbox
        name="isMotorTaxIncluded"
        label={t("bfm.isMotorTaxIncluded.label")}
        styles={{
          root: {
            fontWeight: 600,
          },
        }}
        disabled={false}
        checked={isMotorTaxIncluded}
        onChange={onChange}
      />

      {isMotorTaxIncluded ? (
        <>
          <div className={classes.fieldItem}>
            <ComboBoxField
              key={"motorTaxPaymentMethod"}
              required
              disabled={false}
              errors={errors}
              name={"motorTaxPaymentMethod"}
              label={"bfm.vehicleForm.paymentMethod.label"}
              setFieldValue={setFieldValue}
              options={paymentMethodOptions || []}
              // defaultValue={values?.paymentMethod}
              defaultValue={
                touched["paymentMethod"]
                  ? values?.paymentMethod
                  : values?.motorTaxPaymentMethod
              }
              // defaultValue={
              //   values?.motorTaxPaymentMethod || values?.paymentMethod
              // }
              confidentiality={null}
            />
          </div>
          <div className={classes.fieldItem}>
            <DoubleNumberInputField
              required
              setFieldValue={setFieldValue}
              value={values?.motorTaxSuspensionDaysLimit}
              onChange={handleChange}
              errors={errors}
              name={"motorTaxSuspensionDaysLimit"}
              disabled={false}
              label={"bfm.suspensionDaysLimit"}
              isMarked={false}
            />
          </div>
          <div className={classes.fieldItem}>
            <TextInputField
              value={values?.motorTaxExclusionComment}
              onChange={handleChange}
              errors={errors}
              name={"motorTaxExclusionComment"}
              multiline
              rows={7}
              disabled={false}
              label={"bfm.comment.label"}
              isMarked={false}
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.fieldItem}>
            <ComboBoxField
              key={"motorTaxExclusionReason"}
              required
              disabled={false}
              errors={errors}
              name={"motorTaxExclusionReason"}
              label={"bfm.reasonForMotorTaxExclusion.label"}
              setFieldValue={setFieldValue}
              options={reasonForMotorTaxExclusionOptions || []}
              defaultValue={null}
              confidentiality={null}
            />
          </div>
          <div className={classes.fieldItem}>
            <TextInputField
              value={values?.motorTaxExclusionComment}
              onChange={handleChange}
              errors={errors}
              name={"motorTaxExclusionComment"}
              multiline
              rows={7}
              disabled={false}
              label={"bfm.comment.label"}
              isMarked={false}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default MotorTaxFields;

const getClasses = () =>
  mergeStyleSets({
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
  });
