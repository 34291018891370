import { CommandBarButton } from "@fluentui/react";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { spinAnimationRule } from "components/styled";
import { useSearchVehiclesForExportExcel } from "hooks/data/mutations/useSearchVehiclesForExportExcel";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { transformVehiclesForExcel } from "utils/excel";
import { useSelector } from "../../../store/hooks";

type Props = {
  refExcelExport: ExcelExport;
  isFleetPage: boolean;
};

export const ExportExcelButton = ({ refExcelExport, isFleetPage }: Props) => {
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();

  const fleetPageData = useSelector((s) => s.fleetPage);
  const requireActionVehiclesData = useSelector((s) => s.requireActionVehicles);
  const clientFilter = useSelector((s) => s.filter.selectedClients);
  const taxonomy = useSelector((s) => s.taxonomy);
  const insurerList1 = useSelector((s) => s.fleetForm.insurerList);

  const { mutate: onSearchVehiclesForExcel, isLoading } =
    useSearchVehiclesForExportExcel();

  const insurerList2 = requireActionVehiclesData.insurerList;

  const isTradeLicensePlate = useSelector(
    (s) => s.tradeLicensePlates.isTradeLicensePlate
  );
  const ravColumns = requireActionVehiclesData.columns;

  return (
    <StyledCommandBarButton
      disabled={isLoading}
      className={isLoading ? "isLoading" : ""}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "ExcelDocument",
      }}
      text="Export Excel"
      onClick={() => {
        isFleetPage
          ? onSearchVehiclesForExcel(
              {
                isFleetPage,
                search: fleetPageData?.search,
                sort: fleetPageData?.sort,
                isTEMP: fleetPageData?.showTEMP,
                columns: fleetPageData?.columns,
                vehicleStatusCode: fleetPageData?.vehicleStatusFilter,
                isTradeLicensePlate,
                fleetId,
              },
              {
                onSuccess: (vehicles) => {
                  refExcelExport?.save(
                    transformVehiclesForExcel({
                      vehicles,
                      columns: fleetPageData?.columns,
                      t,
                      taxonomy,
                      insurerList: insurerList1,
                    })
                  );
                },
              }
            )
          : onSearchVehiclesForExcel(
              {
                isFleetPage,
                isTEMP: false,
                search: requireActionVehiclesData?.search,
                sort: requireActionVehiclesData?.sort,
                columns: requireActionVehiclesData?.columns,
                clientIds: clientFilter,
                isNeedsAttention:
                  requireActionVehiclesData?.vehicleAttentionFilter,
                isTradeLicensePlate: requireActionVehiclesData?.tlpFilter,
              },
              {
                onSuccess: (vehicles) => {
                  refExcelExport?.save(
                    transformVehiclesForExcel({
                      vehicles,
                      columns: ravColumns,
                      t,
                      taxonomy,
                      insurerList: insurerList2,
                    })
                  );
                },
              }
            );
      }}
    />
  );
};

const StyledCommandBarButton = styled(CommandBarButton)`
  height: 32px;

  &:hover {
    .ms-Button-icon {
      color: green;
    }
  }
  &.isLoading {
    i {
      animation: ${spinAnimationRule};
    }
  }
`;
