import { UseQueryResult, useQuery } from "react-query";
import { getInsurer } from "store/api/api";
import { InsurerSettings } from "types/types";
import { QUERY_KEYS } from "../queryKeys";

const getInsurerSettings = async (insurerId: string | number) => {
  const res = await getInsurer(insurerId);

  return res?.data;
};

export function useGetInsurerSettings(
  insurerId: string | number
): UseQueryResult<InsurerSettings> {
  return useQuery(
    [QUERY_KEYS.insurerSettings, insurerId],
    () => getInsurerSettings(insurerId),
    {
      enabled: !!insurerId,
    }
  );
}
