import { mergeStyleSets } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fleetPageSlice } from "store/fleetPage";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { Timeline } from "../../components/timeline/Timeline";
import {
  fleetFormSlice,
  loadAllSharedLicensePlatesRoutine,
  loadFleetWithInsurersActions,
} from "../../store/fleetForm";
import { useSelector } from "../../store/hooks";
import { setTimelineInitValues } from "../../store/timeline";
import { TimelineType } from "../../types/types";
import SharedLicensePlateNavigation from "./SharedLicensePlateNavigation";
import SharedLicensePlateSkeleton from "./SharedLicensePlateSkeleton";

const SharedLicensePlate = () => {
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const sharedLicensePlateVehicles = useSelector(
    (s) => s.fleetForm.sharedLicensePlateVehicles
  );
  const loadAllSharedLicesePlates = useSelector(
    (s) => s.fleetForm.loadAllSharedLicesePlates
  );

  const fleetName = useSelector((s) => s.fleetPage.fleet?.fleetName);
  const fleetNameFromForm = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.fleetName
  );

  const theme = useTheme() as IStyledTheme;
  const classes = getClassNames(theme);

  const isLoading = loadAllSharedLicesePlates === "loading";

  useEffect(() => {
    dispatch(loadAllSharedLicensePlatesRoutine.trigger({ fleetId }));
  }, []);

  useEffect(() => {
    if (!fleetName && !fleetNameFromForm) {
      dispatch(
        loadFleetWithInsurersActions.trigger({ fleetId, getById: true })
      );
    }
  }, [dispatch, fleetId, fleetName, fleetNameFromForm]);

  useEffect(() => {
    dispatch(
      setTimelineInitValues({
        types: [TimelineType.SHARED_LICENSE_PLATE_STATUS],
        readOnly: false,
      })
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(fleetPageSlice.actions.resetState());
      dispatch(fleetFormSlice.actions.resetState());
    };
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <SharedLicensePlateNavigation />
      {isLoading ? (
        <SharedLicensePlateSkeleton />
      ) : (
        <div className={classes.main}>
          {sharedLicensePlateVehicles && (
            <Timeline marginLeft={100} width={1200} />
          )}
        </div>
      )}
    </div>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      overflow: "auto",
      height: "100vh",
    },
    main: {
      background: theme.palette.white,
      boxShadow:
        "0 3.2px 7.2px 0 rgba(0,0,0,.132), 0 0.6px 1.8px 0 rgba(0,0,0,.108)",
      display: "flex",
      flexDirection: "column",
      margin: "50px auto 0",
      borderRadius: "4px",
      maxWidth: "80%",
      //height: "90%", //
      position: "relative",
      marginBottom: "100px",
      flexWrapp: "wrapp",
    },
  });

export default SharedLicensePlate;
