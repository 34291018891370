import { mergeStyleSets } from "@uifabric/merge-styles";
import { useCurrentRoute } from "config/routes";
import { constants } from "library/constants";
import React from "react";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import InsurerCommandBar from "./InsurerCommandBar";

const InsurerFormToolbar = () => {
  const route = useCurrentRoute();
  const theme = useTheme() as IStyledTheme;

  const isDarkMode = (useTheme() as IStyledTheme).isDark;
  const isCreateFleet = route === constants.createFleet;

  const classes = mergeStyleSets({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: isDarkMode
        ? theme.palette.blackTranslucent40
        : theme.palette.white,
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "50px",
      padding: "0 10px",
      marginBottom: "10px",
      justifyContent: "flex-end",
    },
    toolbarRight: {
      height: "100%",
      width: "100%",
      paddingRight: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  });

  return (
    <div className={classes.container}>
      <div className={classes.toolbarRight}>
        <>
          <InsurerCommandBar />
        </>
      </div>
    </div>
  );
};

export default InsurerFormToolbar;
