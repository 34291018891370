import {
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDeleteDialog } from "store/insurersPage";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { toast } from "../../../components/FluentToast";
import { FormSpinner } from "../../../components/FormSpinner";
import { useSelector } from "../../../store/hooks";
import { useDeleteInsurerSettings } from "hooks/data/mutations/useDeleteInsurerSettings";

export const DeleteDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const deleteDialog = useSelector((s) => s.insurersPage.deleteDialog);
  const { t } = useTranslation();
  const phraseActivated = usePhraseActivationStatus().activated;
  const { mutate: onDeleteInsurerSettings, isLoading } =
    useDeleteInsurerSettings();

  const dispatch = useDispatch();

  const close = () => {
    dispatch(
      setDeleteDialog({
        isOpen: false,
        insurerSettings: null,
      })
    );
  };

  return (
    <Dialog
      hidden={!deleteDialog.isOpen}
      onDismiss={close}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              iconName="Warning"
              style={{
                color: theme.semanticColors.errorIcon,
                fontSize: "25px",
                marginRight: "10px",
              }}
            />
            {t("greco.delete")}
          </div>
        ),
      }}
    >
      {t("greco.dialog.deleteInsurerSettings")}
      <DialogFooter>
        <PrimaryButton
          text={t("greco.delete")}
          iconProps={{
            iconName: "Delete",
          }}
          disabled={isLoading}
          onClick={() => {
            onDeleteInsurerSettings(
              {
                insurerSettingsId:
                  deleteDialog.insurerSettings.insurerPartnerNumber.toString(),
              },
              {
                onSuccess: () => {
                  toast.success(t("bfm.success"));
                  close();
                },
              }
            );
          }}
        />
      </DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};
