import React from "react";
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { NavigationBar } from "../../../components/styled";

import FleetNavigation from "components/FleetNavigation";

type Props = {
  refExcelExport: ExcelExport;
};

export const Navigation = ({ refExcelExport }: Props) => {
  return (
    <NavigationBar>
      <FleetNavigation refExcelExport={refExcelExport} />
    </NavigationBar>
  );
};
