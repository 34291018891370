import Tippy from "@tippyjs/react";
import { timeFormat } from "d3";
import React from "react";
import { useTranslation } from "react-i18next";
import "tippy.js/dist/tippy.css";
import { useSelector } from "../../../../../store/hooks";
import {
  ETimelineExtraDataLineSpecial,
  PremiumType,
} from "../../../../../types/types";

const formatter = new Intl.NumberFormat("de-DE", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const tickFormat = timeFormat("%d.%m.%Y");
export const currencyFormat = (num: number) => {
  if (!num && num !== 0) return;
  return formatter.format(num);
};

export const BookingSegmentTooltip = ({ children, d }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  let period = (
    <>
      {tickFormat(d.startDate)}
      <br />
    </>
  );
  const total: number = d.extraData.premiums
    .map((el) => el.amount)
    .reduce((prev, current) => prev + current, 0);

  const getDomainValue = (domainValue, lineSpecial) => {
    if (
      domainValue === PremiumType.FLEETLEGAL_PRORATA &&
      lineSpecial === ETimelineExtraDataLineSpecial.CLOSING_DATE
    ) {
      return PremiumType.FLEETLEGAL_CLOSINGDATE;
    }
    if (
      domainValue === PremiumType.FLEETLEGAL_PRORATA &&
      lineSpecial !== ETimelineExtraDataLineSpecial.CLOSING_DATE
    ) {
      return PremiumType.FLEETLEGAL_PRORATA;
    }

    if (
      domainValue === PremiumType.HULL &&
      lineSpecial === ETimelineExtraDataLineSpecial.BASIC
    ) {
      return PremiumType.HULL_BASIC;
    }
    if (
      domainValue === PremiumType.HULL &&
      lineSpecial !== ETimelineExtraDataLineSpecial.BASIC
    ) {
      return PremiumType.HULL;
    }

    return domainValue;
  };
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      maxWidth={1000}
      content={
        <span>
          {t("bfm.vehicleForm.tabPremiumBooking.label")}
          {" - "}

          {t(getDomainValue(d.domain.value, d.extraData.lineSpecial))}
          <hr />
          {t(
            taxonomy.VehicleIssuingStatus.byId[
              d.associatedObject.vehicleIssuingStatusCode
            ]?.code
          )}
          <br />
          {period}
          <hr />
          {d.extraData.premiums.map((premium, index) => {
            return (
              <span key={`premium_${premium}_${index}`}>
                {d.extraData.premiums.length > 1 ? (
                  <span
                    style={{
                      paddingLeft: "20px",
                    }}
                  >
                    {t(
                      taxonomy.PremiumBookingReason.byId[premium.reason].code
                    ).replace(/ /g, "\u00a0")}
                    {":\u00a0"}
                    {currencyFormat(premium.amount)}&nbsp;&euro;
                    <br />
                  </span>
                ) : (
                  <span>
                    {t(
                      taxonomy.PremiumBookingReason.byId[premium.reason].code
                    ).replace(/ /g, "\u00a0")}
                    {":\u00a0"}
                    {currencyFormat(premium.amount)}&nbsp;&euro;
                    <br />
                  </span>
                )}
              </span>
            );
          })}
          {d.extraData.premiums.length > 1 ? (
            <>
              {t("bfm.total")}
              {":"}&nbsp;
              {currencyFormat(total)}
              &nbsp;&euro;
            </>
          ) : null}
        </span>
      }
    >
      {children}
    </Tippy>
  );
};
