import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserRole } from "types/types";
import { useSelector } from "../../store/hooks";
import { selectSortInsurersPage } from "../../store/insurersPage";
import { defaultColumns } from "./InsurerList/columns";
import { DeleteDialog } from "./InsurerList/DeleteDialog";
import InsurerListTable from "./InsurerList/InsurerListTable";
import { Navigation } from "./Navigation";

import useFilteredInsurerList from "./useFilteredInsurerList";
import { useGetInsurerList } from "hooks/data/queries/useGetInsurerList";

export const Insurers = () => {
  const { t } = useTranslation();
  const { data: insurerSettings } = useGetInsurerList();

  const sortValue = useSelector(selectSortInsurersPage);

  const insurers = useFilteredInsurerList();

  const userRole = useSelector((s) => s.auth.userRole);

  const insurersWithSettings = insurers?.map((insurer) => {
    const insurerSetting = insurerSettings?.find(
      (sett) => sett.insurerPartnerNumber === insurer.insurerInternalNumber
    );
    return {
      ...insurer,
      insurerSetting,
    };
  });

  const detailsListColumns = useMemo(() => {
    const filteredTableColumns = defaultColumns.filter((c) => {
      if (
        userRole === undefined ||
        userRole === null ||
        userRole === UserRole.external
      ) {
        return c.key !== "options";
      }
      return true;
    });
    const withTranslatedHeader = filteredTableColumns
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sortValue.key && column.isSortable) {
          iconName =
            sortValue.key === column.key
              ? sortValue!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.name),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
    return [...withTranslatedHeader];
  }, [userRole, sortValue, t]);
  return (
    <>
      <Navigation />
      <InsurerListTable
        items={insurersWithSettings}
        columns={detailsListColumns}
      />
      <DeleteDialog />
    </>
  );
};
