import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Timeline,
  TimelineContextMenu,
  TimelineContextMenuType,
  TimelineDialogType,
  TimelineSegment,
  TimelineType,
} from "../types/types";
import { LoadStatus } from "./store";

export type TimelineState = {
  types: TimelineType[];
  readOnly: boolean;
  timeline: Timeline;
  operationsEnabled: boolean;
  loadInsurerSettingsStatus: LoadStatus;
  operationRetVal: any;
  dialog: {
    isOpen: boolean;
    item: TimelineSegment;
    date: Date;
    previousDialogData: any;
    type: TimelineDialogType;
    defaultValueOperation: {
      fieldNames: string[];
      formValues: any;
      results: any;
      noMessage: boolean;
    };
    insurerSettingsOperation: {
      fieldNames: string[];
      formValues: any;
      results: any;
      noMessage: boolean;
    };
  };
  contextMenu: TimelineContextMenu;
};

const initialState: TimelineState = {
  types: [],
  readOnly: true,
  timeline: {
    startDate: null,
    endDate: null,
    prorata: null,
    bookingTotals: null,
    domain: null,
    data: null,
    dateFilter: null,
    filteredData: null,
    legend: null,
    insurerList: [],
    showBookings: true,
    showBookingsWithDocumentDate: true,
  },
  loadInsurerSettingsStatus: "none",
  operationRetVal: null,
  operationsEnabled: true,
  dialog: {
    isOpen: false,
    item: null,
    date: null,
    previousDialogData: null,
    type: null,
    defaultValueOperation: {
      fieldNames: null,
      formValues: null,
      results: null,
      noMessage: false,
    },
    insurerSettingsOperation: {
      fieldNames: null,
      formValues: null,
      results: null,
      noMessage: false,
    },
  },
  contextMenu: {
    type: null,
    isOpen: false,
    item: null,
    ref: null,
    date: null,
  },
};

export const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    setTimeline: (s, a: PayloadAction<TimelineState["timeline"]>) => {
      s.timeline = a.payload;
    },
    setTypes: (s, a: PayloadAction<TimelineState["types"]>) => {
      s.types = a.payload;
    },
    setTimelineInitValues: (s, a: PayloadAction<any>) => {
      s.types = a.payload.types;
      s.readOnly = a.payload.readOnly;
    },
    setOperationRetVal: (s, a: PayloadAction<any>) => {
      s.operationRetVal = a.payload;
    },
    setShowBookings: (s, a: PayloadAction<any>) => {
      s.timeline.showBookings = a.payload;
    },
    setShowBookingsWithDocumentDate: (s, a: PayloadAction<any>) => {
      s.timeline.showBookingsWithDocumentDate = a.payload;
    },
    setDialogDefaultValueOperation: (s, a: PayloadAction<any>) => {
      s.dialog.defaultValueOperation.fieldNames = a.payload.fieldNames;
      s.dialog.defaultValueOperation.formValues = a.payload.formValues;
      s.dialog.defaultValueOperation.results = null;
      s.dialog.defaultValueOperation.noMessage = a.payload.noMessage || false;
    },

    setDialogInsurerSettingsOperation: (s, a: PayloadAction<any>) => {
      s.dialog.insurerSettingsOperation.fieldNames = a.payload.fieldNames;
      s.dialog.insurerSettingsOperation.formValues = a.payload.formValues;
      s.dialog.insurerSettingsOperation.results = null;
      s.dialog.insurerSettingsOperation.noMessage =
        a.payload.noMessage || false;
    },
    setDialogDefaultValueOperationResult: (s, a: PayloadAction<any>) => {
      s.dialog.defaultValueOperation.results = a.payload;
    },
    setDialogInsurerSettingsOperationResult: (s, a: PayloadAction<any>) => {
      s.dialog.insurerSettingsOperation.results = [];
      for (const fieldName in a.payload) {
        s.dialog.insurerSettingsOperation.results[fieldName] =
          a.payload[fieldName];
      }
    },
    resetDialogDefaultValueOperation: (s, a: PayloadAction<any>) => {
      const fieldName = a.payload;
      const fieldNames = s.dialog.defaultValueOperation.fieldNames.filter(
        (a) => a !== fieldName
      );
      let results = { ...s.dialog.defaultValueOperation.results };
      delete results[fieldName];

      s.dialog.defaultValueOperation = {
        fieldNames: fieldNames,
        formValues: s.dialog.defaultValueOperation.formValues,
        results: results,
        noMessage: false,
      };
    },

    resetDialogDefaultValue: (s, a: PayloadAction<any>) => {
      s.dialog.defaultValueOperation = {
        fieldNames: null,
        formValues: null,
        results: null,
        noMessage: false,
      };
    },

    resetDialogInsurerSettings: (s, a: PayloadAction<any>) => {
      s.dialog.insurerSettingsOperation = {
        fieldNames: null,
        formValues: null,
        results: null,
        noMessage: false,
      };
    },

    resetDialogInsurerSettingsOperation: (s, a: PayloadAction<any>) => {
      const fieldName = a.payload;
      const fieldNames = s.dialog.insurerSettingsOperation.fieldNames.filter(
        (a) => a !== fieldName
      );
      let results = { ...s.dialog.insurerSettingsOperation.results };
      delete results[fieldName];

      s.dialog.insurerSettingsOperation = {
        fieldNames: fieldNames,
        formValues: s.dialog.insurerSettingsOperation.formValues,
        results: results,
        noMessage: false,
      };
    },

    setDateFilter: (s, a: PayloadAction<Timeline["dateFilter"]>) => {
      s.timeline.dateFilter = a.payload;
      s.timeline.filteredData = s.timeline.dateFilter
        ? s.timeline.data.filter((d) => {
            const startDate = d.startDate;
            const endDate = d.endDate;
            const v1 =
              endDate >= s.timeline.dateFilter[0] &&
              endDate <= s.timeline.dateFilter[1];
            const v2 =
              startDate >= s.timeline.dateFilter[0] &&
              endDate <= s.timeline.dateFilter[1];
            const v3 =
              startDate >= s.timeline.dateFilter[0] &&
              startDate <= s.timeline.dateFilter[1];
            const v4 =
              startDate <= s.timeline.dateFilter[0] &&
              endDate >= s.timeline.dateFilter[1];
            return v1 || v2 || v3 || v4;
          })
        : s.timeline.data;
      if (s.timeline.dateFilter === null) {
        s.timeline.dateFilter = [s.timeline.startDate, s.timeline.endDate];
      }
    },
    openDialog: (
      s,
      a: PayloadAction<{
        contextMenuItem: TimelineSegment;
        contextMenuDate: Date;
        previousDialogData?: any;
        dialogType: TimelineDialogType;
      }>
    ) => {
      s.dialog = {
        isOpen: true,
        item: a.payload.contextMenuItem,
        date: a.payload.contextMenuDate,
        previousDialogData: a.payload.previousDialogData,
        type: a.payload.dialogType,
        defaultValueOperation: {
          fieldNames: null,
          formValues: null,
          results: null,
          noMessage: false,
        },
        insurerSettingsOperation: {
          fieldNames: null,
          formValues: null,
          results: null,
          noMessage: false,
        },
      };
    },
    closeDialog: (s) => {
      s.dialog = {
        isOpen: false,
        item: null,
        date: null,
        previousDialogData: null,
        type: null,
        defaultValueOperation: {
          fieldNames: null,
          formValues: null,
          results: null,
          noMessage: false,
        },
        insurerSettingsOperation: {
          fieldNames: null,
          formValues: null,
          results: null,
          noMessage: false,
        },
      };
    },
    openContextMenu: (
      s,
      a: PayloadAction<{
        type: TimelineContextMenuType;
        item: TimelineSegment;
        ref: any;
        date: Date;
      }>
    ) => {
      s.contextMenu = {
        isOpen: true,
        type: a.payload.type,
        item: a.payload.item,
        ref: a.payload.ref,
        date: a.payload.date,
      };
    },
    closeContextMenu: (s) => {
      s.contextMenu = {
        type: null,
        isOpen: false,
        item: null,
        ref: null,
        date: null,
      };
    },
    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
  },
});

export const {
  actions: {
    openDialog,
    closeDialog,
    resetState,
    openContextMenu,
    closeContextMenu,
    setTimeline,
    setTypes,
    setTimelineInitValues,
    setDateFilter,
    setOperationRetVal,
    setShowBookings,
    setShowBookingsWithDocumentDate,
    setDialogDefaultValueOperation,
    setDialogDefaultValueOperationResult,
    setDialogInsurerSettingsOperation,
    setDialogInsurerSettingsOperationResult,
    resetDialogDefaultValueOperation,
    resetDialogInsurerSettingsOperation,
    resetDialogDefaultValue,
    resetDialogInsurerSettings,
  },
  reducer: timelineReducer,
} = timelineSlice;
