import { ExcelExport } from "@progress/kendo-react-excel-export";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Country_AUSTRIA } from "types/types";
import { ExportExcelVehicles } from "../../components/ExportExcelVehicles";
import { useSelector } from "../../store/hooks";
import {
  loadInsurersActions,
  loadVehiclesActions,
  resetState,
  selectAllVehicles,
  selectColumnsTable,
} from "../../store/requireActionVehicles";
import { AppDispatch } from "../../store/store";
import { sanitizeFileName } from "../../utils/utils";
import { CancelStatusDialog } from "./CancelStatusDialog";
import { ChangeVehicleStatusDialog } from "./ChangeStatusDialog";
import { CorrectStatusDateDialog } from "./CorrectStatusDateDialog";
import RequireActionVehicleListTable from "./RequireActionVehicleListTable/RequireActionVehicleListTable";
import { RequiredColumnOptions } from "./RequiredColumnOptions";
import { Navigation } from "./navigation/Navigation";

const RequireActionVehicles = () => {
  const { t } = useTranslation();
  const vehicles = useSelector(selectAllVehicles);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const fileName = useMemo(() => {
    return sanitizeFileName(
      "Vehicles" + moment(new Date()).format("_YYYY_MM_DD_HH_mm_ss")
    );
  }, []);

  const detailsListColumnsRedux = useSelector(selectColumnsTable)(t);
  const refExcelExport = useRef<ExcelExport>();
  const isChangeStatusDialogOpen = useSelector(
    (s) => s.requireActionVehicles.changeVehicleStatusDialog.isOpen
  );
  const isCancelStatusDialogOpen = useSelector(
    (s) => s.requireActionVehicles.cancelVehicleStatusDialog.isOpen
  );
  const isCorrectStatusDateDialogOpen = useSelector(
    (s) => s.requireActionVehicles.correctStatusDateDialog.isOpen
  );
  const isColumnOptionsPanelOpen = useSelector(
    (s) => s.requireActionVehicles.isColumnOptionsPanelOpen
  );

  const columns = useSelector((s) => s.fleetPage.columns);

  useEffect(() => {
    dispatch(
      loadVehiclesActions.trigger({
        isReset: true,
        shouldDelay: false,
      })
    );
    dispatch(loadInsurersActions.trigger(Country_AUSTRIA));
  }, []);

  const detailsListColumns = useMemo(() => {
    const hasFleetName = detailsListColumnsRedux.some(
      (item) => item.key === "fleetName"
    );
    if (!hasFleetName) {
      detailsListColumnsRedux.splice(2, 0, {
        key: "fleetName",
        isResizable: true,
        fieldName: "fleetName",
        labelKey: "bfm.fleetName",
        name: t("bfm.fleetName"),
        minWidth: 150,
        maxWidth: 150,
        isVisible: true,
        isFixed: true,
      });
    }

    return detailsListColumnsRedux
      ?.map((column) => {
        if (column?.key === "insuranceLineIcons") {
          return {
            ...column,
            minWidth: 320,
            maxWidth: 400,
          };
        }
        return column;
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
  }, [detailsListColumnsRedux]);

  return (
    <>
      <Navigation refExcelExport={refExcelExport?.current} />
      <RequireActionVehicleListTable
        items={vehicles}
        columns={detailsListColumns}
      />
      <ExportExcelVehicles
        ref={refExcelExport}
        columns={detailsListColumns.filter((col) => col.key !== "filler")}
        fileName={fileName}
      />
      {isColumnOptionsPanelOpen && (
        <RequiredColumnOptions countryCode={Country_AUSTRIA} />
      )}
      {isChangeStatusDialogOpen && <ChangeVehicleStatusDialog />}
      {isCancelStatusDialogOpen && <CancelStatusDialog />}
      {isCorrectStatusDateDialogOpen && <CorrectStatusDateDialog />}
    </>
  );
};

export default RequireActionVehicles;
