import styled from "styled-components";

import {
  DefaultButton,
  Icon,
  IconButton,
  Persona,
  PersonaSize,
  TooltipHost,
} from "@fluentui/react";
import { Flex, HSpace, VSpace } from "components/styled";
import { isExternalOrReadonlyAdmin } from "form/util";
import { useGetFleetVehicleDefaultSettings } from "hooks/data/queries/getFleetVehicleDefaultSettings";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { FleetHullCalculationDefaultSettingType } from "types/types";
import { formatNumber } from "utils/number";
import { DeleteDialog } from "./DeleteDialog";

import { EActionDialog, EDialogAttributeName } from "../../constants";

export const DefaultSettings = ({ settingRef, userRole }) => {
  const dispatch = useDispatch();
  const { fleetId } = useParams() as any;
  const { t } = useTranslation();
  const insurerList = useSelector((s) => s.fleetForm.insurerList);
  const { data: defaultSettings, isLoading } =
    useGetFleetVehicleDefaultSettings(fleetId);

  const taxonomy = useSelector((s) => s.taxonomy);
  const [deleteDialog, setDeleteDialog] = useState({
    isOpen: false,
    setting: null,
  });

  const openSettingDialog = (
    type: string,
    attributeName: string,
    title: string,
    settingId?: number,
    selectedSetting?: any,
    isReadOnly?: boolean
  ) => {
    dispatch(
      setDialog({
        type,
        isOpen: true,
        title: isReadOnly
          ? title
          : `${
              settingId
                ? t("bfm.fleet.defaultSettings.edit.label")
                : t("bfm.fleet.defaultSettings.create.label")
            } - ${title}`,
        icon: "Add",
        data: { attributeName, settingId, selectedSetting },
      })
    );
  };

  const openCopySettingDialog = ({
    data,
    title,
  }: {
    title: string;
    data: {
      attributeName: string;
      settingId: null;
      selectedSetting: any;
      hasTradeLicensePlate;
    };
  }) => {
    dispatch(
      setDialog({
        type: EActionDialog.CopySettings,
        isOpen: true,
        title,
        icon: "Add",
        data,
      })
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <div
      ref={settingRef}
      id="default-values-tab"
      style={{
        marginTop: "100px",
      }}
    >
      <DeleteDialog
        deleteDialog={deleteDialog}
        close={() => setDeleteDialog({ isOpen: false, setting: null })}
      />
      <Flex
        style={{
          height: "44px",
          padding: "0 8px",
        }}
      >
        <DefaultButton
          text={t("bfm.fleet.defaultSettings.create.label")}
          iconProps={{ iconName: "Add" }}
          menuProps={{
            items: [
              {
                key: "annualGrossPremium",
                text: t("bfm.annualGrossPremium"),
                onClick: () =>
                  openSettingDialog(
                    EActionDialog.CreateSetting,
                    EDialogAttributeName.AnnualGrossPremium,
                    t("bfm.annualGrossPremium")
                  ),
                disabled: isExternalOrReadonlyAdmin(userRole),
              },
              {
                key: "deductible",
                text: t("bfm.hulldeductible.label"),
                onClick: () =>
                  openSettingDialog(
                    EActionDialog.CreateSetting,
                    EDialogAttributeName.Deductible,
                    t("bfm.hulldeductible.label")
                  ),
                disabled: isExternalOrReadonlyAdmin(userRole),
              },
              {
                key: "calculation",
                text: t("bfm.hullPremiumCalculation.label"),
                onClick: () =>
                  openSettingDialog(
                    EActionDialog.CreateSetting,
                    EDialogAttributeName.Calculation,
                    t("bfm.hullPremiumCalculation.label")
                  ),
                disabled: isExternalOrReadonlyAdmin(userRole),
              },
            ],
          }}
        />
        <HSpace />
      </Flex>
      <Table>
        <thead>
          <tr>
            <th>{t("bfm.user")}</th>
            <th>{t("bfm.fleet.defaultSettings.table.attributeName.label")}</th>
            <th>{t("bfm.fleet.premiumType.label")}</th>
            <th>{t("bfm.insurer.label")}</th>
            <th style={{ width: "320px" }}>
              {t("bfm.fleet.defaultSettings.table.attributeValue.label")}
            </th>
            <th>{t("bfm.criteria")}</th>
            <th style={{ width: "80px" }}></th>
          </tr>
        </thead>
        <tbody>
          {defaultSettings?.map((s) => {
            const insurerName = insurerList.find(
              (insurer) =>
                insurer.insurerInternalNumber === s.insurerPartnerNumber
            ).insurerName;
            const premiumTypeTaxonomyCode =
              taxonomy.PremiumType.byId[s.premiumType]?.code;

            const carUsageCode =
              taxonomy.VehicleUsageType.byId[s.carUsage]?.code;

            // const vehicleMainGroupTaxonomyCode =
            //   taxonomy.VehicleMainGroup.byId[s.vehicleMainGroup]?.code;
            const vehicleTypeTaxonomyCode =
              taxonomy.VehicleTypeLegal.byId[s.vehicleType]?.code;
            const maxGrossWeightKgRange =
              taxonomy.MaxGrossWeightKgRange.byId[s.maxGrossWeightKgRange]
                ?.code;
            const weightCapacityTaxonomyCode =
              taxonomy.WeightCapacityKgRange.byId[s.weightCapacityKgRange]
                ?.code;

            const powerKwFrom = s?.powerKwFrom;
            const powerKwTo = s?.powerKwTo;

            const emptyWeightKgFrom = s?.emptyWeightKgFrom;
            const emptyWeightKgTo = s?.emptyWeightKgTo;

            const cubicCapacityCcmRange =
              taxonomy.CubicCapacityCcmRangeHg1.byId[s.cubicCapacityCcmRange]
                ?.code;

            const numberOfSeatsMoped =
              taxonomy.NumberOfSeatsMoped.byId[s.numberOfSeatsMoped]?.code;

            const numberOfSeatsRange =
              taxonomy.NumberOfSeatsRange.byId[s.numberOfSeatsRange]?.code;

            const listPriceTypeCode =
              taxonomy.ListPriceType.byId[s.listPriceType]?.code;

            const listPriceMin = s.listPriceMin;

            const listPriceMax = s.listPriceMax;

            let tempUser;
            if (s.lastModifiedByUserName) {
              tempUser = s.lastModifiedByUserName;
            } else if (s.lastModifiedByUserEmail) {
              tempUser = s.lastModifiedByUserEmail;
            } else if (s.lastModifiedByUserId) {
              tempUser = s.lastModifiedByUserId;
            }

            const lastModifiedByUser = tempUser;

            let attributeValue: any = s.attributeValue;
            if (s.attributeName === "annualGrossPremium") {
              attributeValue = formatNumber(Number(s.attributeValue));
            } else if (s.attributeName === "deductible") {
              const attributeValues = JSON.parse(s.attributeValue as string);
              attributeValue = (
                <>
                  {attributeValues.hullDeductibleMinAmount
                    ? t("bfm.vehicleForm.hullDeductibleMinAmount.label") +
                      ": " +
                      attributeValues.hullDeductibleMinAmount
                    : null}
                  {attributeValues.hullDeductibleMinAmount ? <br /> : null}
                  {attributeValues.hullDeductibleMaxAmount
                    ? t("bfm.vehicleForm.hullDeductibleMaxAmount.label") +
                      ": " +
                      attributeValues.hullDeductibleMaxAmount
                    : null}
                  {attributeValues.hullDeductibleMaxAmount ? <br /> : null}
                  {attributeValues.hullDeductiblePercent
                    ? t("bfm.vehicleForm.hullDeductiblePercent.label") +
                      ": " +
                      attributeValues.hullDeductiblePercent
                    : null}
                  {attributeValues.hullDeductiblePercent ? <br /> : null}
                  {attributeValues.hullDeductibleComment
                    ? t("bfm.vehicleForm.hullDeductibleComment.label") +
                      ": " +
                      attributeValues.hullDeductibleComment
                    : null}
                </>
              );
            } else if (s.attributeName === "calculation") {
              const attributeValues = JSON.parse(s.attributeValue as string);
              attributeValue = (
                <>
                  {(attributeValues.valueType &&
                  attributeValues.valueType ===
                    FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
                    ? t(
                        "bfm.hullPremiumCalculation.absoulutePremiumValue.label"
                      )
                    : t(
                        "bfm.hullPremiumCalculation.premiumRatePercentage.label"
                      )) + ": "}
                  {attributeValues.valueType &&
                  attributeValues.valueType ===
                    FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
                    ? attributeValues.absoulutePremiumValue
                    : attributeValues.premiumRatePercentage}
                  <br />
                  {t(
                    "bfm.hullPremiumCalculation.premiumFreeSpecialEquipment.label"
                  ) + ": "}
                  <br />
                  {attributeValues.premiumFreeSpecialEquipment}
                  <br />
                  {t("bfm.hullPremiumCalculation.gap.label") + ": "}
                  {attributeValues.gap}
                  <br />
                  {t("bfm.hullPremiumCalculation.replacementValue.label") +
                    ": "}
                  {attributeValues.replacementValue}
                  <br />
                  {t("bfm.hullPremiumCalculation.grossNegligence.label") + ": "}
                  {attributeValues.grossNegligence}
                  <br />
                  {t("bfm.hullPremiumCalculation.electroPlusPackage.label") +
                    ": "}
                  {attributeValues.electroPlusPackage}
                  <br />
                  {t(
                    "bfm.hullPremiumCalculation.discountForWaiverOfTaxRefund.label"
                  ) + ": "}
                  {attributeValues.discountForWaiverOfTaxRefund}
                </>
              );
            }
            let attributeTitle;

            switch (s.attributeName) {
              case "annualGrossPremium":
                attributeTitle = "bfm.annualGrossPremium";
                break;
              case "deductible":
                attributeTitle = "bfm.deductible";
                break;
              case "calculation":
                attributeTitle = "bfm.hullPremiumCalculation.label";
                break;

              default:
                attributeTitle = "";
            }

            return (
              <tr
                key={s.fleetVehicleDefaultSettingId}
                style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
              >
                <td>
                  <Flex justify="center">
                    <Persona
                      text={lastModifiedByUser}
                      title={lastModifiedByUser}
                      hidePersonaDetails
                      size={PersonaSize.size32}
                    />
                  </Flex>
                </td>
                <td>
                  <Cell>
                    {t(`bfm.fleet.defaultSettings.table.${s.attributeName}`)}
                  </Cell>
                </td>
                <td>
                  <Cell>
                    {!!!s.vehicleType ? (
                      <TooltipHost content={t("bfm.tradeLicensePlates.label")}>
                        <Icon
                          style={{
                            visibility: s.isTradeLicensePlate
                              ? "visible"
                              : "hidden",
                          }}
                          iconName="Market"
                        />
                        &nbsp;&nbsp;
                      </TooltipHost>
                    ) : null}

                    {t(premiumTypeTaxonomyCode)}
                  </Cell>
                </td>
                <td>
                  <Cell>{insurerName}</Cell>
                </td>
                <td>
                  <Cell>{attributeValue}</Cell>
                </td>
                <td>
                  <Cell>
                    <>
                      {t("bfm.tradeLicensePlates.label") + ": "}
                      {s.isTradeLicensePlate ? t("greco.yes") : t("greco.no")}
                      <br />
                    </>
                    {vehicleTypeTaxonomyCode ? (
                      <>
                        {t("bfm.vehicleForm.vehicleTypeCode.label") + ": "}
                        {t(vehicleTypeTaxonomyCode)}
                        <br />
                      </>
                    ) : null}
                    {carUsageCode ? (
                      <>
                        {t("bfm.vehicleForm.carUsageCode.label") + ": "}
                        {t(carUsageCode)}
                        <br />
                      </>
                    ) : null}
                    {listPriceTypeCode ? (
                      <>
                        {t("bfm.vehicleForm.listPriceTypeCode.label") + ": "}
                        {t(listPriceTypeCode)}
                        <br />
                      </>
                    ) : null}
                    {listPriceMin !== null ? (
                      <>
                        {t("bfm.listPriceMin.label") + ": "}
                        {listPriceMin}
                        <br />
                      </>
                    ) : null}
                    {listPriceMax !== null ? (
                      <>
                        {t("bfm.listPriceMax.label") + ": "}
                        {listPriceMax}
                        <br />
                      </>
                    ) : null}
                    {weightCapacityTaxonomyCode ? (
                      <>
                        {t("bfm.vehicleForm.weightCapacityKg.label") + ": "}
                        {t(weightCapacityTaxonomyCode)}
                        <br />
                      </>
                    ) : null}
                    {maxGrossWeightKgRange ? (
                      <>
                        {t("bfm.fleet.adjustmentDialog.maxGrossWeightKgRange") +
                          ": "}
                        {t(maxGrossWeightKgRange)}
                        <br />
                      </>
                    ) : null}
                    {powerKwFrom ? (
                      <>
                        {t("bfm.vehicleForm.powerKwFrom.label") + ": "}
                        {powerKwFrom}
                        <br />
                      </>
                    ) : null}
                    {powerKwTo ? (
                      <>
                        {t("bfm.vehicleForm.powerKwTo.label") + ": "}
                        {powerKwTo}
                        <br />
                      </>
                    ) : null}
                    {emptyWeightKgFrom ? (
                      <>
                        {t("bfm.vehicleForm.emptyWeightKgFrom.label") + ": "}
                        {emptyWeightKgFrom}
                        <br />
                      </>
                    ) : null}
                    {emptyWeightKgTo ? (
                      <>
                        {t("bfm.vehicleForm.emptyWeightKgTo.label") + ": "}
                        {emptyWeightKgTo}
                        <br />
                      </>
                    ) : null}
                    {cubicCapacityCcmRange ? (
                      <>
                        {t("bfm.vehicleForm.cubicCapacityCcm.label") + ": "}
                        {t(cubicCapacityCcmRange)}
                        <br />
                      </>
                    ) : null}
                    {numberOfSeatsMoped ? (
                      <>
                        {t("bfm.vehicleForm.seats.label") + ": "}
                        {t(numberOfSeatsMoped)}
                        <br />
                      </>
                    ) : null}
                    {numberOfSeatsRange ? (
                      <>
                        {t("bfm.vehicleForm.seats.label") + ": "}
                        {t(numberOfSeatsRange)}
                        <br />
                      </>
                    ) : null}
                  </Cell>
                </td>

                <td>
                  <Flex
                    justify="flex-end"
                    style={{
                      flexWrap: "nowrap",
                    }}
                  >
                    <IconButton
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      iconProps={{ iconName: "View" }}
                      onClick={() => {
                        openSettingDialog(
                          EActionDialog.ReadOnly,
                          s.attributeName,
                          t(attributeTitle),
                          s.fleetVehicleDefaultSettingId,
                          s,
                          true
                        );
                      }}
                    />
                    <IconButton
                      iconProps={{ iconName: "Copy" }}
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      onClick={() => {
                        openCopySettingDialog({
                          data: {
                            attributeName: s.attributeName,
                            settingId: null,
                            selectedSetting: s,
                            hasTradeLicensePlate: !s?.vehicleType,
                          },
                          title: t(attributeTitle),
                        });
                      }}
                    />
                    <IconButton
                      iconProps={{ iconName: "Edit" }}
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      onClick={() => {
                        openSettingDialog(
                          EActionDialog.EditSetting,
                          s.attributeName,
                          t(attributeTitle),
                          s.fleetVehicleDefaultSettingId,
                          s
                        );
                      }}
                    />
                    <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      disabled={isExternalOrReadonlyAdmin(userRole)}
                      onClick={() =>
                        setDeleteDialog({
                          isOpen: true,
                          setting: s,
                        })
                      }
                    />
                  </Flex>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <VSpace height={200} />
    </div>
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

export const CellStyled = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: center;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Cell = ({ children }) => {
  return (
    // <FluentTooltip content={children}>
    <CellStyled justify="center">{children}</CellStyled>
    // </FluentTooltip>
  );
};
