import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { Country_AUSTRIA_CODE, PremiumType } from "types/types";

const useActionConfig = () => {
  const { t } = useTranslation();

  const insurerList = useSelector((s) => s.fleetForm.insurerList);
  const taxonomy = useSelector((s) => s.taxonomy);

  const insurerOptions = useMemo(() => {
    return insurerList?.map((insurer) => ({
      value: insurer.insurerInternalNumber,
      label: t(insurer.insurerName),
    }));
  }, [insurerList, t]);

  const vehicleMainGroupOptions = useMemo(() => {
    return taxonomy?.VehicleMainGroup?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code.split(".")[1],
    }));
  }, [t, taxonomy?.VehicleMainGroup?.items]);

  const premiumTypeOptions = useMemo(() => {
    return taxonomy?.PremiumType?.items
      .filter((item) => item.code !== PremiumType.HORSE_POWER)
      .map((item) => ({
        value: item.id,
        label: t(item.code),
        code: item.code.split(".")[1],
      }));
  }, [t, taxonomy?.PremiumType?.items]);

  const premiumTypeOptionsHull = useMemo(() => {
    return taxonomy?.PremiumType?.items
      .filter(
        (item) =>
          item.code === PremiumType.HULL || item.code === PremiumType.HULL_BASIC
      )
      .map((item) => ({
        value: item.id,
        label: t(item.code),
        code: item.code.split(".")[1],
      }));
  }, [t, taxonomy?.PremiumType?.items]);

  const changePaymentMethodPremiumTypeOptions = useMemo(() => {
    return taxonomy?.PremiumType?.items
      .filter(
        (item) =>
          item.code === PremiumType.MTPL ||
          item.code === PremiumType.HULL ||
          item.code === PremiumType.HULL_BASIC ||
          item.code === PremiumType.HORSE_POWER ||
          item.code === PremiumType.FLEETLEGAL_PRORATA ||
          item.code === PremiumType.FLEETLEGAL_CLOSINGDATE
      )
      .map((item) => ({
        value: item.id,
        label: t(item.code),
        code: item.code.split(".")[1],
      }));
  }, [t, taxonomy?.PremiumType?.items]);

  const paymentMethodOptions = useMemo(() => {
    return taxonomy?.PaymentMethod?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code,
    }));
  }, [t, taxonomy?.PaymentMethod?.items]);

  const paymentMeanOptions = useMemo(() => {
    return taxonomy?.PremiumPaymentMean?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code,
    }));
  }, [t, taxonomy?.PremiumPaymentMean?.items]);

  const carUsageOptions = useMemo(() => {
    return taxonomy?.VehicleUsageType?.items
      .filter((item) => {
        return item.code.includes(Country_AUSTRIA_CODE);
      })
      .map((item) => ({
        value: item.id,
        label: t(item.code),
        code: item.code,
      }));
  }, [t, taxonomy?.VehicleUsageType?.items]);

  const vehicleTypeCodeOptions = useMemo(() => {
    return taxonomy.VehicleTypeLegal.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code,
    }));
  }, [t, taxonomy?.VehicleTypeLegal?.items]);

  const listPriceTypeOptions = useMemo(() => {
    return taxonomy.ListPriceType.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code,
    }));
  }, [t, taxonomy.ListPriceType.items]);

  const reasonForMotorTaxExclusionOptions = useMemo(() => {
    return taxonomy?.ReasonForMotorTaxExclusion?.items.map((item) => ({
      value: item.id,
      label: t(item.code),
      code: item.code,
    }));
  }, [t, taxonomy?.ReasonForMotorTaxExclusion?.items]);

  return {
    insurerOptions,
    premiumTypeOptions,
    vehicleMainGroupOptions,
    paymentMethodOptions,
    paymentMeanOptions,
    vehicleTypeCodeOptions,
    carUsageOptions,
    premiumTypeOptionsHull,
    changePaymentMethodPremiumTypeOptions,
    listPriceTypeOptions,
    reasonForMotorTaxExclusionOptions,
  };
};

export default useActionConfig;
