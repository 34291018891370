import { mergeStyleSets, Text, TextField } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";

const getTextFieldStyle = (transparent, theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: transparent ? "transparent" : "inherit",
      ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup input": {
      ...(isMarked && theme.isDark && { color: "black" }),
    },
    ".ms-TextField-fieldGroup input:disabled": {
      background: transparent ? "transparent" : "inherit",
    },
  },
});

const NumberInputField = ({
  disabled,
  label,
  tooltip,
  required = false,
  name,
  error = "",
  value,
  setFieldValue = () => {},
  errors,
  transparent,
  isNegativeAllowed,
  isMarked,
  ...props
}: any) => {
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const styles = getTextFieldStyle(transparent, theme, isMarked);
  const { t } = useTranslation();

  const classes = mergeStyleSets({
    label: {
      fontSize: "14px",
      fontWeight: "600",
      padding: "5px",
      display: "block",

      color: (function () {
        if (disabled) {
          return theme.palette.neutralSecondaryAlt;
        }
        if (isDarkMode) {
          return "white";
        }
        return theme.palette.black;
      })(),
    },
    labelAsterix: {
      color: theme.palette.redDark,
    },
  });

  const onRenderLabel = () => (
    <Text className={classes.label}>
      {t(label)}

      <Text className={classes.labelAsterix}>{required && " *"}</Text>
    </Text>
  );

  const parseInteger = useMemo(
    () => (string) => {
      const integerAccept = isNegativeAllowed ? /-?[\d]*/g : /\d+/g;
      return (string?.match(integerAccept) || []).join("");
    },
    [isNegativeAllowed]
  );

  const formatInteger = useMemo(() => {
    return (string) => {
      const parsed = parseInteger(string);
      const number = Number.parseInt(parsed, 10);
      if (Number.isNaN(number) && parsed === "-") {
        return parsed;
      }
      if (Number.isNaN(number)) {
        return "";
      }
      return number.toLocaleString("en").replace(/,/g, ".");
    };
  }, [parseInteger]);
  let errorMessage = errors[name];
  let nameString = name + "";
  if (nameString.includes("[")) {
    nameString = nameString.replaceAll("]", "");
    const items: string[] = nameString.split("[");
    const itemName = items[0];
    const itemIndex = parseInt(items[1]);
    const errorsItemName = errors[itemName];
    if (errorsItemName) {
      errorMessage = errorsItemName[itemIndex];
    }
  }

  return (
    <FieldContainer
      isTooltipHidden={false}
      tooltipText={label === "" ? tooltip : label}
      isReadOnly={true}
    >
      <TextField
        type="tel"
        onRenderLabel={onRenderLabel}
        label={t(label)}
        name={name}
        styles={styles}
        disabled={disabled}
        onChange={(e) => {
          setFieldValue(e.currentTarget.value);
        }}
        value={value}
        errorMessage={errorMessage}
        autoComplete="off"
        {...props}
      />{" "}
    </FieldContainer>
  );
};

export default React.memo(NumberInputField);

////for float numbers

// <div>Number with fractional part: {fixedFloat}</div>
// <Rifm
//   accept={/[\d.]/g}
//   format={v => formatFixedPointNumber(v, 2)}
//   // 00 is needed here see disadvantages comment at formatNumber
//   value={`${fixedFloat}00`}
//   onChange={value => setFixedFloat(parseNumber(value))}
// >
//   {renderInput}
// </Rifm>
