import { Label, mergeStyleSets } from "@fluentui/react";
import { currencyFormat } from "components/timeline/visual-control/main-timeline/segments/OneTimePremiumSegmentTooltip";
import { useCurrentRoute } from "config/routes";
import { constants } from "library/constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { selectWithDocumentDate } from "store/slices/bookingSlice";
import { useSelector } from "../../../store/hooks";
import FormItemTitle from "../FormItemTitle";
import { Tabs } from "./Tabs";
import WithDocumentDateCheckbox from "./WithDocumentDateCheckbox";

const classes = mergeStyleSets({
  premiumBookingContainer: {
    padding: 40,
  },

  previewPadding: {
    padding: "20px 50px",
  },
  tabsContaner: {
    marginTop: 20,
  },
});

const PremiumBooking = () => {
  const withDocumentDate = useSelector(selectWithDocumentDate);
  const route = useCurrentRoute();
  const isReadOnly = route === constants.readOnlyVehicle;
  const { t } = useTranslation();
  const vehiclePremiumBookingData = useSelector((s) =>
    withDocumentDate
      ? s.vehicle.vehicle?.vehiclePremiumBookingWithDocumentDateData
      : s.vehicle.vehicle?.vehiclePremiumBookingData
  );

  const totals = currencyFormat(vehiclePremiumBookingData?.totalBookedAmount);
  const totalsPerCoverPeriod = currencyFormat(
    vehiclePremiumBookingData?.totalBookedAmountPerCurrentPolicyCoverPeriod
  );

  if (!totals && !totalsPerCoverPeriod) return null;
  const totalsEl = (
    <>
      <Label>
        {t("bfm.vehicleForm.tabPremiumBooking.currentPeriod.label")}:{" "}
        {totalsPerCoverPeriod}
        &nbsp;&euro;
      </Label>
      <Label>
        {t("bfm.vehicleForm.tabPremiumBooking.totalPeriod.label")}: {totals}
        &nbsp;&euro;
      </Label>
    </>
  );

  return (
    <div className={classes.premiumBookingContainer}>
      {isReadOnly && (
        <FormItemTitle
          title={t("bfm.bookings.label")}
          iconName={"BookingsLogo"}
        />
      )}
      <div className={isReadOnly ? classes.previewPadding : undefined}>
        {totalsEl}
        <WithDocumentDateCheckbox />
        <div className={classes.tabsContaner}>
          <Tabs />
        </div>
      </div>
    </div>
  );
};

export default PremiumBooking;
