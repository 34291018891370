import { useGetInsurerListByCountry } from "hooks/data/queries/useGetInsurerListByCountry";

import { useSelector } from "store/hooks";
import { selectSearch, selectSortInsurersPage } from "store/insurersPage";
import { sort as sortUtil } from "store/util";
import { Country_AUSTRIA } from "types/types";

function useFilteredInsurerList() {
  const sortValue = useSelector(selectSortInsurersPage);
  const search = useSelector(selectSearch);
  const { data: insurers } = useGetInsurerListByCountry(Country_AUSTRIA);

  const filteredInsurerList = insurers
    ?.filter((f) => {
      return (
        f.insurerName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        (f.insurerInternalNumber + "")
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 ||
        f.city.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        f.street.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        f.zipCode.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    })
    .sort(sortUtil(sortValue));

  return filteredInsurerList;
}

export default useFilteredInsurerList;
