import { CommandBarButton } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";

const saveBtnStyle = {
  root: {
    height: "100%",
  },
};

const ImportScanButton = ({ disabled, action }: any) => {
  const { t } = useTranslation();

  return (
    <CommandBarButton
      styles={saveBtnStyle}
      type="button"
      iconProps={{ iconName: "GenericScan" }}
      text={t("bfm.scanDocument.label")}
      disabled={disabled}
      onClick={() => {
        action();
      }}
    />
  );
};

export default ImportScanButton;
