import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";
import { formatNumber } from "../../../../utils/number";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
interface IProps {
  yScale: any;
  timezoneRowHeight: number;
}

export const ProrataTable = ({ yScale, timezoneRowHeight }: IProps) => {
  const sectionsDistance = 120;
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;
  const { t } = useTranslation();
  const prorata = useSelector((s) => s.timeline.timeline.prorata);
  const bookingTotals = useSelector((s) => s.timeline.timeline.bookingTotals);
  const showBookings = useSelector((s) => s.timeline.timeline.showBookings);

  const renderTable = (yDomain, x, y, primary) => {
    // debugger;

    const ticks = yDomain.map((tickValue, i) =>
      tickValue.premiumType ? (
        <g className={isDarkMode ? "tickdark" : "tick"} key={i}>
          <text
            className="boldText"
            style={{ textAnchor: "end" }}
            x={0}
            dy=".32em"
            y={yScale(tickValue.premiumType) + yScale.bandwidth() / 2}
          >
            {primary
              ? formatNumber(tickValue.current)
              : "(" + formatNumber(tickValue.current) + ")"}
          </text>
          <text
            className="boldText"
            style={{ textAnchor: "end" }}
            x={sectionsDistance}
            dy=".32em"
            y={yScale(tickValue.premiumType) + yScale.bandwidth() / 2}
          >
            {primary
              ? formatNumber(tickValue.overall)
              : "(" + formatNumber(tickValue.overall) + ")"}
          </text>
        </g>
      ) : (
        <g key={i} className={isDarkMode ? "darkfill" : ""}>
          <text
            className="boldText"
            style={{ textAnchor: "end" }}
            x={0}
            dy=".32em"
            y={yDomain.length * timezoneRowHeight}
          >
            {!primary ? "(" : null}
            &euro;
            {formatNumber(tickValue.current)}
            {!primary ? ")" : null}
          </text>
          <text
            className="boldText"
            style={{ textAnchor: "end" }}
            x={sectionsDistance}
            dy=".32em"
            y={yDomain.length * timezoneRowHeight}
          >
            {!primary ? "(" : null}
            &euro;
            {formatNumber(tickValue.overall)}
            {!primary ? ")" : null}
          </text>
        </g>
      )
    );
    if (primary) {
      ticks.push(
        <g key={"labels"} className={isDarkMode ? "darkfill" : ""}>
          <text
            className="boldText"
            style={{ textAnchor: "end" }}
            x={0}
            dy=".32em"
            y={-timezoneRowHeight / 2}
          >
            {t("bfm.current")}
          </text>
          <text
            className="boldText"
            style={{ textAnchor: "end" }}
            x={sectionsDistance}
            dy=".32em"
            y={-timezoneRowHeight / 2}
          >
            {t("bfm.overall")}
          </text>
        </g>
      );
    }
    return (
      <g transform={`translate(${x},${y})`}>
        {ticks}{" "}
        {primary ? (
          <>
            <line
              stroke={isDarkMode ? "white" : "black"}
              y1={
                yDomain.length * timezoneRowHeight - (timezoneRowHeight * 3) / 4
              }
              y2={
                yDomain.length * timezoneRowHeight - (timezoneRowHeight * 3) / 4
              }
              x1={0}
              x2={0 - (sectionsDistance * 3) / 4}
            />
            <line
              stroke={isDarkMode ? "white" : "black"}
              y1={
                yDomain.length * timezoneRowHeight - (timezoneRowHeight * 3) / 4
              }
              y2={
                yDomain.length * timezoneRowHeight - (timezoneRowHeight * 3) / 4
              }
              x1={sectionsDistance}
              x2={sectionsDistance - (sectionsDistance * 3) / 4}
            />
          </>
        ) : null}
      </g>
    );
  };

  const prorataTable = renderTable(prorata, 0, 0, true);
  const bookingTotalsTable = renderTable(bookingTotals, 0, 17, false);

  return (
    <>
      {prorataTable}
      {showBookings ? bookingTotalsTable : null}
    </>
  );
};
