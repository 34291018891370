import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "store/hooks";
import {
  PremiumType,
  TimelineSegment,
  TimelineSegmentType,
} from "../../../types/types";
import { useFields } from "../../form/useFields";
import FleetLegalFormContent from "../components/FleetLegalFormContent";
import { useAnnualNetPremium } from "../hooks/useAnnualNetPremium";
import { useDefaultSettings } from "../hooks/useDefaultSettings";
import { useEqualPaymentMethods } from "../hooks/useEqualPaymentMethods";
import { useInsurerSettings } from "../hooks/useInsurerSettings";
import { TSetDefaultSettingValues } from "./AddPremiumDialog/AddPremiumDialog";
import { addPremiumDialogConfig } from "./addPremiumDialogConfig";

const config = addPremiumDialogConfig;

interface IProps {
  premiumTypeKey: string;
  item: TimelineSegment;
  setDefaultSettingValues: ({
    setFieldValue,
    values,
  }: TSetDefaultSettingValues) => void;
}

export const AddPremiumDialogContent = ({
  premiumTypeKey,
  item,
  setDefaultSettingValues,
}: IProps) => {
  const vehicle = useSelector((s) => s.vehicle.vehicle);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    setDefaultSettingValues({
      setFieldValue,
      values,
      noToastMsg: true,
      isHullPremium: true,
      setFieldTouched,
    });
  }, []);

  useAnnualNetPremium(premiumTypeKey);
  useEqualPaymentMethods(premiumTypeKey);

  useDefaultSettings();
  useInsurerSettings();

  const { all: mtplOrHullFieldsLeft } = useFields([
    config.dueDate,
    config.insurerPartnerNumber,
    config.insurerBranchOffice,
    config.insurerPolicyNumber,
    config.isPolicyIssued,
    config.documentDate,
  ]);
  const { all: leftFieldsAdd } = useFields([
    config.annualGrossPremium,
    config.annualNetPremium,
  ]);

  const { all: leftFieldsInsert } = useFields([
    config.annualGrossPremium,
    config.annualNetPremium,
  ]);

  const { all: mtplOrHullFieldsRightAdd } = useFields(
    [
      config.validFromDate,
      config.expiryDate,
      config.paymentMethod,
      config.paymentMean,
      config.suspensionDays,
      config.activationDays,
    ],
    undefined,
    setFieldTouched
  );

  const { all: mtplOrHullFieldsRightInsert } = useFields([
    config.validFromDate,
    config.validToDate,
    config.cancelationDocumentDate,
    config.paymentMethod,
    config.paymentMean,
    config.suspensionDays,
    config.activationDays,
  ]);

  let leftFields = leftFieldsAdd;
  if (
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN
  ) {
    leftFields = leftFieldsInsert;
  }

  let rightFieldsMtplOrHull = mtplOrHullFieldsRightAdd;
  if (
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN
  ) {
    rightFieldsMtplOrHull = mtplOrHullFieldsRightInsert;
  }

  const { all: rightFields } = useFields([config.suspensionDaysLimit]);
  const { all: rightFieldsOtherAdd } = useFields([config.validFromDate]);
  const { all: rightFieldsOtherInsert } = useFields([
    config.validFromDate,
    config.validToDate,
  ]);

  let rightFieldsOther = rightFieldsOtherAdd;
  if (
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN
  ) {
    rightFieldsOther = rightFieldsOtherInsert;
  }

  const { all: motorTaxWithMtplLeft } = useFields([
    config.isMotorTaxIncluded,
    config.motorTaxExclusionReason,
  ]);
  const { all: motorTaxWithMtplRight } = useFields([
    config.motorTaxPaymentMethod,
    config.motorTaxSuspensionDaysLimit,
  ]);

  const { all: motorTaxWithMtplBottom } = useFields([
    config.motorTaxExclusionComment,
  ]);

  const { all: hullFields1 } = useFields([config.garageRiskDiscountPercent]);
  const { all: hullFields2 } = useFields([config.hullIsBasic]);

  const { all: bottomFields } = useFields([
    config.reasonForModificationComment,
  ]);

  const { all: hullDeductibleFields } = useFields([
    config.hullProduct,
    config.gap,
    config.replacementValue,
    config.grossNegligence,
    config.electroPlusPackage,
    config.hullDeductiblePercent,
    config.hullDeductibleMinAmount,
    config.hullDeductibleMaxAmount,
    config.hullDeductibleComment,
  ]);

  let left = (
    <>
      {premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? hullFields2
        : null}
      {premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? mtplOrHullFieldsLeft
        : null}
      {leftFields}
    </>
  );
  let right = (
    <>
      {premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? rightFieldsMtplOrHull
        : rightFieldsOther}
      {rightFields}
      {premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC
        ? hullFields1
        : null}
    </>
  );
  let bottom = <>{bottomFields}</>;

  if (premiumTypeKey === PremiumType.MTPL && !vehicle.isTradeLicensePlate) {
    const mtplLeft = <>{motorTaxWithMtplLeft}</>;
    const mtplRight = <>{motorTaxWithMtplRight}</>;
    const mtplBottom = <>{motorTaxWithMtplBottom}</>;
    const mtplDialogContents = (
      <div className="row">
        <div className="column">
          {mtplLeft}
          {mtplRight}
          {mtplBottom}
        </div>
      </div>
    );

    return (
      <>
        <div className="row">
          <div className="column">{left}</div>
          <div className="column">{right}</div>
          {premiumTypeKey === PremiumType.MTPL ? (
            <>
              <div
                className="column"
                style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
              ></div>
              <div className="column">{mtplDialogContents}</div>
            </>
          ) : null}
        </div>
        <div className="row">{bottom}</div>
      </>
    );
  }

  if (
    premiumTypeKey === PremiumType.HULL ||
    premiumTypeKey === PremiumType.HULL_BASIC
  ) {
    return (
      <>
        <div className="row">
          <div className="column">{left}</div>
          <div className="column">{right}</div>
          <>
            <div
              className="column"
              style={{ flex: "0.1%", backgroundColor: "#cccccc" }}
            ></div>
            <div className="column">{hullDeductibleFields}</div>
          </>
        </div>
        <div className="row">{bottom}</div>
      </>
    );
  }

  if (
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE ||
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA
  ) {
    return <FleetLegalFormContent />;
  }

  //     hullProduct
  // hullDeductible
  // hullDeductiblePercent
  // hullDeductibleMaxAmount
  // hullDeductibleMinAmount
  // hullDeductibleComment
  // gap
  // replacementValue
  // grossNegligence
  // electroPlusPackage

  return (
    <>
      <div className="row">
        <div className="column">{left}</div>
        <div className="column">{right}</div>
      </div>
      <div className="row">{bottom}</div>
    </>
  );
};
