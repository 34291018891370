import { Column, ColumnOptions as GrecoColumnOptions } from "@greco/components";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getInsurersByCountryId } from "store/api/api";
import { useSelector } from "../../store/hooks";
import {
  loadVehiclesActions,
  selectColumnsSelectedView,
  selectSelectedView,
  setColumns,
  setIsColumnOptionsPanelOpen,
  setSelectedViewId,
} from "../../store/requireActionVehicles";
import {
  clearAllFilters,
  hideAllColumns,
  reorderColumn,
  selectAllColumns,
  setFilter,
  setColumns as setTempColumns,
  showAllColumns,
  toggleColumnVisibility,
} from "../../store/requireActionVehiclesColumnOptions";
import {
  selectColumnOptionsSettings,
  setAllSettings,
} from "../../store/settings";
import { AppDispatch } from "../../store/store";
import { useAddAppSettings } from "hooks/data/mutations/useAddAppSettings";
import { Setting } from "types/types";
import { setSelectedViewIdFleet } from "store/fleetPage";
import { useUpdateAppSettings } from "hooks/data/mutations/useUpdateAppSettings";
import { useDeleteAppSettings } from "hooks/data/mutations/useDeleteAppSettings";

type Props = {
  countryCode: number | undefined;
};

export const RequiredColumnOptions = ({ countryCode }: Props) => {
  const tempColumns = useSelector(selectAllColumns) as any;
  const dispatch: AppDispatch = useDispatch();
  const selectedView = useSelector(selectSelectedView) as any;
  const selectedViewColumns = useSelector(selectColumnsSelectedView) as any;
  const taxonomy = useSelector((s) => s.taxonomy);
  const applicationTax = taxonomy.ApplicationCode.byCode["Application.BFM"];
  const columnOptionsTax =
    taxonomy.ApplicationSetting.byCode["AppSetting.ColumnOptions"];
  const columnOptionsSettings = useSelector(selectColumnOptionsSettings);
  const areSettingsLoading = useSelector((s) => s.settings.isLoading);
  const areSettingsLoaded = useSelector((s) => s.settings.isLoaded);

  const { mutate: addAppSetting } = useAddAppSettings();
  const { mutate: updateAppSetting, isLoading: isLoadingUpdate } =
    useUpdateAppSettings();

  const { mutate: deleteAppSetting, isLoading: isLoadingDelete } =
    useDeleteAppSettings();

  const { t } = useTranslation();

  const isViewLoading =
    areSettingsLoading || isLoadingUpdate || isLoadingDelete;
  const [searchInsurerTaxonomyData, setSearchInsurerTaxonomyData] = useState(
    []
  );
  const fetchInsurer = async (countryCode) => {
    const res = await getInsurersByCountryId(countryCode);
    const insurerData = res.data.map((item) => {
      return { code: item.insurerName, id: item.insurerInternalNumber };
    });
    setSearchInsurerTaxonomyData(insurerData);
  };
  useEffect(() => {
    fetchInsurer(countryCode);
  }, [countryCode]);
  const transformedColumns = useMemo(() => {
    return tempColumns.map((c) => {
      if (c.key === "needsGrECoUserAttention") {
        return {
          ...c,
          label: t(c.labelKey),
          isPresentInColumnOptions: false,
        };
      }
      if (c.key === "insuranceLineIcons") {
        return {
          ...c,
          minWidth: 320,
          maxWidth: 400,
        };
      }

      return {
        ...c,
        label: t(c.labelKey),
      };
    });
  }, [tempColumns]);

  const closePanel = () => {
    dispatch(setIsColumnOptionsPanelOpen(false));
  };
  return (
    <div>
      <GrecoColumnOptions
        t={t}
        columns={transformedColumns as Column[]}
        isReady={areSettingsLoaded}
        isViewLoading={isViewLoading}
        closePanel={closePanel}
        onChangeView={(id) => {
          dispatch(setSelectedViewId(id));
        }}
        onClickApply={() => {
          dispatch(setColumns(tempColumns));
          dispatch(
            loadVehiclesActions.trigger({
              isReset: true,
              shouldDelay: false,
            })
          );
          closePanel();
        }}
        onClickClearAllFilters={() => {
          dispatch(clearAllFilters());
        }}
        onClickDeleteView={() => {
          deleteAppSetting(selectedView.userAppSettingId, {
            onSuccess: (res) => {
              dispatch(setAllSettings(res.data));

              dispatch(setSelectedViewIdFleet(null));
              dispatch(setSelectedViewId(null));
            },
          });
        }}
        onClickReset={() => {
          dispatch(setTempColumns(selectedViewColumns));
        }}
        onClickSaveView={() => {
          updateAppSetting(
            {
              ...selectedView,
              userAppSettingValue: JSON.stringify(
                tempColumns.map((column) => {
                  if (column.key === "insuranceLineIcons") {
                    return {
                      ...column,
                      minWidth: 320,
                      maxWidth: 400,
                    };
                  }
                  return column;
                })
              ),
            },
            {
              onSuccess: (res) => {
                dispatch(setAllSettings(res.data));
              },
            }
          );
        }}
        onCreateView={(data) => {
          const applicationCodeTax =
            taxonomy.ApplicationCode.byCode["Application.BFM"];
          addAppSetting(
            {
              applicationCodeId: applicationTax.id,
              appSettingCodeId: columnOptionsTax.id,
              userAppSettingValue: JSON.stringify(tempColumns),
              ...data,
            },
            {
              onSuccess: (res) => {
                const allSettings = res?.data as Setting[];
                const newSetting = allSettings.find((s) => {
                  return (
                    s.applicationCodeId === applicationCodeTax?.id &&
                    s.userAppSettingName === data.userAppSettingName
                  );
                });
                dispatch(setSelectedViewIdFleet(newSetting.userAppSettingId));
                dispatch(setSelectedViewId(newSetting.userAppSettingId));
                dispatch(setAllSettings(allSettings));

                closePanel();
              },
            }
          );
        }}
        onUpdateView={(data) => {
          updateAppSetting(
            {
              ...selectedView,
              ...data,
            },
            {
              onSuccess: (res) => {
                dispatch(setAllSettings(res.data));
              },
            }
          );
        }}
        onDeselectAll={() => {
          dispatch(hideAllColumns());
        }}
        onSelectAll={() => {
          dispatch(showAllColumns());
        }}
        onReorderColumn={(src, dest) => {
          dispatch(
            reorderColumn({
              index1: src,
              index2: dest,
            })
          );
        }}
        onToggleColumnVisibility={(columnKey) => {
          dispatch(toggleColumnVisibility(columnKey));
        }}
        selectedView={selectedView}
        setFilter={(columnKey, filter) => {
          dispatch(setFilter({ columnKey, filter }));
        }}
        taxonomy={{
          ...taxonomy.response,
          mtplInsurerPartnerNumber: [...searchInsurerTaxonomyData],
          hullInsurerPartnerNumber: [...searchInsurerTaxonomyData],
        }}
        views={columnOptionsSettings as any}
      />
    </div>
  );
};
