import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import { RefProvider } from "contexts/RefProvider";
import { Formik } from "formik";
import { useFleetVehicleReport } from "hooks/data/mutations/useFleetVehicleReport";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setApplicationReportDialog } from "store/fleetPage";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { PremiumType } from "types/types";
import { setYupLocale } from "utils/setYupLocale";
import * as yup from "yup";
import { AppDispatch } from "../store/store";
import { toast } from "./FluentToast";
import { FormSpinner } from "./FormSpinner";
import { VSpace } from "./Spacer";
import { Field } from "./form/Field";
import { combine, maxLength } from "./form/validation";
import { StyledPrimaryButton } from "./styled";

export const ApplicationReportDialogComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme() as IStyledTheme;
  const { premiumTypeOptions } = useActionConfig();

  const applicationReportDialog = useSelector(
    (s) => s.fleetPage.applicationReportDialog
  );

  const {
    data: excelReportResponse,
    mutate: onGenerateApplicationReport,
    isLoading: isReportLoading,
  } = useFleetVehicleReport();

  const dispatch: AppDispatch = useDispatch();
  const { vehicleIds, fleetId } = applicationReportDialog;
  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const close = () => {
    dispatch(
      setApplicationReportDialog({
        isOpen: false,
        fleetId: null,
        vehicleIds: [],
      })
    );
  };

  const excelReportData = excelReportResponse?.data;

  const vehicles = useSelector((s) => s.fleetPage.entities);
  const insurerList = useSelector((s) => s.fleetForm.insurerList);
  const taxonomy = useSelector((s) => s.taxonomy);

  const insurerIds = useMemo(() => {
    const retVal = [];
    vehicleIds.forEach((vehicleId) => {
      const mtplInsurerPartnerNumber =
        vehicles[vehicleId].mtplInsurerPartnerNumber;
      const hullInsurerPartnerNumber =
        vehicles[vehicleId].hullInsurerPartnerNumber;
      if (
        mtplInsurerPartnerNumber &&
        !retVal.includes(mtplInsurerPartnerNumber)
      )
        retVal.push(mtplInsurerPartnerNumber);
      if (
        hullInsurerPartnerNumber &&
        !retVal.includes(hullInsurerPartnerNumber)
      )
        retVal.push(hullInsurerPartnerNumber);
    });

    return retVal.map((el) => {
      const insurer = insurerList.find(
        (ins) => ins?.insurerInternalNumber === parseInt(el)
      );
      return {
        value: insurer?.insurerInternalNumber,
        label: insurer?.insurerName,
      };
    });
  }, [vehicleIds, vehicles, insurerList]);

  const onSubmit = (data) => {
    const payload: any = {
      fleetId: fleetId,
      body: {
        comment: data.comment,
        insurerPartnerNumber: data.insurerPartnerNumber.value,
        reportType: "application",
        premiumType: data.premiumType?.value,
        vehicleIds: vehicleIds.map((vid) => {
          return {
            fleetId: parseInt(fleetId),
            vehicleId: parseInt(vid.toString()),
          };
        }),
      },
    };
    onGenerateApplicationReport(payload, {
      onSuccess: () => {
        toast.success(t("bfm.reportGeneratedSuccessfully.label"));
      },
    });
  };
  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Icon
        // iconName="SyncStatus"
        style={{
          color: theme.palette.themePrimary,
          fontSize: "25px",
          marginRight: "5px",
        }}
      />
      {t("bfm.applicationReport.label")}
    </div>
  );

  const initialValues = useMemo(() => {
    return {
      insurerPartnerNumber: insurerIds.length > 0 ? insurerIds[0] : null,
      validFromDate: null,
      validToDate: null,
      comment: "",
    };
  }, []);
  const schema = useMemo(() => {
    setYupLocale(t);
    return yup.object({
      insurerPartnerNumber: yup.mixed().required(),
      //validFromDate: yup.mixed().required(),
      //validToDate: yup.mixed().nullable(),
      comment: yup.string().required(),
    });
  }, [t]);

  const filteredPremiumTypeOptions = useMemo(() => {
    return premiumTypeOptions.filter(
      (option) =>
        option.value !==
        taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id
    );
  }, [premiumTypeOptions, taxonomy.PremiumType.byCode]);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true,
      }}
    >
      {" "}
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          enableReinitialize
          validationSchema={schema}
        >
          {({ submitForm }) => {
            return (
              <>
                {excelReportData ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "25px",
                    }}
                  >
                    <PrimaryButton
                      title={excelReportData.name}
                      onClick={() => {
                        window.open(excelReportData.url);

                        close();
                      }}
                      text={t("bfm.downloadReport.label")}
                    />
                  </div>
                ) : (
                  <>
                    <Field
                      name="insurerPartnerNumber"
                      type="insurerTaxonomy"
                      label={"bfm.insurer.label"}
                      include={insurerIds ? insurerIds : []}
                      isRequired
                      d={({ t, values, errors, setFieldValue }) => ({
                        label: "bfm.insurer.label",
                        values,
                        errors,
                        setFieldValue,
                        defaultValue: values?.insurerPartnerNumber,
                      })}
                    />
                    <Field
                      type="combobox"
                      disabled={false}
                      name="premiumType"
                      required
                      label={"bfm.fleet.premiumType.label"}
                      options={filteredPremiumTypeOptions || []}
                      defaultValue={null}
                      d={({ t, values, errors, setFieldValue }) => ({
                        label: "bfm.fleet.premiumType.label",
                        values,
                        errors,
                        setFieldValue,
                        defaultValue: values?.premiumType,
                      })}
                    />
                    <Field
                      name="comment"
                      type="text"
                      label={"bfm.comment.label"}
                      isMultiline
                      rows={4}
                      isRequired
                      validate={(value) => combine(maxLength(value, 512, t))}
                    />
                    <VSpace height={25} />
                    <DialogFooter>
                      <SaveButton
                        onClick={() => {
                          setDidTrySubmit(true);
                          submitForm();
                        }}
                        isLoading={isReportLoading}
                      />
                      <DefaultButton
                        onClick={close}
                        text={t("bfm.close.label")}
                      />
                    </DialogFooter>
                  </>
                )}
                {isReportLoading && <FormSpinner />}
              </>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("bfm.generateReport.label")}
      disabled={isDisabled}
      style={{
        marginRight: "5px",
      }}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};
