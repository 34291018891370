import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  resetDialogInsurerSettings,
  setDialogInsurerSettingsOperationResult,
} from "store/fleetForm";
import { toast } from "../../../../components/FluentToast";
import { useSelector } from "../../../../store/hooks";

export const useInsurerSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const insurerSettings = useSelector((s) => s.fleetForm.insurerSettings);

  const formValues = useSelector(
    (s) => s.fleetForm.insurerSettingsOperation.formValues
  );
  const premiumType = formValues?.premiumType;
  const premiumTypeCode = premiumType ? premiumType.code : null;
  const defaultValueOperation = useSelector(
    (s) => s.fleetForm.insurerSettingsOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;

      const insurerSetting = insurerSettings.find(
        (is) => is.insurerPartnerNumber === insurer
      );

      if (insurerSetting) {
        let valueString = null;

        let results = {};
        for (const fieldName of fieldNames) {
          switch (fieldName) {
            case "suspensionDays":
              valueString = insurerSetting.suspensionDays + "";
              results[fieldName] = valueString;
              //dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "activationDays":
              valueString = insurerSetting.activationDays + "";
              results[fieldName] = valueString;
              // dispatch(setDialogInsurerSettingsOperationResult(valueString));
              break;
            case "garageRiskDiscountPercent":
              if (premiumTypeCode === "HULL_BASIC") {
                valueString = insurerSetting.garageRiskDiscountPercentHullBasic;
              } else {
                valueString = insurerSetting.garageRiskDiscountPercentHull;
              }
              valueString = valueString.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              valueString = valueString + "";
              results[fieldName] = valueString;
              break;
          }
        }
        dispatch(setDialogInsurerSettingsOperationResult(results));
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInInsurerSettingsSuccess.label"));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInInsurerSettingsNoValue.label"));
        dispatch(resetDialogInsurerSettings(null));
      }
    }
  }, [defaultValueOperation]);
};
