import { IconButton } from "@fluentui/react";
import { format } from "date-fns";
import moment from "moment";
import * as React from "react";
import { IStyledTheme } from "theme/types";
import { VehicleShortInfo } from "types/types";
import { formatNumber } from "./number";

export const toBase64 = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : undefined
          : undefined
      );
    };
    reader.readAsDataURL(file);
  });
};

export function removeEmpty(obj: { [key: string]: any }) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    if (value !== null && value !== undefined && value !== "") {
      result[key] = value;
    }
    return result;
  }, {});
}

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

export const formatDoubleFieldValue = (value) =>
  value
    ?.toString()
    ?.replaceAll(" ", "")
    .replaceAll(".", "")
    ?.replaceAll(",", ".");

export const formatDateWithMoment = (date: Date) =>
  moment(date).format("DD.MM.YYYY");

export const formatDateString = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy");
};

export const formatDateStringWithTime = (date: string) => {
  return format(new Date(date), "dd.MM.yyyy kk:mm:ss");
};

export const formatDateStringTimeOnly = (date: string) => {
  return format(new Date(date), "kk:mm:ss");
};

export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const stringToNumber = (value: string) => {
  if (typeof value === "number") return value;
  if (!value) return "";
  let _str = value.toString();
  _str = _str?.replace(/\./g, "");
  _str = String(_str)?.replace(",", ".");
  return parseFloat(_str);
};

export const getSelectStyles = (
  hasError: boolean,
  theme: IStyledTheme
): any => {
  return {
    control: (p, s) => {
      return {
        ...p,
        border: 0,
        borderRadius: 0,
        borderBottom: "1px solid",
        borderBottomColor: s.isFocused
          ? hasError
            ? "red"
            : "#005aa1"
          : "#ccc",
        outline: "none",
        "&:hover": {
          borderBottomColor: s.isFocused
            ? hasError
              ? "red"
              : "#005aa1"
            : "#ccc",
        },
        backgroundColor: s.isDisabled ? theme.palette.white : undefined,
      };
    },
  };
};

export const getDatePickerClearSuffix =
  (value: Date | null | any, setValue: (value: Date | null) => void) => () => {
    if (value !== null && value !== undefined && value !== "") {
      return (
        <IconButton
          iconProps={{
            iconName: "ChromeClose",
          }}
          onMouseDown={() => setValue(null)}
          styles={{
            root: {
              cursor: "auto",
              "&:hover": {
                background: "transparent",
              },
              ".ms-Icon.ms-Icon": {
                fontSize: "11px",
                color: "#ababab",
              },
            },
          }}
        />
      );
    }
  };

export const capitalize = (s: string) => {
  if (s.length === 0) return "";
  return `${s[0].toUpperCase()}${s.slice(1)}`;
};

export const sanitizeFileName = (s: string) => {
  return s.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

export const isStringNotEmpty = (v: any) => {
  return typeof v === "string" && v.length > 0;
};

export const isTaxonomyNotEmpty = (v: any) => {
  return v !== null;
};

export const isStringEmpty = (v: any) => {
  return (
    typeof v !== "string" ||
    v === null ||
    v === undefined ||
    (typeof v === "string" && v.length === 0)
  );
};

export const invertColor = (hexTripletColor) => {
  var color = hexTripletColor;
  // color = color.substring(1); // remove #
  // color = parseInt(color, 16); // convert to integer
  // color = 0xffffff ^ color; // invert three bytes
  // color = color.toString(16); // convert to hex
  // color = ("000000" + color).slice(-6); // pad with leading zeros
  // color = "#" + color; // prepend #
  return color;
};

export const hashCode = (s) =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const truncateTextIfLong = (
  text: string,
  containerWidth: number,
  averageCharWidthPixels: number = 7
): string => {
  if (text.length * averageCharWidthPixels > containerWidth) {
    let numChars =
      (text.length * averageCharWidthPixels - containerWidth) /
      averageCharWidthPixels;
    if (text.length - Math.round(numChars) < 4) return "…";
    let retVal = text.substr(0, text.length - Math.round(numChars) - 2) + "…";
    return retVal;
  }
  return text;
};

export const colors = [
  { color: "#8AC5FA", border: "#003054" },
  { color: "#92BD88", border: "#305544" },
  { color: "#E9C19E", border: "#7C3E15" },
  { color: "#72AFE5", border: "#003054" },
  { color: "#76AC6E", border: "#305544" },
  { color: "#DD9C66", border: "#7C3E15" },
  { color: "#2B88D8", border: "#FFFFFF" },
  { color: "#68AA62", border: "#FFFFFF" },
  { color: "#CE762E", border: "#7C3E15" },
  { color: "#0178D4", border: "#FFFFFF" },
  { color: "#4F8450", border: "#FFFFFF" },
  { color: "#91551F", border: "#E9C19E" },
  { color: "#0F6EBE", border: "#FFFFFF" },
  { color: "#60865D", border: "#FFFFFF" },
  { color: "#7C3E15", border: "#E9C19E" },
  { color: "#005B9E", border: "#8AC5FA" },
  { color: "#47634D", border: "#92BD88" },
  { color: "#004578", border: "#72AFE5" },
  { color: "#3D6445", border: "#76AC6E" },
  { color: "#003054", border: "#2B88D8" },
  { color: "#305544", border: "#68AA62" },
];

export const licenseColors = [
  { color: "#7C3E15", border: "#E9C19E" },
  { color: "#60865D", border: "#FFFFFF" },
  { color: "#0F6EBE", border: "#FFFFFF" },
  { color: "#91551F", border: "#E9C19E" },
  { color: "#4F8450", border: "#FFFFFF" },
  { color: "#0178D4", border: "#FFFFFF" },
  { color: "#CE762E", border: "#7C3E15" },
  { color: "#68AA62", border: "#FFFFFF" },
  { color: "#2B88D8", border: "#FFFFFF" },
  { color: "#DD9C66", border: "#7C3E15" },
  { color: "#76AC6E", border: "#305544" },
  { color: "#72AFE5", border: "#003054" },
  { color: "#E9C19E", border: "#7C3E15" },
  { color: "#92BD88", border: "#305544" },
  { color: "#8AC5FA", border: "#003054" },
  { color: "#305544", border: "#68AA62" },
  { color: "#003054", border: "#2B88D8" },
  { color: "#3D6445", border: "#76AC6E" },
  { color: "#004578", border: "#72AFE5" },
  { color: "#47634D", border: "#92BD88" },
  { color: "#005B9E", border: "#8AC5FA" },
];

export const sharedLicenseColors = [
  { color: "#0F6EBE", border: "#FFFFFF" },
  { color: "#60865D", border: "#FFFFFF" },
  { color: "#7C3E15", border: "#E9C19E" },
  { color: "#0178D4", border: "#FFFFFF" },
  { color: "#4F8450", border: "#FFFFFF" },
  { color: "#91551F", border: "#E9C19E" },
  { color: "#2B88D8", border: "#FFFFFF" },
  { color: "#68AA62", border: "#FFFFFF" },
  { color: "#CE762E", border: "#7C3E15" },
  { color: "#72AFE5", border: "#003054" },
  { color: "#76AC6E", border: "#305544" },
  { color: "#DD9C66", border: "#7C3E15" },
  { color: "#8AC5FA", border: "#003054" },
  { color: "#92BD88", border: "#305544" },
  { color: "#E9C19E", border: "#7C3E15" },
  { color: "#005B9E", border: "#8AC5FA" },
  { color: "#47634D", border: "#92BD88" },
  { color: "#004578", border: "#72AFE5" },
  { color: "#3D6445", border: "#76AC6E" },
  { color: "#003054", border: "#2B88D8" },
  { color: "#305544", border: "#68AA62" },
];

export const sharedLicenseInfoColors = [
  { color: "#E9C19E", border: "#7C3E15" },
  { color: "#8AC5FA", border: "#003054" },
  { color: "#92BD88", border: "#305544" },
  { color: "#DD9C66", border: "#7C3E15" },
  { color: "#72AFE5", border: "#003054" },
  { color: "#76AC6E", border: "#305544" },
  { color: "#CE762E", border: "#7C3E15" },
  { color: "#2B88D8", border: "#FFFFFF" },
  { color: "#68AA62", border: "#FFFFFF" },
  { color: "#91551F", border: "#E9C19E" },
  { color: "#0178D4", border: "#FFFFFF" },
  { color: "#4F8450", border: "#FFFFFF" },
  { color: "#7C3E15", border: "#E9C19E" },
  { color: "#0F6EBE", border: "#FFFFFF" },
  { color: "#60865D", border: "#FFFFFF" },
  { color: "#005B9E", border: "#8AC5FA" },
  { color: "#47634D", border: "#92BD88" },
  { color: "#004578", border: "#72AFE5" },
  { color: "#3D6445", border: "#76AC6E" },
  { color: "#003054", border: "#2B88D8" },
  { color: "#305544", border: "#68AA62" },
];

export const displayVehicleInfo = (
  tax: VehicleShortInfo,
  premiumTypeId: number,
  taxonomy,
  t,
  showPremium: boolean = false
): string => {
  return (
    (tax.brandCode
      ? t(taxonomy.VehicleBrand.byId[tax.brandCode].code) + " "
      : "") +
    (tax.model ? tax.model : " ") +
    (tax.vin ? " (" + tax.vin + ") " : " ") +
    (premiumTypeId !== taxonomy.PremiumType.byCode["PremiumType.MTPL"].id &&
    showPremium
      ? tax.horsePowerBasedTaxAnnualy
        ? formatNumber(tax.horsePowerBasedTaxAnnualy) + " \u20AC"
        : ""
      : "")
  );
};

export const validateNumberFieldValueRange = ({
  yup,
  fieldName,
  valueRange,
  message,
  type,
  isDouble = false,
}) => {
  return yup.mixed().test({
    name: fieldName,
    exclusive: true,
    message: `${message} ${type === "max" ? valueRange + 1 : ""}`,
    test: (value) => {
      if (value === "") {
        return true;
      }
      if (value) {
        let newValue;
        if (isDouble) {
          newValue = Number(value.toString().replaceAll(",", "."));
        } else {
          newValue = Number(value.toString().replaceAll(".", ""));
        }

        if (type === "max") {
          if (newValue > valueRange) {
            return false;
          }
        } else if (type === "min") {
          if (newValue < valueRange) {
            return false;
          }
        }
      }
      return true;
    },
  });
};

export const urlFormat = (url: string) => {
  if (!url) return "";

  let result = url;
  if (url.charAt(url.length - 1) === "/") {
    result = url.slice(0, -1);
  }
  return result;
};
