import { toast } from "components/FluentToast";
import { Formik } from "formik";
import { useCreateFleetVehicleDefaultSetting } from "hooks/data/mutations/useCreateFleetVehicleDefaultSetting";
import { useUpdateFleetVehicleDefaultSetting } from "hooks/data/mutations/useUpdateFleetVehicleDefaultSetting";
import { delay } from "library/delay";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { FleetHullCalculationDefaultSettingType } from "types/types";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import useDefaultSettingsData from "../hooks/useDefaultSettingsData";
import HullPremiumCalculationFormFields from "./HullPremiumCalculationFormFields";

type Props = {
  settingId: string;
  attributeName: string;
  isLoading: boolean;
};

const HullPremiumCalculationForm = ({
  settingId,
  attributeName,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const [isDirty, setIsDirty] = useState(false);
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);

  const { mutate: onUpdateFleetVehicleDefaultSetting } =
    useUpdateFleetVehicleDefaultSetting();

  const { mutate: onCreateFleetVehicleDefaultSetting } =
    useCreateFleetVehicleDefaultSetting();

  const hasTradeLicensePlateDefault = useSelector(
    (s) => s.fleetForm.dialog.data?.hasTradeLicensePlate
  );

  const actionsConfig = useActionConfig();

  const defaultData = useDefaultSettingsData();

  const rowVersion = defaultData?.rowVersion;

  const dispatch = useDispatch();
  const vehicleType = defaultData?.vehicleType;

  useEffect(() => {
    if ((!vehicleType && settingId) || hasTradeLicensePlateDefault) {
      setTradeLicensePlate(true);
    }
  }, [vehicleType, settingId]);
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };
  const premiumTypeOptions = actionsConfig.premiumTypeOptionsHull;

  const insurerOptions = actionsConfig.insurerOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;
  //const vehicleMainGroupOptions = actionsConfig.vehicleMainGroupOptions;
  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const carUsageOptions = actionsConfig.carUsageOptions;

  const initialValues = useMemo(() => {
    const hullValues = defaultData.attributeValue
      ? JSON.parse(defaultData.attributeValue)
      : null;
    return {
      valueType: hullValues?.valueType
        ? hullValues?.valueType
        : "premiumRatePercentage",
      premiumRatePercentage:
        hullValues?.valueType &&
        hullValues?.valueType ===
          FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
          ? hullValues?.absoulutePremiumValue
          : hullValues?.premiumRatePercentage,
      premiumFreeSpecialEquipment: hullValues?.premiumFreeSpecialEquipment,
      gap: hullValues?.gap,
      replacementValue: hullValues?.replacementValue,
      grossNegligence: hullValues?.grossNegligence,
      electroPlusPackage: hullValues?.electroPlusPackage,
      discountForWaiverOfTaxRefund: hullValues?.discountForWaiverOfTaxRefund,
      premiumType: defaultData
        ? premiumTypeOptions.find(
            (item) => item.value === defaultData.premiumType
          )
        : "",
      insurerPartnerNumber: defaultData
        ? insurerOptions.find(
            (item) => item.value === defaultData.insurerPartnerNumber
          )
        : "",
      listPriceType: defaultData
        ? listPriceTypeOptions.find(
            (item) => item.value === defaultData.listPriceType
          )
        : "",
      listPriceMin: defaultData ? defaultData.listPriceMin + "" : "",
      listPriceMax: defaultData ? defaultData.listPriceMax + "" : "",
      // vehicleMainGroup: defaultData
      //   ? vehicleMainGroupOptions.find(
      //       (item) => item.value === defaultData.vehicleMainGroup
      //     )
      //   : "",
      vehicleType: defaultData
        ? vehicleTypeCodeOptions.find(
            (item) => item.value === defaultData.vehicleType
          )
        : "",
      carUsage: defaultData
        ? carUsageOptions.find((item) => item.value === defaultData.carUsage)
        : "",
      cubicCapacityCcmRange: defaultData
        ? defaultData.cubicCapacityCcmRange
        : "",
      powerKwTo: defaultData ? defaultData.powerKwTo : "",

      powerKwFrom: defaultData ? defaultData.powerKwFrom : "",
      weightCapacityKgRange: defaultData
        ? defaultData.weightCapacityKgRange
        : "",
      maxGrossWeightKgRange: defaultData
        ? defaultData.maxGrossWeightKgRange
        : "",
      emptyWeightKgFrom: defaultData ? defaultData?.emptyWeightKgFrom : "",
      emptyWeightKgTo: defaultData ? defaultData.emptyWeightKgTo : "",
    };
  }, [
    defaultData,
    insurerOptions,
    premiumTypeOptions,
    vehicleTypeCodeOptions,
    listPriceTypeOptions,
    carUsageOptions,
  ]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({
      valueType: yup.mixed().required(),
      premiumRatePercentage: yup.string().required(),
      premiumFreeSpecialEquipment: yup.string().required(),
      gap: yup.string().required(),
      replacementValue: yup.string().required(),
      grossNegligence: yup.string().required(),
      electroPlusPackage: yup.string().required(),
      discountForWaiverOfTaxRefund: yup.string().required(),

      premiumType: yup.mixed().required(),
      listPriceType: yup.mixed().required(),

      insurerPartnerNumber: yup.mixed().required(),
      //vehicleMainGroup: yup.mixed().required(),
      vehicleType: isTradeLicensePlate
        ? yup.mixed().nullable()
        : yup.mixed().required(),
      cubicCapacityCcmRange: yup.mixed().nullable(),
      powerKwFrom: yup.number().nullable(),
      powerKwTo: yup
        .number()
        .min(
          yup.ref("powerKwFrom"),
          "Field powerKwTo must be greater than powerKwFrom"
        )
        .nullable(),
      weightCapacityKgRange: yup.mixed().nullable(),
      emptyWeightKgFrom: yup.number().nullable(),
      emptyWeightKgTo: yup
        .number()
        .min(
          yup.ref("emptyWeightKgFrom"),
          "Field emptyWeightKgTo must be greater than emptyWeightKgFrom"
        )
        .nullable(),
    });
  }, [t, isTradeLicensePlate]);

  const onSubmit = (values) => {
    const body = {
      isTradeLicensePlate: isTradeLicensePlate,
      attributeValue: JSON.stringify({
        valueType: values?.valueType.value,
        premiumRatePercentage:
          values?.valueType.value ===
          FleetHullCalculationDefaultSettingType.PREMIUM_RATE_PERCENTAGE
            ? values?.premiumRatePercentage
            : null,
        absoulutePremiumValue:
          values?.valueType.value ===
          FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
            ? values?.premiumRatePercentage
            : null,
        premiumFreeSpecialEquipment: values?.premiumFreeSpecialEquipment,
        gap: values?.gap,
        replacementValue: values?.replacementValue,
        grossNegligence: values?.grossNegligence,
        electroPlusPackage: values?.electroPlusPackage,
        discountForWaiverOfTaxRefund: values?.discountForWaiverOfTaxRefund,
      }),
      attributeName,
      premiumType: values?.premiumType.value,
      listPriceType: values?.listPriceType.value,

      listPriceMin: values?.listPriceMin
        ? Number(formatDoubleFieldValue(values?.listPriceMin))
        : undefined,
      listPriceMax: values?.listPriceMax
        ? Number(formatDoubleFieldValue(values?.listPriceMax))
        : undefined,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      //vehicleMainGroup: values?.vehicleMainGroup?.value,
      carUsage: values?.carUsage?.value,
      vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,
      weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      powerKwFrom: values?.powerKwFrom,
      powerKwTo: values?.powerKwTo,
      numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
      emptyWeightKgFrom: values?.emptyWeightKgFrom,
      emptyWeightKgTo: values?.emptyWeightKgTo,
    };

    if (!!settingId) {
      onUpdateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          fleetDefaultSettingId: settingId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
            rowVersion,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
          },
        }
      );
    } else {
      onCreateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
          },
        }
      );
    }
  };

  const validate = (values) => {
    const errors = {} as any;
    if (values.valueType?.value !== "premiumRatePercentage") return;
    const premiumRatePercentageNumber = parseFloat(
      (values.premiumRatePercentage as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (premiumRatePercentageNumber) {
      if (premiumRatePercentageNumber > 100) {
        errors.premiumRatePercentage = t(
          "greco.form.errors.number.lessorequal",
          {
            max: 100,
          }
        );
      }
    }

    const gapNumber = parseFloat(
      (values.gap as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (gapNumber) {
      if (gapNumber > 100) {
        errors.gap = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const replacementValueNumber = parseFloat(
      (values.replacementValue as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (replacementValueNumber) {
      if (replacementValueNumber > 100) {
        errors.replacementValue = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const grossNegligenceNumber = parseFloat(
      (values.grossNegligence as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (grossNegligenceNumber) {
      if (grossNegligenceNumber > 100) {
        errors.grossNegligence = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const electroPlusPackageNumber = parseFloat(
      (values.electroPlusPackage as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (electroPlusPackageNumber) {
      if (electroPlusPackageNumber > 100) {
        errors.electroPlusPackage = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const discountForWaiverOfTaxRefundNumber = parseFloat(
      (values.discountForWaiverOfTaxRefund as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (discountForWaiverOfTaxRefundNumber) {
      if (discountForWaiverOfTaxRefundNumber > 100) {
        errors.discountForWaiverOfTaxRefund = t(
          "greco.form.errors.number.lessorequal",
          {
            max: 100,
          }
        );
      }
    }

    //...
    if (!values.listPriceType?.value) {
      errors.listPriceType = t("greco.required");
    }
    if (values.listPriceMin === "" && values.listPriceMax !== "") {
      errors.listPriceMin = t("greco.required");
    }
    if (values.listPriceMax === "" && values.listPriceMin !== "") {
      errors.listPriceMax = t("greco.required");
    }
    if (
      !errors.listPriceMin &&
      !errors.listPriceMax &&
      Number.parseFloat(
        values.listPriceMin
          .replaceAll(" ", "")
          .replaceAll(".", "")
          .replaceAll(",", ".")
      ) >
        Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        )
    ) {
      errors.listPriceMin = t("greco.form.errors.number.max", {
        max: Number.parseFloat(
          values.listPriceMax
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ),
      });
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { handleSubmit } = props;
        return (
          <>
            <div>
              <form onSubmit={handleSubmit}>
                <HullPremiumCalculationFormFields
                  listPriceTypeOptions={listPriceTypeOptions}
                  isTradeLicensePlate={isTradeLicensePlate}
                  setTradeLicensePlate={setTradeLicensePlate}
                  settingId={settingId}
                  fieldProps={props}
                  defaultData={defaultData}
                  insurerOptions={insurerOptions}
                  //vehicleMainGroupOptions={vehicleMainGroupOptions}
                  carUsageOptions={carUsageOptions}
                  vehicleTypeCodeOptions={vehicleTypeCodeOptions}
                  isLoading={isLoading}
                  premiumTypeOptions={premiumTypeOptions}
                  setIsDirty={setIsDirty}
                />
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default HullPremiumCalculationForm;
