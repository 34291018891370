import {
  getCubicCapacityCodeByNumber,
  getNumberOfSeatsMopedCodeByNumber,
  getNumberOfSeatsRangeCodeByNumber,
  getWeightCapacityCodeByNumber,
} from "form/util";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  resetDialogDefaultValue,
  setDialogDefaultValueOperationResult,
} from "store/timeline";
import {
  ETimelineExtraDataLineSpecial,
  FleetDefaultSetting,
  FleetHullCalculationDefaultSettingType,
  PremiumType,
  TimelineDialogType,
  TimelineSegment,
  TimelineSegmentType,
} from "types/types";
import { isStringEmpty, stringToNumber } from "utils/utils";
import { toast } from "../../../components/FluentToast";
import { useSelector } from "../../../store/hooks";

export const useDefaultSettings = () => {
  const dialogDate = useSelector((s) => s.timeline.dialog.date);
  const dialogType = useSelector((s) => s.timeline.dialog.type);
  const timelineSegments = useSelector((s) => s.timeline.timeline.data);
  const extraDataLineSpecial = useSelector(
    (s) => s.timeline.dialog.item?.extraData?.lineSpecial
  );
  const isHullBasic =
    extraDataLineSpecial === ETimelineExtraDataLineSpecial.BASIC;
  const isFleetLegalClosingDate =
    extraDataLineSpecial === ETimelineExtraDataLineSpecial.CLOSING_DATE;

  const { t } = useTranslation();

  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const vehicleType = vehicle?.vehicleTypeCode;
  const dispatch = useDispatch();

  const defaultSettings = useSelector((s) => s.vehicle.fleetDefaultSettings);

  const emptyWeightKg = vehicle?.emptyWeightKg;

  const premiumTypeCode = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );
  const taxonomy = useSelector((s) => s.taxonomy);

  const premiumTypeId = taxonomy.PremiumType.byCode[premiumTypeCode]?.id;
  const weightCapacityKg = useSelector(
    (s) => s.vehicle.vehicle.weightCapacityKg
  );
  const seats = vehicle?.seats;
  const carUsageCode = vehicle?.carUsageCode;
  const isTradeLicensePlate = useSelector(
    (s) => s.vehicle.vehicle.isTradeLicensePlate
  );
  const listPriceTypeCode = useSelector(
    (s) => s.vehicle.vehicle.listPriceTypeCode
  );

  const listPrice = vehicle?.listPrice;
  const specialEquipment = useSelector(
    (s) => s.vehicle.vehicle.specialEquipment
  );
  const powerKw = vehicle?.powerKw;

  const cubicCapacityCcm = useSelector(
    (s) => s.vehicle.vehicle.cubicCapacityCcm
  );

  const isIncludingTax = vehicle?.isIncludingTax;
  const vatReimbursment = vehicle?.vatReimbursment;

  const defaultValueOperation = useSelector(
    (s) => s.timeline.dialog.defaultValueOperation
  );
  useEffect(() => {
    if (
      defaultValueOperation.fieldNames &&
      defaultValueOperation.formValues &&
      !defaultValueOperation.results
    ) {
      const fieldNames = defaultValueOperation.fieldNames;
      const formValues = defaultValueOperation.formValues;

      let insurer = formValues.insurerPartnerNumber?.value;

      if (
        premiumTypeCode !== "PremiumType.MTPL" &&
        premiumTypeCode !== "PremiumType.HULL" &&
        premiumTypeCode !== "PremiumType.HULL_BASIC" &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_PRORATA &&
        premiumTypeCode !== PremiumType.FLEETLEGAL_CLOSINGDATE &&
        premiumTypeCode !== "PremiumType.HORSE_POWER"
      ) {
        const segment: TimelineSegment = timelineSegments
          .filter(
            (seg) =>
              seg.domain.value === "PremiumType.MTPL" &&
              (seg.segmentType === TimelineSegmentType.PREMIUM ||
                seg.segmentType === TimelineSegmentType.SUSPENSION)
          )
          .find(
            (seg) =>
              moment(seg.startDate).toDate() <= dialogDate &&
              moment(seg.endDate).toDate() > dialogDate
          );
        if (segment) {
          insurer = segment.associatedObject.insurerPartnerNumber;
        }
      }

      let hullIsBasic = null;
      let fleetLegalClosingDate = null;
      let premiumType = premiumTypeId;
      if (
        dialogType === TimelineDialogType.ADD_PREMIUM ||
        dialogType === TimelineDialogType.INSERT_PREMIUM
      ) {
        let hullIsBasicForm = formValues.hullIsBasic?.value;
        if (hullIsBasicForm) {
          hullIsBasic = hullIsBasicForm === "true" ? true : false;
        }
        fleetLegalClosingDate =
          formValues?.premiumType?.value ===
          taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id
            ? true
            : false;
      }
      if (dialogType === TimelineDialogType.CORRECT_PREMIUM) {
        hullIsBasic = isHullBasic;
        fleetLegalClosingDate = isFleetLegalClosingDate;
      }
      if (premiumTypeCode === "PremiumType.HULL" && hullIsBasic) {
        premiumType = taxonomy.PremiumType.byCode["PremiumType.HULL_BASIC"].id;
      }

      if (
        premiumTypeCode === PremiumType.FLEETLEGAL_PRORATA &&
        fleetLegalClosingDate
      ) {
        premiumType =
          taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE].id;
      }
      const fieldNameMapping = {
        annualGrossPremium: "annualGrossPremium",
        deductible: "deductible",
        calculation: "calculation",
      };
      let results: any = {};

      for (const fieldName of fieldNames) {
        const params = {
          attributeName: fieldNameMapping[fieldName],
          premiumType,
          defaultSettings,
          insurerPartnerNumber: insurer,
          vehicleType,
          carUsage: carUsageCode,
          weightCapacityKg: weightCapacityKg ? weightCapacityKg + "" : null,
          powerKw: powerKw ? powerKw + "" : null,
          cubicCapacityCcm: cubicCapacityCcm ? cubicCapacityCcm + "" : null,
          numberOfSeatsMoped: seats ? seats + "" : null,
          numberOfSeatsRange: seats ? seats + "" : null,
          listPriceType: listPriceTypeCode,
          listPrice,
          taxonomy,
          hullIsBasic,
          isTradeLicensePlate,
          specialEquipment,
          emptyWeightKg: emptyWeightKg?.toString() || null,
        };
        let premium = determineDefaultValue(params);

        // if (!premium) {
        //   premium = "";
        // }

        if (params.attributeName === "annualGrossPremium") {
          let premiumString = null;
          try {
            premiumString = premium.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            results[fieldName] = premiumString.replaceAll(".", ",");
          } catch (ex) {
            //
          }
        }

        if (params.attributeName === "deductible") {
          let premiumString = null;
          try {
            let premiumJson = JSON.parse(premium as string);
            if (premiumJson.hullDeductibleMinAmount) {
              premiumString =
                premiumJson.hullDeductibleMinAmount.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              results["hullDeductibleMinAmount"] = premiumString;
            }
            if (premiumJson.hullDeductibleMaxAmount) {
              premiumString =
                premiumJson.hullDeductibleMaxAmount.toLocaleString("de-DE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              results["hullDeductibleMaxAmount"] = premiumString;
            }
            if (premiumJson.hullDeductiblePercent) {
              premiumString = premiumJson.hullDeductiblePercent.toLocaleString(
                "de-DE",
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              );
              premiumString = premiumString + "";
              results["hullDeductiblePercent"] = premiumString;
            }
            if (premiumJson.hullDeductibleComment) {
              results["hullDeductibleComment"] =
                premiumJson.hullDeductibleComment;
            }
          } catch (ex) {
            //
          }
        }

        if (params.attributeName === "calculation") {
          let premiumString = null;
          try {
            let calculationJson = JSON.parse(premium as string);
            const valueType = calculationJson.valueType;
            const premiumRatePercentage = parseFloat(
              (calculationJson.premiumRatePercentage as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            const absoulutePremiumValue = parseFloat(
              (calculationJson.absoulutePremiumValue as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            const premiumFreeSpecialEquipment = parseFloat(
              (calculationJson.premiumFreeSpecialEquipment as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const gap = parseFloat(
              (calculationJson.gap as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const replacementValue = parseFloat(
              (calculationJson.replacementValue as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const grossNegligence = parseFloat(
              (calculationJson.grossNegligence as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const electroPlusPackage = parseFloat(
              (calculationJson.electroPlusPackage as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );

            const discountForWaiverOfTaxRefund = parseFloat(
              (calculationJson.discountForWaiverOfTaxRefund as string)
                ?.replaceAll(" ", "")
                ?.replaceAll(".", "")
                .replaceAll(",", ".")
            );
            const listPriceValue = listPrice;
            if (!listPriceValue) continue;
            const specialEquipmentValue = specialEquipment
              ? specialEquipment
              : 0;

            //(Listprice + special equipment - premium-free special equipment) x Premium rate + Surcharge for hull additional covers - Discount for waiver of tax refund = hull premium
            let calculatedPremium = 0;
            if (
              valueType ===
              FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
            ) {
              calculatedPremium = absoulutePremiumValue;
            } else {
              calculatedPremium =
                listPriceValue +
                specialEquipmentValue -
                (premiumFreeSpecialEquipment > specialEquipmentValue
                  ? specialEquipmentValue
                  : premiumFreeSpecialEquipment);
              calculatedPremium =
                calculatedPremium * (premiumRatePercentage / 100.0);
            }
            if (formValues.gap === true) {
              calculatedPremium = calculatedPremium * (1 + gap / 100.0);
            }
            if (formValues.replacementValue === true) {
              calculatedPremium =
                calculatedPremium * (1 + replacementValue / 100.0);
            }
            if (formValues.grossNegligence === true) {
              calculatedPremium =
                calculatedPremium * (1 + grossNegligence / 100.0);
            }

            if (formValues.electroPlusPackage === true) {
              calculatedPremium =
                calculatedPremium * (1 + electroPlusPackage / 100.0);
            }

            // Case 1
            // IF field “VAT included” is YES
            // AND field „VAT Reimbursement“ is NO
            // THEN the percentage value entered in the "Discount for waiver of tax refund" field in the premium calculation must be deducted
            if (isIncludingTax === true && vatReimbursment !== true) {
              calculatedPremium =
                calculatedPremium * (1 - discountForWaiverOfTaxRefund / 100.0);
            }

            // Case 2
            // IF field “VAT included” is NO
            // AND field „VAT Reimbursement“ is NO
            // THEN there is no "discount for waiver of tax refund".
            if (isIncludingTax !== true && vatReimbursment !== true) {
              calculatedPremium = calculatedPremium * 1;
            }

            // Case 3
            // IF field “VAT included” is YES
            // AND field „VAT Reimbursement“ is YES
            // THEN there is "no discount for waiver of tax refund".
            if (isIncludingTax === true && vatReimbursment === true) {
              calculatedPremium = calculatedPremium * 1;
            }

            // Case 4
            // IF field “VAT included”  is NO
            // AND field „VAT Reimbursement“ is YES
            // THEN a surcharge in the amount of 20% must be charged instead of a discount.
            if (isIncludingTax !== true && vatReimbursment === true) {
              calculatedPremium = calculatedPremium * 1.2;
            }

            premiumString = calculatedPremium.toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            results["annualGrossPremium"] = premiumString;
          } catch (ex) {
            //
          }
        }
      }
      if (Object.keys(results).length === 0) {
        !defaultValueOperation?.noMessage &&
          toast.info(t("bfm.fillInFleetDefaultsValueNoValue.label"));
        dispatch(resetDialogDefaultValue(null));
      } else {
        !defaultValueOperation?.noMessage &&
          toast.success(t("bfm.fillInFleetDefaultsValueSuccess.label"));
      }
      dispatch(setDialogDefaultValueOperationResult(results));
    }
  }, [defaultValueOperation]);
};

const determineDefaultValue = (params) => {
  const defaultSettings: FleetDefaultSetting[] = params.defaultSettings;
  // Filter default settings by attribute name and premium type
  let interestingDefaultSettings = defaultSettings
    .filter((ds) => ds.attributeName === params.attributeName)
    .filter((ds) => ds.premiumType === params.premiumType)
    .filter((ds) => ds.isTradeLicensePlate === params.isTradeLicensePlate);

  // Filter default settings by insurer
  if (!!!params.insurerPartnerNumber) return null;
  interestingDefaultSettings = interestingDefaultSettings.filter(
    (ds) => ds.insurerPartnerNumber === params.insurerPartnerNumber
  );

  // Filter default settings by main group
  // if (!!!params.vehicleMainGroup) return null;
  // interestingDefaultSettings = interestingDefaultSettings.filter(
  //   (ds) => ds.vehicleMainGroup === params.vehicleMainGroup
  // );

  ///if (!!!params.vehicleType) return null;
  interestingDefaultSettings = interestingDefaultSettings.filter(
    (ds) => ds.vehicleType === params.vehicleType
  );

  interestingDefaultSettings = interestingDefaultSettings.filter(
    (ds) => !ds.carUsage || ds.carUsage === params.carUsage
  );

  interestingDefaultSettings.sort(nullsLast("carUsage", true));

  // If weight capacity is specified Leave only those default settings
  // where weight capacity range is not set or it matches specified value
  if (!isStringEmpty(params.weightCapacityKg)) {
    const parsedWeightCapacityKg = stringToNumber(params.weightCapacityKg);
    const weightCapacityTaxonomyCode = getWeightCapacityCodeByNumber(
      Number(parsedWeightCapacityKg)
    );
    const weightCapacityTaxonomyObject =
      params.taxonomy.WeightCapacityKgRange.byCode[weightCapacityTaxonomyCode];
    if (weightCapacityTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.weightCapacityKgRange ||
          ds.weightCapacityKgRange === weightCapacityTaxonomyObject.id
      );
    }
    interestingDefaultSettings.sort(nullsLast("weightCapacityKgRange", true));
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => !!!ds.weightCapacityKgRange
    );
  }

  // If powerKw is specified Leave only those default settings
  // where powerKw range is not set or it matches specified value

  if (!isStringEmpty(params.powerKw)) {
    const formattedPowerKw = Number(params.powerKw);
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        (!ds.powerKwFrom && !ds.powerKwTo) ||
        (formattedPowerKw >= ds.powerKwFrom && formattedPowerKw <= ds.powerKwTo)
      );
    });
    interestingDefaultSettings.sort(nullsLast("powerKwFrom", true));
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => !ds.powerKwFrom && !ds.powerKwTo
    );
  }

  if (!isStringEmpty(params.emptyWeightKg)) {
    const formattedEmptyWeightKg = Number(params.emptyWeightKg);

    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      return (
        (!ds.emptyWeightKgFrom && !ds.emptyWeightKgTo) ||
        (formattedEmptyWeightKg >= ds.emptyWeightKgFrom &&
          formattedEmptyWeightKg <= ds.emptyWeightKgTo)
      );
    });
    interestingDefaultSettings.sort(nullsLast("emptyWeightKg", true));
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => !ds.emptyWeightKgFrom && !ds.emptyWeightKgTo
    );
  }

  // If cubicCapacityCcm is specified Leave only those default settings
  // where cubicCapacityCcm range is not set or it matches specified value
  if (!isStringEmpty(params.cubicCapacityCcm)) {
    const parsedCubicCapacityCcm = stringToNumber(params.cubicCapacityCcm);
    const parsedCubicCapacityCcmTaxonomyCode = getCubicCapacityCodeByNumber(
      Number(parsedCubicCapacityCcm)
    );
    const cubicCapacityCcmTaxonomyObject =
      params.taxonomy.CubicCapacityCcmRangeHg1.byCode[
        parsedCubicCapacityCcmTaxonomyCode
      ];
    if (cubicCapacityCcmTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.cubicCapacityCcmRange ||
          ds.cubicCapacityCcmRange === cubicCapacityCcmTaxonomyObject.id
      );
    }
    interestingDefaultSettings.sort(nullsLast("cubicCapacityCcmRange", true));
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => !!!ds.cubicCapacityCcmRange
    );
  }

  // If numberOfSeatsRange is specified Leave only those default settings
  // where numberOfSeatsRange range is not set or it matches specified value
  if (!isStringEmpty(params.numberOfSeatsRange)) {
    const parsedNumberOfSeatsRange = stringToNumber(params.numberOfSeatsRange);
    const parsedNumberOfSeatsRangeTaxonomyCode =
      getNumberOfSeatsRangeCodeByNumber(Number(parsedNumberOfSeatsRange));
    const numberOfSeatsRangeTaxonomyObject =
      params.taxonomy.NumberOfSeatsRange.byCode[
        parsedNumberOfSeatsRangeTaxonomyCode
      ];
    if (numberOfSeatsRangeTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.numberOfSeatsRange ||
          ds.numberOfSeatsRange === numberOfSeatsRangeTaxonomyObject.id
      );
    }
    interestingDefaultSettings.sort(nullsLast("numberOfSeatsRange", true));
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => !!!ds.numberOfSeatsRange
    );
  }

  // If numberOfSeatsMoped is specified Leave only those default settings
  // where numberOfSeatsMoped range is not set or it matches specified value
  if (!isStringEmpty(params.numberOfSeatsMoped)) {
    const parsedNumberOfSeatsMoped = stringToNumber(params.numberOfSeatsMoped);
    const parsedNumberOfSeatsMopedTaxonomyCode =
      getNumberOfSeatsMopedCodeByNumber(Number(parsedNumberOfSeatsMoped));
    const numberOfSeatsMopedTaxonomyObject =
      params.taxonomy.NumberOfSeatsMoped.byCode[
        parsedNumberOfSeatsMopedTaxonomyCode
      ];
    if (numberOfSeatsMopedTaxonomyObject) {
      interestingDefaultSettings = interestingDefaultSettings.filter(
        (ds) =>
          !ds.numberOfSeatsMoped ||
          ds.numberOfSeatsMoped === numberOfSeatsMopedTaxonomyObject.id
      );
    }
    interestingDefaultSettings.sort(nullsLast("numberOfSeatsMoped", true));
  } else {
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => !!!ds.numberOfSeatsMoped
    );
  }

  if (
    (params.premiumType ===
      params.taxonomy.PremiumType.byCode["PremiumType.HULL"].id ||
      params.premiumType ===
        params.taxonomy.PremiumType.byCode["PremiumType.HULL_BASIC"].id) &&
    (params.attributeName === "calculation" ||
      params.attributeName === "annualGrossPremium")
  ) {
    if (!!!params.listPriceType) return null;
    interestingDefaultSettings = interestingDefaultSettings.filter(
      (ds) => ds.listPriceType === params.listPriceType
    );

    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      let premiumFreeSpecialEquipment = 0;
      if (ds.attributeName === "calculation") {
        let calculationJson = JSON.parse(ds.attributeValue as string);
        const valueType = calculationJson.valueType;
        const premiumFreeSpecialEquipmentValue = parseFloat(
          (calculationJson.premiumFreeSpecialEquipment as string)
            ?.replaceAll(" ", "")
            ?.replaceAll(".", "")
            .replaceAll(",", ".")
        );
        // if (
        //   valueType ===
        //   FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
        // ) {
        premiumFreeSpecialEquipment =
          premiumFreeSpecialEquipmentValue > params.specialEquipment
            ? params.specialEquipment
            : premiumFreeSpecialEquipmentValue;
      }
      // }
      return (
        ds.listPriceMin === null ||
        ds.listPriceMin <=
          params.listPrice +
            params.specialEquipment -
            premiumFreeSpecialEquipment
      );
    });
    interestingDefaultSettings = interestingDefaultSettings.filter((ds) => {
      let premiumFreeSpecialEquipment = 0;
      if (ds.attributeName === "calculation") {
        let calculationJson = JSON.parse(ds.attributeValue as string);
        const valueType = calculationJson.valueType;
        const premiumFreeSpecialEquipmentValue = parseFloat(
          (calculationJson.premiumFreeSpecialEquipment as string)
            ?.replaceAll(" ", "")
            ?.replaceAll(".", "")
            .replaceAll(",", ".")
        );
        // if (
        //   valueType ===
        //   FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
        // ) {
        premiumFreeSpecialEquipment =
          premiumFreeSpecialEquipmentValue > params.specialEquipment
            ? params.specialEquipment
            : premiumFreeSpecialEquipmentValue;
        // }
      }
      return (
        ds.listPriceMax === null ||
        ds.listPriceMax >=
          params.listPrice +
            params.specialEquipment -
            premiumFreeSpecialEquipment
      );
    });
  }
  if (interestingDefaultSettings.length > 0)
    return interestingDefaultSettings[0].attributeValue;
};

function nullsLast(attributeName, ascending) {
  return function (a, b) {
    // equal items sort equally
    if (a[attributeName] === b[attributeName]) {
      return 0;
    }
    // nulls sort after anything else
    else if (a[attributeName] === null) {
      return 1;
    } else if (b[attributeName] === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    else if (ascending) {
      return a[attributeName] < b[attributeName] ? -1 : 1;
    }
    // if descending, highest sorts first
    else {
      return a[attributeName] < b[attributeName] ? 1 : -1;
    }
  };
}
