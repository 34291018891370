import { Dialog, Icon, TooltipHost } from "@fluentui/react";
import { Form, Formik } from "formik";
import { useAddVehiclePremium } from "hooks/data/mutations/useAddVehiclePremium";
import { useInsertVehiclePremium } from "hooks/data/mutations/useInsertVehiclePremium";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import moment from "moment";
import { getInsurerBranchOffice } from "pages/fleet-form/FleetForm/tabs/InsurerDefaultSettings/utils";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { removeEmpty } from "utils/utils";
import * as yup from "yup";
import { RefProvider } from "../../../../contexts/RefProvider";
import { useSelector } from "../../../../store/hooks";
import {
  closeDialog,
  setDialogDefaultValueOperation,
  setDialogInsurerSettingsOperation,
  setOperationRetVal,
} from "../../../../store/timeline";
import {
  IFleetInsurerDefaultSetting,
  PremiumType,
  TimelineSegmentType,
} from "../../../../types/types";
import { setYupLocale } from "../../../../utils/setYupLocale";
import { toast } from "../../../FluentToast";
import { FormSpinner } from "../../../FormSpinner";
import { transformForServer } from "../../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../../styled";
import { AddPremiumDialogContent } from "../AddPremiumDialogContent";
import { addPremiumDialogConfig } from "../addPremiumDialogConfig";
import { validationObjectConfig } from "../addPremiumDialogValidation";
import { transformForForm } from "../transformForForm";

export type TSetDefaultSettingValues = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, value: boolean) => void;
  values: any;
  insurer?: {
    label: string;
    value: number | string;
  };
  noToastMsg?: boolean;
  mustHaveInsurer?: boolean;
  isHullPremium?: boolean;
};

export const AddPremiumDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const { fleetId } = useParams() as any;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paymentMeanOptions, paymentMethodOptions } = useActionConfig();

  const item = useSelector((s) => s.timeline.dialog.item);
  const premiumTypesTaxonomy = useSelector(
    (s) => s.taxonomy.PremiumType.byCode
  );
  const insurerList = useSelector((s) => s.vehicle.insurerList);

  const insurerOptions = insurerList
    ? insurerList.map((tax) => {
        return {
          value: tax.insurerInternalNumber,
          label: tax.insurerName,
        };
      })
    : [];

  const { data: fleetInsurerDefaultSettings } =
    useGetFleetInsurerDefaultSettings(fleetId);

  const {
    mutate: onInsertVehiclePremium,
    isLoading: insertVehiclePremiumInProgress,
  } = useInsertVehiclePremium();

  const {
    mutate: onAddVehiclePremium,
    isLoading: addVehiclePremiumInProgress,
  } = useAddVehiclePremium();

  const mainMtplInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainMtplInsurerPartnerNumber
  );

  const mainHullInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainHullInsurerPartnerNumber
  );
  const mainFleetLegalInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainFleetLegalInsurerPartnerNumber
  );

  const premiumTypeKeyItem = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );

  const phraseActivated = usePhraseActivationStatus().activated;
  const date = useSelector((s) => s.timeline.dialog.date);

  const insurerPartnerNumber = useSelector(
    (s) => s.timeline.dialog.item.associatedObject?.insurerPartnerNumber
  );
  const isLoading =
    insertVehiclePremiumInProgress || addVehiclePremiumInProgress;
  const premiumTypeKey = premiumTypeKeyItem;
  const premiumTypeValue = premiumTypesTaxonomy[premiumTypeKey]?.id;

  const isMtplPremiumType = premiumTypeKey === PremiumType.MTPL;
  const isHullPremiumType = premiumTypeKey === PremiumType.HULL;

  const isFleetLegalProRataPremiumType =
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA;
  const isFleetLegalClosingDatePremiumType =
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE;

  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const taxonomy = useSelector((s) => s.taxonomy);

  const dueDay = useSelector((s) => s.vehicle.fleet.dueDay);
  const dueMonth = useSelector((s) => s.vehicle.fleet.dueMonth);

  const fleetPolicyNumber = useSelector(
    (s) => s.vehicle.fleet.fleetPolicyNumber
  );

  const fleetLegalPolicyNumber = useSelector(
    (s) => s.vehicle.fleet.fleetLegalPolicyNumber
  );

  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const isInsertOperation =
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN;

  const hasPremiumHistory = vehicle?.vehiclePremiumSegmentHistoryData?.some(
    (item) => item.premiumTypeCode === premiumTypeValue
  );

  const vehicleRegistrationDate = vehicle?.registrationDate;

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(
      premiumTypeKey,
      t,
      vehicle.isTradeLicensePlate,
      isInsertOperation
    );
    return yup.object(yupObject);
  }, [t, premiumTypeKey, isInsertOperation, vehicle.isTradeLicensePlate]);

  const setDefaultSettingValues = async ({
    setFieldValue,
    values,
    insurer,
    noToastMsg = true,
    mustHaveInsurer = false,
    isHullPremium = true,
    setFieldTouched,
  }: TSetDefaultSettingValues) => {
    // if (!isMtplPremiumType && !isHullBasicType && !isHullPremiumType) return;
    if (mustHaveInsurer && !insurer) return;

    const insurerInternalNumberByPremiumType = (() => {
      if (insurer) {
        return Number(insurer.value);
      }
      if (isMtplPremiumType) {
        return Number(mainMtplInsurerPartnerNumber);
      }
      if (isHullPremiumType) {
        return Number(mainHullInsurerPartnerNumber);
      }
      if (isFleetLegalProRataPremiumType) {
        return Number(mainFleetLegalInsurerPartnerNumber);
      }

      return null;
    })();

    const insurerPartnerNumberByPremiumType = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === insurerInternalNumberByPremiumType
    )?.insurerInternalNumber;

    const selectedFleetInsurerDefaultSetting: IFleetInsurerDefaultSetting =
      (() => {
        if (
          (isMtplPremiumType ||
            isHullPremiumType ||
            isFleetLegalProRataPremiumType) &&
          insurerInternalNumberByPremiumType
        ) {
          return fleetInsurerDefaultSettings.find(
            (setting) =>
              setting.insurerPartnerNumber ===
              insurerInternalNumberByPremiumType
          );
        }
        return null;
      })();

    const selectedInsurer = insurerOptions.find(
      (option) => option.value === insurerPartnerNumberByPremiumType
    );

    const insurerBranchOffice = getInsurerBranchOffice({
      formInsurer: insurerPartnerNumberByPremiumType,
      insurerList,
      formBranchOffice: selectedFleetInsurerDefaultSetting?.insurerBranchOffice,
    });

    const paymentMean = paymentMeanOptions.find(
      (item) => item.value === selectedFleetInsurerDefaultSetting?.paymentMean
    );

    const paymentMethod = paymentMethodOptions.find(
      (item) => item.value === selectedFleetInsurerDefaultSetting?.paymentMethod
    );

    const paymentMeanFleetLegal = paymentMeanOptions.find(
      (item) =>
        item.value === selectedFleetInsurerDefaultSetting?.paymentMeanFleetLegal
    );

    const paymentMethodFleetLegal = paymentMethodOptions.find(
      (item) =>
        item.value ===
        selectedFleetInsurerDefaultSetting?.paymentMethodFleetLegal
    );

    const paymentMethodEngineTax = paymentMethodOptions.find(
      (item) =>
        item.value ===
        selectedFleetInsurerDefaultSetting?.paymentMethodEngineTax
    );

    setFieldValue("insurerPartnerNumber", selectedInsurer);
    setFieldValue("insurerBranchOffice", insurerBranchOffice);

    if (isFleetLegalProRataPremiumType) {
      if (paymentMeanFleetLegal) {
        setFieldValue("paymentMean", paymentMeanFleetLegal);
      } else {
        setFieldValue("paymentMean", paymentMean);
      }
      if (paymentMethodFleetLegal) {
        setFieldValue("paymentMethod", paymentMethodFleetLegal);
      } else {
        setFieldValue("paymentMethod", paymentMethod);
      }
    } else {
      setFieldValue("paymentMean", paymentMean);
      setFieldValue("paymentMethod", paymentMethod);
    }

    let fieldNames1 = ["annualGrossPremium", "deductible", "calculation"];

    dispatch(
      setDialogDefaultValueOperation({
        fieldNames: fieldNames1,
        formValues: values,
        noMessage: noToastMsg,
      })
    );

    let fieldNames2 = [];

    setFieldTouched("motorTaxPaymentMethod", false);

    if (paymentMethodEngineTax) {
      setFieldValue("motorTaxPaymentMethod", paymentMethodEngineTax);
    } else {
      setFieldValue("motorTaxPaymentMethod", paymentMethod);
    }

    if (selectedFleetInsurerDefaultSetting?.activationDays?.toString()) {
      setFieldValue(
        "activationDays",
        selectedFleetInsurerDefaultSetting?.activationDays
      );
    } else {
      fieldNames2.push("activationDays");
    }

    if (selectedFleetInsurerDefaultSetting?.suspensionDays?.toString()) {
      setFieldValue(
        "suspensionDays",
        selectedFleetInsurerDefaultSetting?.suspensionDays
      );
    } else {
      fieldNames2.push("suspensionDays");
    }

    if (isHullPremium) {
      if (
        selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull?.toString()
      ) {
        setFieldValue(
          "garageRiskDiscountPercent",
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull
            ?.toString()
            .replace(".", ",")
        );
      } else {
        fieldNames2.push("garageRiskDiscountPercent");
      }
    } else {
      if (
        selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic?.toString()
      ) {
        setFieldValue(
          "garageRiskDiscountPercent",
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic
            ?.toString()
            .replace(".", ",")
        );
      } else {
        fieldNames2.push("garageRiskDiscountPercent");
      }
    }

    fieldNames2.push("suspensionDaysLimit");
    fieldNames2.push("motorTaxSuspensionDaysLimit");

    dispatch(
      setDialogInsurerSettingsOperation({
        fieldNames: fieldNames2,
        formValues: values,
        noMessage: noToastMsg,
      })
    );
  };

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: addPremiumDialogConfig,
    }) as any;
    result = removeEmpty(result);
    let premiumTypeKeyFinal = premiumTypeKey;

    const isFleetLegalClosingDate =
      data?.premiumType?.value ===
      taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

    if (
      premiumTypeKeyFinal === PremiumType.HULL &&
      data.hullIsBasic.value === "true"
    ) {
      premiumTypeKeyFinal = PremiumType.HULL_BASIC;
    }

    let objectToSend = {
      vehicleId: vehicle.vehicleId as any,
      fleetId: vehicle.fleetId as any,
      body: {
        ...result,
        premiumType:
          data?.premiumType?.value ||
          taxonomy.PremiumType.byCode[`${premiumTypeKeyFinal}`]?.id,
        hullPremiumRelatedInfo: {
          hullProduct: result.hullProduct,
          hullDeductiblePercent: result.hullDeductiblePercent,
          hullDeductibleMaxAmount: result.hullDeductibleMaxAmount,
          hullDeductibleMinAmount: result.hullDeductibleMinAmount,
          hullDeductibleComment: result.hullDeductibleComment,
          gap: result.gap,
          replacementValue: result.replacementValue,
          grossNegligence: result.grossNegligence,
          electroPlusPackage: result.electroPlusPackage,
        },
      },
    };
    delete objectToSend.body.hullProduct;
    delete objectToSend.body.hullDeductiblePercent;
    delete objectToSend.body.hullDeductibleMaxAmount;
    delete objectToSend.body.hullDeductibleMinAmount;
    delete objectToSend.body.hullDeductibleComment;
    delete objectToSend.body.gap;
    delete objectToSend.body.replacementValue;
    delete objectToSend.body.grossNegligence;
    delete objectToSend.body.electroPlusPackage;

    if (objectToSend.body?.isMotorTaxIncluded === true) {
      objectToSend.body = {
        ...objectToSend.body,
        motorTaxExclusionReason: null,
      };
    }
    if (
      premiumTypeKey !== PremiumType.HULL &&
      premiumTypeKey !== PremiumType.HULL_BASIC
    ) {
      delete objectToSend.body.hullPremiumRelatedInfo;
    }

    if (isFleetLegalClosingDate) {
      objectToSend.body.isFirstForFleetLegalClosingDate =
        data.isFirstForFleetLegalClosingDate;
    }

    isInsertOperation
      ? onInsertVehiclePremium(
          {
            rowVersion,
            params: objectToSend,
          },
          {
            onSuccess: (res) => {
              dispatch(setOperationRetVal(res?.data));
              toast.success(t("bfm.success"));
              dispatch(closeDialog());
            },
          }
        )
      : onAddVehiclePremium(
          {
            rowVersion,
            params: objectToSend,
          },
          {
            onSuccess: (res) => {
              dispatch(setOperationRetVal(res?.data));
              toast.success(t("bfm.success"));
              dispatch(closeDialog());
            },
          }
        );
  };

  const Title = (
    <Flex>
      <Icon
        iconName="CircleAddition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {isInsertOperation ? t("bfm.insertPremium") : t("bfm.addPremium")}{" "}
        {t(`${premiumTypeKey}`)}
      </div>
    </Flex>
  );

  const validFromRaw = hasPremiumHistory ? date : vehicleRegistrationDate;
  const validFromDate = moment(validFromRaw).toDate();
  const momentDate = moment(date).toDate();

  const initialValues = useMemo(() => {
    const insurerInternalNumberByPremiumType = (() => {
      if (isMtplPremiumType) {
        return Number(mainMtplInsurerPartnerNumber);
      }
      if (isHullPremiumType) {
        return Number(mainHullInsurerPartnerNumber);
      }
      if (isFleetLegalProRataPremiumType) {
        return Number(mainFleetLegalInsurerPartnerNumber);
      }

      return null;
    })();

    const insurerPartnerNumberByPremiumType = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === insurerInternalNumberByPremiumType
    )?.insurerInternalNumber;

    const expiryDate = new Date(
      validFromDate.getFullYear() + 1,
      Number(dueMonth) - 1, // if month is 1 that is february
      Number(dueDay)
    );
    let obj: any = {
      insurerPartnerNumber: insurerPartnerNumber,
      validFromDate,
      expiryDate,
      validToDate: item.endDate,
      annualGrossPremium: 0,
    };

    if (
      isMtplPremiumType ||
      isHullPremiumType ||
      isFleetLegalProRataPremiumType
    ) {
      obj = {
        ...obj,
        insurerPartnerNumber: insurerPartnerNumberByPremiumType,
      };
    }

    if (isHullPremiumType) {
      obj = {
        ...obj,
        hullIsBasic: false,
      };
    }

    if (
      premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC ||
      //debugger
      isFleetLegalProRataPremiumType ||
      isFleetLegalClosingDatePremiumType
      //debugger
    ) {
      obj = {
        ...obj,
        insurerPolicyNumber: isFleetLegalProRataPremiumType
          ? fleetLegalPolicyNumber
          : fleetPolicyNumber,
        dueDate:
          ((dueDay + "").length === 1 ? "0" + dueDay : dueDay) +
          "" +
          ((dueMonth + "").length === 1 ? "0" + dueMonth : dueMonth),
      };
    }

    if (isFleetLegalProRataPremiumType) {
      obj = {
        ...obj,
        suspensionDaysLimit: "-1",
      };
    }

    if (isFleetLegalClosingDatePremiumType) {
      obj = {
        ...obj,
        isFirstForFleetLegalClosingDate: null,
      };
    }
    let initObj = transformForForm({
      obj: obj,
      config: addPremiumDialogConfig,
      t,
      taxonomy,
      insurerList,
      possibleMainVehicles: null,
    });
    initObj = {
      ...initObj,

      validToDate: item.endDate,
    };
    if (premiumTypeKey === PremiumType.MTPL) {
      initObj = {
        ...initObj,
        isMotorTaxIncluded: true,
        motorTaxSuspensionDaysLimit: "45",
      };
    }

    return initObj;
  }, [
    t,
    premiumTypeKey,
    taxonomy,
    insurerList,
    insurerPartnerNumber,
    item,
    momentDate,
    dueDay,
    dueMonth,
    vehicleRegistrationDate,
    hasPremiumHistory,
    mainMtplInsurerPartnerNumber,
  ]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={
        premiumTypeKey === PremiumType.MTPL ||
        premiumTypeKey === PremiumType.HULL
          ? 1050
          : 700
      }
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm, values, setFieldValue, setFieldTouched }) => {
            return (
              <Form>
                <AddPremiumDialogContent
                  item={item}
                  premiumTypeKey={premiumTypeKey}
                  setDefaultSettingValues={setDefaultSettingValues}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TooltipHost content={t("bfm.fillInAllDefaults.label")}>
                    <StyledPrimaryButton
                      text={t("bfm.defaultSettings.label")}
                      onClick={() => {
                        const isHullPremium =
                          values["hullIsBasic"]?.value === "full" ||
                          values["hullIsBasic"]?.value === "false";

                        setDefaultSettingValues({
                          setFieldValue,
                          values,
                          insurer: values["insurerPartnerNumber"],
                          mustHaveInsurer: true,
                          noToastMsg: false,
                          isHullPremium,
                          setFieldTouched,
                        });
                      }}
                      iconProps={{
                        iconName: "Shield",
                      }}
                      disabled={isLoading || !values["insurerPartnerNumber"]}
                    />
                  </TooltipHost>

                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </div>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
