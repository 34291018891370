import { IContextualMenuItem, mergeStyles } from "@fluentui/react";
import { isExternalOrReadonlyAdmin } from "form/util";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useTransferTempVehicle } from "hooks/data/mutations/useTransferTempVehicle";
import { UserRole } from "types/types";
import { routes } from "../config/routes";
import {
  setApplicationReportDialog,
  setDeleteDialog,
  setDeleteTEMPDialog,
  setDuplicateVehicleWithPremiumsDialog,
  setProrataReportDialog,
  setRemoveTEMPVehicle,
} from "../store/fleetPage";
import { useSelector } from "../store/hooks";
import { AppDispatch } from "../store/store";
import AppReportFleetLegalClosingDateDialog from "./AppReportFleetLegalClosingDateDialog";
import { toast } from "./FluentToast";
import { ListContextMenuIconButton } from "./styled";

const displayNoneClass = mergeStyles({
  display: "none",
});

type Props = {
  vehicle: any;
  fleetId: number | string;
  onClickChangeStatus: () => void;
  onClickChangeIssuingStatus: () => void;
  onClickChangePendingIssuingStatus: () => void;
  onClickCorrectStatusDate: () => void;
  onClickApproveStatus: () => void;
  onClickCancelStatus: () => void;
  isUpdateVehicleStatusLoading: boolean;
  isApproveVehicleStatusLoading: boolean;
};

export const VehicleContextButton = ({
  vehicle,
  fleetId,
  onClickChangeStatus,
  onClickCorrectStatusDate,
  onClickChangeIssuingStatus,
  onClickChangePendingIssuingStatus,
  onClickApproveStatus,
  onClickCancelStatus,
  isUpdateVehicleStatusLoading,
  isApproveVehicleStatusLoading,
}: Props) => {
  const [
    isAppReportFleetLegalClosingDateOpen,
    setIsAppReportFleetLegalClosingDateOpen,
  ] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);
  const { mutate: onTransferTempVehicle, isLoading } = useTransferTempVehicle();

  const userRole = useSelector((s) => s.auth.userRole);

  const selectedVehicleIds = useSelector((s) => s.fleetPage.selectedVehicleIds);
  const isMultipleAdminSelection =
    selectedVehicleIds.length > 1 && userRole !== UserRole.external;
  const isMultipleExternalSelection =
    selectedVehicleIds.length > 1 && userRole === UserRole.external;
  const vehicleStatusTaxCode =
    taxonomy.VehicleStatusCode.byId[vehicle.vehicleStatusCode]?.code;

  const toggleFleetLegalClosingDateDialog = useCallback(() => {
    setIsAppReportFleetLegalClosingDateOpen((prev) => !prev);
  }, []);

  let items: IContextualMenuItem[] = [
    {
      key: "open",
      text: t("bfm.openInNewTab.label"),
      onClick: () => {
        window.open(
          routes[
            vehicle.isTEMP ? "editTempVehicle" : "readOnlyVehicle"
          ].getPath(vehicle?.fleetId, vehicle?.vehicleId)
        );
      },
      disabled: isUpdateVehicleStatusLoading || isMultipleAdminSelection,
    },
    {
      key: "edit",
      text: t("bfm.editVehicle.label"),
      onClick: () => {
        if (vehicle.isTEMP) {
          navigate(
            routes.editTempVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
          );
        }
        //
        else {
          navigate(
            routes.editVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
          );
        }
      },

      disabled:
        userRole === UserRole.adminreadonly ||
        isUpdateVehicleStatusLoading ||
        isMultipleAdminSelection ||
        isMultipleExternalSelection,
    },
    {
      key: "duplicate",
      text: t("bfm.duplicateVehicle.label"),
      onClick: () => {
        navigate(
          routes.duplicateVehicle.getPath(vehicle.fleetId, vehicle.vehicleId)
        );
      },
      className: vehicle.isTEMP ? displayNoneClass : "",
      disabled:
        userRole === UserRole.adminreadonly ||
        isUpdateVehicleStatusLoading ||
        isMultipleAdminSelection ||
        isMultipleExternalSelection,
    },
    {
      key: "duplicateWithPremiums",
      text: t("bfm.duplicateVehicleWithPremiums.label"),
      onClick: () => {
        dispatch(
          setDuplicateVehicleWithPremiumsDialog({
            isOpen: true,
            vehicleId: vehicle.vehicleId,
            fleetId: "" + fleetId,
            isTradeLicensePlate: vehicle.isTradeLicensePlate,
          })
        );
      },
      className:
        vehicle.isTEMP || isExternalOrReadonlyAdmin(userRole)
          ? displayNoneClass
          : "",
      disabled:
        isUpdateVehicleStatusLoading ||
        isMultipleAdminSelection ||
        isMultipleExternalSelection,
    },
    {
      key: "changeStatus",
      text: t("bfm.changeStatus.label"),
      onClick: onClickChangeStatus,
      className: vehicle.isTEMP ? displayNoneClass : "",
      disabled:
        (userRole === UserRole.external &&
          [
            "VehicleStatusCode.PENDING_FOR_ACTIVATION",
            "VehicleStatusCode.PENDING_FOR_SUSPENSION",
            "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
          ].includes(vehicleStatusTaxCode)) ||
        userRole === UserRole.adminreadonly ||
        isUpdateVehicleStatusLoading,
    },
    {
      key: "changeIssuingStatus",
      text: t("bfm.changeIssuingStatus.label"),
      onClick: onClickChangeIssuingStatus,
      className: vehicle.isTEMP
        ? displayNoneClass
        : "" || isExternalOrReadonlyAdmin(userRole)
        ? displayNoneClass
        : "",
      disabled: isUpdateVehicleStatusLoading,
    },
    {
      key: "changePendingIssuingStatus",
      text: t("bfm.changePendingIssuingStatus.label"),
      onClick: onClickChangePendingIssuingStatus,
      className: vehicle.isTEMP
        ? displayNoneClass
        : "" || isExternalOrReadonlyAdmin(userRole)
        ? displayNoneClass
        : "",
      disabled: isUpdateVehicleStatusLoading,
    },
    {
      key: "transfer",
      text: t("bfm.transferVehicle.label"),
      onClick: (e) => {
        e.preventDefault();

        onTransferTempVehicle(
          {
            fleetId: vehicle.fleetId,
            vehicleId: vehicle.vehicleId,
          },
          {
            onSuccess: (res) => {
              dispatch(setRemoveTEMPVehicle(res.data));
              toast.success(t("bfm.notification.transfer.success"));
            },
          }
        );
      },
      disabled: isMultipleAdminSelection || isExternalOrReadonlyAdmin(userRole),
      className: !vehicle.isTEMP ? displayNoneClass : "",
    },
    {
      key: "delete",
      text: t("bfm.deleteTempVehicle.label"),
      className: userRole === UserRole.external ? displayNoneClass : "",
      disabled: isExternalOrReadonlyAdmin(userRole),
      onClick: () => {
        if (vehicle.isTEMP) {
          dispatch(
            setDeleteTEMPDialog({
              isOpen: true,
              fleetId: vehicle.fleetId,
              vehicleId: vehicle.vehicleId,
            })
          );
        }
        //
        else {
          dispatch(
            setDeleteDialog({
              isOpen: true,
              vehicleIds: selectedVehicleIds,
              fleetId: "" + fleetId,
            })
          );
        }
      },
    },

    {
      key: "approveStatus",
      text: t("bfm.approveStatus"),
      onClick: onClickApproveStatus,
      disabled:
        isApproveVehicleStatusLoading ||
        isMultipleAdminSelection ||
        userRole == UserRole.adminreadonly,
      className:
        isExternalOrReadonlyAdmin(userRole) ||
        vehicle.isTEMP ||
        ![
          "VehicleStatusCode.PENDING_FOR_ACTIVATION",
          "VehicleStatusCode.PENDING_FOR_SUSPENSION",
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
        ].includes(vehicleStatusTaxCode)
          ? displayNoneClass
          : "",
    },
    {
      key: "cancelStatus",
      text: t("bfm.cancelStatus"),
      onClick: onClickCancelStatus,
      disabled:
        isApproveVehicleStatusLoading ||
        isMultipleAdminSelection ||
        userRole == UserRole.adminreadonly,
      className:
        vehicle.isTEMP ||
        ![
          "VehicleStatusCode.PENDING_FOR_ACTIVATION",
          "VehicleStatusCode.PENDING_FOR_SUSPENSION",
          "VehicleStatusCode.PENDING_FOR_DEACTIVATION",
        ].includes(vehicleStatusTaxCode)
          ? displayNoneClass
          : "",
    },

    {
      key: "prorataReport",
      text: t("bfm.prorataReport.label"),
      onClick: () => {
        dispatch(
          setProrataReportDialog({
            isOpen: true,
            vehicleIds: selectedVehicleIds,
            fleetId: "" + fleetId,
          })
        );
      },
      className: vehicle.isTEMP ? displayNoneClass : "",
    },
    {
      key: "applicationReport",
      text: t("bfm.applicationReport.label"),
      className:
        vehicle.isTEMP || userRole === UserRole.external
          ? displayNoneClass
          : "",
      onClick: () => {
        dispatch(
          setApplicationReportDialog({
            isOpen: true,
            vehicleIds: selectedVehicleIds,
            fleetId: "" + fleetId,
          })
        );
      },
    },
  ];

  return (
    <>
      <ListContextMenuIconButton
        iconProps={{ iconName: "MoreVertical" }}
        menuIconProps={{ style: { display: "none" } }}
        menuProps={{
          items: items,
        }}
      />
      {isAppReportFleetLegalClosingDateOpen && (
        <AppReportFleetLegalClosingDateDialog
          open={isAppReportFleetLegalClosingDateOpen}
          onClose={toggleFleetLegalClosingDateDialog}
          vehicleIds={selectedVehicleIds}
          fleetId={fleetId?.toString()}
        />
      )}
    </>
  );
};
