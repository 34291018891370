import React, { PropsWithChildren } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
import { timeFormat } from "d3";
import {
  PremiumType,
  TimelineSegmentType,
  TimelineType,
} from "../../../../../types/types";
import { formatNumber } from "../../../../../utils/number";
import moment from "moment";
import { useSelector } from "../../../../../store/hooks";

const tickFormat = timeFormat("%d.%m.%Y");

export const SharedLicensePlateSegmentTooltip = ({ children, d }) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  let period = (
    <>
      {tickFormat(d.startDate)} - {tickFormat(d.endDate)}
      <br />
    </>
  );

  const vehicle = d.associatedObject?.vehicleShortInfo;
  const vehicleInfo = vehicle ? (
    <>
      {t("bfm.vehicleBrand.label")}
      {": "}
      {vehicle.brandCode
        ? t(taxonomy.VehicleBrand.byId[vehicle.brandCode].code)
        : null}
      <br />
      {t("bfm.vehicleForm.model.label")}
      {": "}
      {vehicle.model ? vehicle.model : null}
      <br />
      {t("bfm.vehicleVin.label")}
      {": "}
      {vehicle.vin}
      <br />
      {t("bfm.vehicleCarUsage.label")}
      {": "}
      {vehicle.carUsageCode
        ? `${t(taxonomy.VehicleUsageType.byId[vehicle.carUsageCode].code)}`
        : null}
      <br />
      {t("bfm.vehicleForm.vehicleTypeCode.label")}
      {": "}
      {`${t(taxonomy.VehicleTypeLegal.byId[vehicle.vehicleTypeCode].code)}`}
      <br />
      {t("bfm.vehicleForm.vehiclePowerTypeCode.label")}
      {": "}
      {vehicle.vehiclePowerTypeCode
        ? t(taxonomy.VehiclePowerType.byId[vehicle.vehiclePowerTypeCode].code)
        : null}
      <br />
      {t("bfm.vehicleForm.maxGrossWeightKg.label")}
      {": "}
      {`${vehicle.maxGrossWeightKg ? vehicle.maxGrossWeightKg : ""}`}
      <br />
      {t("bfm.vehicleForm.cubicCapacityCcm.label")}
      {": "}
      {`${vehicle.cubicCapacityCcm ? vehicle.cubicCapacityCcm : ""}`}
      <br />
      {t("bfm.vehicleForm.co2Emission.label")}
      {": "}
      {vehicle.co2emissions}
      <br />
      {t("bfm.vehicleForm.powerKw.label")}
      {": "}
      {vehicle.powerKw}
      <br />
      {t("bfm.vehicleForm.horsePowerBasedTaxAnnualy.label")}
      {": "}
      {vehicle.horsePowerBasedTaxAnnualy
        ? `${vehicle.horsePowerBasedTaxAnnualy} €`
        : "0 €"}
      <br />
      {t("bfm.vehicleForm.firstRegistrationDate.label")}
      {": "}
      {vehicle.firstRegistrationDate
        ? tickFormat(moment(vehicle.firstRegistrationDate).toDate())
        : null}
    </>
  ) : (
    <>{d.associatedObject?.licensePlate}</>
  );

  let extraInfo = <>No license plate</>;
  if (d.associatedObject !== null) {
    extraInfo = (
      <>
        {t("bfm.setByUserName.label")}: {d.associatedObject?.setByUserName}
        <br />
        {t("bfm.setAt.label")}:{" "}
        {tickFormat(moment(d.associatedObject?.setAt).toDate())}
        <br />
        {t("bfm.changeReasonComment.label")}: {d.associatedObject?.comment}
      </>
    );
  }
  return (
    <Tippy
      delay={100}
      followCursor={true}
      disabled={false}
      content={
        <span>
          {d.domain.type === TimelineType.SHARED_LICENSE_PLATE_STATUS ? (
            <>
              {d.associatedObject?.licensePlate ? (
                <>
                  {d.associatedObject?.licensePlate}{" "}
                  {d.extraData?.suspendedRange ? (
                    <>
                      {" - "}
                      {t("VehicleStatusCode.SUSPENDED")}
                    </>
                  ) : null}
                  <br />
                </>
              ) : (
                <>
                  {t(
                    taxonomy.PremiumType.byId[
                      d.associatedObject?.premiumTypeCode
                    ]?.code
                  )}
                </>
              )}
              {period}
            </>
          ) : d.domain.type === TimelineType.SHARED_LICENSE_PLATE_MTPL ||
            d.domain.type === TimelineType.SHARED_LICENSE_PLATE_ENGINE_TAX ? (
            <>
              <>
                {taxonomy.PremiumType.byId[d.associatedObject?.premiumTypeCode]
                  ?.code ? (
                  t(
                    taxonomy.PremiumType.byId[
                      d.associatedObject?.premiumTypeCode
                    ]?.code
                  )
                ) : (
                  <>
                    {d.domain.type === TimelineType.SHARED_LICENSE_PLATE_MTPL
                      ? t(PremiumType.MTPL)
                      : t(PremiumType.HORSE_POWER)}
                  </>
                )}
              </>
              {period ? (
                <>
                  <br />
                  {period}
                </>
              ) : null}
              {taxonomy.PremiumType.byId[d.associatedObject?.premiumTypeCode]
                ?.code ? (
                <>
                  <hr />
                  {vehicleInfo}
                  <hr />
                  {extraInfo}
                </>
              ) : null}
            </>
          ) : null}
        </span>
      }
    >
      {children}
    </Tippy>
  );
};

// {d.associatedObject ? (
//   <>
// {t(
//   taxonomy.PremiumType.byId[d.associatedObject?.premiumTypeCode]
//     ?.code
// )}
//     {taxonomy.PremiumType.byId[d.associatedObject?.premiumTypeCode]
//       ?.code ? (
//       <hr />
//     ) : null}
//     {vehicleInfo}
//   </>
// ) : null}
// <br />
