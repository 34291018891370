import {
  IconButton,
  Persona,
  PersonaSize,
  mergeStyleSets,
} from "@fluentui/react";
import LoadingButton from "components/LoadingButton";
import { Flex } from "components/styled";
import { isExternalOrReadonlyAdmin } from "form/util";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import { EActionDialog } from "pages/fleet-form/FleetForm/constants";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { IFleetInsurerDefaultSetting } from "types/types";
import { DeleteDialog } from "../DeleteDialog";
import { ISettingDialogState } from "../InsurerDefaultSettings";
import { getInsurerBranchOffice } from "../utils";

type Props = {
  onSetSettingDialog: (data: ISettingDialogState) => void;
};

function InsurerTable({ onSetSettingDialog }: Props) {
  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const { fleetId } = useParams() as any;

  const { data: fleetInsurerDefaultSettings } =
    useGetFleetInsurerDefaultSettings(fleetId);

  const theme = useTheme() as IStyledTheme;
  const userRole = useSelector((s) => s.auth.userRole);
  const { paymentMeanOptions, paymentMethodOptions } = useActionConfig();

  const [deleteDialog, setDeleteDialog] = useState({
    isOpen: false,
    setting: null,
  });

  const { t } = useTranslation();

  const classes = getClasses(theme);

  if (!fleetInsurerDefaultSettings) {
    return <></>;
  }

  return (
    <>
      <DeleteDialog
        deleteDialog={deleteDialog}
        close={() => setDeleteDialog({ isOpen: false, setting: null })}
      />

      <table className={classes.table}>
        <thead>
          <tr>
            <th>{t("bfm.user")}</th>
            <th>{t("bfm.insurer.label")}</th>
            <th>{t("bfm.paymentValues.label")}</th>
            <th>{t("bfm.garageRiskDiscount.label")}</th>
            <th>{t("bfm.otherValues.label")}</th>
            <th style={{ width: "80px" }}></th>
          </tr>
        </thead>
        <tbody>
          {fleetInsurerDefaultSettings?.map(
            (s: IFleetInsurerDefaultSetting) => {
              const insurerName = insurerList.find(
                (insurer) =>
                  insurer.insurerInternalNumber === s.insurerPartnerNumber
              )?.insurerName;

              let tempUser;
              if (s.lastModifiedByUserName) {
                tempUser = s.lastModifiedByUserName;
              } else if (s.lastModifiedByUserEmail) {
                tempUser = s.lastModifiedByUserEmail;
              } else if (s.lastModifiedByUserId) {
                tempUser = s.lastModifiedByUserId;
              }

              const lastModifiedByUser = tempUser;

              const paymentMean = paymentMeanOptions.find(
                (item) => item.value === s.paymentMean
              )?.label;

              const paymentMeanFleetLegal = paymentMeanOptions.find(
                (item) => item.value === s.paymentMeanFleetLegal
              )?.label;
              const paymentMethod = paymentMethodOptions.find(
                (item) => item.value === s.paymentMethod
              )?.label;

              const paymentMethodEngineTax = paymentMethodOptions.find(
                (item) => item.value === s.paymentMethodEngineTax
              )?.label;
              const paymentMethodFleetLegal = paymentMethodOptions.find(
                (item) => item.value === s.paymentMethodFleetLegal
              )?.label;

              const insurerBranchOffice = getInsurerBranchOffice({
                formInsurer: s.insurerPartnerNumber,
                insurerList,
                formBranchOffice: s.insurerBranchOffice,
              })?.label;

              return (
                <tr
                  key={s.fleetInsurerDefaultSettingId}
                  style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
                >
                  <td>
                    <Flex justify="center">
                      <Persona
                        text={lastModifiedByUser}
                        title={lastModifiedByUser}
                        hidePersonaDetails
                        size={PersonaSize.size32}
                      />
                    </Flex>
                  </td>
                  <td>{insurerName}</td>

                  <td>
                    {paymentMethod && (
                      <>
                        {t("bfm.fleet.paymentMethod.label")}: {paymentMethod}
                        <br />
                      </>
                    )}
                    {paymentMethodEngineTax && (
                      <>
                        {t("bfm.vehicleForm.paymentMethodEngineTax.label")}:{" "}
                        {paymentMethodEngineTax}
                        <br />
                      </>
                    )}
                    {paymentMethodFleetLegal && (
                      <>
                        {t("bfm.vehicleForm.paymentMethodFleetLegal.label")}:{" "}
                        {paymentMethodFleetLegal}
                        <br />
                      </>
                    )}
                    {paymentMean && (
                      <>
                        {t("bfm.vehicleForm.paymentMean.label")}: {paymentMean}
                        <br />
                      </>
                    )}
                    {paymentMeanFleetLegal && (
                      <>
                        {t("bfm.vehicleForm.paymentMeanFleetLegal.label")}:{" "}
                        {paymentMeanFleetLegal}
                        <br />
                      </>
                    )}
                  </td>
                  <td>
                    {s.garageRiskDiscountPercentHull?.toString() && (
                      <>
                        {t("bfm.garageRiskDiscountPercentHull.label")}:{" "}
                        {s.garageRiskDiscountPercentHull
                          .toString()
                          .replace(".", ",")}
                        <br />
                      </>
                    )}
                    {s.garageRiskDiscountPercentHullBasic?.toString() && (
                      <>
                        {t("bfm.garageRiskDiscountPercentHullBasic.label")}:{" "}
                        {s.garageRiskDiscountPercentHullBasic
                          .toString()
                          .replace(".", ",")}
                        <br />
                      </>
                    )}
                  </td>
                  <td>
                    {s.insurerBranchOffice && (
                      <>
                        {t("bfm.vehicleForm.insurerBranchOffice.label")}:{" "}
                        {insurerBranchOffice}
                        <br />
                      </>
                    )}
                    {s.suspensionDays?.toString() && (
                      <>
                        {t("bfm.suspensionDays")}: {s.suspensionDays}
                        <br />
                      </>
                    )}
                    {s.activationDays?.toString() && (
                      <>
                        {t("bfm.activationDays")}: {s.activationDays}
                        <br />
                      </>
                    )}
                  </td>
                  <td>
                    <Flex
                      justify="flex-end"
                      style={{
                        flexWrap: "nowrap",
                      }}
                    >
                      <IconButton
                        disabled={isExternalOrReadonlyAdmin(userRole)}
                        iconProps={{ iconName: "View" }}
                        onClick={() => {
                          onSetSettingDialog({
                            actionType: EActionDialog.ReadOnly,
                            isOpen: true,
                            title: t("bfm.fleet.tabs.insurerDefaultSetting"),
                            settings: s,
                          });
                        }}
                      />
                      <LoadingButton
                        iconProps={{ iconName: "Copy" }}
                        disabled={isExternalOrReadonlyAdmin(userRole)}
                        handleClick={async () => {
                          onSetSettingDialog({
                            actionType: EActionDialog.CopySettings,
                            isOpen: true,
                            title: t("bfm.createInsurerDefaultSettings.label"),
                            settings: s,
                          });
                        }}
                      />
                      <LoadingButton
                        iconProps={{ iconName: "Edit" }}
                        disabled={isExternalOrReadonlyAdmin(userRole)}
                        handleClick={async () => {
                          onSetSettingDialog({
                            actionType: EActionDialog.EditSetting,
                            isOpen: true,
                            title: t("bfm.fleet.defaultSettings.edit.label"),
                            settings: s,
                          });
                        }}
                      />
                      <IconButton
                        disabled={isExternalOrReadonlyAdmin(userRole)}
                        iconProps={{ iconName: "Cancel" }}
                        onClick={() => {
                          setDeleteDialog({
                            isOpen: true,
                            setting: s,
                          });
                        }}
                      />
                    </Flex>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}

export default InsurerTable;

const getClasses = (theme) => {
  return mergeStyleSets({
    table: {
      borderCollapse: "collapse",
      borderTop: `1px solid ${theme.palette.neutralLighterAlt}`,
      width: "100%",
      "& td": {
        padding: "5px 8px",
        fontSize: "12px",
        color: theme.palette.neutralPrimary,
        textAlign: "center",
      },
      "& th": {
        padding: "5px",
        fontSize: "12px",
        fontWeight: "650",
        borderBottom: `1px solid ${theme.palette.neutralLighterAlt}`,
        height: "42px",
        color: theme.palette.neutralPrimary,
      },
    },
  });
};
