import {
  ActionButton,
  Checkbox,
  ContextualMenu,
  TooltipHost,
} from "@fluentui/react";
import { useCurrentRoute } from "config/routes";
import { scaleBand, scaleTime } from "d3";
import { Dialog } from "pages/fleet-form/FleetForm/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "store/fleetForm";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { useSelector } from "../../store/hooks";
import { AppDispatch } from "../../store/store";
import {
  closeContextMenu,
  setShowBookings,
  setShowBookingsWithDocumentDate,
} from "../../store/timeline";
import { TimelineType } from "../../types/types";
import { FormSpinner } from "../FormSpinner";
import { SvgBackground, TimelinePlaceholder } from "../styled";
import { Accordion } from "./controls/accordion";
import { ChangeVehicleStatusDialog } from "./dialogs/ChangeVehicleStatusDialog";
import { TimelineDialog } from "./dialogs/TimelineDialog";
import { useContextMenuItems } from "./hooks/useContextMenuItems";
import { useTimeline } from "./hooks/useTimeline";
import { SvgPatternDefinitions } from "./visual-control/SvgPatternDefinitions";
import { Legend } from "./visual-control/legend/Legend";
import { MainTimeline } from "./visual-control/main-timeline/MainTimeline";
import { ProrataTable } from "./visual-control/main-timeline/ProrataTable";
import { PeriodSelector } from "./visual-control/period-selector/PeriodSelector";

interface IProps {
  width?: number;
  height?: number;
  marginLeft?: number;
}

/* Component */
export const Timeline = ({ width = 1100, marginLeft = 50 }: IProps) => {
  useTimeline();
  const types = useSelector((s) => s.timeline.types);
  const isChangeStatusDialogOpen = useSelector(
    (s) => s.vehicle?.changeVehicleStatusDialog?.isOpen
  );
  const { t } = useTranslation();
  const route = useCurrentRoute();

  const mainTimelineX = marginLeft;
  const mainTimelineY = 50;

  const timezoneX = 100;
  const timezoneY = 0;
  let timezoneWidth = width - 350 - mainTimelineX;
  const timezoneRowHeight = 57;

  const prorataTableX = mainTimelineX + timezoneX + timezoneWidth + 120;
  const prorataTableY = mainTimelineY;
  if (!types.includes(TimelineType.VEHICLE_PREMIUM)) {
    timezoneWidth += 120;
  }

  const startDate = useSelector((s) => s.timeline.timeline.startDate);
  const endDate = useSelector((s) => s.timeline.timeline.endDate);
  const yDomain = useSelector((s) => s.timeline.timeline.domain);
  const dispatch: AppDispatch = useDispatch();
  const contextMenu = useSelector((s) => s.timeline.contextMenu);
  const isDialogOpen = useSelector((s) => s.timeline.dialog.isOpen);
  const legend = useSelector((s) => s.timeline.timeline.legend);
  const showBookings = useSelector((s) => s.timeline.timeline.showBookings);
  const showBookingsWithDocumentDate = useSelector(
    (s) => s.timeline.timeline.showBookingsWithDocumentDate
  );

  const contextMenuItems = useContextMenuItems();

  const xScale = scaleTime()
    .domain([startDate, endDate])
    .range([0, timezoneWidth]);

  const dateFilter = useSelector((s) => s.timeline.timeline.dateFilter);
  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  const isLoading = useSelector(
    (s) => s.vehicle.modifyStatusStatus === "loading"
  );

  let dynamicHeight = 0;
  legend?.forEach((element) => {
    return (dynamicHeight += (element.items.length / 2 + 1) * 60);
  });
  if (!startDate) {
    return (
      <TimelinePlaceholder
        style={{
          width: `${width}px`,
        }}
      >
        <FormSpinner />
      </TimelinePlaceholder>
    );
  }

  const xScaleFiltered = scaleTime()
    .domain([dateFilter[0], dateFilter[1]])
    .range([0, timezoneWidth]);

  const timezoneHeight = yDomain.length * timezoneRowHeight;
  const yScale = scaleBand()
    .domain(yDomain.map((domain) => domain.value))
    .range([0, timezoneHeight])
    .paddingInner(0.4)
    .paddingOuter(0.4);

  const height =
    mainTimelineY + timezoneHeight + mainTimelineY + timezoneRowHeight * 3;

  const onHideContextMenu = () => {
    dispatch(closeContextMenu());
  };

  return (
    <div
      style={{
        padding: "5px",
      }}
    >
      <TimelinePlaceholder
        style={{
          width: `${width}px`,
          height: `${height}px`,
          padding: `5px`,
        }}
      >
        {types.includes(TimelineType.VEHICLE_PREMIUM) ? (
          <>
            <Checkbox
              label={t("bfm.showbookings.label")}
              checked={showBookings}
              styles={{
                root: {
                  marginLeft: mainTimelineX + timezoneX,
                  display: "inline-block",
                },
              }}
              onChange={(ev) => dispatch(setShowBookings(!showBookings))}
            />
            <Checkbox
              label={t("bfm.showbookingsDocumentDate.label")}
              disabled={!showBookings}
              checked={showBookingsWithDocumentDate}
              styles={{
                root: {
                  marginLeft: 30,
                  display: "inline-block",
                },
              }}
              onChange={(ev) =>
                dispatch(
                  setShowBookingsWithDocumentDate(!showBookingsWithDocumentDate)
                )
              }
            />
            {route === "editVehicle" ? (
              <TooltipHost
                content={t("bfm.fleet.action.recalculateProRataAndBookings")}
              >
                <ActionButton
                  styles={{
                    root: {
                      marginLeft: `20px`,
                      fontWeight: "inherit",
                    },
                  }}
                  iconProps={{
                    iconName: "Calculator",
                    styles: {
                      root: {
                        fontWeight: "bold",
                      },
                    },
                  }}
                  text={t("bfm.recalculateProRataAndBookings.label")}
                  onClick={() => {
                    dispatch(
                      setDialog({
                        type: "recalculateProRataAndBookings",
                        isOpen: true,
                        title: t(
                          "bfm.fleet.action.recalculateProRataAndBookings"
                        ),
                        icon: "Calculator",
                      })
                    );
                  }}
                />
              </TooltipHost>
            ) : null}
          </>
        ) : null}
        <Dialog />

        <SvgBackground width={width} height={height}>
          <SvgPatternDefinitions />
          <g transform={`translate(${mainTimelineX},${mainTimelineY})`}>
            <MainTimeline
              x={timezoneX}
              y={timezoneY}
              timezoneWidth={timezoneWidth}
              timezoneHeight={timezoneHeight}
              xScale={xScaleFiltered}
              yScale={yScale}
              showBookings={showBookings}
            />
            <PeriodSelector
              x={timezoneX}
              y={timezoneY + timezoneHeight + timezoneRowHeight}
              timezoneWidth={timezoneWidth}
              timezoneHeight={timezoneRowHeight}
              xScale={xScale}
            />
          </g>
          {types.includes(TimelineType.VEHICLE_PREMIUM) ? (
            <g transform={`translate(${prorataTableX},${prorataTableY})`}>
              <ProrataTable
                yScale={yScale}
                timezoneRowHeight={timezoneRowHeight}
              />
            </g>
          ) : null}
        </SvgBackground>
      </TimelinePlaceholder>

      <Accordion
        title={t("bfm.legend")}
        defaultCollapsed={true}
        isDark={isDarkMode}
      >
        <div>
          <SvgBackground width={"100%"} height={dynamicHeight}>
            <SvgPatternDefinitions />
            <g transform={`translate(${mainTimelineX},${mainTimelineY})`}>
              <Legend x={timezoneX} width={width} />
            </g>
          </SvgBackground>
        </div>
      </Accordion>

      <ContextualMenu
        items={contextMenuItems}
        hidden={!contextMenu.isOpen}
        target={contextMenu.ref}
        onItemClick={onHideContextMenu}
        onDismiss={onHideContextMenu}
      />
      {isDialogOpen && <TimelineDialog />}
      {isChangeStatusDialogOpen && <ChangeVehicleStatusDialog />}
      {isLoading && <FormSpinner />}

      {/* {isModifyStatusDialogOpen && <ModifyStatusPointDialog />}
      {isChangeStatusDialogOpen && <ChangeVehicleStatusDialog />} */}
    </div>
  );
};
