import { UseQueryResult, useQuery } from "react-query";
import { getInsurers } from "store/api/api";
import { InsurerSettings } from "types/types";
import { QUERY_KEYS } from "../queryKeys";

const getInsurerList = async () => {
  const res = await getInsurers();
  return res?.data;
};

export function useGetInsurerList(): UseQueryResult<InsurerSettings[]> {
  return useQuery([QUERY_KEYS.insurerListSettings], getInsurerList, {
    staleTime: 3_600_000, //1 hour
    enabled: true,
  });
}
