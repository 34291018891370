import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ApplicationReportDialogComponent } from "components/ApplicationReportDialogComponent";
import { ChangeVehicleIssuingStatusDialogComponent } from "components/ChangeVehicleIssuingStatusDialogComponent/ChangeVehicleIssuingStatusDialogComponent";
import { ChangeVehiclePendingIssuingStatusDialogComponent } from "components/ChangeVehiclePendingIssuingStatusDialogComponent/ChangeVehiclePendingIssuingStatusDialogComponent";
import { ExportExcelVehicles } from "components/ExportExcelVehicles";
import { ProrataReportDialog } from "components/ProrataReportDialog";
import { useCurrentRoute } from "config/routes";
import moment from "moment";
import { CancelStatusDialog } from "pages/fleet/CancelStatusDialog";
import { ChangeVehicleStatusDialog } from "pages/fleet/ChangeVehicleStatusDialog";
import { DeleteDialog } from "pages/fleet/DeleteDialog";
import { DeleteTEMPDialog } from "pages/fleet/DeleteTEMPDialog";
import { DuplicateWithPremiumsDialog } from "pages/fleet/DuplicateWithPremiumsDialog";
import { defaultColumns } from "pages/fleet/VehicleListTable/columns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { fleetFormSlice, loadFleetWithInsurersActions } from "store/fleetForm";
import {
  fleetPageSlice,
  loadVehiclesActions,
  selectAllVehicles,
} from "store/fleetPage";
import { useSelector } from "store/hooks";
import { setIsTradeLicensePlate } from "store/slices/tradeLicensePlateSlice";
import { AppDispatch } from "store/store";
import { sanitizeFileName } from "utils/utils";
import VehicleListTable from "../fleet/VehicleListTable/VehicleListTable";
import TradeLicensePlatesNavigation from "./TradeLicensePlatesNavigation";
import TradeLicensePlatesToolbar from "./TradeLicensePlatesToolbar";

const canHave = [
  "vehicleStatusCode",
  //"certificateNumber",
  "costCenterClient",
  "registrationDate",
  "licensePlate",
  "options",
  "insuranceLineIcons",
  "clientComment",
  "registrationDate",
  //"vehicleCreatedAt",
  "vehicleLastModifiedAt",
  "vehicleLastStatusDate",
  "totalAnnualGrossPremium",
  "totalMtplRelatedAnnualGrossPremium",
  "totalProRataGrossPremium",
  "totalMtplRelatedProRataGrossPremium",
  //"hullAnnualGrossPremium",
  "totalBookingAmount",
  "mtplRelatedBookingAmount",
  //"hullRelatedBookingAmount",
];

const TradeLicensePlates = () => {
  const dispatch: AppDispatch = useDispatch();
  const vehicles = useSelector(selectAllVehicles);
  const { fleetId } = useParams() as any;
  const refExcelExport = useRef<ExcelExport>();
  const sort = useSelector((s) => s.fleetPage.sort);
  const fleet = useSelector((s) => s.fleetPage.fleet);
  const route = useCurrentRoute();
  const isTradeLicensePlatesRoute = route === "tradeLicensePlates";
  const { t } = useTranslation();

  const [clearAllSelectedVehicles, setClearAllSelectedVehicles] =
    useState(false);

  const tradeDetailsListColumns = defaultColumns
    .filter((item) => canHave.includes(item.key))
    .map((column) => {
      let iconName = column.iconName;
      if (column.key === sort.key && column.isSortable) {
        iconName =
          sort.key === column.key
            ? sort!.dir === "asc"
              ? "SortUp"
              : "SortDown"
            : "Sort";
      }
      return {
        ...column,
        name: t(column?.name),
        iconName,
      };
    });
  const isChangeStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehicleStatusDialog.isOpen
  );
  const isChangeIssuingStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehicleIssuingStatusDialog.isOpen
  );
  const isChangePendingIssuingStatusDialogOpen = useSelector(
    (s) => s.fleetPage.changeVehiclePendingIssuingStatusDialog.isOpen
  );
  const isCancelStatusDialogOpen = useSelector(
    (s) => s.fleetPage.cancelVehicleStatusDialog.isOpen
  );
  const isProrataReportDialogOpen = useSelector(
    (s) => s.fleetPage.prorataReportDialog.isOpen
  );
  const isApplicationReportDialogOpen = useSelector(
    (s) => s.fleetPage.applicationReportDialog.isOpen
  );
  const fleetName = useSelector((s) => s.fleetPage.fleet?.fleetName);
  const fleetNameFromForm = useSelector(
    (s) => s.fleetForm.fleetWithPremiums?.fleetName
  );

  const isDuplicateVehicleWithPremiumsDialogOpen = useSelector(
    (s) => s.fleetPage.duplicateVehicleWithPremiumsDialog.isOpen
  );
  const fileName = useMemo(() => {
    return sanitizeFileName(
      "TLP_" +
        (fleetNameFromForm ?? "List") +
        moment(new Date()).format("_YYYY_MM_DD_HH_mm_ss")
    );
  }, [fleetNameFromForm]);

  const columnsToPassToExcel = useMemo(() => {
    return tradeDetailsListColumns.filter(
      (c) => c.isVisible && c.key !== "fleetName"
    );
  }, [tradeDetailsListColumns]);

  useEffect(() => {
    dispatch(setIsTradeLicensePlate(true));

    return () => {
      dispatch(setIsTradeLicensePlate(false));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(fleetPageSlice.actions.resetState());
      dispatch(fleetFormSlice.actions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!fleetName && !fleetNameFromForm) {
      dispatch(
        loadFleetWithInsurersActions.trigger({ fleetId, getById: true })
      );
    }
  }, [dispatch, fleetId, fleetName, fleetNameFromForm]);

  useEffect(() => {
    dispatch(
      loadVehiclesActions.trigger({
        isReset: true,
        shouldDelay: false,
      })
    );
  }, [dispatch, fleetId]);

  return (
    <div>
      <TradeLicensePlatesNavigation refExcelExport={refExcelExport.current} />
      <TradeLicensePlatesToolbar
        setClearAllSelectedVehicles={setClearAllSelectedVehicles}
      />
      <VehicleListTable
        items={vehicles}
        columns={tradeDetailsListColumns}
        setClearAllSelectedVehicles={setClearAllSelectedVehicles}
        clearAllSelectedVehicles={clearAllSelectedVehicles}
      />
      <ExportExcelVehicles
        ref={refExcelExport}
        columns={columnsToPassToExcel}
        fileName={fileName}
      />
      {isTradeLicensePlatesRoute && <DeleteTEMPDialog />}
      {isTradeLicensePlatesRoute && <DeleteDialog />}
      {isChangeIssuingStatusDialogOpen && isTradeLicensePlatesRoute && (
        <ChangeVehicleIssuingStatusDialogComponent />
      )}
      {isChangePendingIssuingStatusDialogOpen && isTradeLicensePlatesRoute && (
        <ChangeVehiclePendingIssuingStatusDialogComponent />
      )}
      {isChangeStatusDialogOpen && isTradeLicensePlatesRoute && (
        <ChangeVehicleStatusDialog />
      )}
      {isDuplicateVehicleWithPremiumsDialogOpen && (
        <DuplicateWithPremiumsDialog />
      )}
      {isCancelStatusDialogOpen && isTradeLicensePlatesRoute && (
        <CancelStatusDialog />
      )}
      {isProrataReportDialogOpen && isTradeLicensePlatesRoute && (
        <ProrataReportDialog />
      )}
      {isApplicationReportDialogOpen && isTradeLicensePlatesRoute && (
        <ApplicationReportDialogComponent />
      )}
    </div>
  );
};

export default TradeLicensePlates;
