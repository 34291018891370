import { createSelector, createSlice } from "@reduxjs/toolkit";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import {
  Taxonomy,
  TaxonomyResponse,
  TaxonomyUADResponse,
} from "../types/types";
import * as API from "./api/api";
import { LoadStatus, RootState } from "./store";
import { createAsyncRoutine, handleAxiosError } from "./util";

export type TaxonomyState = TaxonomyTaxonomyState & {
  loadTaxonomyStatus: LoadStatus;
  loadTaxonomyUADStatus: LoadStatus;
};

export type TaxonomyTaxonomyState = {
  Country: TaxonomyGroupState;
  PaymentMethod: TaxonomyGroupState;
  PremiumPaymentMean: TaxonomyGroupState;
  VehicleBrand: TaxonomyGroupState;
  VehicleClass: TaxonomyGroupState;
  VehiclePowerType: TaxonomyGroupState;
  VehicleStatusCode: TaxonomyGroupState;
  VehicleBodyType: TaxonomyGroupState;
  TruckMounting: TaxonomyGroupState;
  VehicleIssuingStatusCode: TaxonomyGroupState;
  VehicleTypeLegal: TaxonomyGroupState;
  VehicleMainGroup: TaxonomyGroupState;
  VehicleUsageType: TaxonomyGroupState;
  ApplicationCode: TaxonomyGroupState;
  ApplicationSetting: TaxonomyGroupState;
  Gender: TaxonomyGroupState;
  SupportedLanguage: TaxonomyGroupState;
  LanguageListType: TaxonomyGroupState;
  VehicleIssuingStatus: TaxonomyGroupState;
  VehicleIssuingSubstatus: TaxonomyGroupState;
  ListPriceType: TaxonomyGroupState;
  OneTimePremiumType: TaxonomyGroupState;
  ReasonForFleetModification: TaxonomyGroupState;
  PremiumType: TaxonomyGroupState;
  ReasonForMotorTaxExclusion: TaxonomyGroupState;
  WeightCapacityKgRange: TaxonomyGroupState;
  MaxGrossWeightKgRange: TaxonomyGroupState;
  PowerKwRangeHg2: TaxonomyGroupState;
  PowerKwRangeHg5: TaxonomyGroupState;
  PremiumBookingReason: TaxonomyGroupState;
  CubicCapacityCcmRangeHg1: TaxonomyGroupState;
  NumberOfSeatsMoped: TaxonomyGroupState;
  NumberOfSeatsRange: TaxonomyGroupState;
  response: TaxonomyResponse;
};

export const loadTaxonomyActions = createAsyncRoutine<
  void,
  void,
  TaxonomyResponse,
  any
>("taxonomy/load");

export const loadTaxonomyUADActions = createAsyncRoutine<
  void,
  void,
  TaxonomyUADResponse,
  any
>("taxonomy/loadUAD");

export function* triggerLoadTaxonomySaga(
  a: ReturnType<typeof loadTaxonomyActions.trigger>
) {
  try {
    yield put(loadTaxonomyActions.loading());
    const res = yield call(API.getTaxonomy);

    yield put(loadTaxonomyActions.success(res.data));
  } catch (err) {
    yield put(loadTaxonomyActions.error(err));
    handleAxiosError(err);
  }
}

export function* triggerLoadTaxonomyUADSaga(
  a: ReturnType<typeof loadTaxonomyUADActions.trigger>
) {
  try {
    yield put(loadTaxonomyUADActions.loading());
    const res = yield call(API.getUADTaxonomy);
    yield put(loadTaxonomyUADActions.success(res.data));
  } catch (err) {
    yield put(loadTaxonomyUADActions.error(err));
    handleAxiosError(err);
  }
}

export const taxonomySaga = function* () {
  yield fork(function* () {
    yield takeEvery(loadTaxonomyActions.trigger.type, triggerLoadTaxonomySaga);
  });
  yield fork(function* () {
    yield takeEvery(
      loadTaxonomyUADActions.trigger.type,
      triggerLoadTaxonomyUADSaga
    );
  });
};

const initialTaxonomyGroupState: TaxonomyGroupState = {
  byId: {},
  byCode: {},
  items: [],
};

export const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState: {
    Country: initialTaxonomyGroupState,
    PaymentMethod: initialTaxonomyGroupState,
    VehicleBrand: initialTaxonomyGroupState,
    VehicleClass: initialTaxonomyGroupState,
    VehiclePowerType: initialTaxonomyGroupState,
    VehicleStatusCode: initialTaxonomyGroupState,
    VehicleIssuingStatusCode: initialTaxonomyGroupState,
    VehicleTypeLegal: initialTaxonomyGroupState,
    VehicleUsageType: initialTaxonomyGroupState,
    VehicleIssuingStatus: initialTaxonomyGroupState,
    VehicleIssuingSubstatus: initialTaxonomyGroupState,
    ListPriceType: initialTaxonomyGroupState,
    OneTimePremiumType: initialTaxonomyGroupState,
    ApplicationCode: initialTaxonomyGroupState,
    ApplicationSetting: initialTaxonomyGroupState,
    Gender: initialTaxonomyGroupState,
    LanguageListType: initialTaxonomyGroupState,
    SupportedLanguage: initialTaxonomyGroupState,
    ReasonForFleetModification: initialTaxonomyGroupState,
    PremiumType: initialTaxonomyGroupState,
    ReasonForMotorTaxExclusion: initialTaxonomyGroupState,
    WeightCapacityKgRange: initialTaxonomyGroupState,
    PremiumBookingReason: initialTaxonomyGroupState,
    NumberOfSeatsMoped: initialTaxonomyGroupState,
    NumberOfSeatsRange: initialTaxonomyGroupState,
    loadTaxonomyStatus: "none",
    loadTaxonomyUADStatus: "none",
    response: {},
  } as TaxonomyState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTaxonomyActions.loading, (s, a) => {
      s.loadTaxonomyStatus = "loading";
    });
    builder.addCase(loadTaxonomyActions.success, (s, a) => {
      const taxonomyResponse = a.payload;
      s.loadTaxonomyStatus = "success";
      createTaxonomyGroupsState(taxonomyResponse, s);
      s.response = taxonomyResponse;
    });
    builder.addCase(loadTaxonomyActions.error, (s, a) => {
      s.loadTaxonomyStatus = "error";
    });
    builder.addCase(loadTaxonomyUADActions.loading, (s, a) => {
      s.loadTaxonomyUADStatus = "loading";
    });
    builder.addCase(loadTaxonomyUADActions.success, (s, a) => {
      const taxonomyResponse = a.payload;
      s.loadTaxonomyUADStatus = "success";
      createTaxonomyGroupsState(taxonomyResponse, s);
    });
    builder.addCase(loadTaxonomyUADActions.error, (s, a) => {
      s.loadTaxonomyUADStatus = "error";
    });
  },
});

const createTaxonomyGroupsState = (
  res: { [key: string]: any },
  state: TaxonomyState
) => {
  Object.entries(res).forEach(([key, taxonomyGroup]) => {
    const taxonomyStateByTaxonomy = {
      byId: taxonomyGroup.reduce((byId: any, t: any) => {
        byId[t.id] = t;
        return byId;
      }, {} as ByIdStateByTaxonomy),
      byCode: taxonomyGroup.reduce((byCode: any, t: any) => {
        byCode[t.code] = t;
        return byCode;
      }, {} as ByCodeStateByTaxonomy),
      items: taxonomyGroup,
    };
    state[key] = taxonomyStateByTaxonomy;
  });
};

export const taxonomyReducer = taxonomySlice.reducer;

export const {} = taxonomySlice.actions;

type ByIdStateByTaxonomy = { [id: number]: Taxonomy };
type ByCodeStateByTaxonomy = { [code: string]: Taxonomy };

type TaxonomyGroupState = {
  [x: string]: any;
  byId: ByIdStateByTaxonomy;
  byCode: ByCodeStateByTaxonomy;
  items: Taxonomy[];
};

export type TaxonomyKey =
  | "Country"
  | "PaymentMethod"
  | "PremiumPaymentMean"
  | "VehicleBrand"
  | "VehicleClass"
  | "VehiclePowerType"
  | "VehicleStatusCode"
  | "VehicleIssuingStatusCode"
  | "VehicleTypeLegal"
  | "VehicleMainGroup"
  | "VehicleUsageType"
  | "VehicleIssuingStatus"
  | "VehicleIssuingSubstatus"
  | "ListPriceType"
  | "OneTimePremiumType"
  | "ReasonForFleetModification"
  | "PremiumType"
  | "ReasonForMotorTaxExclusion"
  | "WeightCapacityKgRange"
  | "MaxGrossWeightKgRange"
  | "PowerKwRangeHg2"
  | "PremiumBookingReason"
  | "NumberOfSeatsMoped"
  | "NumberOfSeatsRange"
  | "CubicCapacityCcmRangeHg1"
  | "VehicleBodyType"
  | "TruckMounting";

export const selectTaxonomy = (s: RootState) => s.taxonomy;

export const selectTaxonomyByCode = createSelector(
  (s) => s.taxonomy,
  (taxonomy) => (key, code) => {
    return taxonomy[key].byCode[code];
  }
);
