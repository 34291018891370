import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Client } from "../types/types";

import { LoadStatus } from "./store";

export type FilterState = {
  clientList: null | Client[];
  selectedClients: number[];
  selectedMainIds: number[];
  loadClientsStatus: LoadStatus;
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    clientList: null,
    loadClientsStatus: "none",
    selectedClients: null,
    selectedMainIds: null,
  } as FilterState,
  reducers: {
    setSelectedClients: (
      s,
      a: PayloadAction<FilterState["selectedClients"]>
    ) => {
      s.selectedClients = a.payload;
    },
    setSelectedMainIds: (
      s,
      a: PayloadAction<FilterState["selectedMainIds"]>
    ) => {
      s.selectedMainIds = a.payload;
    },
  },
});

export const {
  actions: { setSelectedClients, setSelectedMainIds },
  reducer: filterReducer,
} = filterSlice;
