import React, { useMemo, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelectedClients, setSelectedMainIds } from "store/filter";
import { useSelector } from "store/hooks";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import "./ClientFilter.css";
import { useGetClientList } from "hooks/data/queries/useGetClientList";

export const ClientFilter = () => {
  const dispatch = useDispatch();

  const { data: clientList } = useGetClientList();

  const selectedClients = useSelector((s) => s.filter.selectedClients);
  const [dataTable, setDataTable] = useState([]);
  const { t } = useTranslation();

  const theme = useTheme() as IStyledTheme;
  const isDarkMode = theme.isDark;

  const findAllNodes = (retVal, nodes) => {
    if (nodes) {
      nodes?.forEach((element) => {
        retVal.push(element.value);
        findAllNodes(retVal, element.children);
      });
    }
  };
  const onChange = (currentNode, selectedNodes) => {
    const retVal = [];
    const mappetList = selectedNodes.map((e) => dataTable[e.value]);
    const mappedMainIds = mappetList.map((e) => e.value);
    findAllNodes(retVal, mappetList);
    dispatch(setSelectedClients(retVal));
    dispatch(setSelectedMainIds(mappedMainIds));
  };

  const clientTree = useMemo(() => {
    const createDataTree = (dataset) => {
      if (!dataset) return [];
      const hashTable = Object.create(null);
      const reformated = dataset.map((item) => {
        return {
          label: item.clientName + " (" + item.clientInternalNumber + ")",
          value: item.clientInternalNumber,
          parent: item.parentClientInternalNumber,
          checked: selectedClients?.includes(item.clientInternalNumber),
          expanded: selectedClients?.includes(item.clientInternalNumber),
        };
      });
      reformated.forEach(
        (aData) => (hashTable[aData.value] = { ...aData, children: [] })
      );
      const dataTree = [];
      reformated.forEach((aData) => {
        if (aData.parent)
          hashTable[aData.parent].children.push(hashTable[aData.value]);
        else dataTree.push(hashTable[aData.value]);
      });
      setDataTable(hashTable);
      return dataTree;
    };

    const data = createDataTree(clientList);
    return data;
  }, [clientList, selectedClients]);

  let className = isDarkMode ? "dark" : "light";

  return (
    <DropdownTreeSelect
      className={className}
      data={clientTree}
      onChange={onChange}
      keepTreeOnSearch={true}
      keepChildrenOnSearch={true}
      keepOpenOnSelect={true}
      texts={{
        placeholder:
          selectedClients && selectedClients.length > 0
            ? "  " +
              t("bfm.fleetForm.SearchForClient.numSelected.label", {
                numClients: selectedClients.length,
              })
            : "  " + t("bfm.fleetForm.SearchForClient.label") + "...",
        noMatches: t("greco.noResults"),
      }}
    />
  );
};
