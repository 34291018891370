import {
  Checkbox,
  DefaultButton,
  DialogFooter,
  mergeStyleSets,
} from "@fluentui/react";
import ComboBoxField from "components/controls/ComboBoxField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import { EActionDialog } from "pages/fleet-form/FleetForm/constants";
import VehicleTypeDependencyField from "pages/fleet-form/actionDialogs/VehicleTypeDependencyField";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import { FleetHullCalculationDefaultSettingType } from "types/types";

const HullPremiumCalculationFormFields = ({
  settingId,
  fieldProps,
  defaultData,
  insurerOptions,
  carUsageOptions,
  vehicleTypeCodeOptions,
  isLoading,
  premiumTypeOptions,
  isTradeLicensePlate,
  setTradeLicensePlate,
  listPriceTypeOptions,
  setIsDirty,
}) => {
  const classes = getClassNames();
  const { setFieldValue, values, errors, handleChange, dirty } = fieldProps;
  const { t } = useTranslation();

  const isReadOnly =
    useSelector((s) => s.fleetForm.dialog.type) === EActionDialog.ReadOnly;

  const valueTypeOptions = [
    {
      value: FleetHullCalculationDefaultSettingType.PREMIUM_RATE_PERCENTAGE,
      label: t("bfm.hullPremiumCalculation.premiumRatePercentage.label"),
    },
    {
      value: FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE,
      label: t("bfm.hullPremiumCalculation.absoulutePremiumValue.label"),
    },
  ];
  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem}>
          <Checkbox
            id="isTradeLicensePlate"
            label={t("bfm.tradeLicensePlates.label")}
            styles={{
              root: {
                fontWeight: 600,
              },
            }}
            checked={isTradeLicensePlate}
            disabled={settingId || isReadOnly}
            onChange={(ev) => {
              setFieldValue("vehicleType", null);
              setFieldValue("carUsage", null);
              setTradeLicensePlate(!isTradeLicensePlate);
            }}
          />
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem}>
          <ComboBoxField
            id="valueType"
            disabled={isReadOnly}
            errors={errors}
            name="valueType"
            required
            label={"bfm.fleet.valueType.label"}
            setFieldValue={setFieldValue}
            options={valueTypeOptions || []}
            defaultValue={
              values?.valueType && typeof values?.valueType === "string"
                ? valueTypeOptions.find((e) => e.value === values?.valueType)
                : values?.valueType
            }
            confidentiality={null}
          />
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <DoubleNumberInputField
            maxDecimals={4}
            id="premiumRatePercentage"
            setFieldValue={setFieldValue}
            value={values.premiumRatePercentage}
            onChange={handleChange}
            errors={errors}
            required
            name={"premiumRatePercentage"}
            disabled={isReadOnly}
            label={"bfm.fleet.setting.attributeValue.label"}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <DoubleNumberInputField
            id="absoulutePremiumValue"
            setFieldValue={setFieldValue}
            required
            value={values.premiumFreeSpecialEquipment}
            onChange={handleChange}
            errors={errors}
            name={"premiumFreeSpecialEquipment"}
            disabled={isReadOnly}
            label={
              "bfm.hullPremiumCalculation.premiumFreeSpecialEquipment.label"
            }
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <DoubleNumberInputField
            id="gap"
            setFieldValue={setFieldValue}
            value={values.gap}
            onChange={handleChange}
            errors={errors}
            required
            name={"gap"}
            disabled={isReadOnly}
            label={"bfm.hullPremiumCalculation.gap.label"}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <DoubleNumberInputField
            id="replacementValue"
            setFieldValue={setFieldValue}
            value={values.replacementValue}
            onChange={handleChange}
            required
            errors={errors}
            name={"replacementValue"}
            disabled={isReadOnly}
            label={"bfm.hullPremiumCalculation.replacementValue.label"}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <DoubleNumberInputField
            id="grossNegligence"
            setFieldValue={setFieldValue}
            value={values.grossNegligence}
            onChange={handleChange}
            errors={errors}
            required
            name={"grossNegligence"}
            disabled={isReadOnly}
            label={"bfm.hullPremiumCalculation.grossNegligence.label"}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <DoubleNumberInputField
            id="electroPlusPackage"
            setFieldValue={setFieldValue}
            value={values.electroPlusPackage}
            onChange={handleChange}
            errors={errors}
            required
            name={"electroPlusPackage"}
            disabled={isReadOnly}
            label={"bfm.hullPremiumCalculation.electroPlusPackage.label"}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <DoubleNumberInputField
            id="discountForWaiverOfTaxRefund"
            setFieldValue={setFieldValue}
            value={values.discountForWaiverOfTaxRefund}
            onChange={handleChange}
            required
            errors={errors}
            name={"discountForWaiverOfTaxRefund"}
            disabled={isReadOnly}
            label={
              "bfm.hullPremiumCalculation.discountForWaiverOfTaxRefund.label"
            }
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <ComboBoxField
            id="premiumType"
            disabled={!!settingId || isReadOnly}
            errors={errors}
            name="premiumType"
            required
            label={"bfm.fleet.premiumType.label"}
            setFieldValue={setFieldValue}
            options={premiumTypeOptions || []}
            defaultValue={values?.premiumType}
            confidentiality={null}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          <ComboBoxField
            id="listPriceType"
            disabled={!!settingId || isReadOnly}
            errors={errors}
            name="listPriceType"
            required
            label={"bfm.vehicleForm.listPriceTypeCode.label"}
            setFieldValue={setFieldValue}
            options={listPriceTypeOptions || []}
            defaultValue={values?.listPriceType}
            confidentiality={null}
          />
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          <ComboBoxField
            id="insurerPartnerNumber"
            disabled={!!settingId || isReadOnly}
            errors={errors}
            required
            name="insurerPartnerNumber"
            label={"bfm.insurer.label"}
            setFieldValue={setFieldValue}
            options={insurerOptions || []}
            defaultValue={values?.insurerPartnerNumber}
            confidentiality={null}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          className={classes.fieldItem}
          style={{ display: "flex", gap: "15px", marginRight: "10px" }}
        >
          <div style={{ width: "50%" }}>
            <DoubleNumberInputField
              id="listPriceMin"
              setFieldValue={setFieldValue}
              required={
                values.listPriceMin !== "" || values.listPriceMax !== ""
              }
              value={values.listPriceMin}
              onChange={handleChange}
              errors={errors}
              name={"listPriceMin"}
              disabled={!!settingId || isReadOnly}
              label={"bfm.listPriceMin.label"}
            />
          </div>
          <div style={{ width: "50%" }}>
            <DoubleNumberInputField
              id="listPriceMax"
              setFieldValue={setFieldValue}
              required={
                values.listPriceMin !== "" || values.listPriceMax !== ""
              }
              value={values.listPriceMax}
              onChange={handleChange}
              errors={errors}
              name={"listPriceMax"}
              disabled={!!settingId || isReadOnly}
              label={"bfm.listPriceMax.label"}
            />
          </div>
        </div>
        <div className={classes.fieldItem} style={{ marginLeft: "10px" }}>
          {!isTradeLicensePlate ? (
            <ComboBoxField
              id="carUsage"
              disabled={!!settingId || isReadOnly}
              errors={errors}
              name="carUsage"
              label={"bfm.vehicleForm.carUsageCode.label"}
              setFieldValue={setFieldValue}
              options={carUsageOptions || []}
              defaultValue={values?.carUsage}
              confidentiality={null}
            />
          ) : null}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.fieldItem} style={{ marginRight: "10px" }}>
          {!isTradeLicensePlate ? (
            <ComboBoxField
              id="vehicleType"
              disabled={!!settingId || isTradeLicensePlate || isReadOnly}
              errors={errors}
              name="vehicleType"
              required={!isTradeLicensePlate}
              label={"bfm.vehicleForm.vehicleTypeCode.label"}
              setFieldValue={setFieldValue}
              options={vehicleTypeCodeOptions || []}
              defaultValue={values?.vehicleType}
              confidentiality={null}
            />
          ) : null}
        </div>
        <div
          className={classes.fieldItem}
          style={{ marginTop: "0px", marginLeft: "10px" }}
        ></div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <VehicleTypeDependencyField
          values={values}
          vehicleTypeSelected={values?.vehicleType}
          fieldItemStyle={classes.fieldItem}
          errors={errors}
          setFieldValue={setFieldValue}
          selectedDependency={defaultData}
          disabled={!!settingId || isReadOnly}
        />
      </div>
      <div className={classes.placeholder}></div>
      {!isReadOnly && (
        <DialogFooter className={classes.footer}>
          <DefaultButton
            id="saveButton"
            primary
            text={t("greco.save")}
            type="submit"
            iconProps={{
              iconName: "Save",
            }}
            disabled={isLoading}
          />
        </DialogFooter>
      )}
    </>
  );
};

const getClassNames = () =>
  mergeStyleSets({
    placeholder: {
      height: 200,
    },
    fieldItem: {
      width: "100%",
      marginTop: 10,
    },
    footer: {
      marginTop: 50,
    },
  });

export default HullPremiumCalculationFormFields;
