import { Insurer, InsurerSettings } from "types/types";

export const convertFormToRequestObject = (
  data: any,
  selectedInsurer: Insurer,
  taxonomy,
  premiumTypes,
  isEditInsurer,
  insurer: InsurerSettings
): any => {
  let retVal: any = {
    insurerPartnerNumber: parseInt(selectedInsurer.insurerInternalNumber + ""),
    suspensionDays: parseInt(data.suspensionDays),
    activationDays: parseInt(data.activationDays),
    garageRiskDiscountPercentHull: parseFloat(
      data.garageRiskDiscountPercentHull.replace(",", ".")
    ),
    garageRiskDiscountPercentHullBasic: parseFloat(
      data.garageRiskDiscountPercentHullBasic.replace(",", ".")
    ),
    insurerSettingPerPremiumTypeAndVehicleMainGroupDetails: [],
  };
  if (isEditInsurer) {
    retVal = {
      ...retVal,
      id: insurer.id,
      rowVersion: insurer.rowVersion,
    };
  }
  let index = 0;
  taxonomy?.VehicleMainGroup?.items.forEach((item1, index1) => {
    premiumTypes.forEach((item2, index2) => {
      const premiumType = taxonomy?.PremiumType?.items.find(
        (item3) => item2 === item3.code
      );
      let itemElement: any = {
        premiumTypeCode: premiumType.id,
        vehicleMainGroupCode: item1.id,
        suspensionDaysLimit: parseInt(data.suspensionDaysLimit[index]),
      };
      if (isEditInsurer) {
        const el =
          insurer.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.find(
            (det) =>
              det.premiumTypeCode === itemElement.premiumTypeCode &&
              det.vehicleMainGroupCode === itemElement.vehicleMainGroupCode
          );
        itemElement = {
          ...itemElement,
          id: el.id,
        };
      }
      retVal.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.push(
        itemElement
      );
      index++;
    });
  });

  return retVal;
};

export const convertInsurerToFormObject = (
  insurer: InsurerSettings,
  taxonomy,
  premiumTypes
): any => {
  let retVal = {
    garageRiskDiscountPercentHull: (
      insurer?.garageRiskDiscountPercentHull + ""
    ).replace(".", ","),
    garageRiskDiscountPercentHullBasic: (
      insurer?.garageRiskDiscountPercentHullBasic + ""
    ).replace(".", ","),
    suspensionDays: insurer?.suspensionDays + "",
    activationDays: insurer?.activationDays + "",
    suspensionDaysLimit: [],
  };

  taxonomy?.VehicleMainGroup?.items.forEach((item1) => {
    premiumTypes.forEach((item2) => {
      const premiumType = taxonomy?.PremiumType?.items.find(
        (item3) => item2 === item3.code
      );
      const item =
        insurer.insurerSettingPerPremiumTypeAndVehicleMainGroupDetails.find(
          (setting) =>
            setting.premiumTypeCode === premiumType.id &&
            setting.vehicleMainGroupCode === item1.id
        );
      retVal.suspensionDaysLimit.push(item?.suspensionDaysLimit + "");
    });
  });

  return retVal;
};
