import { useCurrentRoute } from "config/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadInsurerSettingsActions } from "store/fleetForm";
import { useSelector } from "../../../store/hooks";

export const useFetchInsurerSettings = () => {
  const insurerSettingsExist = useSelector((s) => s.fleetForm.insurerSettings);
  const dispatch = useDispatch();
  const route = useCurrentRoute();

  useEffect(() => {
    if (!insurerSettingsExist) {
      dispatch(loadInsurerSettingsActions.trigger());
    }
  }, [insurerSettingsExist, route]);
};
