import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Timeline } from "../../../components/timeline/Timeline";
import { setTimelineInitValues } from "../../../store/timeline";
import { TimelineType } from "../../../types/types";
import FinanceForm from "./FinanceForm/FinanceForm";
import FinancePreview from "./FinancePreview";

const FinanceSection = ({
  vehicle,
  route,
  defaultSettings,
  initialValues,
  formikProps,
}) => {
  const isReadOnlyMode = route === "readOnlyVehicle";

  const dispatch = useDispatch();

  useEffect(() => {
    if (route !== "editTempVehicle")
      dispatch(
        setTimelineInitValues({
          types: [
            TimelineType.VEHICLE_PREMIUM,
            TimelineType.VEHICLE_PREMIUM_BOOKINGS,
            TimelineType.VEHICLE_STATUS,
            TimelineType.VEHICLE_LICENSE_PLATE,
            TimelineType.SHARED_LICENSE_PLATE_STATUS,
          ],
          readOnly: isReadOnlyMode,
        })
      );
  }, [route]);
  return (
    <div>
      {isReadOnlyMode ? (
        <>
          <FinancePreview vehicle={vehicle} route={route} />
          {vehicle && (
            <div
              style={{
                padding: 50,
              }}
            >
              {route !== "editTempVehicle" && <Timeline width={1100} />}
            </div>
          )}
        </>
      ) : (
        <>
          {vehicle &&
            route !== "duplicateVehicle" &&
            route !== "editTempVehicle" && (
              <Timeline width={970} marginLeft={70} />
            )}

          <FinanceForm
            formikProps={formikProps}
            vehicle={vehicle}
            route={route}
          />
        </>
      )}
    </div>
  );
};

export default FinanceSection;
