import React from "react";
import { AxisBottom } from "./AxisBottom";
import { AxisLeft } from "./AxisLeft";
import { PastBackground } from "./PastBackground";
import { BookingSegments } from "./segments/BookingSegments";
import { LicensePlateSegments } from "./segments/LicensePlateSegments";
import { OneTimePremiumSegments } from "./segments/OneTimePremiumSegments";
import { PremiumSegments } from "./segments/PremiumSegments";
import { SharedLicensePlateSegments } from "./segments/SharedLicensePlateSegments";
import { StatusSegments } from "./segments/StatusSegments";

interface IProps {
  x: number;
  y: number;
  timezoneWidth: number;
  timezoneHeight: number;
  xScale: any;
  yScale: any;
  showBookings: boolean;
}

export const MainTimeline = ({
  x,
  y,
  timezoneWidth,
  xScale,
  yScale,
  timezoneHeight,
  showBookings,
}: IProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <PastBackground
        innerHeight={timezoneHeight}
        innerWidth={timezoneWidth}
        xScale={xScale}
      />
      <AxisBottom innerHeight={timezoneHeight} xScale={xScale} />
      <PremiumSegments
        xScale={xScale}
        yScale={yScale}
        innerWidth={timezoneWidth}
      />
      <OneTimePremiumSegments
        xScale={xScale}
        yScale={yScale}
        innerWidth={timezoneWidth}
      />
      <BookingSegments
        xScale={xScale}
        yScale={yScale}
        innerWidth={timezoneWidth}
        showBookings={showBookings}
      />
      <StatusSegments
        xScale={xScale}
        yScale={yScale}
        innerWidth={timezoneWidth}
      />
      <LicensePlateSegments
        xScale={xScale}
        yScale={yScale}
        innerWidth={timezoneWidth}
      />
      <SharedLicensePlateSegments
        xScale={xScale}
        yScale={yScale}
        innerWidth={timezoneWidth}
      />
      <AxisLeft yScale={yScale} />
    </g>
  );
};
